// store/index.js

import Vue from "vue";
import Vuex from "vuex";
import { cloneDeep, forEach, omit } from "lodash";
import { sortByName, getToken } from "@/utils";
import { sortByKeyword } from "@/utils";
import {
  agencyPlans,
  unlimitedServices,
  watchdogOnlyServices,
  agencyPlansWithAdditionalSeatsAvailable,
  correspondingSeatPlans,
} from "@/components/plans_constants";
import { getPureServicePkgName } from "@/utils";

// imports of AJAX functions will go here
import {
  fetchUser,
  fetchSite,
  fetchSites,
  getActiveSiteContentAudit,
  updateSiteContentAudit,
  fetchBulkRun,
  getSilos,
  getSilo,
  createSilo,
  getSiloKeywords,
  getSiloKeyword,
  getSiloPDFReport,
  getSiloWordReport,
  addKeywordToSilo,
  removeKeywordFromSilo,
  restoreSiloKeyword,
  updateSiloKeyword,
  deleteSilo,
  updateSilo,
  getAvailableSiloContentReportPackages,
  getAvailableSiloContentReportDeliveryOptionsForSite,
  getSiloContentReportOrders,
  updateSiloContentReportOrder,
  fetchBulkRuns,
  deleteBulkRun,
  downloadBulkRunZip,
  fetchPages,
  fetchPageRuns,
  fetchPageRunsBySearchReportId,
  fetchPageRunsForExtension,
  fetchPageRunForExtension,
  fetchPageRun,
  fetchPageRunBySecretKeyOrPageRunId,
  fetchLsaPhrases,
  // getGuardReportDiffData,
  createSecretKey,
  fetchData,
  fetchVariations,
  createStripeCardAdditionLink,
  fetchPaymentSources,
  fetchProxies,
  updatePageRunNote,
  fetchInvoiceData,
  authenticate,
  authenticateFromExtension,
  change2FaAuthSetting,
  getLoginPageNotifications,
  emailIsAvailable,
  register,
  registerNoCard,
  saveCompanyDetails,
  setNotificationsAsRead,
  saveAccountName,
  savePaypalEmailForAffiliateCommission,
  deleteCustomLogo,
  sendApiPartnershipContactEmail,
  getPayAmountForPartnerApiKeyUsage,
  addSubAccount,
  updateSubAccount,
  deleteSubAccount,
  deleteSubAccounts,
  getSubAccountActivityLogs,
  addDevAccount,
  deleteDevAccount,
  updateDevCallsTopupSetting,
  createAPIAccessKey,
  leaveMainAccount,
  saveInvoicingEmail,
  changeSiteName,
  changePageName,
  validateSiteDomain,
  createSite,
  createPage,
  createPageRunInBackend,
  generateNewBulkRun,
  updatePageSettings,
  createGuardRunFromSilo,
  updatePagePublishStatus,
  pauseWatchdogSetupForPages,
  cancelWatchdogSetupForPages,
  // updatePagePinStatus,
  createDemoPageRun,
  urlUsedin24Hrs,
  getPageRunStatus,
  markAsReadForRunAutoStatusUpdate,
  getHtmlCountData,
  bulkExcelUploadAndValidate,
  createNewBulkRun,
  cancelBulkRunTask,
  fetchTaskResult,
  submitGoogleSearchTask,
  filterGoogleVariations,
  fetchGoogleSearchLocations,
  downloadExcelReport,
  downloadExcelReportFromUrl,
  fetchKeywordWizardReports,
  updateKeywordWizardReport,
  deleteKeywordWizardReport,
  downloadKeywordResearchReport,
  resetPassword,
  updatePassword,
  changeEmailRequest,
  getAllTasksForPageRun,
  addOneToCheckList,
  fetchValidatedCoupons,
  archiveOneFromCheckList,
  restoreOneFromChecklist,
  archiveAllChecklistWithType,
  addAllToCheckList,
  updateChecklistState,
  updateChecklistDueDate,
  updateChecklistAssignee,
  updateChecklistStateFromExtension,
  createChecklistNote,
  deleteChecklistNote,
  updateChecklistNote,
  updateChecklistNoteFromExtension,
  downloadExcelForChecklist,
  downloadTasksForProgressNote,
  downloadDocxForAiWriter,
  getChecklistForExtension,
  getContentBriefNotes,
  createContentBriefNote,
  updateContentBriefNote,
  deleteContentBriefNote,
  updateContentBriefStatus,
  downloadContentBrief,
  getProratedAmountForPurchase,
  makePurchase,
  createPayPalPayment,
  createStripePayment,
  convertSubscription,
  buyNlpEatPackage,
  buyUniversalCreditsPakcage,
  fetchYearlyUnlimitedConversionDiscount,
  cancelSubscription,
  reactivateSubscription,
  updatePaymentSource,
  setDefaultPaymentSource,
  deletePaymentSource,
  updateCouponCode,
  updatePaypalEmail,
  generateCancellationCode,
  addOpenAiKey,
  getAvailableNlpEatPackages,
  getAvailableUniversalPackages,
  getAvailableAPICreditPackages,
  getAvailableWhiteGloveAddonPackages,
  makeNewPageRun,
  loadApiUsageForUser,
  getUsersForAdmin,
  loadAdminComparisonData,
  downloadAllUserExcel,
  downloadAllSales,
  updateUserAccountForAdmin,
  resendRegistrationEmailForAdmin,
  confirmEmailAsValidated,
  adminResetUsageLimitForUser,
  adminCancelSubscription,
  getTotalUsers,
  getHistoryForAdmin,
  getKeywordResearchForAdmin,
  getSiteContentAuditOrdersForAdmin,
  getSiloOrdersForAdmin,
  getWatchDogTrackHistoryForAdmin,
  getGuardRunsForUser,
  getAIRunsForUser,
  getAIRunsForAdmin,
  getCreditUsageForUser,
  getKeywordResearchCreditUsageForUser,
  getBulkCreditLog,
  getCouponsForAdmin,
  autoGenCouponCode,
  createCouponForAdmin,
  updateCouponForAdmin,
  deleteCouponForAdmin,
  addAliasToCouponForAdmin,
  createAdminUserComment,
  getAllUsersForAdmin,
  getAllNotificationsForAdmin,
  createAdminNotification,
  updateNotificationForAdmin,
  deleteNotificationForAdmin,
  deleteSite,
  deleteSites,
  deletePage,
  deletePages,
  updatePinPageRun,
  deletePageRun,
  deletePageRuns,
  removeTermFromCB,
  recoverTermForCB,
  removeTermsFromCB,
  toggleScoringMethodOfCB,
  setContentBriefCustomWordCount,
  acknowledgeForContentBriefScoreNotChanged,
  sendContactUs,
  getSales,
  getNewUserStats,
  deleteAccount,
  getTotalUsagePerDay,
  getRunsPerDay,
  getAffiliateSales,
  getAffiliatePayouts,
  removeFromPayout,
  executePayout,
  getPaypalRefunds,
  executePaypalRefund,
  testCancelledWatchdogSetup,
  getUserNotifications,
  deleteUserNotification,
  exportInvoices,
  exportRefundInvoices,
  validatePageAnalysisUrls,
  validateUrlForEditor,
  updateUserSettings,
  saveProjectSettings,
  // updateProjectPinStatus,
  saveRevisionHistoryForContentEditor,
  saveEditedSourceCode,
  loadSavedSourceCode,
  loadForSpecificRevisionData,
  getWeeklyPageRunStats,
  unsubscribeWatchdogMadeByAdmin,
  openApiTest,
  getPromptForAI,
  getAnswerWithAI,
  getGenerateSchema,
  getRequestSchema,
  getAIAnswerForContentPrompt,
  loadAiRun,
  aiRunChangeCurrentSelection,
  aiRunSaveEditedContent,
  aiRunChangeCurrentSelectionForRichItem,
  aiRunUpdateEditedContentForRichItem,
  deleteAIRun,
  aiRunGetFullHtml,
  createKeywordResearchCampaign,
  getCampaignById,
  getCampaignLatestGeneratedReports,
  getCampaignsForSite,
  createKeywordInsightResearch,
  getKeywordResearchHistoryForCampaign,
  loadKeywordResearchData,
  updateKeywordsVisibility,
  getKeywordsFromTopic,
  clusteringUserKeywordsExcel,
  createSupportKeywordResearch,
  getSupportKeywordResearchHistoryForCampaign,
  loadSupportKeywordResearchData,
  supportKeywordResearch_analyzeClustersWithAI,
  keywordResearchAnalyzeWithAI,
  updateKeywordLabel,
  saveKeywordWizardResult,
  loadRecentKeywordWizardData,
  loadKeywordWizardMonthlySearchAndExtras,
  loadKeywordWizardLabels,
  wizardUpdateKeywordLabel,
  topLevel_getKeywordsFromTopic,
  topLevel_createSupportKeywordResearch,
  topLevel_analyzeClustersWithAI,
  topLevel_getSupportKeywordResearchHistoryForCampaign,
  topLevel_loadSupportKeywordResearchData,
  addKeywordToTrack,
  bulkTrackKeywordToTrack,
  removeKeywordFromTrack,
  updateTrackEmailNotificationSetting,
  getKeywordTrackForCampaign,
  startKeywordTrack,
  getMonthlySearches,
  updateKeywordResearchReport,
  deleteKeywordResearchReport,
  createKeywordGapAnalysis,
  loadKeywordGapHistory,
  loadOneKeywordGapData,
  playWithOpenAI,
  getProjectsForWorkstation,
  getPagesForWorkstation,
  getPageRunForWorkStation,
  getTaskStatus,
  getActiveTasks,
  acknowledgeTask,
  updateScoringStrategy,
  updateSummaryReportDay,
  neverMindClicked,
  testRunForSummaryReport,
  updateRecommendationsForPage,
  getDemoUserToken,
  updateSiloKeywordFlag,
  resetBalanceForUser,
  fetchPOPCharges,
} from "@/api";
import { isValidJwt, PageRunQueueItem } from "@/utils";
import router from "@/router";
import { KEYWORDS_INSIGHT_CONFIG } from "../components/PageRun/constants";
import { decodeToken } from "../utils";
import { addKeywordsToSilo, fetchUserHasPayments, getReportHeader } from "../api";

Vue.use(Vuex);

const state = {
  // single source of data
  sites: [],
  siteContentAuditsBySiteId: {},
  pages: [],
  bulkRuns: [],
  pageRuns: [],
  currentPageRun: {},
  user: {},
  customLogoBase64: "",
  jwt: "",
  selectedSubAccountRelation: "", // This is for Sub Account
  selectedDevAccountRelation: "",
  queue: [],
  globalErrorMsg: "",
  allUsers: [],
  pageRunActiveTab: "",
  coupons: [],
  currentSiteId: "",
  currentPageId: "",
  schemaProgresses: {},
  modalData: {},

  isUserClosedSideBar: false, // this has sidebar user's change
  isHideSidebar: false, // show/hide sidebar current setting

  adminHistoryRecords: [],
  isLoading: false,
  siloReportProgress: 0,
  notificationsForAdmin: [],
  userInvoices: [],
  menuSearchType: null,
  menuSearchCriteria: "",
  isNewSiloUpdates: false,
  isNewTrackingUpdates: false,
  submittingGoogleSearch: false,
  showGoogleResultsModal: false,
  googleSearchProgress: 1,
  validateUrlsProgress: 1,

  sortModeSite: "recent",
  sortModePage: "recent",
  sortModePageRun: "recent", //   "recent", "alphabetic", "worstscore", "bestscore"

  // edit html
  isExpressEditor: false,
  seoEditHtmlSource: "",
  seoEditWordCounts: {},
  seoEditEmCounts: {},
  seoEditLsiEmCounts: {},
  seoEditTagCounts: {},
  aiWriterStep: 0,

  editorArchivedList: [],

  // for cancel button
  currentTaskId: -1,
  currentGoogleCompetitorsTaskId: -1,
  currentGoogleVariationsTaskId: -1,
  fetchedVariations: false,
  originGoogleVariations: [],
  variations: [],
  excludedVariations: [],
  usedGoogleDomain: "",
  googleSearchLocations: [],
  relatedQuestions: [],
  relatedSearches: [],
  insightKeywordsConfig: cloneDeep(KEYWORDS_INSIGHT_CONFIG),
  aiWriterNeedsToSave: false,

  asyncTasks: [],
  clientSideAsyncTasks: [],
  triggerAsyncTasksRefresh: null,  // mutate this to trigger tasks list refresh (the value does not matter)

  triggerCreateDomainCampaignModal: null,

  // this is for pagerun strategy
  dashboardType: "contentBrief", // contentBrief / signals
  strategy: "target", // target, adjusted, focus, max
  approach: "regular", // regular, conservative, aggressive, hyper_aggressive

  // this needs updated when new languages get added
  targetLanguages: [
    "english",
    "spanish",
    "french",
    "italian",
    "portuguese",
    // "separator_line",
    "albanian",
    "arabic",
    "armenian",
    "azerbaijani",
    "basque",
    "belarusian",
    "bengali",
    "bulgarian",
    "catalan",
    "chinese",
    "croatian",
    "czech",
    "danish",
    "dutch",
    "estonian",
    "farsi",
    "filipino",
    "finnish",
    "galician",
    "german",
    "greek",
    "hausa",
    "hebrew",
    "hindi",
    "hungarian",
    "icelandic",
    "indonesian",
    "irish",
    "japanese",
    "kazakh",
    "korean",
    "kurdish",
    "latvian",
    "lithuanian",
    "malay",
    "marathi",
    "mongolian",
    "nepali",
    "norwegian",
    "persian",
    "polish",
    "romanian",
    "russian",
    "serbian",
    "slovak",
    "slovenian",
    "swahili",
    "swedish",
    "tagalog",
    "telugu",
    "thai",
    "turkish",
    "ukranian",
    "urdu",
    "vietnamese",
    "yoruba",
  ],
  googleNlpSupportedLanguages: [
    "chinese",
    // "dutch", due to entity analysis is not supported. only supported for content classification
    "english",
    "french",
    "german",
    "italian",
    "japanese",
    "korean",
    "portuguese",
    "russian",
    "spanish",
  ],

  strategies: ["target", "adjusted", "focus", "max", "custom"],
  approachies: ["regular", "conservative", "aggressive", "hyper_aggressive"],
  dashboardTypes: ["contentBrief", "signals"],

  lastReportCreationTime: null,

  currentAIRun: null,

  isDemoTitleDone: false,
  isDemoHeadingsDone: false,
  isDemoMainContentDone: false,

  currentVideoTutorial: null,

  sitesPerPage: 12,
  currentPageForSite: 1,
  pagesPerPage: 12,
  currentPageForPage: 1,
  genSchemaLoading: {},
  selectedSiloKeywords: [],
  process: {},

  currentCampaign: null,
  googleUser: null,

  siloContentReportsOrders: [],
  showSiloContentReportsOrdersCart: false,
  refreshSiloContentReportsOrdersCart: null,  // mutate this to trigger cart data refresh (the value does not matter)
  popCreditCharges: {
    aiGenSchemaCredits: 0,
    aiWriterCreditsPer1000Words: 0,
    aiWriterCreditsFlatRateOwnApiKey: 0,
    googleNlpCredits: 0,
  },
};

const UNLIMITED_SUBSCRIPTION_SERVICES = unlimitedServices;

const AGENCY_PLANS = agencyPlans;

const actions = {
  // asynchronous operations
  loadData({ getters, commit, dispatch }) {
    return dispatch("makeHTTPRequest", {
      callback: () => {
        return fetchData(getters.getToken).then((response) =>
          commit("setAllData", response.data)
        );
      },
    });
  },
  loadInvoiceData({ getters, dispatch, state }) {
    return dispatch("makeHTTPRequest", {
      callback: () => {
        return fetchInvoiceData(getters.getToken, state.user.id);
      },
    });
  },
  loadUser({ state, getters, commit }, isForIndependentRoutes = false) {
    let token = getters.getToken;
    if (isForIndependentRoutes) {
      token = getToken();
      commit("setJwtToken", { jwt: token });

      if (!token) {
        return new Promise((resolve, reject) => {
          reject({
            data: {
              msg: "You are not logged In. Even you don't have token to check",
            },
          });
        });
      }
    }
    return fetchUser(token, isForIndependentRoutes);
  },
  loadUserHasPaymentSources({state, getters}) {
    return fetchUserHasPayments(getters.getToken);
  },
  adminResetBalance({ getters }, user_id) {
    return resetBalanceForUser(getters.getToken, user_id);
  },
  loadSite({ state, getters }, siteId) {
    return fetchSite(
      getters.getToken,
      siteId,
      state.selectedSubAccountRelation.id
    );
  },
  loadSites({ state, getters }) {
    return fetchSites(getters.getToken, state.selectedSubAccountRelation.id);
  },
  getActiveSiteContentAudit({ getters }, data) {
    return getActiveSiteContentAudit(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  updateSiteContentAudit({ getters, state }, data) {
    return updateSiteContentAudit(getters.getToken, data.auditId, data.body, state.selectedSubAccountRelation.id);
  },
  loadBulkRuns({ state, getters }) {
    return fetchBulkRuns(getters.getToken, state.selectedSubAccountRelation.id);
  },
  loadBulkRun({ state, getters }, runId) {
    return fetchBulkRun(
      getters.getToken,
      runId,
      state.selectedSubAccountRelation.id
    );
  },
  deleteBulkRun({ state, getters }, runId) {
    return deleteBulkRun(
      getters.getToken,
      runId,
      state.selectedSubAccountRelation.id
    );
  },
  downloadBulkRunZip({ state, getters }, data) {
    return downloadBulkRunZip(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },

  loadPages({ getters }, { siteId, pageId }) {
    return fetchPages(
      getters.getToken,
      siteId,
      pageId,
      state.selectedSubAccountRelation.id
    );
  },
  loadPageRuns({ getters }, { siteId, pageId }) {
    return fetchPageRuns(
      getters.getToken,
      siteId,
      pageId,
      state.selectedSubAccountRelation.id
    );
  },
  loadPageRunsBySearchReportId({ getters }, searchReportId) {
    return fetchPageRunsBySearchReportId(
      getters.getToken,
      searchReportId,
      state.selectedSubAccountRelation.id
    );
  },
  loadPageRunsForExtension({ getters }) {
    return fetchPageRunsForExtension(
      getters.getToken,
      state.selectedSubAccountRelation.id
    );
  },
  loadPageRunForExtension({ getters }, { pageRunId }) {
    return fetchPageRunForExtension(
      getters.getToken,
      pageRunId,
      state.selectedSubAccountRelation.id
    );
  },
  login(context, userData) {
    return authenticate(userData);
  },
  demo_user_token(_, data) {
    return getDemoUserToken(data);
  },
  loginFromExtension(context, userData) {
    return authenticateFromExtension(userData);
  },
  change2FaAuthSetting({ getters }, data) {
    return change2FaAuthSetting(getters.getToken, data);
  },
  getLoginPageNotifications(context) {
    return getLoginPageNotifications();
  },
  emailIsAvailable({ commit }, email) {
    return emailIsAvailable(email);
  },
  register({ commit }, data) {
    commit("setUser", data);
    return register(data);
  },
  registerNoCard({ commit }, data) {
    commit("setUser", data);
    return registerNoCard(data);
  },
  saveCompanyDetails({ getters }, companyData) {
    return saveCompanyDetails(getters.getToken, companyData);
  },
  setNotificationsAsRead({ getters }, ids) {
    return setNotificationsAsRead(getters.getToken, ids);
  },
  saveAccountName({ getters }, data) {
    return saveAccountName(getters.getToken, data);
  },
  savePaypalEmailForAffiliateCommission({ getters }, paypalEmail) {
    return savePaypalEmailForAffiliateCommission(getters.getToken, paypalEmail);
  },
  deleteCustomLogo({ getters }) {
    return deleteCustomLogo(getters.getToken);
  },
  sendApiPartnershipContactEmail({ getters }, data) {
    return sendApiPartnershipContactEmail(getters.getToken, data);
  },
  getPayAmountForPartnerApiKeyUsage({ getters }) {
    return getPayAmountForPartnerApiKeyUsage(getters.getToken);
  },
  addSubAccount({ getters }, data) {
    return addSubAccount(getters.getToken, data);
  },
  updateSubAccount({ getters }, data) {
    return updateSubAccount(getters.getToken, data);
  },
  deleteSubAccount({ getters }, data) {
    return deleteSubAccount(getters.getToken, data);
  },
  deleteSubAccounts({ getters }, data) {
    return deleteSubAccounts(getters.getToken, data);
  },
  getSubAccountActivityLogs({ getters }, { params }) {
    return getSubAccountActivityLogs(getters.getToken, params);
  },
  addDevAccount({ getters }, data) {
    return addDevAccount(getters.getToken, data);
  },
  deleteDevAccount({ getters }, data) {
    return deleteDevAccount(getters.getToken, data);
  },
  updateDevCallsTopupSetting({ getters }, data) {
    return updateDevCallsTopupSetting(getters.getToken, data);
  },
  createAPIAccessKey({ getters }, data) {
    return createAPIAccessKey(
      getters.getToken,
      data,
      state.selectedDevAccountRelation.id
    );
  },
  leaveMainAccount({ getters }, data) {
    return leaveMainAccount(getters.getToken, data);
  },
  saveInvoicingEmail({ getters }, data) {
    return saveInvoicingEmail(getters.getToken, data);
  },
  changeSiteName({ getters, commit, dispatch }, { id, siteName }) {
    return changeSiteName(getters.getToken, id, siteName);
  },
  changePageName({ getters }, { id, pageName }) {
    return changePageName(getters.getToken, id, pageName);
  },
  createSite({ getters, state }, siteData) {
    return createSite(
      getters.getToken,
      siteData,
      state.selectedSubAccountRelation.id
    );
  },
  validateSiteDomain({ getters }, url) {
    return validateSiteDomain(getters.getToken, url);
  },
  fetchVariations({ getters }, data) {
    return fetchVariations(getters.getToken, data.keyword, data.proxyLocation);
  },
  createPage({ getters, state }, data) {
    return createPage(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  createPageRunInBackend({ getters, state }, data) {
    return createPageRunInBackend(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },

  updatePageSettings({ getters, state }, data) {
    return updatePageSettings(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  createGuardRunFromSilo({ getters, state }, siloKeywordId) {
    return createGuardRunFromSilo(getters.getToken, siloKeywordId, state.selectedSubAccountRelation.id);
  },
  updatePagePublishStatus({ getters, state }, data) {
    return updatePagePublishStatus(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  pauseWatchdogSetupForPages({ getters, state }, data) {
    return pauseWatchdogSetupForPages(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  cancelWatchdogSetupForPages({ getters, state }, data) {
    return cancelWatchdogSetupForPages(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },

  // updatePagePinStatus ({ getters, state }, data) {
  //   return updatePagePinStatus(
  //     getters.getToken,
  //     data,
  //     state.selectedSubAccountRelation.id
  //   );
  // },

  fetchLsaPhrases({ getters }, data) {
    return fetchLsaPhrases(getters.getToken, data);
  },
  // getGuardReportDiffData ({ getters }, data) {
  //   return getGuardReportDiffData(getters.getToken, data)
  // },
  fetchPageRun({ getters, state }, { siteId, pageId, pageRunId }) {
    return fetchPageRun(
      getters.getToken,
      siteId,
      pageId,
      pageRunId,
      state.selectedSubAccountRelation.id
    );
  },
  fetchPageRunBySecretKeyOrPageRunId({ getters, state }, data) {
    // secretKey must passed in data OR token (means logged in )and pageRunId (in data) must passed
    return fetchPageRunBySecretKeyOrPageRunId(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  createSecretKey({ getters, state }, { siteId, pageId, pageRunId }) {
    return createSecretKey(
      getters.getToken,
      siteId,
      pageId,
      pageRunId,
      state.selectedSubAccountRelation.id
    );
  },
  createDemoPageRun({ getters, state }, data) {
    return createDemoPageRun(data);
  },
  urlUsedin24Hrs({ getters, state }, data) {
    return urlUsedin24Hrs(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  checkStatusOfPageRun({ getters }, { pageRunId }) {
    return getPageRunStatus(getters.getToken, pageRunId);
  },
  markAsReadForRunAutoStatusUpdate({ getters }, { pageRunId }) {
    return markAsReadForRunAutoStatusUpdate(getters.getToken, pageRunId);
  },
  getHtmlCountData({ getters }, data) {
    return getHtmlCountData(getters.getToken, data);
  },
  generateNewBulkRun({ getters }, data) {
    return generateNewBulkRun(getters.getToken, data);
  },
  bulkExcelUploadAndValidate({ getters, state, commit, dispatch }, xlsxFile) {
    return bulkExcelUploadAndValidate(
      getters.getToken,
      xlsxFile,
      state.selectedSubAccountRelation.id
    );
  },
  createNewBulkRun({ getters, state, commit, dispatch }, data) {
    return createNewBulkRun(
      getters.getToken,
      state.selectedSubAccountRelation.id,
      omit(data, 'isSilo')
    ).then((response) => {
      let { status, bulkRun } = response.data;

      commit("hideLoading");
      if (data.isSilo) commit("setSiloReportProgress", 1);

      if (status === "SUCCESS") {
        commit("addOrUpdateBulkRun", bulkRun);
        const { taskId } = bulkRun;
        const bulkRunId = bulkRun.id;

        const getBulkRun = () => {
          dispatch("loadBulkRun", bulkRunId).then((res) => {
            if (res.data && res.data.status == "SUCCESS") {
              if (res.data.bulkRun.id) {
                commit("addOrUpdateBulkRun", res.data.bulkRun);
                commit("setUser", res.data.user);
              }
            }
          });
        };

        return new Promise((resolve, reject) => {
          const timer = setInterval(() => {
            return fetchTaskResult(taskId)
              .then((resultResponse) => {
                let { status, value } = resultResponse.data;

                if (status === "SUCCESS") {
                  if (data.isSilo) commit("setSiloReportProgress", 0);
                  clearInterval(timer);
                  getBulkRun();
                  resolve(resultResponse);
                } else if (status === "REVOKED") {
                  if (data.isSilo) commit("setSiloReportProgress", 0);
                  getBulkRun();
                  resolve(resultResponse);
                  if (state.isCalculatingBulkNow) {
                    clearInterval(timer);
                  }
                } else if (status === "FAILURE") {
                  if (data.isSilo) commit("setSiloReportProgress", 0);
                  clearInterval(timer);
                  getBulkRun();
                  reject(resultResponse);
                } else if (status === "PROGRESS") {
                  if (data.isSilo) commit("setSiloReportProgress", value);
                  // load updated bulk runs again
                  getBulkRun();
                }
              })
              .catch((e) => {
                console.log("error in interval timer ", e);
                clearInterval(timer);
                reject({ status: "FAILURE" });
              });
          }, 6000);
        });
      } else {
        return response;
      }
    });
  },
  cancelBulkRunTask({ getters }, taskId) {
    return cancelBulkRunTask(getters.getToken, taskId);
  },
  checkPageRunsQueue({ state, commit }, { siteId, pageId }) {
    return new Promise((resolve, reject) => {
      state.pageRuns.forEach((pageRun) => {
        if (pageRun.status === "PENDING") {
          if (!state.queue.find((q) => q.pageRunId === pageRun.id)) {
            commit("enQueuePageRunItem", {
              siteId: parseInt(siteId),
              pageId: parseInt(pageId),
              pageRunId: pageRun.id,
            });
          }
        }
      });
      resolve("ok");
    });
  },
  makeHTTPRequest({ getters, commit }, { callback }) {
    try {
      if (getters.isAuthenticated) {
        return callback().catch((err) => {
          console.log("****makeHTTPRequest callback error ", err);
          if (err.response.data && !err.response.data.authenticated) {
            commit("setJwtToken", "");
            commit("setGlobalErrorMsg", { msg: "Please reauthenticate" });
          }
          return 1;
        });
      }
      return 1;
    } catch (err) {
      console.log("****Error making http request", err);
      return 1;
    }
  },
  downloadExcelReport({ getters }, { filePath }) {
    return downloadExcelReport(getters.getToken, filePath);
  },
  downloadExcelReportFromUrl({ getters }, { url }) {
    return downloadExcelReportFromUrl(getters.getToken, url);
  },
  fetchKeywordWizardReports({ getters }, campaignId) {
    return fetchKeywordWizardReports(
      getters.getToken,
      campaignId,
      state.selectedSubAccountRelation.id
    );
  },
  updateKeywordWizardReport({ getters }, { data, subAccountRelationId }) {
    return updateKeywordWizardReport(getters.getToken, data, subAccountRelationId);
  },
  deleteKeywordWizardReport({ getters }, { reportId, subAccountRelationId }) {
    return deleteKeywordWizardReport(getters.getToken, reportId, subAccountRelationId);
  },
  updateKeywordResearchReport({ getters }, { data, subAccountRelationId }) {
    return updateKeywordResearchReport(getters.getToken, data, subAccountRelationId);
  },
  deleteKeywordResearchReport({ getters }, { reportId, subAccountRelationId }) {
    return deleteKeywordResearchReport(getters.getToken, reportId, subAccountRelationId);
  },
  downloadKeywordResearchReport({ getters }, { reportId }) {
    return downloadKeywordResearchReport(getters.getToken, reportId);
  },
  updatePassword({ commit }, data) {
    return updatePassword(data);
  },
  changeEmailRequest({ commit }, data) {
    return changeEmailRequest(data);
  },
  getAllTasksForPageRun({ state, getters }, data) {
    return getAllTasksForPageRun(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  addOneToCheckList({ getters }, data) {
    return addOneToCheckList(getters.getToken, data);
  },
  archiveOneFromCheckList({ getters }, data) {
    return archiveOneFromCheckList(getters.getToken, data);
  },
  restoreOneFromChecklist({ getters }, data) {
    return restoreOneFromChecklist(getters.getToken, data);
  },
  archiveAllChecklistWithType({ getters }, data) {
    // data = { type: type, pageId: pageId }
    return archiveAllChecklistWithType(getters.getToken, data);
  },
  addAllToCheckList({ getters }, data) {
    return addAllToCheckList(getters.getToken, data);
  },
  updateChecklistState({ getters }, data) {
    return updateChecklistState(getters.getToken, data);
  },
  updateChecklistDueDate({ getters }, data) {
    return updateChecklistDueDate(getters.getToken, data);
  },
  updateChecklistAssignee({ getters }, data) {
    return updateChecklistAssignee(getters.getToken, data);
  },
  updateChecklistStateFromExtension({ getters }, data) {
    return updateChecklistStateFromExtension(getters.getToken, data);
  },
  createChecklistNote({ getters }, data) {
    return createChecklistNote(getters.getToken, data);
  },
  updateChecklistNote({ getters }, data) {
    return updateChecklistNote(getters.getToken, data);
  },
  updateChecklistNoteFromExtension({ getters }, data) {
    return updateChecklistNoteFromExtension(getters.getToken, data);
  },
  deleteChecklistNote({ getters }, noteId) {
    return deleteChecklistNote(getters.getToken, noteId);
  },
  downloadExcelForChecklist({ getters }, data) {
    return downloadExcelForChecklist(getters.getToken, data);
  },
  downloadTasksForProgressNote({ getters }, data) {
    return downloadTasksForProgressNote(getters.getToken, data);
  },
  downloadDocxForAiWriter({ getters }, data) {
    return downloadDocxForAiWriter(getters.getToken, data);
  },
  getChecklistForExtension({ getters }, data) {
    return getChecklistForExtension(getters.getToken, data);
  },
  getContentBriefNotes({ getters }, data) {
    return getContentBriefNotes(getters.getToken, data);
  },
  createContentBriefNote({ getters }, data) {
    return createContentBriefNote(getters.getToken, data);
  },
  updateContentBriefNote({ getters }, data) {
    return updateContentBriefNote(getters.getToken, data);
  },
  deleteContentBriefNote({ getters }, noteId) {
    return deleteContentBriefNote(getters.getToken, noteId);
  },
  updateContentBriefStatus({ getters }, data) {
    return updateContentBriefStatus(getters.getToken, data);
  },
  downloadContentBrief({ getters }, data) {
    return downloadContentBrief(getters.getToken, data);
  },
  resetPassword(context, data) {
    return resetPassword(data);
  },
  getProratedAmountForPurchase({ state, getters }, data) {
    return getProratedAmountForPurchase(getters.getToken, state.user.id, data);
  },
  createPurchase({ state, getters }, data) {
    return makePurchase(getters.getToken, state.user.id, data);
  },
  createPayPalPayment({ getters }, data) {
    return createPayPalPayment(getters.getToken, data);
  },
  createStripePayment({ getters }, data) {
    return createStripePayment(getters.getToken, data);
  },
  convertSubscription({ getters }) {
    return convertSubscription(getters.getToken);
  },
  buyNlpEatPackage({ getters }, data) {
    return buyNlpEatPackage(getters.getToken, data);
  },
  buyUniversalCreditsPakcage({ getters }, data) {
    return buyUniversalCreditsPakcage(getters.getToken, data);
  },
  executePayPalSubscription({ getters }, data) {
    //http://localhost:8080/execute-subscription?token=EC-1T983725F19521617
    return this.executePayPalSubscription(getters.getToken, data);
  },
  fetchYearlyUnlimitedConversionDiscount({ state, getters }) {
    return fetchYearlyUnlimitedConversionDiscount(
      getters.getToken,
      state.user.id
    );
  },
  cancelSubscription({ getters, state }, data) {
    return cancelSubscription(getters.getToken, state.user.id, data);
  },
  updateUserSettings({ getters, state }, data) {
    return updateUserSettings(getters.getToken, state.user.id, data);
  },
  reactivateSubscription({ getters, state }, data) {
    return reactivateSubscription(getters.getToken, state.user.id, data);
  },
  addCoupon({ getters, state }, data) {
    return updateCouponCode(getters.getToken, state.user.id, data);
  },
  updatePaypalEmail({ getters }, data) {
    return updatePaypalEmail(getters.getToken, data);
  },
  generateCancellationCode({ getters }) {
    return generateCancellationCode(getters.getToken);
  },
  addOpenAiKey({ getters }, data) {
    return addOpenAiKey(getters.getToken, data);
  },
  getAvailableNlpEatPackages({ getters }) {
    return getAvailableNlpEatPackages(getters.getToken);
  },
  getAvailableUniversalPackages({ getters }) {
    return getAvailableUniversalPackages(getters.getToken);
  },
  getAvailableAPICreditPackages({ getters }) {
    return getAvailableAPICreditPackages(getters.getToken);
  },
  getAvailableWhiteGloveAddonPackages({ getters }) {
    return getAvailableWhiteGloveAddonPackages(getters.getToken);
  },
  rerunAnalysis({ getters, state }, data) {
    return makeNewPageRun(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadApiUsageForUser({ getters }, data) {
    return loadApiUsageForUser(
      getters.getToken,
      data,
      state.selectedDevAccountRelation.id
    );
  },
  loadUsersForAdmin({ getters }, { params }) {
    return getUsersForAdmin(getters.getToken, params);
  },
  loadAdminComparisonData(
    { getters },
    {
      primaryStartDate,
      primaryEndDate,
      secondaryStartDate,
      secondaryEndDate,
      source,
      aggregator,
    }
  ) {
    return loadAdminComparisonData(
      getters.getToken,
      primaryStartDate,
      primaryEndDate,
      secondaryStartDate,
      secondaryEndDate,
      source,
      aggregator
    );
  },
  downloadAllUserExcel({ getters }, data) {
    return downloadAllUserExcel(getters.getToken, data);
  },
  downloadAllSales({ getters }) {
    return downloadAllSales(getters.getToken);
  },
  updateUserAccountForAdmin({ getters }, data) {
    return updateUserAccountForAdmin(getters.getToken, data);
  },
  resendRegistrationEmailForAdmin({ getters }, data) {
    return resendRegistrationEmailForAdmin(getters.getToken, data);
  },
  confirmEmailAsValidated({ getters }, data) {
    return confirmEmailAsValidated(getters.getToken, data);
  },
  adminResetUsageLimitForUser({ getters }, data) {
    return adminResetUsageLimitForUser(getters.getToken, data);
  },
  adminCancelSubscription({ getters }, data) {
    return adminCancelSubscription(getters.getToken, data);
  },
  loadCouponsForAdmin({ getters }, { params }) {
    return getCouponsForAdmin(getters.getToken, params);
  },
  contactUs(context, { email, name, message }) {
    return sendContactUs(email, name, message);
  },
  makeCouponForAdmin({ getters }, data) {
    return createCouponForAdmin(getters.getToken, data);
  },
  autoGenCouponCode({ getters }, data) {
    return autoGenCouponCode(getters.getToken, data);
  },
  updateCouponForAdmin({ getters }, data) {
    return updateCouponForAdmin(getters.getToken, data);
  },
  deleteCouponForAdmin({ getters }, { id }) {
    return deleteCouponForAdmin(getters.getToken, id);
  },
  addAliasToCouponForAdmin({ getters }, data) {
    return addAliasToCouponForAdmin(getters.getToken, data);
  },
  makeAdminUserComment({ getters }, data) {
    return createAdminUserComment(getters.getToken, data);
  },
  removeSite({ getters }, { siteId }) {
    return deleteSite(getters.getToken, siteId);
  },
  removeSites({ getters }, { siteIds }) {
    return deleteSites(getters.getToken, siteIds);
  },
  removePage({ getters }, { siteId, pageId }) {
    return deletePage(getters.getToken, siteId, pageId);
  },
  removePages({ getters }, { siteId, pageIds }) {
    return deletePages(getters.getToken, siteId, pageIds);
  },
  updatePinPageRun({ getters }, data) {
    return updatePinPageRun(getters.getToken, data);
  },
  removePageRun({ getters }, { siteId, pageId, pageRunId }) {
    return deletePageRun(getters.getToken, siteId, pageId, pageRunId);
  },
  removePageRuns({ getters }, { siteId, pageId, pageRunIds }) {
    return deletePageRuns(getters.getToken, siteId, pageId, pageRunIds);
  },
  removeTermFromCB({ getters }, data) {
    return removeTermFromCB(getters.getToken, data);
  },
  recoverTermForCB({ getters }, data) {
    return recoverTermForCB(getters.getToken, data);
  },
  removeTermsFromCB({ getters }, data) {
    return removeTermsFromCB(getters.getToken, data);
  },
  toggleScoringMethodOfCB({ getters }, data) {
    return toggleScoringMethodOfCB(getters.getToken, data);
  },
  setContentBriefCustomWordCount({ getters }, data) {
    return setContentBriefCustomWordCount(getters.getToken, data);
  },
  acknowledgeForContentBriefScoreNotChanged({ getters }, data) {
    return acknowledgeForContentBriefScoreNotChanged(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadHistoryForAdmin({ getters }, { params }) {
    return getHistoryForAdmin(getters.getToken, params);
  },
  loadKeywordResearchForAdmin({ getters }, { params }) {
    return getKeywordResearchForAdmin(getters.getToken, params);
  },
  getSiteContentAuditOrdersForAdmin({ getters }, { params }) {
    return getSiteContentAuditOrdersForAdmin(getters.getToken, params);
  },
  getSiloOrdersForAdmin({ getters }, { params }) {
    return getSiloOrdersForAdmin(getters.getToken, params);
  },
  getWatchDogTrackHistoryForAdmin({ getters }, { params }) {
    return getWatchDogTrackHistoryForAdmin(getters.getToken, params);
  },
  getGuardRunsForUser({ getters }, { params }) {
    return getGuardRunsForUser(
      getters.getToken,
      params,
      state.selectedSubAccountRelation.id
    );
  },
  getAIRunsForUser({ getters }, { params }) {
    return getAIRunsForUser(
      getters.getToken,
      params,
      state.selectedSubAccountRelation.id
    );
  },
  getAIRunsForAdmin({ getters }, { params }) {
    return getAIRunsForAdmin(getters.getToken, params);
  },
  getCreditUsageForUser({ getters }, { params }) {
    return getCreditUsageForUser(getters.getToken, params);
  },
  getKeywordResearchCreditUsageForUser({ getters }, { params }) {
    return getKeywordResearchCreditUsageForUser(getters.getToken, params);
  },
  getBulkCreditLog({ getters }, { params }) {
    return getBulkCreditLog(getters.getToken, params);
  },
  updatePageRunNote({ getters }, { siteId, pageId, pageRunId, note }) {
    return updatePageRunNote(getters.getToken, {
      siteId,
      pageId,
      pageRunId,
      note,
    });
  },
  fetchValidatedCoupons({ getters }, { promoCode, isRegisterPage }) {
    return fetchValidatedCoupons(getters.getToken, promoCode, isRegisterPage);
  },
  fetchAvailableProxies({ getters }) {
    return fetchProxies(getters.getToken);
  },
  fetchSalesData({ getters }, { startDate, endDate }) {
    return getSales(getters.getToken, startDate, endDate);
  },
  fetchNewUserStats({ getters }, { startDate, endDate }) {
    return getNewUserStats(getters.getToken, startDate, endDate);
  },
  fetchRunsPerDay({ getters }, { startDate, endDate }) {
    return getRunsPerDay(getters.getToken, startDate, endDate);
  },
  fetchTotalUsagePerDay({ getters }, { days }) {
    return getTotalUsagePerDay(getters.getToken, days);
  },
  fetchTotalUsersForAdmin({ getters }) {
    return getTotalUsers(getters.getToken);
  },
  fetchAffiliateSales({ getters }) {
    return getAffiliateSales(getters.getToken);
  },
  fetchAffiliatePayouts({ getters }) {
    return getAffiliatePayouts(getters.getToken);
  },
  removeFromPayout({ getters }, data) {
    return removeFromPayout(getters.getToken, data);
  },
  executePayout({ getters }, data) {
    return executePayout(getters.getToken, data);
  },
  fetchPaypalRefunds({ getters }) {
    return getPaypalRefunds(getters.getToken);
  },
  executePaypalRefund({ getters }, data) {
    return executePaypalRefund(getters.getToken, data);
  },
  testCancelledWatchdogSetup({ getters }, data) {
    return testCancelledWatchdogSetup(getters.getToken, data);
  },
  deleteUserAccount({ getters, state }) {
    return deleteAccount(getters.getToken, state.user.id);
  },
  fetchAllUsersForAdmin({ getters }) {
    return getAllUsersForAdmin(getters.getToken);
  },
  fetchAllNotificationsForAdmin({ getters }, { params }) {
    return getAllNotificationsForAdmin(getters.getToken, params);
  },
  createNotification({ getters }, notification) {
    return createAdminNotification(getters.getToken, notification);
  },
  fetchUserNotifications({ getters }, { userId }) {
    return getUserNotifications(getters.getToken, userId);
  },
  deleteUserNotification({ getters }, { userId, notificationId }) {
    return deleteUserNotification(getters.getToken, userId, notificationId);
  },
  updateNotificationForAdmin({ getters }, notification) {
    return updateNotificationForAdmin(getters.getToken, notification);
  },
  deleteNotificationForAdmin({ getters }, { id }) {
    return deleteNotificationForAdmin(getters.getToken, id);
  },
  createStripeCardAdditionLink({ getters, state }) {
    return createStripeCardAdditionLink(getters.getToken, state.user.id);
  },
  fetchPaymentSources({ getters, state }) {
    return fetchPaymentSources(getters.getToken, state.user.id);
  },
  updatePaymentSource({ getters, state }, { cardId, stripeData }) {
    return updatePaymentSource(
      getters.getToken,
      state.user.id,
      cardId,
      stripeData
    );
  },
  setDefaultPaymentSource({ getters, state }, cardOrPaymentId) {
    return setDefaultPaymentSource(
      getters.getToken,
      state.user.id,
      cardOrPaymentId
    );
  },
  deletePaymentSource({ getters, state }, { cardId }) {
    return deletePaymentSource(getters.getToken, state.user.id, cardId);
  },
  downloadInvoices({ getters, state }, data) {
    return exportInvoices(getters.getToken, state.user.id, data);
  },
  downloadRefundInvoices({ getters, state }, data) {
    return exportRefundInvoices(getters.getToken, state.user.id, data);
  },
  fetchGoogleSearchLocations({ getters, commit }) {
    return fetchGoogleSearchLocations(getters.getToken).then((response) => {
      commit("setGoogleSearchLocations", response.data);
      return response.data;
    });
  },
  submitGoogleSearchTask({ getters, state, commit, dispatch }, data) {
    const { keyword, secondaryKeywords } = data;
    commit("setGoogleSearchProgress", 1);
    return submitGoogleSearchTask(getters.getToken, data).then((response) => {
      // let { status, taskId } = response.data
      const { status, competitorTaskId, variationsTaskId } = response.data;
      commit("setCurrentGoogleCompetitorsTaskId", competitorTaskId);
      commit("setCurrentGoogleVariationsTaskId", variationsTaskId);

      if (status !== "FAILURE") {
        let variationsTimer = null;
        try {
          variationsTimer = setInterval(() => {
            return fetchTaskResult(variationsTaskId)
              .then((resultResponse) => {
                let { status: variationsStatus } = resultResponse.data;
                if (variationsTaskId != state.currentGoogleVariationsTaskId) {
                  clearInterval(variationsTimer);
                } else if (variationsStatus === "SUCCESS") {
                  clearInterval(variationsTimer);

                  let variations = resultResponse.data.results.variations;

                  commit("setFetchedVariations", true);
                  commit("setOriginGoogleVariations", variations);
                  // commit("setVariations", variations);
                  // commit(
                  //   "setExcludedVariations",
                  //   resultResponse.data.results.excludedVariations
                  // );
                  let gDomain = resultResponse.data.results.gDomain;
                  commit("setUsedGoogleDomain", gDomain);

                  // fetched variations - need to set current variations in store
                  // and somehow let rest of app know variations have been set

                  dispatch("filterGoogleVariations", {
                    keyword: keyword,
                    secondaryKeywords: secondaryKeywords,
                    variations: variations,
                    gDomain: gDomain,
                  });
                } else if (variationsStatus === "FAILURE") {
                  clearInterval(variationsTimer);
                }
              })
              .catch((e) => {
                console.log("gv error ", e);
                clearInterval(variationsTimer);
              });
          }, 8000);
        } catch (e) {
          console.log("gv error", e);
          if (variationsTimer) {
            clearInterval(variationsTimer);
          }
        }
      }

      return new Promise((resolve, reject) => {
        let competitorsTimer = null;
        try {
          if (status === "FAILURE") {
            reject(response.data);
          } else if (status === "SUCCESS") {
            competitorsTimer = setInterval(() => {
              return fetchTaskResult(competitorTaskId)
                .then((resultResponse) => {
                  let { status: competitorsStatus } = resultResponse.data;
                  if (
                    competitorTaskId != state.currentGoogleCompetitorsTaskId
                  ) {
                    clearInterval(competitorsTimer);
                    resolve({ status: "CANCELLED" });
                  } else if (competitorsStatus === "SUCCESS") {
                    clearInterval(competitorsTimer);
                    resultResponse.data.proxyId = response.data.proxyId;

                    commit(
                      "setRelatedQuestions",
                      resultResponse.data.results.hasOwnProperty(
                        "relatedQuestions"
                      )
                        ? resultResponse.data.results.relatedQuestions
                        : []
                    );
                    commit(
                      "setRelatedSearches",
                      resultResponse.data.results.hasOwnProperty(
                        "relatedSearches"
                      )
                        ? resultResponse.data.results.relatedSearches
                        : []
                    );
                    resolve(resultResponse.data);
                  } else if (competitorsStatus === "FAILURE") {
                    clearInterval(competitorsTimer);
                    reject(resultResponse.data);
                  } else if (competitorsStatus === "PROGRESS") {
                    let currentProgress = parseInt(state.googleSearchProgress);
                    currentProgress =
                      currentProgress < 99
                        ? currentProgress + 1
                        : currentProgress;
                    let progressUpdate = parseInt(resultResponse.data.value);
                    if (currentProgress < progressUpdate) {
                      currentProgress = progressUpdate;
                    }
                    commit("setGoogleSearchProgress", currentProgress);
                  }
                })
                .catch((e) => {
                  console.log("gc tasks error ", e);
                  clearInterval(competitorsTimer);
                  reject({ status: "FAILURE" });
                });
            }, 500);
          }
        } catch (e) {
          console.log("gc tasks error ", e);
          if (competitorsTimer) {
            clearInterval(competitorsTimer);
          }
        }
      });
    });
  },
  filterGoogleVariations(
    { state, getters, commit },
    { keyword, secondaryKeywords, variations }
  ) {
    return filterGoogleVariations(
      getters.getToken,
      keyword,
      secondaryKeywords,
      variations
    ).then((response) => {
      if (response.data.status == "SUCCESS") {
        commit("setVariations", response.data.variations);
        let excludedVariations = state.excludedVariations
          .slice()
          .concat(response.data.excludedVariations);
        commit("addExcludedVariations", excludedVariations);
      }
    });
  },
  validatePageAnalysisUrls({ getters, commit, state }, data) {
    // commit('showLoading')
    commit("setValidateUrlsProgress", 1);
    return validatePageAnalysisUrls(getters.getToken, data).then((response) => {
      return new Promise((resolve, reject) => {
        if (response.data.status === "FAILURE") {
          reject(response.data);
        } else {
          const timer = setInterval(() => {
            const { taskId } = response.data;
            return fetchTaskResult(taskId)
              .then((resultResponse) => {
                const { status } = resultResponse.data;
                if (status === "SUCCESS") {
                  clearInterval(timer);
                  // commit('hideLoading')
                  resultResponse.data.proxyId = response.data.proxyId;
                  resolve(resultResponse.data);
                } else if (status === "FAILURE") {
                  clearInterval(timer);
                  // commit('hideLoading')
                  resolve({ status: "FAILURE" });
                } else if (status === "PROGRESS" || status == "PENDING") {
                  let currentProgress = parseInt(state.validateUrlsProgress);
                  currentProgress =
                    currentProgress < 97
                      ? currentProgress + 3
                      : currentProgress;
                  let progressUpdate = 1;
                  try {
                    progressUpdate = parseInt(resultResponse.data.value);
                  } catch (e) {
                    console.log(e);
                  }
                  if (currentProgress < progressUpdate) {
                    currentProgress = progressUpdate;
                  }
                  commit("setValidateUrlsProgress", currentProgress);
                }
              })
              .catch((err) => {
                console.log(err);
                clearInterval(timer);
                // commit('hideLoading')
                resolve({ status: "FAILURE" });
              });
          }, 5000); //was 10000
        }
      });
    });
  },
  validateUrlForEditor({ getters }, data) {
    return validateUrlForEditor(getters.getToken, data);
  },
  setMenuSearchType({ commit }, searchType) {
    return commit("setMenuSearchType", searchType);
  },
  setMenuSearchCriteria({ commit }, searchCriteria) {
    return commit("setMenuSearchCriteria", searchCriteria);
  },
  saveProjectSettings({ getters, commit }, data) {
    return saveProjectSettings(getters.getToken, data);
  },
  // updateProjectPinStatus({ getters, commit }, data) {
  //   return updateProjectPinStatus(getters.getToken, data);
  // },
  saveRevisionHistoryForContentEditor({ getters, commit }, data) {
    return saveRevisionHistoryForContentEditor(getters.getToken, data);
  },
  saveEditedSourceCode({ getters, commit }, data) {
    return saveEditedSourceCode(
      getters.getToken, 
      data, 
      state.selectedSubAccountRelation?.id
    );
  },
  loadSavedSourceCode({ getters, commit }, data) {
    return loadSavedSourceCode(
      getters.getToken, 
      data, 
      state.selectedSubAccountRelation?.id
    );
  },
  loadForSpecificRevisionData({ getters, commit }, data) {
    return loadForSpecificRevisionData(getters.getToken, data);
  },
  getWeeklyPageRunStats(state) {
    return getWeeklyPageRunStats();
  },
  unsubscribeWatchdogMadeByAdmin({ getters }, data) {
    return unsubscribeWatchdogMadeByAdmin(data);
  },
  updateScoringStrategy({ getters, state }, data) {
    return updateScoringStrategy(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  updateSummaryReportDay({ getters, state }, data) {
    return updateSummaryReportDay(
      getters.getToken,
      state.user.id,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  neverMindClicked({ getters, state }) {
    return neverMindClicked(getters.getToken, state.user.id);
  },
  testRunForSummaryReport({ getters, state }) {
    return testRunForSummaryReport(
      getters.getToken,
      state.user.id,
      state.selectedSubAccountRelation.id
    );
  },
  updateRecommendationsForPage({ getters, state }, data) {
    return updateRecommendationsForPage(getters.getToken, data);
  },
  // Test api endpoint
  openApiTest({ getters }, data) {
    return openApiTest(getters.getToken, data);
  },
  getPromptForAI({ getters }, data) {
    return getPromptForAI(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getGenerateSchema({ getters }, data) {
    return getGenerateSchema(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getRequestSchema({ getters }, data) {
    return getRequestSchema(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getAnswerWithAI({ getters }, data) {
    return getAnswerWithAI(getters.getToken, data);
  },
  getAIAnswerForContentPrompt({ getters }, data) {
    return getAIAnswerForContentPrompt(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadAiRun({ getters }, data) {
    return loadAiRun(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  aiRunChangeCurrentSelection({ getters }, data) {
    return aiRunChangeCurrentSelection(getters.getToken, data);
  },
  aiRunSaveEditedContent({ getters }, data) {
    return aiRunSaveEditedContent(getters.getToken, data);
  },
  aiRunChangeCurrentSelectionForRichItem({ getters }, data) {
    return aiRunChangeCurrentSelectionForRichItem(getters.getToken, data);
  },
  aiRunUpdateEditedContentForRichItem({ getters }, data) {
    return aiRunUpdateEditedContentForRichItem(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  deleteAIRun({ getters }, data) {
    return deleteAIRun(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },

  aiRunGetFullHtml({ getters }, data) {
    return aiRunGetFullHtml(getters.getToken, data);
  },

  createKeywordResearchCampaign({ getters }, data) {
    return createKeywordResearchCampaign(getters.getToken, data, state.selectedSubAccountRelation.id);
  },
  getCampaignById({ getters }, campaignId) {
    return getCampaignById(
      getters.getToken,
      campaignId,
      state.selectedSubAccountRelation.id
    );
  },
  getCampaignLatestGeneratedReports({ getters }, campaignId) {
    return getCampaignLatestGeneratedReports(
      getters.getToken,
      campaignId,
      state.selectedSubAccountRelation.id
    );
  },
  getCampaignsForSite({ getters }, siteId) {
    return getCampaignsForSite(
      getters.getToken,
      siteId,
      state.selectedSubAccountRelation.id
    );
  },

  createKeywordInsightResearch({ getters }, data) {
    return createKeywordInsightResearch(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getKeywordResearchHistoryForCampaign({ getters }, data) {
    return getKeywordResearchHistoryForCampaign(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadKeywordResearchData({ getters }, data) {
    return loadKeywordResearchData(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  updateKeywordsVisibility({ getters }, data) {
    return updateKeywordsVisibility(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  keywordResearchAnalyzeWithAI({ getters }, data) {
    return keywordResearchAnalyzeWithAI(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  updateKeywordLabel({ getters }, data) {
    return updateKeywordLabel(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getKeywordsFromTopic({ getters }, data) {
    return getKeywordsFromTopic(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  clusteringUserKeywordsExcel({ getters }, excelFile) {
    return clusteringUserKeywordsExcel(
      getters.getToken,
      excelFile,
      state.selectedSubAccountRelation.id
    );
  },
  createSupportKeywordResearch({ getters }, data) {
    return createSupportKeywordResearch(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getSupportKeywordResearchHistoryForCampaign({ getters }, data) {
    return getSupportKeywordResearchHistoryForCampaign(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadSupportKeywordResearchData({ getters }, data) {
    return loadSupportKeywordResearchData(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  supportKeywordResearch_analyzeClustersWithAI({ getters }, data) {
    return supportKeywordResearch_analyzeClustersWithAI(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  saveKeywordWizardResult({ getters }, data) {
    return saveKeywordWizardResult(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadRecentKeywordWizardData({ getters }, data) {
    return loadRecentKeywordWizardData(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadKeywordWizardMonthlySearchAndExtras({ getters }, data) {
    return loadKeywordWizardMonthlySearchAndExtras(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadKeywordWizardLabels({ getters }, data) {
    return loadKeywordWizardLabels(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  wizardUpdateKeywordLabel({ getters }, data) {
    return wizardUpdateKeywordLabel(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  topLevel_getKeywordsFromTopic({ getters }, data) {
    return topLevel_getKeywordsFromTopic(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  topLevel_createSupportKeywordResearch({ getters }, data) {
    return topLevel_createSupportKeywordResearch(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  topLevel_analyzeClustersWithAI({ getters }, data) {
    return topLevel_analyzeClustersWithAI(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  topLevel_getSupportKeywordResearchHistoryForCampaign({ getters }, data) {
    return topLevel_getSupportKeywordResearchHistoryForCampaign(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  topLevel_loadSupportKeywordResearchData({ getters }, data) {
    return topLevel_loadSupportKeywordResearchData(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  addKeywordToTrack({ getters }, data) {
    return addKeywordToTrack(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  bulkTrackKeywordToTrack({ getters }, data) {
    return bulkTrackKeywordToTrack(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  removeKeywordFromTrack({ getters }, data) {
    return removeKeywordFromTrack(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  updateTrackEmailNotificationSetting({ getters }, data) {
    return updateTrackEmailNotificationSetting(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getKeywordTrackForCampaign({ getters }, campaignId) {
    return getKeywordTrackForCampaign(
      getters.getToken,
      campaignId,
      state.selectedSubAccountRelation.id
    );
  },
  startKeywordTrack({ getters }, data) {
    return startKeywordTrack(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getMonthlySearches({ getters }, data) {
    return getMonthlySearches(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getSilos({ getters }, data) {
    return getSilos(getters.getToken, data, state.selectedSubAccountRelation?.id);
  },
  getSilo({ getters }, siloId) {
    return getSilo(getters.getToken, siloId, state.selectedSubAccountRelation?.id);
  },
  deleteSilo({ getters }, siloId) {
    return deleteSilo(getters.getToken, siloId, state.selectedSubAccountRelation?.id);
  },
  updateSilo({ getters }, data) {
    return updateSilo(getters.getToken, data.siloId, data.body, state.selectedSubAccountRelation?.id);
  },
  getSiloKeywords({ getters }, data) {
    return getSiloKeywords(getters.getToken, data, state.selectedSubAccountRelation?.id);
  },
  getSiloKeyword({ getters }, data) {
    return getSiloKeyword(getters.getToken, data, state.selectedSubAccountRelation?.id);
  },
  getSiloPDFReport({ getters }, data) {
    return getSiloPDFReport(getters.getToken, data, state.selectedSubAccountRelation?.id);
  },
  getSiloWordReport({ getters }, data) {
    return getSiloWordReport(getters.getToken, data, state.selectedSubAccountRelation?.id);
  },
  getReportHeader({ getters }, data) {
    return getReportHeader(getters.getToken, data);
  },
  addKeywordToSilo({ getters }, data) {
    return addKeywordToSilo(getters.getToken, data.siloId, data.body, state.selectedSubAccountRelation?.id);
  },
  addKeywordsToSilo({ getters }, data) {
    return addKeywordsToSilo(getters.getToken, data.siloId, data.body, state.selectedSubAccountRelation?.id);
  },
  removeKeywordFromSilo({ getters }, keywordId) {
    return removeKeywordFromSilo(getters.getToken, keywordId, state.selectedSubAccountRelation?.id);
  },
  restoreSiloKeyword({ getters }, keywordId) {
    return restoreSiloKeyword(getters.getToken, keywordId, state.selectedSubAccountRelation?.id);
  },
  updateSiloKeyword({ getters, state }, data) {
    return updateSiloKeyword(getters.getToken, data.siloKeywordId, data.body, state.selectedSubAccountRelation.id);
  },
  createSilo({ getters, state }, data) {
    return createSilo(getters.getToken, data, state.selectedSubAccountRelation.id);
  },
  getAvailableSiloContentReportPackages({ getters }) {
    return getAvailableSiloContentReportPackages(getters.getToken);
  },
  getAvailableSiloContentReportDeliveryOptionsForSite({ state, getters }, data) {
    return getAvailableSiloContentReportDeliveryOptionsForSite(getters.getToken, data, state.selectedSubAccountRelation.id);
  },
  getSiloContentReportOrders({ getters }, data) {
    return getSiloContentReportOrders(getters.getToken, data, state.selectedSubAccountRelation?.id);
  },
  updateSiloContentReportOrder({ getters, state }, data) {
    return updateSiloContentReportOrder(getters.getToken, data.siloId, data.body, state.selectedSubAccountRelation.id);
  },
  createKeywordGapAnalysis({ getters }, data) {
    return createKeywordGapAnalysis(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadKeywordGapHistory({ getters }, data) {
    return loadKeywordGapHistory(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  loadOneKeywordGapData({ getters }, data) {
    return loadOneKeywordGapData(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  playWithOpenAI({ getters }, data) {
    return playWithOpenAI(getters.getToken, data);
  },
  getProjectsForWorkstation({ getters }, params) {
    return getProjectsForWorkstation(
      getters.getToken,
      params,
      state.selectedSubAccountRelation.id
    );
  },
  getPagesForWorkstation({ getters }, data) {
    return getPagesForWorkstation(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  getPageRunForWorkStation({ getters }, data) {
    return getPageRunForWorkStation(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },

  getTaskStatus({ getters }, taskId) {
    return getTaskStatus(taskId);
  },
  getActiveTasks({ getters }, data) {
    return getActiveTasks(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  acknowledgeTask({ getters }, data) {
    return acknowledgeTask(
      getters.getToken,
      data,
      state.selectedSubAccountRelation.id
    );
  },
  updateSiloKeywordFlag({ getters }, data) {
    return updateSiloKeywordFlag(
      getters.getToken, 
      data,
      state.selectedSubAccountRelation?.id
    );
  },
  fetchPOPCharges({ getters }) {
    return fetchPOPCharges(getters.getToken);
  },
};

const mutations = {
  // isolated data mutations
  setProjects(state, projects) {
    state.projects = projects;
  },
  setSiloReportProgress(state, newVal) {
    state.siloReportProgress = newVal;
  },
  incrementSiloReportProgress(state, increment) {
    state.siloReportProgress = (state.siloReportProgress || 0) + increment;
  },
  setInsightKeywordsConfig(state, payload) {
    state.insightKeywordsConfig[payload.key] = payload.value;
  },
  resetInsightKeywords(state) {
    state.insightKeywordsConfig = cloneDeep(KEYWORDS_INSIGHT_CONFIG);
  },
  setAsyncTasks(state, payload) {
    state.asyncTasks = payload;
  },
  resetAsyncTasks(state) {
    state.asyncTasks = [];
  },
  triggerAsyncTasksRefresh(state) {
    state.triggerAsyncTasksRefresh = !state.triggerAsyncTasksRefresh;
  },
  triggerCreateDomainCampaignModal(state) {
    state.triggerCreateDomainCampaignModal = !state.triggerCreateDomainCampaignModal;
  },
  registerNewClientSideAsyncTask(state, payload) {
    state.clientSideAsyncTasks = [
      ...state.clientSideAsyncTasks,
      {
        'is_client_side': true,
        'id': payload.id,
        'type': payload.type,
        'record_id': payload.record_id,
        'task_status': {
          'status': 'PROGRESS',
          'msg': payload.task_status.msg,
          'value': payload.task_status.value
        },
        'initiated_at': (new Date()).toISOString().replace('T', ' ').split('.')[0],
        'is_pending_or_in_progress': true,
        'is_completed': 0,
        'is_acknowledged_by_user': 0,
      }
    ];
  },
  updateClientSideAsyncTaskStatus(state, payload) {
    state.clientSideAsyncTasks = state.clientSideAsyncTasks.map((task) => {
      if (task.id !== payload.id) {
        return task
      }
      if (payload.task_status.status === 'PROGRESS' && task.is_pending_or_in_progress) {
        return {
          ...task,
          'task_status': {
            ...task.task_status,
            ...payload.task_status
          },
          'is_pending_or_in_progress': true,
          'is_completed': 0,
        }
      } else if (payload.task_status.status === 'SUCCESS') {
        return {
          ...task,
          'task_status': {
            ...task.task_status,
            ...payload.task_status
          },
          'is_pending_or_in_progress': false,
          'is_completed': 1,
        }
      } else if (payload.task_status.status === 'FAILURE') {
        return {
          ...task,
          'task_status': {
            ...task.task_status,
            ...payload.task_status
          },
          'is_pending_or_in_progress': false,
          'is_completed': 0,
        }
      } else {
        return task
      }
    });
  },
  removeClientSideAsyncTask(state, targetId) {
    state.clientSideAsyncTasks = state.clientSideAsyncTasks.filter(task => task.id !== targetId)
  },
  setDemoStepDone(state, key) {
    state[key] = true;
  },
  setGlobalErrorMsg(state, { msg }) {
    state.globalErrorMsg = msg;
  },
  setAiWriterLoading(state, isLoading) {
    state.aiWriterLoading = isLoading;
  },
  setAiWriterStep(state, step) {
    state.aiWriterStep = step;
  },
  setAiWritingData(state, payload) {
    state.aiWriterData = payload;
  },
  setGenerateSchema(state, { schemaType, isLoading }) {
    state.genSchemaLoading[schemaType] = isLoading;
    state.genSchemaLoading = {
      ...state.genSchemaLoading,
      [schemaType]: isLoading,
    };
  },
  toggleSelectedSiloKeyword(state, id) {
    const newArr = cloneDeep(state.selectedSiloKeywords);
    const idx = newArr.findIndex(keywordId => keywordId === id);
    if (idx === -1) newArr.push(id);
    else newArr.splice(idx, 1)
    state.selectedSiloKeywords = newArr;
  },
  resetSelectedSiloKeywords(state) {
    state.selectedSiloKeywords = [];
  },
  selectAllSiloKeywords(state, ids) {
    state.selectedSiloKeywords = ids;
  },
  setProgress(state, { schemaType, value }) {
    state.process = { ...state.process, [schemaType]: value };
  },
  setSchemaProgress(state, { schemaType, pageRunId, value }) {
    const cpState = cloneDeep(state.schemaProgresses);
    const key = `${pageRunId}>${schemaType}`;
    cpState[key] = value || (cpState[key] || 0) + 5;

    state.schemaProgresses = cpState;
  },
  setPopUpModal(state, { pageRunId, currentLocation, value, schemaType }) {
    const cloneState = cloneDeep(state.modalData);
    state.modalData = {
      ...cloneState,
      pageRunId,
      currentLocation,
      value:
        state.pageRunActiveTab === `schemaType-${pageRunId}` ? false : value,
      schemaType,
    };
  },
  setInvoices(state, { invoices }) {
    state.userInvoices = invoices
      .slice()
      .filter((invoice) => invoice.charge !== 0)
      .sort((a, b) => a.created - b.created);
  },
  setAllData(state, payload) {
    state.user = payload;
    state.sites = payload.sites;
  },
  setSites(state, sites) {
    state.sites = sites;
  },
  setSiteContentAuditBySiteId(state, {siteId, audit}) {
    state.siteContentAuditsBySiteId = {
      ...state.siteContentAuditsBySiteId,
      [siteId]: audit,
    }
  },
  setBulkRuns(state, bulkRuns) {
    state.bulkRuns = bulkRuns;
  },
  addOrUpdateBulkRun(state, bulkRun) {
    let isExist = false;
    for (let i = 0; i < state.bulkRuns.length; i++) {
      if (state.bulkRuns[i].id == bulkRun.id) {
        isExist = true;
        break;
      }
    }
    if (isExist) {
      state.bulkRuns = state.bulkRuns.map((run) => {
        if (run.id == bulkRun.id) {
          return bulkRun;
        }
        return run;
      });
    } else {
      state.bulkRuns = [bulkRun].concat(state.bulkRuns);
    }
  },
  addSite(state, site) {
    const sites = state.sites.slice();
    sites.push(site);
    state.sites = sites;
  },
  addPage(state, page) {
    const sites = state.sites.slice();
    const idx = sites.findIndex((s) => s.id === page.siteId);
    sites[idx].pages.push(page);
    state.sites = sites;
  },
  setPage(state, page) {
    const sites = state.sites.slice();
    const idx = sites.findIndex((s) => s.id === page.siteId);
    sites[idx].pages = sites[idx].pages.map((p) => {
      if (p.id == page.id) {
        return page;
      } else {
        return p;
      }
    });
    state.sites = sites;
  },
  setPageRuns(state, pageRuns) {
    state.pageRuns = pageRuns;
  },
  setAiRuns(state, aiRuns) {
    state.aiRuns = aiRuns;
  },
  addPageRun(state, pageRun) {
    const pageRuns = state.pageRuns.slice();
    let found = pageRuns.find((p) => p.id == pageRun.id);
    if (!found) {
      pageRuns.push(pageRun);
      state.pageRuns = pageRuns;
    } else {
      state.pageRuns = pageRuns.map((item) => {
        return item.id == pageRun.id ? pageRun : item;
      });
    }
  },
  updatePageRun(state, pageRun) {
    const pageRuns = state.pageRuns.slice();
    for (let i = 0; i < pageRuns.length; i++) {
      if (pageRun.id == pageRuns[i].id) {
        pageRuns[i] = pageRun;
      }
    }
    state.pageRuns = pageRuns;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserHasPaymentSources(state, hasPaymentSource) {
    state.user.hasPaymentSource = hasPaymentSource;
  },
  setCustomLogoBase64(state, logoBase64) {
    state.customLogoBase64 = logoBase64;
  },
  setUserData(state, payload) {
    state.user = payload.userData;
  },
  setUserSettings(state, settings) {
    const user = Object.assign({}, state.user, { settings });
    state.user = user;
  },
  setJwtToken(state, payload) {
    if (payload && payload.hasOwnProperty("jwt")) {
      state.jwt = payload.jwt;
      if (payload?.is_demo) {
        sessionStorage.setItem("token", payload.jwt);
        localStorage.removeItem("token")
      } else {
        localStorage.setItem("token", payload.jwt);
        sessionStorage.removeItem("token");
      }
    } else {
      state.jwt = "";
      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
    }
  },
  setCurrentSubAccount(state, payload) {
    state.selectedSubAccountRelation = payload;
  },
  setCurrentDevAccount(state, payload) {
    state.selectedDevAccountRelation = payload;
  },
  setSite(state, payload) {
    const siteIdx = state.sites.findIndex((site) => site.id === payload.id);
    if (siteIdx > -1) {
      let sites = state.sites.map((site) => site);
      sites[siteIdx] = payload;
      state.sites = sites;
    } else {
      state.sites.push(payload);
    }
  },
  setCurrentSiteById(state, { siteId }) {
    state.currentSiteId = siteId;
    state.currentPageId = "";
  },
  setCurrentPageById(state, { pageId }) {
    state.currentPageId = pageId;
  },
  setIsUserClosedSideBar(state, payload) {
    state.isUserClosedSideBar = payload;
  },
  setHideSideBar(state, payload) {
    state.isHideSidebar = payload;
  },
  setCurrentPage(state, payload) {
    state.currentPage = payload;
  },
  setCurrentPageRun(state, payload) {
    state.currentPageRun = payload;
  },
  enQueuePageRunItem(state, { siteId, pageId, pageRunId }) {
    const queue = state.queue.slice();
    queue.push(new PageRunQueueItem(this, siteId, pageId, pageRunId));
    state.queue = queue;
  },
  deQueuePageRunItem(state, { siteId, pageId, pageRunId }) {
    const queue = state.queue.slice();
    const itemIdx = queue.findIndex(
      (item) =>
        item.siteId === siteId &&
        item.pageId === pageId &&
        item.pageRunId === pageRunId
    );
    state.queue = queue.splice(itemIdx, 1);
  },
  setAllUsers(state, { users }) {
    state.allUsers = users;
  },
  setPageRunActiveTab(state, tab) {
    state.pageRunActiveTab = tab;
  },
  updateUser(state, { user }) {
    const users = state.allUsers.slice();
    const idx = users.findIndex((u) => u.id === user.id);
    if (idx > -1) {
      users[idx] = user;
    }
    state.allUsers = users;
  },
  setCoupons(state, { coupons }) {
    state.coupons = coupons;
  },
  setAdminHistory(state, { historyRecords }) {
    state.adminHistoryRecords = historyRecords;
  },
  showLoading(state) {
    state.isLoading = true;
  },
  hideLoading(state) {
    state.isLoading = false;
  },
  setAiWriterNeedsToSave(state, value) {
    state.aiWriterNeedsToSave = value;
  },
  setSortModeSites(state, mode) {
    state.sortModeSite = mode;
  },
  setSortModePages(state, mode) {
    state.sortModePage = mode;
  },
  setSortModePageruns(state, mode) {
    state.sortModePageRun = mode;
  },
  setSeoSourceCode(state, source) {
    state.seoEditHtmlSource = source;
  },
  setEditorType(state, value) {
    state.isExpressEditor = value;
  },
  setEditedHtmlCountData(
    state,
    { wordCounts, emCounts, lsiEmCounts, tagCounts }
  ) {
    state.seoEditWordCounts = wordCounts;
    state.seoEditEmCounts = emCounts;
    state.seoEditLsiEmCounts = lsiEmCounts;
    state.seoEditTagCounts = tagCounts;
  },
  addEditorArchivedItem(state, value) {
    let found = state.editorArchivedList.find((item) => {
      return item.signal == value.signal && item.type == value.type;
    });
    if (!found) {
      state.editorArchivedList.push(value);
    }
  },
  clearEditorArchivedList(state) {
    state.editorArchivedList = [];
  },
  clearStore(state) {
    localStorage.removeItem("token");
    state.sites = [];
    state.pages = [];
    state.pageRuns = [];
    state.currentPageRun = {};
    state.user = {};
    state.jwt = "";
    state.selectedSubAccountRelation = "";
    state.queue = [];
    state.globalErrorMsg = "";
    state.allUsers = [];
    state.coupons = [];
    state.currentSiteId = "";
    state.adminHistoryRecords = [];
    state.isLoading = false;
    state.aiWriterNeedsToSave = false;
    state.notificationsForAdmin = [];
    state.userInvoices = [];
    state.menuSearchType = null;
    state.menuSearchCriteria = "";
  },
  setMenuSearchType(state, searchType) {
    state.menuSearchType = searchType;
  },
  setMenuSearchCriteria(state, searchCriteria) {
    state.menuSearchCriteria = searchCriteria;
  },
  setIsNewSiloUpdates(state, value) {
    state.isNewSiloUpdates = value;
  },
  setIsNewTrackingUpdates(state, value) {
    state.isNewTrackingUpdates = value;
  },
  setSubmittingGoogleSearch(state, value) {
    state.submittingGoogleSearch = value;
    state.currentTaskId = -1;
  },
  setShowGoogleResultsModal(state, value) {
    state.showGoogleResultsModal = value;
  },
  setGoogleSearchProgress(state, value) {
    state.googleSearchProgress = value;
  },
  setValidateUrlsProgress(state, value) {
    state.validateUrlsProgress = value;
  },
  // setCurrentTaskId(state, value) {
  //   state.currentTaskId = value;
  // },
  setCurrentGoogleCompetitorsTaskId(state, value) {
    state.currentGoogleCompetitorsTaskId = value;
  },
  setCurrentGoogleVariationsTaskId(state, value) {
    state.currentGoogleVariationsTaskId = value;
  },
  setFetchedVariations(state, value) {
    state.fetchedVariations = value;
  },
  setVariations(state, value) {
    state.variations = value;
  },
  setOriginGoogleVariations(state, value) {
    state.originGoogleVariations = value;
  },
  resetVariations(state) {
    state.variations = [];
    state.fetchedVariations = false;
  },
  resetRelatedQuestionsAndSearches(state) {
    state.originGoogleVariations = [];
    state.relatedQuestions = [];
    state.relatedSearches = [];
  },
  setRelatedQuestions(state, value) {
    state.relatedQuestions = value;
  },
  setRelatedSearches(state, value) {
    state.relatedSearches = value;
  },
  setExcludedVariations(state, value) {
    state.excludedVariations = value;
  },
  setUsedGoogleDomain(state, value) {
    state.usedGoogleDomain = value;
  },
  addExcludedVariations(state, value) {
    state.excludedVariations = [...state.excludedVariations, ...value];
  },
  setGoogleSearchLocations(state, value) {
    value.splice(5, 0, "Exact Location (Chrome Extension)");
    state.googleSearchLocations = value;
  },
  setDashboardType(state, payload) {
    state.dashboardType = payload;
  },
  setStrategy(state, strategy) {
    state.strategy = strategy;
  },
  setApproach(state, approach) {
    state.approach = approach;
  },
  updateLastReportCreationTime(state) {
    state.lastReportCreationTime = Date.now();
  },
  setCurrentAIRun(state, payload) {
    state.currentAIRun = payload;
  },

  setCurrentVideoTutorialToDisplay(state, payload) {
    state.currentVideoTutorial = payload;
  },
  setCurrentPageForSite(state, pageNum) {
    state.currentPageForSite = pageNum;
  },
  setCurrentPageForPage(state, pageNum) {
    state.currentPageForPage = pageNum;
  },

  setCurrentCampaign(state, c) {
    state.currentCampaign = c;
  },
  updateGoogleUser(state, u) {
    state.googleUser = u;
  },
  setShowSiloContentReportsOrdersCart(state, payload) {
    state.showSiloContentReportsOrdersCart = payload;
  },
  triggerRefreshSiloContentReportsOrdersCart(state, payload) {
    state.refreshSiloContentReportsOrdersCart = payload;
  },
  setPOPCharges(state, charges) {
    state.popCreditCharges = charges;
  },
};

const getters = {
  // reusable data accessors
  isSchemaLoading: (state) => {
    return state.genSchemaLoading;
  },
  aiWriterNeedsToSave: (state) => {
    return state.aiWriterNeedsToSave;
  },
  siloReportProgress: (state) => {
    return state.siloReportProgress;
  },
  isNewSiloUpdates: (state) => {
    return state.isNewSiloUpdates;
  },
  isNewTrackingUpdates: (state) => {
    return state.isNewTrackingUpdates;
  },
  selectedSiloKeywords: (state) => {
    return state.selectedSiloKeywords;
  },
  insightKeywordsConfig: (state) => {
    return state.insightKeywordsConfig;
  },
  asyncTasks: (state) => {
    return state.asyncTasks;
  },
  isDemoUser: () => {
    const { sub } = decodeToken(state.jwt) || {};
    return DEMO_USER_EMAILS.includes(sub);
  },
  aiWriterStep: (state) => {
    return state.aiWriterStep;
  },
  getSiteData: (state) => {
    return state.sites;
  },
  getSiteContentAuditBySiteIdData: (state) => (siteId) => {
    return state.siteContentAuditsBySiteId[siteId];
  },
  getGoogleLocations: (state) => {
    return state.googleSearchLocations;
  },
  getAiWriterData: (state) => {
    return state.aiWriterData;
  },
  isProcess: (state) => {
    return state.process;
  },
  getSchemaProgresses: (state) => state.schemaProgresses,
  getModalData: (state) => state.modalData,
  getToken(state) {
    if (!state.jwt) {
      if (localStorage.getItem("token")) {
        return localStorage.getItem("token");
      }
      return sessionStorage.getItem("token") || false;
    }
    return state.jwt;
  },
  isAuthenticated(state, getters) {
    const token = getters.getToken;
    if (!token) {
      return false;
    }
    return isValidJwt(token);
  },
  isWatchdogOnlyUser(state) {
    if (state.user && watchdogOnlyServices.includes(state.user.accountType)) {
      return true;
    }
    return false;
  },
  requestSchemas(state) {
    return state.user?.requestedSchemas || [];
  },
  isSpecialAdminUser(state) {
    if (state.user && state.user.isAdmin && state.user.isSpecialAdmin) {
      return true;
    }
    return false;
  },
  isSpecialOwnerSelected(state) {
    if (state.user && state.selectedSubAccountRelation) {
      return state.selectedSubAccountRelation.isSpecialAdminOwner;
    }
    return false;
  },
  isAvailableToPurchaseAdditionalSeats(state) {
    const pureServicePkg = getPureServicePkgName(state.user.accountType);
    if (
      state.user &&
      agencyPlansWithAdditionalSeatsAvailable.includes(pureServicePkg)
    ) {
      return true;
    }
    return false;
  },
  additionalSeatPkgName(state) {
    if (state.user) {
      const pkg = correspondingSeatPlans[state.user.accountType]; // here you need to use full package name , not pure package name.
      return pkg || "";
    }
    return "";
  },
  getPages(state) {
    let pages = [];
    if (state.sites) {
      state.sites.forEach((site) => {
        pages = pages.concat(site.pages);
      });
    }
    return pages;
  },
  siteById: (state) => (id) => {
    return state.sites.find((site) => site.id === id);
  },
  pageRunById: (state) => (id) => {
    return state.pageRuns.find((pageRun) => pageRun.id === id);
  },
  isCalculatingBulkNow(state) {
    for (let i = 0; i < state.bulkRuns.length; i++) {
      if (["PROGRESS", "PENDING"].includes(state.bulkRuns[i].status)) {
        return state.bulkRuns[i];
      }
      if (i < 3) {
        // just for performance update
        if (state.bulkRuns[i].status == "REVOKED") {
          for (const run of state.bulkRuns[i].allRuns) {
            if (run.isAIRun) {
              if (run.autoStatus != "SUCCESS" && run.autoStatus != "FAILURE") {
                return state.bulkRuns[i];
              }
            } else {
              if (run.status != "SUCCESS" && run.status != "FAILURE") {
                return state.bulkRuns[i];
              }
            }
          }
        }
      }
    }
    return false;
  },
  isUnlimitedUser(state) {
    if (!state.user) {
      return false;
    }
    if (state.user.isVendor) {
      return 0;
    } // because this is vendor, he can not make pagerun
    const accountType = getPureServicePkgName(state.user.accountType);
    if (UNLIMITED_SUBSCRIPTION_SERVICES.indexOf(accountType) > -1) {
      return 100;
    }
    if (accountType === "end_user") {
      return 100;
    }
    return false;
  },
  availablePOPKeys(state) {
    let rlt = [];
    if (!state.user) {
      return rlt;
    }
    let ownerAcc = state.user;
    if (state.selectedDevAccountRelation) {
      ownerAcc = state.selectedDevAccountRelation.ownerUser;
    }

    if (ownerAcc.addOnApiKey) {
      rlt.push({
        keyLabel: "Add-on API key",
        keyType: "ADD_ON_KEY",
        key: ownerAcc.addOnApiKey,
        dateCreated: ownerAcc.addOnApiKeyCreatedAt,
      });
    }
    if (ownerAcc.isDevApiKeyAllowed) {
      rlt.push({
        keyLabel: "Dev API key",
        keyType: "DEV_PACKAGE_KEY",
        key: ownerAcc.devApiKey,
        dateCreated: ownerAcc.devApiKeyCreatedAt,
      });
    }
    if (ownerAcc.isPartnershipApiKeyAllowed) {
      rlt.push({
        keyLabel: "Partership API Key",
        keyType: "PARTNER_KEY",
        key: ownerAcc.partnerApiKey,
        dateCreated: ownerAcc.partnerApiKeyCreatedAt,
      });
    }
    return rlt;
  },
  // hasSufficientFunds(state, getters) {
  //   if (state.user.isVendor) {
  //     return false;
  //   } // because this is vendor, he can not make pagerun
  //   if (state.selectedSubAccountRelation != "") return true;

  //   if (getters.isUnlimitedUser) {
  //     return true;
  //   }

  //   if (!state.user) {
  //     return false
  //   }
  //   let allCredits = state.user.monthlyCredits + state.user.remainedUniversalCredits
  //   return allCredits > 0;

  //   // if (accountType === "gold" || accountType === "trial") {
  //   //   return allCredits > 0;
  //   // }

  // },
  availableReportCreditsOfUserOrTeam(state, getters) {
    if (state.selectedSubAccountRelation) {
      return 999;
    }
    if (getters.isUnlimitedUser) {
      return 999;
    }

    return state.user.monthlyCredits + state.user.remainedUniversalCredits;
  },
  availableCreditsOfUserOrTeam(state) {
    let user = state.selectedSubAccountRelation || state.user;
    return user.monthlyCredits + user.remainedUniversalCredits;
  },
  isAgencyUnlimitedUser() {
    if (state.selectedSubAccountRelation) {
      return true;
    }
    const accountType = getPureServicePkgName(state.user.accountType);
    return AGENCY_PLANS.indexOf(accountType) > -1;
  },
  hasPOPApiKey(state) {
    if (!state.user) {
      return false;
    }
    return (
      state.user.addOnApiKey ||
      state.user.isDevApiKeyAllowed ||
      state.user.isPartnershipApiKeyAllowed
    );
  },
  hasApiDashboardAccess(state, getters) {
    if (getters.hasPOPApiKey) {
      return true;
    }
    if (state.selectedDevAccountRelation) {
      return true;
    }
    return false;
  },
  isPartnershipApiKeyAllowed(state) {
    return state.user.isPartnershipApiKeyAllowed;
  },
  isAdmin(state) {
    if (state.user && state.user.isAdmin) {
      return true;
    }
    return false;
  },
  isTrialUser(state) {
    if (state.user) {
      return state.user.isTrial;
    }
    return false;
  },
  getFilteredPages(state) {
    let pages = [];
    let criteria =
      state.menuSearchCriteria && state.menuSearchCriteria.toLowerCase();
    state.sites.map((site) =>
      site.pages.map((page) => {
        pages.push({ siteId: site.id, siteName: site.name, ...page });
      })
    );
    return pages
      .filter((x) => {
        if (state.menuSearchType === "pages") {
          return x.name && x.name.toLowerCase().indexOf(criteria) !== -1;
        }
      })
      .sort(sortByName);
  },
  getFilteredPagesByKeyword(state) {
    let pages = [];
    let criteria =
      state.menuSearchCriteria && state.menuSearchCriteria.toLowerCase();
    state.sites.map((site) =>
      site.pages.map((page) => {
        pages.push({ siteId: site.id, siteName: site.name, ...page });
      })
    );
    return pages
      .filter((x) => {
        if (state.menuSearchType === "keywords") {
          return x.keyword && x.keyword.toLowerCase().indexOf(criteria) !== -1;
        }
      })
      .sort(sortByKeyword);
  },
  isLoginOrRegister: (state) => (routeName) => {
    return [
      "StandardRegister",
      "DynamicRegister",
      "RegisterNoCard",
      "Login",
      "PopDemo",
      "Home",
      "ForgotPassword",
      "RegisterCheckEmail",
      "UpdatePassword",
    ].includes(routeName);
  },
  clientSideAsyncTaskById: (state) => (id) => {
    return state.clientSideAsyncTasks.find((task) => task.id === id);
  },
  getPOPCharges(state) {
    return state.popCreditCharges;
  },
};

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
});

export default store;
