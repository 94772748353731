<template>
<div class="tool-card pa-5" style="background: linear-gradient(354.92deg, #F8FFFF 42.34%, #FFFFFF 92.66%);">
  <img class="card-bg" src="/static/keyword-research-card-locked-bg.png" />
  
  <div class="top-part">
    <span class="icon-part">
      <span class="material-icons-outlined">vpn_key</span>
    </span>
    <span class="name">
      Keyword Research<br/>
      <span style="font-weight: normal; font-size: 0.8rem;">Available on Unlimited & Team plans</span>
    </span>
  </div>
  <!-- NOTE: The tab selector code below is commented-out in order to hide the Page tab -->

  <div class="mt-5">

  </div>

  <div class="flex-grow-1 pages-wrapper mt-3" style="position: relative;">
    <div class="font-weight-bold mainColor--text mb-3" style="font-size: 1.1rem; line-height: 1.5;">Unlock new Keyword<br/>Research Tools:</div>
    <div>
      <div>
        <i class="material-icons mr-2 font-weight-bold" style="color: #00AA64; font-size: 1.1rem; line-height: 1.5; vertical-align: sub;">check</i>
        <span class="mainColor--text" style="font-size: 1rem; line-height: 1.5;">Keyword Insight</span>
      </div>
      <div>
        <i class="material-icons mr-2 font-weight-bold" style="color: #00AA64; font-size: 1.1rem; line-height: 1.5; vertical-align: sub;">check</i>
        <span class="mainColor--text" style="font-size: 1rem; line-height: 1.5;">Keyword Strategy</span>
      </div>
      <div>
        <i class="material-icons mr-2 font-weight-bold" style="color: #00AA64; font-size: 1.1rem; line-height: 1.5; vertical-align: sub;">check</i>
        <span class="mainColor--text" style="font-size: 1rem; line-height: 1.5;">Keyword Tracker</span>
      </div>
    </div>
  </div>
  <div class="mt-5 d-flex align-center justify-space-between">
    <v-btn rounded small color="profileAnchorColor"
      class="button-with-icon whiteColor--text text-none"
      @click="() => {
        $router.push({
          name: 'ServicePurchase',
          params: {
            userId: user.id,
            from_locked_keyword_research_card: 1
          }
        })
      }">
      <span>Upgrade here</span>
    </v-btn>
  </div>

</div>
</template>

<script>

export default {
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>
.tool-card {

  position: relative;

  .card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

}
</style>
