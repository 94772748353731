<template>
<div class="wow fadeIn flex-grow-1 rows-with-two-col-for-create-run" data-wow-duration="1s">
<div class="main-padding left-main-area">
  <div>
    <div class="report-top-left-icon">
      <template v-if="isExpressReport">
        <i class="material-icons">rocket_launch</i>
        <span>POP Pro</span>
      </template>
      <template v-else>
        <i class="material-icons">display_settings</i>
        <span>POP Custom</span>
      </template>
    </div>
    <i class="is-pulled-right material-icons newpage-close-button" @click="confirmCloseModal=true">close</i>
  </div>

  <template v-if="isOnePageSetup">
    <one-page-pro-step
      v-model="keyword"
      :useSecondaryKeywords="useSecondaryKeywords"
      :secondaryKeywordsArray="secondaryKeywords"
      @toggleUseSecondaryKeywords="() => useSecondaryKeywords = !useSecondaryKeywords"
      @secondarykeywordschange="(s_kwds) => secondaryKeywords = s_kwds"

      :targetLang="targetLang"
      :googleSearchLocations="googleSearchLocations"

      :targetLanguages="targetLanguages"
      @languagechange="(l) => targetLang = l"

      :ignoreSpaces="ignoreSpaces"
      @ignorespaceschange="(ignore) => ignoreSpaces = ignore"

      :location="proxyLocation"
      @locationchange="(l) => proxyLocation = l"

      :willUseGoogleNlp="willUseGoogleNlp"
      @usegooglenlpchange="(usenlp) => willUseGoogleNlp = usenlp"

      :willUseEatAnalysis="willUseEatAnalysis"
      @useeatanalysischange="(useeat) => willUseEatAnalysis = useeat"

      :willUseNextGenEat="willUseNextGenEatAnalysis"
      @usenextgeneatanalysischange="(useeat) => willUseNextGenEatAnalysis = useeat"

      :isExpressReport="isExpressReport"
      
      :url="targetUrl"
      @urlchange="(url) => targetUrl = url"
      
      :switchedSiteProtocol="switchedSiteProtocol"
      @protocolchange="(p) => switchedSiteProtocol = p"
      
      :pageNotBuilt="pageNotBuilt"
      @pagenotbuiltchange="(notbuilt) => pageNotBuilt = notbuilt"

      :useKeywordPrecisionSearch="useKeywordPrecisionSearch"
      @usekeywordprecisionsearchchange="(p) => useKeywordPrecisionSearch = p"
      
      :name="name"
      @pagenamechange="(n) => name = n"
      
      :submittingRun="submittingRun"
      
      :isTargetUrlSourceDirectly="isTargetUrlSourceDirectly"
      @targetsourcedchange="(d) => isTargetUrlSourceDirectly = d"
      
      :targetUrlSourceCode="targetUrlSourceCode"
      @targetsourcecodechange="(c) => targetUrlSourceCode = c"
      
      :advanceable="isReadyForSubmitInOnePageSetup()"
      @gonextpage="submitForOnePageSetup"
      :cleanBaseUrl="cleanBaseUrl"
      :disablePathInput="disabledPagePathInput()"

      :isAgencyUnlimitedUser="isAgencyUnlimitedUser"

      :isRerunPage="false"
      ></one-page-pro-step>
  </template>
  <template v-else>
    <add-keyword
      v-show="currentPage==PageRunPages.ADD_KEYWORD"
      v-model="keyword"
      :useSecondaryKeywords="useSecondaryKeywords"
      :secondaryKeywordsArray="secondaryKeywords"
      :advanceable="isReadyInPage(PageRunPages.ADD_KEYWORD)"
      @toggleUseSecondaryKeywords="() => useSecondaryKeywords = !useSecondaryKeywords"
      @secondarykeywordschange="(s_kwds) => secondaryKeywords = s_kwds"
      @nextpage="goNextPage"
      :errorMsg="errorMsg"
      :url="targetUrl"
      @urlchange="(url) => targetUrl = url"
      
      :switchedSiteProtocol="switchedSiteProtocol"
      @protocolchange="(p) => switchedSiteProtocol = p"
      
      :pageNotBuilt="pageNotBuilt"
      @pagenotbuiltchange="(notbuilt) => pageNotBuilt = notbuilt"

      :useKeywordPrecisionSearch="useKeywordPrecisionSearch"
      @usekeywordprecisionsearchchange="(p) => useKeywordPrecisionSearch = p"
      
      :name="name"
      @pagenamechange="(n) => name = n"
      
      :submittingRun="submittingRun"
      
      :isTargetUrlSourceDirectly="isTargetUrlSourceDirectly"
      @targetsourcedchange="(d) => isTargetUrlSourceDirectly = d"
      
      :targetUrlSourceCode="targetUrlSourceCode"
      @targetsourcecodechange="(c) => targetUrlSourceCode = c"
      
      :cleanBaseUrl="cleanBaseUrl"
      :disablePathInput="disabledPagePathInput()"
      :isExpressReport="isExpressReport"
      
      ></add-keyword>

    <locale-selection
      v-if="currentPage==PageRunPages.CHOOSE_LANGUAGE_AND_REGION"
      :targetLang="targetLang"
      :googleSearchLocations="googleSearchLocations"
      :location="proxyLocation"
      @locationchange="(l) => proxyLocation = l"
      
      :targetLanguages="targetLanguages"
      @languagechange="(l) => targetLang = l"
      
      :ignoreSpaces="ignoreSpaces"
      @ignorespaceschange="(ignore) => ignoreSpaces = ignore"

      :useKeywordPrecisionSearch="useKeywordPrecisionSearch"
      @usekeywordprecisionsearchchange="(p) => useKeywordPrecisionSearch = p"
      
      :willUseGoogleNlp="willUseGoogleNlp"
      @usegooglenlpchange="(usenlp) => willUseGoogleNlp = usenlp"
      
      :willUseEatAnalysis="willUseEatAnalysis"
      @useeatanalysischange="(useeat) => willUseEatAnalysis = useeat"

      :willUseNextGenEat="willUseNextGenEatAnalysis"
      @usenextgeneatanalysischange="(useeat) => willUseNextGenEatAnalysis = useeat"
      
      :isRememberLangAndProxy="isRememberLangAndProxy"
      @langandproxychange="(remember) => isRememberLangAndProxy = remember"

      :useOwnCompetitorList="useOwnCompetitorList"
      @useOwnCompetitorChange="(useOwn) => useOwnCompetitorList = useOwn"
      
      :googleSearchResults="googleSearchResults"
      :isAvailableChromeExtension="isAvailableChromeExtension"
      :stepRerunnable="googleSearchResults.length"
      :advanceable="isReadyInPage(PageRunPages.CHOOSE_LANGUAGE_AND_REGION)"
      :isAgencyUnlimitedUser="isAgencyUnlimitedUser"
      @goprevpage="goPrevPage"
      @gonextpage="goNextPage"
      @skipsteprerun="goNextPage(jumpToStep= useOwnCompetitorList ? PageRunPages.ADD_COMPETITORS : PageRunPages.METHOD_OF_COMPETITOR_SELECTION)"
      :isExpressReport="isExpressReport"
      :submittingGoogleSearch="submittingGoogleSearch"
      :errorMsg="errorMsg"></locale-selection>

    <variations
      v-if="currentPage==PageRunPages.VARIATIONS"
      :keyword="keyword"
      :secondaryKeywords="cleanedSecondaryKeywords"
      :variations="variations"
      @variationschange="(vars) => $store.commit('setVariations', vars)"
      
      :runWithoutVariation="runWithoutVariation"
      @runvariationschange="(noVars) => runWithoutVariation = noVars"
      
      :isExpressReport="isExpressReport"
      :advanceable="isReadyInPage(PageRunPages.VARIATIONS)"
      @goprevpage="goPrevPage"
      @gonextpage="goNextPage"
      :errorMsg="errorMsg"></variations>
        
    <competitors-methodology
      v-show="currentPage==PageRunPages.METHOD_OF_COMPETITOR_SELECTION"
      :isUserWillPickCompetitors="isUserWillPickCompetitors"
      @userpickscompetitorschange="(c) => isUserWillPickCompetitors = c"
      :advanceable="isReadyInPage(PageRunPages.METHOD_OF_COMPETITOR_SELECTION)"
      @goprevpage="goPrevPage"
      @gonextpage="goNextPage"
      :errorMsg="errorMsg"></competitors-methodology>

    <add-competitors
      v-show="currentPage==PageRunPages.ADD_COMPETITORS"
      :competitorUrls="competitorUrls"
      @competitorurlschange="(urls) => competitorUrls = urls"
      @setErrorMsg="setErrorMsg"
      
      :competitorUrlsArray="competitorUrlsArray"
      :validatedCompetitorUrls="validatedCompetitorUrls"
      :submittingRun="submittingRun"
      :submittingValidation="submittingValidation"
      :activeManualCompetitorSource="activeManualCompetitorSource"
      :manualCopySourceCompetitorUrls="manualCopySourceCompetitorUrls"
      :competitorsUrlValidationError="competitorsUrlValidationError"
      :invalidCompetitorUrls="invalidCompetitorUrls"
      :competitorUrlsMissingHttpError="competitorUrlsMissingHttpError"
      :missingHttpCompetitorUrls="missingHttpCompetitorUrls"
      :missingPastingManualCompetitorUrlSources="missingPastingManualCompetitorUrlSources"
      :manualCompetitorUrlsSources="manualCompetitorUrlsSources"
      
      :advanceable="isReadyInPage(PageRunPages.ADD_COMPETITORS)"
      :isAlreadyDoneValidatingUrl="isAlreadyDoneValidatingUrl"
      :googleSearchResults="googleSearchResults"
      
      @showgoogleresults="onShowGoogleResults"
      @togglecompetitorsourcearea="toggleDisplayActiveCompetitorSourceArea"
      @toggleurlinmanuallist="toggleUrlIsInManualCopySourceList"
      @changemanualcompetitorsource="changedManualCompetitorUrlSource"
      @goprevpage="goPrevPage"
      @gonextpage="goNextPage"
      :errorMsg="errorMsg">
    </add-competitors>

    <focus-group
      v-show="currentPage==PageRunPages.SELECT_FOCUS_GROUP"
      :validatedCompetitorUrls="validatedCompetitorUrls"
      :submittingRun="submittingRun"
      :disabled="false"
      :advanceable="true"
      :focusCompetitorUrls="focusCompetitorUrls"
      @focuscompetitorurlschange="(urls) => focusCompetitorUrls = urls"
      @goprevpage="goPrevPage"
      @gonextpage="goNextPage"
      :errorMsg="errorMsg">
    </focus-group>
    
    <lsa
      v-if="currentPage==PageRunPages.LSA"
      :keyword="keyword"
      :variations="variations"
      
      :phrases="lsaPhrases"
      @phraseschange="(phrases) => lsaPhrases = phrases"
      :removedLSITermsByUser="removedLSITermsByUser"
      @removedLSITermsByUserchange="(terms) => removedLSITermsByUser = terms"
      
      :runWithoutPhrases="runWithoutPhrases"
      @runwithoutphrasesschange="(noPhrases) => runWithoutPhrases = noPhrases"
      
      :isExpressReport="isExpressReport"
      :advanceable="true"
      :submittingRun="submittingRun"
      @submitform="submitForm"
      @goprevpage="goPrevPage"
      :errorMsg="errorMsg"></lsa>

      <!-- <p class='has-text-centered profileAnchorColor--text' v-if="errorMsg">{{ errorMsg }}</p> -->
  </template>
</div>

<steps-summary
  :isOnePageSetup="isOnePageSetup"
  :site="site"
  :keyword="keyword"
  :useSecondaryKeywords="useSecondaryKeywords"
  :secondaryKeywords="cleanedSecondaryKeywords"
  :baseUrl="baseUrl"
  :errorMsg="errorMsg"
  :isGoogleSearchDoneWithExtension="isGoogleSearchDoneWithExtension"
  :proxyLocation="proxyLocation"
  :targetUrl="targetUrl"
  :targetLang="targetLang"
  :pageNotBuilt="pageNotBuilt"
  :cleanBaseUrl="cleanBaseUrl"
  :isExpressReport="isExpressReport"
  :competitorUrlsArray="competitorUrlsArray"
  :validatedCompetitorUrls="validatedCompetitorUrls"
  :invalidCompetitorUrls="invalidCompetitorUrls"
  :focusCompetitorUrls="focusCompetitorUrls"
  :currentPage="currentPage"
  :runWithoutVariation="runWithoutVariation"
  :variations="variations"
  :lsaPhrases="lsaPhrases"
  :runWithoutLsaPhrases="runWithoutPhrases"></steps-summary>

  
  <!-- only for non pro run case modals -->
  <template v-if="!isOnePageSetup">
    
    <div class="modal" :class="{'is-active': submittingGoogleSearch && [PageRunPages.ADD_COMPETITORS, PageRunPages.METHOD_OF_COMPETITOR_SELECTION].includes(currentPage)}">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container d-flex flex-column justify-center">
          <div>
            <div class="d-flex justify-center">
              <v-progress-circular
                :rotate="-90"
                :size="160"
                :width="4"
                :value="googleSearchProgress"
                color="green15Color"
              >
                <img src="/static/pop_green_circle_logo.png" class="logo-img" />
              </v-progress-circular>
            </div>
            <div class="just-moment">
              Just a moment, we're getting<br/>
              your competitors
            </div>
            <div class="sub-explain">
              <template v-if="isGoogleSearchDoneWithExtension">
                When using the POP Chrome Extension, you will notice tabs opening in your Chrome window.
                The extension is using your current Chrome settings.
                The tool will close those windows for you so do not touch them while the tool is running.
              </template>
            </div>
            <!-- <div class="action-area">
              <template v-if="!isGoogleSearchDoneWithExtension">
                <span v-if="isCancellingProgress" class="is-italic is-info">Cancelling...</span>
                <v-btn rounded
                  color="profileAnchorColor"
                  class="button-with-icon whiteColor--text text-none"
                  :disabled="currentTaskId == -1" @click.stop="cancelGoogleSearch">
                  Cancel
                </v-btn>
              </template>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'is-active': showTooManyCompetitorsModal}">
      <div class="modal-background" @click="showTooManyCompetitorsModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <div class="subtitle-font mainColor--text text-center">
              You may only have up to {{ MAX_COMPETITORS }} competitors. <br/>
              Please reduce your competitors to {{ MAX_COMPETITORS }} or fewer and submit again.
            </div>
            <div class="text-right">
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none" @click="showTooManyCompetitorsModal = false">Ok</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal google-result-modal" :class="{'is-active': showGoogleResultsModal && googleSearchResults.length && !submittingGoogleSearch}">
      <div class="modal-background" @click.stop="$store.commit('setShowGoogleResultsModal', false)"></div>
      <div class="modal-content" style="min-width: 70vw !important;">
        <div class="box modal-container flex" style="overflow: hidden;">
          <div class="flex flex-column">
            <div class="top-part">
              <img src="/static/google-icon.png" style="width: 25px; height: auto;" />
              <span class="is-size-4 font-weight-bold">Google Search Results</span>
              <div class="how-many-comp-selected-bar" :class="{'active': combinedSelectedCompetitors.length >= MAX_COMPETITORS}">
                <i class="material-icons">ads_click</i>
                <span >{{ combinedSelectedCompetitors.length }} of {{ MAX_COMPETITORS }} maximum competitors selected</span>
                <span class="badge">{{ combinedSelectedCompetitors.length }}/{{ MAX_COMPETITORS }} </span>
              </div>
            </div>
            <div class="mt-4">
              Some results might be slightly out of order from a true local search. That's ok. If you see results that are not accurate for your location we recommend using the POP Chrome Extension.
              If there are competitors that you want to add that aren't in this list, you can copy and paste them into POP on the next screen.
            </div>
            <div class="must-select-3 mt-7 mb-7">
              <i class="material-icons">info_outline</i>
              <span>You must select at least 3 competitors. You can open this window again after closing it.</span>
            </div>
            <div class="google-result-modal-list flex-grow-1">
              <div class="mb20 google-result-list-item" v-for="(page, i) in googleSearchResults" :key="`search-result-${i}`">
                <div>
                  <div>
                    <label :for="`search-result-${i}`" class="checkbox checkbox-container">
                      <a>{{ i + 1 }}</a>
                      <input
                        v-model="selectedGooglePages"
                        :id="`search-result-${i}`"
                        type="checkbox"
                        :value="page"
                        :disabled="isGoogleSelectionDisabled(page)"
                        >
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div>
                    <div class="subtitle-font mb-2">
                      <a :href="page.url" target="_blank" class="font-weight-bold">{{ _.startCase(page.title) }}</a>
                    </div>
                    <div class="mb-2">
                      <a :href="page.url" target="_blank" class="font-weight-bold link15Color--text word-break-all">{{ page.url }}</a>
                    </div>
                    <div v-html="page.description"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 text-right">
              <v-btn rounded small
                color="profileAnchorColor"
                class="button-with-icon text-none whiteColor--text" @click.stop="useGoogleSearchResults">
                <span>Use selected competitors</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click.stop="$store.commit('setShowGoogleResultsModal', false)"><i class="material-icons">close</i></button>
      </div>
    </div>

    <div class="modal" :class="{'is-active': submittingValidation}">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container d-flex flex-column justify-center">
          <div>
            <div class="d-flex justify-center">
              <v-progress-circular
                :rotate="-90"
                :size="125"
                :width="4"
                :value="$store.state.validateUrlsProgress"
                color="green15Color"
              >
                <img src="/static/pop_green_circle_logo.png" class="logo-img" />
              </v-progress-circular>
            </div>
            <div class="just-moment">
              <template v-if="isFetchingSourceForTargetUrl">
                We're visiting your page.
              </template>
              <template v-else>
                Checking out the competition. <br/>
                This process may take up to 3 minutes.
              </template>
              <div>
                <div class="mt-5">
                  While you're waiting...
                </div>
                <VueSlickCarousel v-if="selected3Tips.length > 0"
                  v-bind="carouselSetting" class="mt-5 carousel">
                  <div class="seo-tip-carousel-item" v-for="(item, idx) in selected3Tips" :key="idx">
                    <b>TIP: </b>{{ item }}
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
            <div class="sub-explain">
            </div>
            <div class="action-area">
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{'is-active': submittingFetchLsaProcess && currentPage == PageRunPages.LSA}">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container">
          <p class="subtitle-font mainColor--text">
            We are getting your LSI terms, please wait just a moment.
          </p>
        </div>
      </div>
    </div>
  </template>
  <template v-if="isOnePageSetup">
    <div class="modal" :class="{'is-active': showModalForProReportSteps }">
      <div class="modal-background"></div>
      <div class="modal-content report-progress-modal-width">
        <div class="box modal-container d-flex flex-column justify-center" :style="{ 'min-height': failedAutoPickCompetitors ? 'auto' : '350px'}">
          <div v-if="failedAutoPickCompetitors" class="pad25">
            <div class="subtitle">
              No direct SEO competitors found in the SERPs. You can now use the custom run feature to manually add your chosen competitors' URLs for targeted analysis.
            </div>
            <div class="mt-5 text-right">
              <v-btn rounded
                color="grayColor"
                class="button-with-icon gray2Color--text text-none mr-1"
                @click="() => $router.go(-1)"
              >
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="switchToCustomRun"
              >
                <span>Switch to custom run</span>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div class="d-flex justify-center">
              <v-progress-circular
                :rotate="-90"
                :size="125"
                :width="4"
                :value="proProgressValue"
                color="green15Color"
              >
                <img src="/static/pop_green_circle_logo.png" class="logo-img" />
              </v-progress-circular>
            </div>
            <template v-if="submittingGoogleSearch">
              <div class="just-moment">
                Just a moment, we're getting<br/>
                your competitors
              </div>
              <div class="sub-explain">
                <template v-if="isGoogleSearchDoneWithExtension">
                  When using the POP Chrome Extension, you will notice tabs opening in your Chrome window.
                  The extension is using your current Chrome settings.
                  The tool will close those windows for you so do not touch them while the tool is running.
                </template>
              </div>
              <!-- <div class="action-area">
                <template v-if="!isGoogleSearchDoneWithExtension">
                  <span v-if="isCancellingProgress" class="is-italic is-info">Cancelling...</span>
                  <v-btn rounded
                    color="profileAnchorColor"
                    class="button-with-icon whiteColor--text text-none" :disabled="currentTaskId == -1" @click.stop="cancelGoogleSearch">
                    Cancel
                  </v-btn>
                </template>
              </div> -->
            </template>
            <template v-else-if="submittingValidation">
              <div class="just-moment">
                <template v-if="isFetchingSourceForTargetUrl">
                  We're visiting your page.
                </template>
                <template v-else>
                  Checking out the competition. <br/>
                  This process may take up to 3 minutes.
                </template>
                <div>
                  <div class="mt-5">
                    While you're waiting...
                  </div>
                  <VueSlickCarousel v-if="selected3Tips.length > 0"
                    v-bind="carouselSetting" class="mt-5 carousel">
                    <div class="seo-tip-carousel-item" v-for="(item, idx) in selected3Tips" :key="idx">
                      <b>TIP: </b>{{ item }}
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
              <div class="sub-explain">
              </div>
              <div class="action-area">
              </div>
            </template>
            <template v-else-if="submittingFetchLsaProcess">
              <div class="just-moment">
                Getting your important terms
              </div>
            </template>
            <template v-else>
              <div class="just-moment">
                Just a moment, we are creating the report in your dashboard now.  You will be redirected to the Pageruns page.
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
  

  <!-- <focus-group-confirm-modal @continueSubmitting='onContinueSubmitting' ref='confirmFocusGrpModal'/> -->
  <run-best-practices-modal ref='bestPractices'/>

  <div class="modal" :class="{'is-active': showConfirmHomeRunModal}">
    <div class="modal-background" @click="showConfirmHomeRunModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <p class="subtitle">You checked the box for home page but put an internal page in the field. Do you really want to run the home page?</p>
        <v-btn rounded
          color="profileAnchorColor"
          class="button-with-icon whiteColor--text text-none"
          @click="submitData()">
          <span>Yes</span>
        </v-btn>
        <v-btn rounded
          color="grayColor"
          class="button-with-icon gray2Color--text text-none ml10"
          @click="showConfirmHomeRunModal=false;">
          <span>Cancel</span>
        </v-btn>
      </div>
    </div>
  </div>
  
  <div class="modal" :class="{'is-active': showMissingProjectDomainModal}">
    <div class="modal-background" @click="showMissingProjectDomainModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <p class="subtitle">Your project's base domain is not present in the page you are trying to optimize.</p>
        <div >
          <span>Project's base domain: {{ baseUrl }}</span><br />
          <span>Page URL: {{ targetUrl }}</span>
        </div>

        <p class="subtitle">Please fix before submitting.</p>
        <div class="field has-addons">
          <div class="control">
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click="showMissingProjectDomainModal = false">
              <span>Ok</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showSuspectRunningHomePage}">
    <div class="modal-background" @click="showSuspectRunningHomePage = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <p class="subtitle">Trying to run the home page ({{ baseUrl }})?</p>
        <div class="field has-addons">
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none"
            @click="doRunHomePage">Yes, run home page</v-btn>
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon whiteColor--text text-none ml10"
            @click="showSuspectRunningHomePage = false">Cancel and fix</v-btn>
        </div>
      </div>
    </div>
  </div>

  <trial-conversion-modal ref='trialConversion' />

  <div class="modal" :class="{'is-active': showNoAutoVariationsModal}">
    <div class="modal-background" @click="showNoAutoVariationsModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="title-font is-size-4 mb20">Unfortunately the tool wasn't able to find any variations for your keyword.</div>
          <div class="subtitle">
            <p class="mb10">Check to see if there are any bolded terms in your search engine results descriptions that the tool may have missed.</p>
            <p class="mb10">If there are, manually add them into the tool. If there simply aren't any variations for your term, we recommend manually adding in singulars, plurals, appropriate stemming -ing, -ed, extremely close synonyms and single words that comprise your keyword phrase.</p>
            <p class="mb10">For example, if your keyword is Dallas lawyers, we would recommend manually adding in the following: <b>Dallas, lawyers, lawyer, attorney, attorneys</b></p>
            <p>If your keyword is Dallas plumbers we would recommend manually adding: <b>Dallas, plumbers, plumber, plumbing</b></p>
          </div>
          <div class="control flex mt20">
            <div class="flex-grow-1">
              If there aren't any variations to manually add for your keyword you can run the report without variations by checking the box underneath the variations drop down.
            </div>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click="showNoAutoVariationsModal = false">
              <span>I got it</span>
              <i class="fa fa-arrow-right"></i>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showNoAutoVariationsModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': confirmCloseModal}">
    <div class="modal-background" @click="confirmCloseModal = false"></div>
    <div class="modal-content" style="max-width: 650px;">
      <div class="box modal-container" style="overflow: hidden;">
        <div>
          <div class="title-font is-size-4 mainColor--text mb20">Do you want to leave this page?</div>
          <div class="subtitle-font mainColor--text">
            You will lose any of the information that you have entered.
          </div>
          <div class="control d-flex mt-5">
            <div class="flex-grow-1"></div>
            <v-btn rounded outlined
              color="mainColor"
              class="button-with-icon text-none" @click="confirmCloseModal = false;">
              <span>No</span>
            </v-btn>
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none" @click="confirmCloseModal = false; $router.go(-1);">
              <span>Yes</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="confirmCloseModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>


  <div class="modal" :class="{'is-active': showPickingReportTypeModal}">
    <div class="modal-background" @click="dismissPickingReportTypeModalAndGoBack"></div>
    <div class="modal-content" style="min-width: 60vw;">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="select-a-setup-type mt-5">
            Select a setup type
            <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
              @click="() => {
                $store.commit('setCurrentVideoTutorialToDisplay', {
                  title: 'How to Choose POP Pro vs POP Custom',
                  videoLink: 'https://www.youtube.com/embed/u65rArQ5zpo?si=qlX9-eKmXIhfc-tc',
                  description: 'Understand the difference between POP Pro and POP Custom setup.',
                  time: '1m 20s'
                });
              }">
              videocam
            </span>
          </div>
          <div class="setup-type-explain mt-5">You can switch between setups when you do a re-run of your report.</div>
          <div class="setup-type-boxes mt-5">
            <div class="full-height has-text-centered flex flex-column" :class="{ 'active': isExpressReport }"
              @click="setReportType(true)">
              <div>
                <i class="material-icons">rocket_launch</i>
              </div>
              <div class="setup-type-name">POP Pro</div>
              <div class="flex-grow-1 type-explain">
                <div>Streamlined setup process</div>
                <ul>
                  <li>For Beginners to Pros alike</li>
                  <li>Simple one page input</li>
                  <li>POP makes all the hard choices</li>
                  <li>Effective SEO</li>
                </ul>
              </div>
            </div>
            <div class="full-height has-text-centered flex flex-column" :class="{ 'active': !isExpressReport }"
              @click="setReportType(false)">
              <div>
                <i class="material-icons">display_settings</i>
              </div>
              <div class="setup-type-name">POP Custom</div>
              <div class="flex-grow-1 type-explain">
                <div>Full setup process</div>
                <ul>
                  <li>Control over input and output</li>
                  <li>Create custom strategies and approaches</li>
                  <li>Multi-step setup wizard</li>
                  <li>Data for all SEO situations</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="next-button-area">
            <v-btn rounded
              color="profileAnchorColor"
              class="button-with-icon whiteColor--text text-none"
              @click="submitPageTypeSelection">
              <span>Next</span>
              <i class="material-icons">arrow_forward</i>
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="dismissPickingReportTypeModalAndGoBack"><i class="material-icons">close</i></button>
    </div>
  </div>

  <missing-extension-modal
    :showModal="showMissingChromeExtensionModal"
    @closemodal="showMissingChromeExtensionModal = false"
    @changelocation="goNextPage(jumpToStep=PageRunPages.CHOOSE_LANGUAGE_AND_REGION)"></missing-extension-modal>

  <same-url-used-in-24-hrs-modal
    ref="sameUrlUsedModal"
    @cancelAction="sameUrlCancelAfterAction"
    @okAction="validateTargetUrl"
    ></same-url-used-in-24-hrs-modal>
</div>

</template>

<script>
import _ from 'lodash';
import Multiselect from 'vue-multiselect'
import AppMenu from '@/components/Menu.vue'
import TrialConversionModal from '@/components/TrialConversionModal.vue'
import { mixin } from '@/components/page_analysis_mixin'
import { mixin as ServiceMixin } from '@/components/service_mixin'
import RunBestPracticesModal from '@/components/PageRun/RunBestPracticesModal.vue'
import AddKeyword from '@/components/PageRun/Steps/AddKeyword.vue'
import LocaleSelection from '@/components/PageRun/Steps/LocaleSelection.vue'
import Variations from '@/components/PageRun/Steps/Variations.vue'
import CompetitorsMethodology from '@/components/PageRun/Steps/CompetitorsMethodology.vue'
import AddCompetitors from '@/components/PageRun/Steps/AddCompetitors.vue'
import FocusGroup from '@/components/PageRun/Steps/FocusGroup.vue'
import Lsa from '@/components/PageRun/Steps/Lsa.vue'
import StepsSummary from '@/components/PageRun/Steps/StepsSummary.vue'
import OnePageProStep from '@/components/PageRun/Steps/OnePageProStep.vue'
import MissingExtensionModal from '@/components/PageRun/Steps/MissingExtensionModal.vue'
import SameUrlUsedIn24HrsModal from '@/components/PageRun/SameUrlUsedIn24HrsModal.vue'
import languages from '@/consts/dataforseo_languages.json';
import dataForSeoLocations from '@/consts/dataforseo_locations.json';

import VueSlickCarousel from 'vue-slick-carousel'

import { isInSameDomain, getPathNameFromUrl } from '@/utils'

export default {
  components: { 
    AppMenu, RunBestPracticesModal, TrialConversionModal,
    Multiselect, AddKeyword, LocaleSelection, Variations, CompetitorsMethodology,
    AddCompetitors, FocusGroup, Lsa, StepsSummary, OnePageProStep, MissingExtensionModal, SameUrlUsedIn24HrsModal,
    VueSlickCarousel,
  },
  mixins: [mixin, ServiceMixin],
  data () {
    return {
      name: this.$route.params.externalData?.keyword || '',
      targetUrl: '',
      tempSaveTargetUrl: '',
      note: '',
      variationsToAdd: '',
      fetchingVariations: false,
      submittingRun: false,
      
      targetLang: this.$route.params.externalData?.lang || '',
      continueSubmitting: false,

      ignoreSpaces: false,
      willUseGoogleNlp: false,
      willUseEatAnalysis: false,
      willUseNextGenEatAnalysis: false,
      pageNotBuilt: this.$route.params.externalData?.isPageNotBuilt ?? false,
      useKeywordPrecisionSearch: false,
      showConfirmHomeRunModal: false,

      isTargetUrlSourceDirectly: false,
      targetUrlSourceCode: '',

      showManualCopyPasteArea: false,
      activeManualCompetitorSource: '', // this indicate the competitor url that can copy & paste for now. expanded manual copy pane
      manualCopySourceCompetitorUrls: [], // collection of teh manual copy source competitor urls.
      manualCompetitorUrlsSources: {},

      isRememberLangAndProxy: true,
    }
  },
  watch: {
    'targetUrl': function(newUrl) {
      let targetUrl = newUrl.trim()
      if (isInSameDomain(targetUrl, this.cleanBaseUrl)) {
        targetUrl = getPathNameFromUrl(targetUrl)
      }
      targetUrl = targetUrl.replace(/\s/g, ''); // remove all spaces from string
      if (targetUrl == '/') { targetUrl = '' }
      if (this.targetUrl == targetUrl) {
        return;
      }
      this.targetUrl = targetUrl
      this.urlsValidated = false
    },
  },
  computed: {
    _ () {
      return _
    },
    targetLanguages () {
      return this.$store.state.targetLanguages
    },
    isAvailableGoogleNlp () {
      return (this.$store.state.selectedSubAccountRelation || this.isAgencyUnlimitedUser)
    },
    remainedGoogleNlpUsage () {
      if (this.$store.state.selectedSubAccountRelation) {
        return this.$store.state.selectedSubAccountRelation.remainedGoogleNlpUsage
      } else {
        return this.$store.state.user.remainedGoogleNlpUsage
      }
    },
    validForm () {
      if (this.isExpressReport) {
        return true;
      }
      return !!this.keyword && !!this.competitorUrls && (this.isGoogleSearchDoneWithExtension || !!this.proxyLocation )
    },
    disableCompetitorUrlsButton () {
      if ( this.validationIssues.length == 0 ) return false;
      if ( this.validationIssues.length > 0 && this.validationIssues[0].includes(this.badCompetitorUrlMsg)) return false;
      if ( this.validationIssues.length > 0 && this.validationIssues[0].includes(this.badTargetUrlMsg)) return false;
      Object.keys(this.manualCompetitorUrlsSources).forEach(key => {
        if (this.manualCompetitorUrlsSources[`${key}`].trim() === '') return false;
      });
      return true;
    },
    availableReportCreditsOfUserOrTeam () {
      return this.$store.getters.availableReportCreditsOfUserOrTeam
    },
    canCreateNewPage () {
      if (this.availableReportCreditsOfUserOrTeam >= 2) {
        return true;
      }
      return false;
    },
    canCreateRerun () {
      if (this.availableReportCreditsOfUserOrTeam >= 1) {
        return true;
      }
      return false;
    },
    validationAlerts () {
      return this.validationIssues.filter(issue => {
        return !issue.includes(this.badCompetitorUrlMsg)
      })
    },
    competitorUrlsMissingHttpError () {
      let found = this.validationIssues.find((element) => {
        return element.includes(this.missingHttpInCompetitorUrlsMsg)
      });
      return found;
    },
    competitorsUrlValidationError () {
      let found = this.validationIssues.find((element) => {
        return element.includes(this.badCompetitorUrlMsg)
      });
      return found;
    },
    missingPastingManualCompetitorUrlSources () {
      if (this.manualCopySourceCompetitorUrls.length === 0) {
        return false
      }
      let resultStr = '';
      Object.keys(this.manualCompetitorUrlsSources).forEach(key => {
        if (this.manualCompetitorUrlsSources[`${key}`].trim() == '') {
          resultStr += key + '<br/>'
        }
      });
      if (resultStr == '') return false // This means filled all manual source codes
      return 'You selected to manually copy and paste this URL.  Please follow the instructions to copy and paste the URL\'s source code or unselect manually copy and paste.<br/>' + resultStr
    }
  },
  methods: {
    setErrorMsg(msg) {
      this.errorMsg = msg
    },
    submitPageTypeSelection() {
      this.showPickingReportTypeModal = false;
    },
    dismissPickingReportTypeModalAndGoBack () {
      this.showPickingReportTypeModal = false;
      this.$router.go(-1);
    },
    setReportType (isOnePageSetup=false) {
      if (isOnePageSetup) {
        this.isExpressReport = true;
        this.isOnePageSetup = true;
      } else {
        this.isExpressReport = false;
        this.isOnePageSetup = false;
        // if (!this.name) {
        //   this.name = this.autoGeneratePageName()
        // }
      }
      // this.showPickingReportTypeModal = false
    },
    showBestPractices () {
      this.$refs.bestPractices.show()
    },
    setErrorMsg(message) {
      this.errorMsg = message
    },
    toggleUrlIsInManualCopySourceList ({ event, url }) {
      if (!event) return;
      if (event.target.checked) {
        let value = this.manualCopySourceCompetitorUrls.slice()
        value.push(url)
        this.manualCopySourceCompetitorUrls = value
        value = Object.assign({}, this.manualCompetitorUrlsSources)
        value[`${url}`] = ''
        this.manualCompetitorUrlsSources = value
        this.toggleDisplayActiveCompetitorSourceArea(url)
      } else {
        this.manualCopySourceCompetitorUrls = this.manualCopySourceCompetitorUrls.filter((item) => {
          return (item !== url)
        })
        if (this.activeManualCompetitorSource === url) this.activeManualCompetitorSource = ''
        delete this.manualCompetitorUrlsSources[`${url}`]
      }
    },
    isManualCopyUrl (url) {
      if (this.manualCopySourceCompetitorUrls.indexOf(url) !== -1) {
        return true
      } else {
        return false
      }
    },
    toggleDisplayActiveCompetitorSourceArea(url) {
      if (this.isManualCopyUrl(url)) {
        this.activeManualCompetitorSource = url
      }
    },
    changedManualCompetitorUrlSource ({ url, htmlCode, done }) {
      console.log('url copy code event', url)
      let value = Object.assign({}, this.manualCompetitorUrlsSources)
      value[`${url}`] = htmlCode
      this.manualCompetitorUrlsSources = value
      console.log('call done callback', done)
      if (done) {
        done()
      }
    },
    disabledPagePathInput () {
      if ( this.pageNotBuilt) {
        if (this.targetUrl !== '') this.tempSaveTargetUrl = this.targetUrl;
        this.targetUrl = '';
        return true;
      } else {
        if (this.tempSaveTargetUrl !== '') {
          this.targetUrl = this.tempSaveTargetUrl;
          this.tempSaveTargetUrl = '';
        }
        return false;
      }
    },
    submitData(dispatchActionName='') {
      const siteId = this.$route.params.siteId

      if (this.validForm) {
        const completeUrl = this.getCompleteUrl()

        if (!this.canCreateReport()) {
          return;
        }

        if (this.submittingRun) {
          return;
        }
        
        this.submittingRun = true

        try {
          this.$store.commit('updateLastReportCreationTime');
          const dispatchName = dispatchActionName ? dispatchActionName : 'createPage'
          this.$store.dispatch(dispatchName, {
            validatedUrls: this.validatedUrls,
            name: this.name,
            externalId: this.$route.params.externalData?.id,
            externaType: this.$route.params.externalData?.type,
            url: completeUrl,
            keyword: this.cleanText(this.keyword),
            secondaryKeywords: this.useSecondaryKeywords ? this.cleanedSecondaryKeywords : [],
            note: this.note,
            competitorUrls: this.cleanCompetitorUrls(this.competitorUrls),
            focusCompetitorUrls: this.focusCompetitorUrls.length > 0 ?  this.cleanCompetitorUrls(this.focusCompetitorUrls).split('\n') : [],
            originGoogleVariations: this.originGoogleVariations,
            variations: this.runWithoutVariation ? [] : this.variations,
            proxyId: this.proxyId,
            proxyLocation: this.proxyLocation,
            isExtensionUsed: this.isGoogleSearchDoneWithExtension ? 1 : 0,
            targetLang: this.targetLang,
            ignoreSpaces: this.ignoreSpaces ? 1 : 0,
            pageNotBuilt: this.pageNotBuilt ? 1 : 0,
            useKeywordPrecisionSearch: this.useKeywordPrecisionSearch ? 1 : 0,
            willUseGoogleNlp: this.willUseGoogleNlp ? 1 : 0,
            willUseEatAnalysis: this.willUseEatAnalysis ? 1 : 0,
            willUseNextGenEatAnalysis: this.willUseNextGenEatAnalysis ? 1 : 0,
            targetUrlSourceCode: this.isTargetUrlSourceDirectly ? this.targetUrlSourceCode : '',
            manualCompetitorUrlsSources: this.manualCompetitorUrlsSources,
            siteId,
            isRememberLangAndProxy: this.isRememberLangAndProxy ? 1 : 0,
            pageRunType: this.isExpressReport ? 1 : 0,
            lsaPhrases: this.runWithoutPhrases ? [] : this.lsaPhrases,
            removedLSITermsByUser: this.removedLSITermsByUser,
            runWithoutPhrases: this.runWithoutPhrases ? 1 : 0,
            switchedSiteProtocol: this.switchedSiteProtocol ? 1 : 0,
            gCompetitors: this.googleSearchResults,
            relatedSearches: this.relatedSearches,
            relatedQuestions: this.relatedQuestions
          })
          .then((response) => {
            if (response.data.status === 'FAILURE') {
              this.errorMsg = response.data.msg
              this.submittingRun = false
              return 1
            } else if (response.data.status === 'UNAVAILABLE_DOMAIN') {
              this.submittingRun = false
              this.$refs.trialConversion.show()
              return 1
            } else {
              const pageId = response.data.pageId
              this.$store.commit('addPage', response.data.page)
              this.$store.commit('addPageRun', response.data.pageRun)
              if (response.data.site) {
                this.$store.commit('setSite', response.data.site) // this is needed to update site default setting like lang and proxy
              }
              return this.$store.dispatch('loadUser')
                .then((response) => {
                  this.$store.commit('setUser', response.data.user)
                  this.submittingRun = false
                  if (this.$route.params.externalData?.type === 'Silo') {
                    this.$router.go(-1);
                  } else {
                    return this.$router.push(`/sites/${siteId}/pages/${pageId}`)
                  }
                })
            }
          })
          .catch(err => {
            this.submittingRun = false
            console.log('Error making page ', err)
            this.errorMsg = 'Error submitting analysis. Please check validation messages. If error persists contact support.'
          })
        } catch(e) {
          console.log('Error creating page')
          console.log(e)
          this.submittingRun = false
          this.errorMsg = 'Error submitting analysis. Please check validation messages. If error persists contact support.'
        }
      } else {
        console.log('this is not valid form so I can not make this to be happen')
      }
    },
    submitForm () {
      this.clearErrorGeneralMessage()
      // if (this.targetUrl == '' && !this.pageNotBuilt) {
      //   this.showConfirmHomeRunModal = true;
      //   return;
      // }
      this.submitData();
    },
    beforeCloseBrowser () {
      return 'You might lose the content you entered.'
    }
  },
  created () {
    window.onbeforeunload = this.beforeCloseBrowser
  },
  beforeMount () {
    this.checkExtensionIsInstalled()
    
    try {
      this.$store.commit('setSubmittingGoogleSearch', false)
      this.$store.commit('setShowGoogleResultsModal', false)
      this.$store.commit('setGoogleSearchProgress', 1)
      if (!this.googleSearchLocations || !this.googleSearchLocations.length) {
        this.$store.dispatch('fetchGoogleSearchLocations')
      }
    } catch(e) {
      console.log('Error fetching proxies', e)
    }
  },
  mounted () {
    if (this.$route.params.externalData?.url) {
      this.targetUrl = this.$route.params.externalData?.url
    }
    // load default setting for project
    let settings = this.site.settings
    const lang = languages.find(lang => this.$route.params.externalData?.lang === lang.language_code)?.language_name;
    const loc = dataForSeoLocations.locations.find(loc => parseInt(this.$route.params.externalData?.loc) === loc.location_code)?.location_name;
    if (loc) this.proxyLocation = loc

    if (lang) {
      this.targetLang = lang.toLowerCase();
    } else if (settings && Object.keys(settings).length > 0) {
      this.targetLang = settings.targetLang
      this.ignoreSpaces = settings.ignoreSpaces==1
      this.proxyLocation = settings.proxyLocation
      if (!this.proxyLocation) this.proxyLocation = 'United States';
      if (!this.targetLang) this.targetLang = 'english'
      if (this.targetLang!='' && this.targetLang!=undefined) {
        this.isRememberLangAndProxy = true
      }
    } else {
      this.targetLang = 'english'
      this.proxyLocation = 'United States'
    }

    const isDemo = this.$store.getters.isDemoUser
    this.showPickingReportTypeModal = !isDemo
    if (isDemo) this.setReportType(true)
  },
  beforeDestroy () {
    window.onbeforeunload = null;
    document.removeEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
  }
}
</script>

<style lang="scss" scoped>
div {
  color: var(--v-mainColor-base);
}
ul {
  margin-left: 0;
}
.tag {
  margin: 3px;
}

@media screen and (min-width: 769px) {
  .modal-content, .modal-card {
      margin: 0 auto;
      max-height: calc(100vh - 40px);
      width: 820px;
  }
}

.select-a-setup-type {
  font-size: 1.7rem;
  font-weight: 800;
  text-align: center;
}
.setup-type-explain {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.setup-type-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  & > div {
    flex: 1 1 270px;
    border: 2px solid var(--v-grayColor-base);
    border-radius: 5px;
    padding: 30px 10px;
    cursor: pointer;

    &.active {
      border-color: var(--v-profileAnchorColor-base);
    }

    i.material-icons {
      color: var(--v-profileAnchorColor-base);
      font-size: 2rem;
    }
    .setup-type-name {
      margin-top: 20px;
      font-size: 1.7rem;
      font-weight: 800;
    }

    .type-explain {
      margin-top: 10px;
      font-size: 1rem;

      ul {
        padding-left: 0;
        margin-top: 10px;
        list-style: inside;
      }
    }
  }
  /* grid-template-columns: 1fr 1fr; */
}
.next-button-area {
  margin-top: 25px;
  text-align: right;
}

/* @media screen and (max-width: 800px) {
    
} */

.red-text {
  color: red;
}

</style>
