import isHtml from 'is-html'
import _, { findLastKey } from 'lodash'
let timer

import { PageRunPages } from '@/components/page_run_pages.js'
import { cleanText, isInSameDomain } from '@/utils'
import { fetchTaskResult } from '@/api'
import { SEO_TIPS } from '@/consts/seo_tips.js';


export const mixin = {
  data () {
    return {
      MAX_COMPETITORS: 10,
      missingPageName: 'Enter a name for this analysis',
      missingPageUrl: 'Enter the page url you wish to run, if you haven\'t built the page yet, click the box \'I haven\'t built this page yet\'',
      missingKeyword: 'Enter a keyword phrase for your analysis',
      missingTargetLanguage: 'Select target language',
      missingLocationMsg: 'Select location closest to your target viewers',
      missingVariationsMsg: 'Fetch variations Google identifies for your keyword',
      missingCompetitorUrls: 'Enter competitor url(s) to evaluate your page against',
      missingBaseUrlMsg: 'Page URL must include base domain ',
      missingTargetSource: 'Copy and paste the target source code to text area',
      invalidTargetSource: 'Source code you copied is not valid html',
      badTargetUrlMsg: 'Your Target URL can\'t be crawled by the tool.  A URL can\'t be crawled for several reasons.  The most common reason is that the site you are trying to crawl is blocking bots.  To use this Target URL, you\'ll need to manually copy and paste the source code into the tool.',
      badCompetitorUrlMsg: 'The following URL(s) can\'t be crawled by the tool:',
      missingHttpInCompetitorUrlsMsg: 'Make sure to include http/https with the following URLs:',
      urlsValidated: false,
      validatedTargetUrl: false,
      invalidCompetitorUrls: [],
      missingHttpCompetitorUrls: [],
      validatedCompetitorUrls: [],
      focusCompetitorUrls: [],
      continueSubmitting: false,
      ranValidation: false,
      submittingValidation: false,
      errorMsg: '',
      showTooManyCompetitorsModal: false,
      showMissingProjectDomainModal: false,
      showSuspectRunningHomePage: false,
      showNoAutoVariationsModal: false,
      isRerunPage: false,
      proxyId: 1,
      validatedUrls: [],
      fetchValidationTimer: {},
      fetchValidationTask: {},
      keyword: this.$route.params.externalData?.keyword || '',
      useSecondaryKeywords: this.$route.params.externalData?.useSecondaryKeywords || false,
      secondaryKeywords: this.$route.params.externalData?.secondaryKeywords || [],
      competitorUrls: '',
      proxyLocation: this.$route.params.externalData?.loc || '',
      proxies: [],
      googleSearchResults: [],
      selectedGooglePages: [],
      switchedSiteProtocol: false,
      useOwnCompetitorList: false, // if this is true, then we will just go input competitors page directly instaed of displaying google competitors list.

      isAvailableChromeExtension: false,
      isGoogleSearchDoneWithExtension: false,
      showMissingChromeExtensionModal: false,

      // for new design
      currentPage: 1,
      selectedVariations: [],
      isShowBallon: true,
      confirmCloseModal: false,
      removedVariationsStack: [],
      runWithoutVariation: false,
      showPickingReportTypeModal: false,
      isExpressReport: true, // default is pro
      isOnePageSetup: true,
      isUserWillPickCompetitors: false,
      // above 3 must changed together if we need to change default one between pro/custom 

      showModalForProReportSteps: false,

      firstRunOnRerunPage: false,
      
      fetchingLSA: false,
      fetchingLSAProgress: 0,
      lsaPhrases: [],
      removedLSITermsByUser: [],
      numberedLsaPhrases: '',
      lasFetchPhrasesTimer: null,
      runWithoutPhrases: false,

      // for cancel feature
      isCancellingProgress: false,
      isFetchingSourceForTargetUrl: false, // to separte extension callback whether is for competitors or target url

      googleSearchProgress: 0,
      googleSearchTimer: null,


      submittingFetchLsaProcess: false,
      failedAutoPickCompetitors: false,

      carouselSetting: {
        "dots": true,
        "dotsClass": "slick-dots dot-item",
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "centerPadding": '50px',
        "arrows": false,
      },
      selected3Tips: [],
    }
  },
  watch: {
    // isFetchingSourceForTargetUrl: function (newVal, oldVal) {
    //   if (newVal && newVal != oldVal) {
    //     this.selected3Tips = []
    //   }
    // },
    'selectedGooglePages': function (newVal, oldVal) {
      if (newVal == oldVal) {
        return
      }
      this.useGoogleSearchResults(false)      
    },
    'competitorUrls':  function(newValue) {
      if (this.competitorUrls.trim()===newValue.trim()) return;

      const newCleanedUrls = this.cleanCompetitorUrls(newValue).split('\n')
      this.validatedUrls = this.validatedUrls.filter(validatedUrl => newCleanedUrls.includes(validatedUrl.url))
      this.manualCopySourceCompetitorUrls = []
      this.manualCompetitorUrlsSources = {}
      let manualCopyCheckboxes = document.querySelectorAll('.manual_copy_source_checkbox')
      manualCopyCheckboxes.forEach(checkbox => {  // resetting all checkbox as unchecked status
        checkbox.checked = false
      });
      this.urlsValidated = false
    },
    'isExpressReport': function(newVal) {
      if (newVal) {
        this.isUserWillPickCompetitors = false
      } else {
        this.isUserWillPickCompetitors = true;
      }
    },
    'originGoogleVariations': function () {
      return this.$store.state.originGoogleVariations
    },
    'submittingGoogleSearch': function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal) {
          this.googleSearchProgress = 0;
          this.googleSearchTimer = setInterval(() => {
            let newGoogleSearchProgress = this.googleSearchProgress + 15
            if (newGoogleSearchProgress > 100) {
              newGoogleSearchProgress += 10
            }
            this.googleSearchProgress  = newGoogleSearchProgress
          }, 1000);
        } else {
          if (this.googleSearchTimer) {
            clearInterval(this.googleSearchTimer);
          }
        }
      }
    }
  },
  computed: {
    cartoonQuestion () {
      let textBallon = [
        "What if I want to optimize for more than one keyword?",
        "What if my location isn't in the drop down?",
        "For my keyword phrase, lawyer San Diego I get the variations lawyer, and San Diego on their own.  Should I keep those variations?",

      ]
      if (textBallon.length < this.currentPage) return ''
      return textBallon[this.currentPage-1]
    },
    cartoonAnswer () {
      let textBallon = [
        "One webpage should target one primary keyword or keyword phrase.  If you properly optimize a page for a primary keyword, you can win its secondary keywords and phrases.",
        "In that case, you should definitely use the POP Chrome Extension.  The extension will do your search from your current location.",
        "Yes, you will use those variations on their own in your text so that you won't overuse your primary keyword phrase.",

      ]
      if (textBallon.length < this.currentPage) return ''
      return textBallon[this.currentPage-1]
    },
    user () {
      return this.$store.state.user
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId))
    },
    baseUrl () {
      return this.site.baseURL
    },
    cleanBaseUrl() {
      let rlt = this.baseUrl.replace(/\/$/, '')
      if (this.switchedSiteProtocol) {
        if (rlt.startsWith('https')) {
          rlt = 'http' + rlt.substring(5)
        } else {
          rlt = 'https' + rlt.substring(4)
        }
      }
      return rlt
    },
    cleanedSecondaryKeywords () {
      if (!this.useSecondaryKeywords) {
        return []
      }
      return this.secondaryKeywords.reduce(function ( acc, currentVal ) {
        let item = (typeof currentVal === 'string') ? currentVal.trim().toLowerCase() : ''
        if (item && !acc.includes(item)) {
          acc.push(item)
        }
        return acc
      }, []).slice(0, 3);
    },
    competitorUrlsArray () {
      return this.cleanCompetitorUrls(this.competitorUrls)
        .split('\n')
        .filter(url => url.length)
    },
    relatedSearches () {
      return this.$store.state.relatedSearches
    },
    relatedQuestions () {
      return this.$store.state.relatedQuestions
    },
    variations () {
      return this.$store.state.variations
    },
    fetchedVariations () {
      return this.$store.state.fetchedVariations
    },
    validationIssues () {
      let validationIssues = []
      !this.name && this.name !== undefined ? validationIssues.push(this.missingPageName) : null
      !this.validatedTargetUrl && this.ranValidation && this.targetUrl ? validationIssues.push(this.badTargetUrlMsg) : null
      !this.keyword ? validationIssues.push(this.missingKeyword) : null
      !this.targetLang ? validationIssues.push(this.missingTargetLanguage) : null
      if (!this.isGoogleSearchDoneWithExtension) {
        !this.proxyLocation ? validationIssues.push(this.missingLocationMsg) : null
      }

      // if (this.fetchedVariations && !this.variations.length) {
      //   validationIssues.push('No variations were returned.  Please add one or more variations manually.')
      // } else if (!this.variations.length) {
      //   validationIssues.push(this.missingVariationsMsg)
      // }

      !this.competitorUrls.length ? validationIssues.push(this.missingCompetitorUrls) : null
      if (this.isTargetUrlSourceDirectly) {
        if (this.targetUrlSourceCode.trim() == '') {
          validationIssues.push(this.missingTargetSource)
        } else if (!isHtml(this.targetUrlSourceCode)){
          console.log('the source code you entered is not valid!!!! :(')
          validationIssues.push(this.invalidTargetSource)
        }
      }
      // this.invalidCompetitorUrls.length ? validationIssues = validationIssues.concat(this.invalidCompetitorUrls.map((url) => this.badCompetitorUrlMsg = + url)) : null 
      let missingHttpUrls = this.competitorUrlsArray.filter(url => {
        return (!(url.toLowerCase().startsWith('http:')) && !(url.toLowerCase().startsWith('https:')))
      })
      if (missingHttpUrls.length > 0) {
        if (!_.isEqual(this.missingHttpCompetitorUrls.slice().sort(), missingHttpUrls.slice().sort())) {
          this.missingHttpCompetitorUrls = missingHttpUrls
        }
        validationIssues.push(this.missingHttpInCompetitorUrlsMsg)
      } else {
        if (this.missingHttpCompetitorUrls.length > 0) {
          this.missingHttpCompetitorUrls = []
        }
        this.invalidCompetitorUrls.length ? validationIssues.push(this.badCompetitorUrlMsg) : null;
      }

      return validationIssues
    },
    isAlreadyDoneValidatingUrl () {
      return this.validatedTargetUrl && this.urlsValidated && this.invalidCompetitorUrls.length==0 && this.ranValidation
    },
    submittingGoogleSearch () {
      return this.$store.state.submittingGoogleSearch
    },
    currentTaskId () {
      return this.$store.state.currentGoogleCompetitorsTaskId
    },
    showGoogleResultsModal () {
      return this.$store.state.showGoogleResultsModal
    },
    combinedSelectedCompetitors () {
      return this.competitorUrlsArray
      // return Array.from(new Set(this.selectedGooglePages.map(selectedPage => selectedPage.url).concat(this.competitorUrlsArray.slice())))
    },
    googleSearchLocations () {
      return this.$store.state.googleSearchLocations
    },
    proProgressValue () {
      if (this.submittingGoogleSearch) {
        return 2;
      }
      else if (this.submittingValidation) {
        if (this.isFetchingSourceForTargetUrl) {
          return 97;
        }
        return 5 + (this.$store.state.validateUrlsProgress / 100 * 85)
      }
      else if (this.submittingFetchLsaProcess) {
        return 93;
      } else {
        return 99
      }
    }
  },
  methods: {
    sameUrlCancelAfterAction () {
      if (this.isOnePageSetup) {
        this.showModalForProReportSteps = false;
      }
    },
    isReadyForSubmitInOnePageSetup () {
      if (!this.isReadyInPage(PageRunPages.ADD_KEYWORD)) {
        return false;
      }
      if (!this.isRerunPage && !this.name) {
        return false;
      }
      return true;
    },
    canCreateReport() {
      let state = this.$store.state;
      if (!state.lastReportCreationTime) {
        return true;
      }
      let now = Date.now();
      try {
        let seconds = Math.abs((now - state.lastReportCreationTime) / 1000);
        if (seconds > 1) {
          // can only create report after X seconds (mentioned in if condition) from last creation
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    },
    isReadyInPage (pageNumber) {
      let ready = true
      switch(pageNumber) {
        case PageRunPages.ADD_KEYWORD:
          ready = true
          if(!this.keyword){
            // this.errorMsg = this.missingKeyword
            ready = false
          }
          if (this.isTargetUrlSourceDirectly) {
            if (this.targetUrlSourceCode.trim() == '') {
              // this.errorMsg = this.missingTargetSource
              ready = false
            } else if (!isHtml(this.targetUrlSourceCode)){
              console.log('the source code you entered is not valid!!!! :(')
              // this.errorMsg = this.invalidTargetSource
              ready = false
            }
          }
          if (this.isRerunPage) {
            return ready
          } else {
            return ready && this.name != ''
          }
        case PageRunPages.CHOOSE_LANGUAGE_AND_REGION:
          return this.targetLang && this.proxyLocation
        case PageRunPages.ADD_VARIATIONS:
        case PageRunPages.VARIATIONS:
          return this.runWithoutVariation || this.variations.length > 0
        case PageRunPages.REMOVE_VARIATIONS:
          return this.runWithoutVariation || this.variations.length > 0
        // case PageRunPages.ADD_PAGE_TO_OPTIMIZE:
        //   break;
        case PageRunPages.METHOD_OF_COMPETITOR_SELECTION:
          // add new logic here
          return ready;
        case PageRunPages.ADD_COMPETITORS:
          ready = true
          if (!this.competitorUrls.length || this.competitorUrls.trim().split('\n').length < 3) {
            // this.errorMsg = this.missingCompetitorUrls
            ready = false
          } else {
            let missingHttpUrls = this.competitorUrlsArray.filter(url => {
              return (!(url.toLowerCase().startsWith('http:')) && !(url.toLowerCase().startsWith('https:')))
            })
            if (missingHttpUrls.length > 0) {
              if (!_.isEqual(this.missingHttpCompetitorUrls.slice().sort(), missingHttpUrls.slice().sort())) {
                this.missingHttpCompetitorUrls = missingHttpUrls
                this.errorMsg = this.missingHttpInCompetitorUrlsMsg
              }
              ready = false
            } else {
              if (this.missingHttpCompetitorUrls.length > 0) {
                this.missingHttpCompetitorUrls = []
              }
              if(this.invalidCompetitorUrls.length) {
                this.errorMsg = this.badCompetitorUrlMsg
                for(let i=0; i<this.invalidCompetitorUrls.length; i++) {
                  let url = this.invalidCompetitorUrls[i]
                  if (this.competitorUrls.includes(url) && !this.manualCompetitorUrlsSources[`${url}`]) {
                    ready = false
                    break;
                  }
                }
                // this.currentPage = PageRunPages.ADD_COMPETITORS
              }
            }
          }
          return ready
        default:
          break;
      }
      return false;
    },
    goNextPage (jumpToStep=-1) {
      console.log('goNextPage start ' + this.currentPage)
      if (_.isInteger(jumpToStep) && jumpToStep !== -1) {
        this.currentPage = jumpToStep
        return
      }

      this.errorMsg = ''
      switch(this.currentPage) {
        case PageRunPages.ADD_KEYWORD:
            if (!this.isReadyInPage(this.currentPage)) {
                return;
            }
            this.currentPage = PageRunPages.CHOOSE_LANGUAGE_AND_REGION
            break
        case PageRunPages.CHOOSE_LANGUAGE_AND_REGION:
            console.log('region selected ' + this.proxyLocation)
            if (this.proxyLocation === 'extension_use') {
              if (!this.isAvailableChromeExtension) {
                this.showMissingChromeExtensionModal = true
                return
              } else {
                this.submitGoogleSearch(true)
              }
            } else {
              this.submitGoogleSearch(false)
              this.currentPage = this.useOwnCompetitorList ? PageRunPages.ADD_COMPETITORS : PageRunPages.METHOD_OF_COMPETITOR_SELECTION
            }
            break
        case PageRunPages.ADD_VARIATIONS: // need to remove this eventually
        case PageRunPages.VARIATIONS:
            this.currentPage = PageRunPages.LSA
            break
        case PageRunPages.REMOVE_VARIATIONS:
            this.currentPage = PageRunPages.VARIATIONS
            break
        // case PageRunPages.ADD_PAGE_TO_OPTIMIZE:
        //     this.validateTargetUrl()
        //     break
        case PageRunPages.METHOD_OF_COMPETITOR_SELECTION:
            if (
              this.isRerunPage &&
              !this.googleSearchResults.length &&
              this.pageRun.gCompetitors.length
            ) {
              this.googleSearchSuccessProcess({
                variations: this.pageRun?.variations || [],
                excludeVariations: [],
                relatedSearches: this.pageRun?.relatedSearches || [],
                relatedQuestions: this.pageRun?.relatedQuestions || [],
                searchPages: this.pageRun?.gCompetitors || []
              })
            }
            if (this.isUserWillPickCompetitors) {
                this.invalidCompetitorUrls = []
                if (this.competitorUrls == '' && !this.useOwnCompetitorList) {
                    this.onShowGoogleResults()
                }
                // Add a condition to check if the googleSearchResults is empty and competitorUrls is empty
                if (this.googleSearchResults.length === 0 && this.competitorUrls === '') {
                  this.errorMsg = "We couldn't find any SERP result on target keyword, please add your competitors manually to proceed with POP run."
                }
                this.currentPage = PageRunPages.ADD_COMPETITORS
                break
            } else {
                // logic to pick competitors automatically
                if (this.googleSearchResults.length > 0) {
                    this.invalidCompetitorUrls = []
                    this.validatedUrls = []

                    let autoPickedUrlCount = 0;
                    let autoPickedUrls = '';
                    let autoPickedFocusUrls = [];
                    console.log('Trying to auto pick urls by POP cuz this is pro run', this.googleSearchResults)
                    for (let i=0; i<this.googleSearchResults.length; i++) {
                      let item = this.googleSearchResults[i]
                      console.log('checking whether this can be used as competitor', item)
                      if (this.isUrlCanbeCompetitor(item.url)) {
                        if (item.validated) { // add it to the validatedUrls if it has source code.
                          this.validatedUrls.push(item)
                        }
                        autoPickedUrls += item.url + '\n'
                        if (autoPickedUrlCount<3) {
                          autoPickedFocusUrls.push(item.url)
                        }
                        autoPickedUrlCount++;
                      }
                      if (autoPickedUrlCount >= 12) break; // auto pick 12 urls to be safe in case we fail.
                    }
                    
                    autoPickedUrls = autoPickedUrls.trim()
                    console.log('Auto picked urls by POP', autoPickedUrls)
                    if (!autoPickedUrls) {
                      this.failedAutoPickCompetitors = true;
                      return;
                    }

                    this.competitorUrls = autoPickedUrls // to get rid of ending new line character
                    this.focusCompetitorUrls = autoPickedFocusUrls
                    this.onValidateInputUrls()
                    // this.currentPage = PageRunPages.ADD_PAGE_TO_OPTIMIZE
                } else if (this.isRerunPage) {
                    this.currentPage = PageRunPages.ADD_COMPETITORS
                    this.goNextPage()
                } else {
                    if (this.isExpressReport) {
                      this.showModalForProReportSteps = false;
                      this.errorMsg = 'Unable to locate competitor websites using the provided keyword and settings. Please review and edit your keyword and try again.'
                    } else {
                      if (this.googleSearchResults.length === 0 && this.competitorUrls === '') {
                        this.errorMsg = "We couldn't find any SERP result on target keyword, please add your competitors manually to proceed with POP run."
                      }
                    }
                    this.$notify({
                      group: 'info', type: 'error',
                      text: this.errorMsg
                    })
                }
                break
            }
        case PageRunPages.ADD_COMPETITORS:
            // validate
            this.onValidateInputUrls()
            break
        case PageRunPages.SELECT_FOCUS_GROUP:
            this.currentPage = PageRunPages.VARIATIONS
            break
        case PageRunPages.LSA:
          break;
        default:
            break
      }
      console.log('goNextPage end ' + this.currentPage)
    },
    goPrevPage () {
      console.log('goPrevPage start ' + this.currentPage)
      switch(this.currentPage) {
        case PageRunPages.ADD_KEYWORD:
        case PageRunPages.CHOOSE_LANGUAGE_AND_REGION:
            this.currentPage = PageRunPages.ADD_KEYWORD
            break
        case PageRunPages.ADD_VARIATIONS:
        case PageRunPages.REMOVE_VARIATIONS:
        case PageRunPages.VARIATIONS:
            this.currentPage = PageRunPages.SELECT_FOCUS_GROUP
            break
        case PageRunPages.METHOD_OF_COMPETITOR_SELECTION:
            this.currentPage = PageRunPages.CHOOSE_LANGUAGE_AND_REGION
            break
        // case PageRunPages.ADD_PAGE_TO_OPTIMIZE:
        //     if (this.isExpressReport) {
        //       // ignore add / remove variations page
        //       this.currentPage = PageRunPages.CHOOSE_LANGUAGE_AND_REGION
        //     } else {
        //       this.currentPage = PageRunPages.SELECT_FOCUS_GROUP
        //     }
        //     break
        case PageRunPages.ADD_COMPETITORS:
            this.clearErrorGeneralMessage()
            this.goBackToEditUrlsMode()
            this.currentPage = PageRunPages.METHOD_OF_COMPETITOR_SELECTION
            break
        case PageRunPages.SELECT_FOCUS_GROUP:
            if (this.isRerunPage) { // if this is rerun
              if (!this.isEditableUrlAndKeyword) {
                this.$router.go(-1);
              } else {
                this.currentPage = PageRunPages.CHOOSE_LANGUAGE_AND_REGION;
              }
            }
            if (!this.isUserWillPickCompetitors) {
              this.currentPage = PageRunPages.CHOOSE_LANGUAGE_AND_REGION;
            } else {
              this.currentPage = PageRunPages.ADD_COMPETITORS;
            }
            break
        case PageRunPages.LSA:
            this.currentPage = PageRunPages.VARIATIONS
            break
        default:
            break
      }
      console.log('goPrevPage end ' + this.currentPage)
      // this.currentPage = this.currentPage - 1;
    },
    // checkWhetherUrlUsedIn24HrsAndValidateTargetUrl () {
    //   let data = { targetUrl: this.getCompleteUrl() }
    //   this.$store.dispatch('urlUsedin24Hrs', data)
    //     .then(response => {
    //       if (response.data && response.data.status == 'SUCCESS') {
    //         if (response.data.isUsedIn24Hrs) {
    //           // display message about this is executed in 24hrs cached one could be used..
    //           if (this.$refs.sameUrlUsedModal) {
    //             this.$refs.sameUrlUsedModal.show();
    //           }
    //           return;
    //         }
    //       }
    //       if (this.rerunType == 0) {
    //         this.validateInputUrls()
    //       } else {
    //         this.validateTargetUrl()
    //       }
    //     })
    //     .catch(error => {
          
    //     })
    // },
    switchToCustomRun () {
      this.isExpressReport = false;
      this.isOnePageSetup = false;
      this.currentPage = PageRunPages.ADD_COMPETITORS
      this.isUserWillPickCompetitors = true;
    },
    submitForOnePageSetup () {
      if (this.submittingRun) {
        return;
      }

      if (this.proxyLocation == 'extension_use') {
        // this.submittingRun = true;
        this.currentPage = this.PageRunPages.CHOOSE_LANGUAGE_AND_REGION
        this.goNextPage();
      } else { // submit directly
        this.submitData('createPageRunInBackend')
      }
    },
    getHostName(url) {
      if (!url) return ''
      let rlt = ''
      if (url.indexOf("//") > -1) {
        rlt = url.split('/')[2];
      }
      else {
        rlt = url.split('/')[0];
      }
      return rlt.toLowerCase()
    },
    getCompleteUrl () {
      this.targetUrl = this.targetUrl.replace(/^\//, '')
      const url = (this.pageNotBuilt || this.targetUrl.trim() == '') ? this.cleanBaseUrl : (this.cleanBaseUrl + '/' + this.targetUrl).trim()
      return url
    },
    isUrlCanbeCompetitor(url) {
      if (!url) {
        return false
      }
      if (this.site.baseURL) {
        if (this.isInSameDomain(this.site.baseURL, url)) {
          return false
        }
      }
      let restrictedSites = [
        'youtube.', 'twitter.', 'facebook.', 'instagram.', 'pinterest.',
        'amazon.', 'yelp.', 'wikipedia.', 'aliexpress.', 'quora.', 'google.', 'angi.',
        'etsy.com', 'linkedin.com', 'walmart.com', 'dictionary.cambridge.org', 'tripadvisor.com', 'reddit.com',
        '://forum.',
      ]
      let hostName = this.getHostName(url)
      if (this.getHostName(this.targetUrl) == hostName) {
        return false
      }
      
      let canBeUsed = true
      for (let i=0; i<restrictedSites.length; i++) {
        if (hostName.includes(restrictedSites[i])) {
          canBeUsed = false
          break;
        }
      }
      return canBeUsed
    },
    deleteVariationToAdd (variation) {
      let variations = this.variations.slice()
      var idx = variations.indexOf(variation)
      if (idx>=0) {
        this.removedVariationsStack.push(variation)
        variations.splice(idx, 1)
        this.$store.commit('setVariations', variations)
      }
    },
    autoGeneratePageName () {
      let name = this.keyword
      let isExist = true
      let pageNames = this.site.pages.map(page => page.name)
      let autoNumber = 0
      let tempName = '';
      while(isExist) {
        tempName = autoNumber > 0 ? name + autoNumber : name
        isExist = pageNames.indexOf(tempName)!=-1
        if (isExist) {
          autoNumber++;
        }
      }
      console.log('Auto generating page name', pageNames, tempName)
      return tempName 
    },
    onShowGoogleResults () {
      const combinedUrls = this.combinedSelectedCompetitors.slice()
      this.selectedGooglePages = []

      this.selectedGooglePages = this.googleSearchResults.filter(page => combinedUrls.includes(page.url))
      if (!this.googleSearchResults.length && this.isRerunPage) {
        this.submitGoogleSearch(this.proxyLocation === 'extension_use')
      } else {
        this.$store.commit('setShowGoogleResultsModal', true)
      }
    },
    onValidateInputUrls () {
      this.firstRunOnRerunPage = false
      this.validateInputUrls()
    },
    isGoogleSelectionDisabled(page) {
      return this.combinedSelectedCompetitors.length >= this.MAX_COMPETITORS && !this.combinedSelectedCompetitors.includes(page.url)
    },
    useGoogleSearchResults (hideModal = true) {
      const existingCompetitors = this.competitorUrlsArray.slice()
      let newCompetitors = this.selectedGooglePages.filter((page) => !existingCompetitors.includes(page.url))
                                    .map((page) => page.url)
      newCompetitors = existingCompetitors.concat(newCompetitors)

      let googleSearchResultsUrls = this.googleSearchResults.map(page => page.url)
      let selectedGooglePagesUrls = this.selectedGooglePages.map(page => page.url)      

      this.competitorUrls = newCompetitors.filter(url => {
        if (googleSearchResultsUrls.includes(url) && !selectedGooglePagesUrls.includes(url)) {
          return false
        }
        return true
      }).join('\n')
      this.selectedGooglePages.forEach((page) => {
        const idx = this.validatedUrls.map(item => item.url).indexOf(page.url)
        if (idx === -1 && page.validated) {
          this.validatedUrls.push(page)
        }
      })
      if (hideModal)
        this.$store.commit('setShowGoogleResultsModal', false)
    },
    isInSameDomain(url1, url2) {
      return isInSameDomain(url1, url2);
    },
    googleSearchSuccessProcess(results) {
      let { variations, excludedVariations, gDomain, searchPages, relatedSearches, relatedQuestions } = results
      if (this.isGoogleSearchDoneWithExtension) { // this will be running if it's from extension. if serp api (or proxy) is used, see store/index.js 
        console.log('varaitions', variations, excludedVariations)
        variations = variations.filter(v => this.isValidWord(v) 
                            && v.toLowerCase() !== this.cleanText(this.keyword).toLowerCase()
                        )
                        .map(v => this.cleanText(v))
        variations.sort();
        variations = Array.from(new Set(variations.concat(this.$store.state.variations.slice())))
        this.$store.commit('setOriginGoogleVariations', variations)
        this.$store.commit('setVariations', variations)
        this.$store.commit('setExcludedVariations', excludedVariations)
        this.$store.commit('setUsedGoogleDomain', gDomain ? gDomain : '')

        this.$store.commit('setRelatedQuestions', relatedQuestions ? relatedQuestions : [])
        this.$store.commit('setRelatedSearches', relatedSearches ? relatedSearches : [])

        this.filterVariations()
      }
      searchPages = searchPages.filter(item => !(!item.title && !item.description)) // to remove urls from "People also ask"
      if (this.site && this.site.baseURL) {
        searchPages = searchPages.map(item => {
          if (this.isInSameDomain(this.site.baseURL, item.url)) {
            item['isFromTargetDomain'] = true;
          } else {
            item['isFromTargetDomain'] = false;
          }
          return item
        })
      }
      const allUrls = searchPages.map(page => page.url)
      this.googleSearchResults = searchPages.filter((page, i) => { 
        const validated = page.validated && page.summary
        const isFirst = allUrls.indexOf(page.url) === i
        return isFirst
      })
      // this.showNoAutoVariationsModal = this.variations.length === 0
      
      this.$store.commit('setSubmittingGoogleSearch', false)
      if (this.currentPage === PageRunPages.CHOOSE_LANGUAGE_AND_REGION || this.currentPage === PageRunPages.METHOD_OF_COMPETITOR_SELECTION) {
        console.log('hkg debug for rerun', allUrls, this.useOwnCompetitorList)
        this.currentPage = this.useOwnCompetitorList ? PageRunPages.ADD_COMPETITORS : PageRunPages.METHOD_OF_COMPETITOR_SELECTION
        
        if (this.isExpressReport) {
          this.goNextPage()
        }
      } else if (this.currentPage === PageRunPages.ADD_COMPETITORS && this.isRerunPage) {
        this.onShowGoogleResults()
      }
    },
    cancelGoogleSearch () {
      this.$store.commit('setCurrentGoogleCompetitorsTaskId', -1)
      this.isCancellingProgress = true
    },
    submitGoogleSearch (useExtension=false) {
      this.isCancellingProgress = false
      this.$store.commit('setSubmittingGoogleSearch', true)
      this.showModalForProReportSteps = true;
      this.validatedCompetitorUrls = []
      this.urlsValidated = false
      this.clearErrorGeneralMessage()

      this.isGoogleSearchDoneWithExtension = useExtension
      try {
        if (useExtension) {
          return this.dispatch_custom_event( "parse_google_search", {
            search_query: this.cleanText(this.keyword),
          });
        } else {
          return this.$store.dispatch('submitGoogleSearchTask', {
            keyword: this.cleanText(this.keyword),
            secondaryKeywords: this.cleanedSecondaryKeywords,
            proxyLocation: this.proxyLocation,
            siteBaseUrl: this.site.baseURL,
            useKeywordPrecisionSearch: this.useKeywordPrecisionSearch ? 1 : 0,
          })
          .then((response) => {
            const { status } = response
            if (status === 'SUCCESS' && response.results) {
              this.googleSearchSuccessProcess(response.results)
            } else if (status === 'FAILURE' || (status !== 'CANCELLED' && !response.results)) {
              this.showModalForProReportSteps = false;
              this.errorMsg = 'There was an error getting Google results. This error means that Google is split testing some new html in the search results.  To fix this error you can try the following: change your POP location, use the POP Chrome Extension, change keyword or simply manually input your competitor URLs.'
              this.googleSearchSuccessProcess({variations: [], excludedVariations: [], searchPages: []})
            } else if (status === 'CANCELLED') {
              this.showModalForProReportSteps = false;
              console.log('Cancelled google search by user')
            }
            return 1
          })
          .catch((err) => {
            console.log('Error submitting google search ', err)
            this.errorMsg = 'There was an error getting Google results. This error means that Google is split testing some new html in the search results.  To fix this error you can try the following: change your POP location, use the POP Chrome Extension, change keyword or simply manually input your competitor URLs.'
          })
          .finally(() => {
            this.$store.commit('setSubmittingGoogleSearch', false)
            this.$store.commit('setFetchedVariations', true)
          })
        }
      } catch(e) {
        console.log(e)
        this.$store.commit('setSubmittingGoogleSearch', false)
        this.$store.commit('setFetchedVariations', true)
      }
      
    },
    filterVariations() {
      if (this.variations.length == 0) { return }
      // apply filter variations rule.
      this.$store.dispatch('filterGoogleVariations', { keyword: this.keyword, secondaryKeywords: this.cleanedSecondaryKeywords, variations: this.variations, gDomain: this.$store.state.usedGoogleDomain })
      .then(response => {
        console.log('Done filtering variations action')
      })
    },
    fetchVariations () {
      this.fetchingVariations = true
      try {
        this.$store.dispatch('fetchVariations', { keyword: this.cleanText(this.keyword), proxyLocation: this.proxyLocation })
          .then((response) => {
              let variations = response.data
                .filter(v => this.isValidWord(v) && v.toLowerCase() !== this.cleanText(this.keyword).toLowerCase())
                .map(v => this.cleanText(v))
              variations.sort();
              this.$store.commit('setVariations', Array.from(new Set(variations)))
              this.showNoAutoVariationsModal = this.variations.length === 0
              this.$store.commit('setFetchedVariations', true)

              this.filterVariations()
              
          })
          .catch((err) => {
            console.log('Error fetching variations ', err)
            this.errorMsg = 'Error fetching variations. Please check validation messages. If error persists contact support.'
          })
          .finally(() => this.fetchingVariations = false)
      } catch (e) {
        console.log('error fetching variations')
        console.log(e)
        this.fetchingVariations = false
        this.errorMsg = 'Error fetching variations. Please check validation messages. If error persists contact support.'
      }
    },
    clearErrorGeneralMessage () {
      this.errorMsg = ''
    },
    isValidWord (word) {
      return word.replace(/[.*&^+?!-_\/\s]/g, '').length > 0
    },
    onContinueSubmitting (continueSubmitting) {
      if (continueSubmitting) {
        this.continueSubmitting = true
        this.submitData()
      } else {
        this.continueSubmitting = false
      }
    },
    cleanCompetitorUrls (urls) {
      if (_.isArray(urls)) {
        return urls.filter(url => url.length).join('\n')
      }
      return urls.split('\n')
        .filter(url => url.length)
        .join('\n')
    },
    doRunHomePage () {
      this.targetUrl = ''
      this.showSuspectRunningHomePage = false
      this.validateInputUrls()
    },
    sourceCodeIsCorrect(source) {
      let invalidSourceCodeTypes = [
        `<html xmlns=\"http://www.w3.org/1999/xhtml\"><head></head><body></body></html>`
      ]

      if (invalidSourceCodeTypes.includes(source)) {
        return false
      }
      return true 
    },
    validateUrlsSuccessProcess (resultResponse) {

      const action = () => {
        if (this.isRerunPage && this.firstRunOnRerunPage) {
          this.firstRunOnRerunPage = false
          this.currentPage = PageRunPages.SUBMITTING_PAGE_RUN
          this.submitForm()
        } else if (this.isExpressReport || !this.isUserWillPickCompetitors) {
          this.currentPage = PageRunPages.VARIATIONS
          // this.currentPage = PageRunPages.ADD_PAGE_TO_OPTIMIZE
        } else {
          this.currentPage = PageRunPages.SELECT_FOCUS_GROUP;
        }
        if (this.isRerunPage && this.rerunType == 0) {
          console.log('this is rerun for update score so do not need lsi calc again')
        } else {
          return this.fetchLsaPhrases()
        }
      }

      // no need to validate anythign then
      if (Object.keys(resultResponse).length == 0) {
        this.clearErrorGeneralMessage()
        this.submittingValidation = false
        this.urlsValidated = true
        this.invalidCompetitorUrls = []
        this.validatedCompetitorUrls = this.competitorUrlsArray
        this.focusCompetitorUrls = this.focusCompetitorUrls.filter(focusUrl => {
          return this.validatedCompetitorUrls.indexOf(focusUrl) > -1
        })
        this.ranValidation = true

        action();
        return;
      }

      this.clearErrorGeneralMessage()
      this.submittingValidation = false
      this.proxyId = resultResponse.proxyId

      this.invalidCompetitorUrls = resultResponse.results.filter((url) => !url.validated ||  !this.sourceCodeIsCorrect(url.source)).map((url) => url.url)
      resultResponse.results.slice().forEach(validatedUrl => {
        if (this.invalidCompetitorUrls.includes(validatedUrl.url)) {
          return
        }
        const idx = this.validatedUrls.map(item => item.url).indexOf(validatedUrl.url)
        if (idx === -1) {
          // unshift is used rather than push to make it work even if competitor urls include target url
          // because if competitor url and target url is same, then on re-run for update score, we will use empty html for target url source
          // since we're not fetching competitors again
          this.validatedUrls.unshift(validatedUrl)
        }
      })
      if (!this.invalidCompetitorUrls.length) {
        // this.validatedCompetitorUrls = competitorUrls.map((url) => url.url)
        this.validatedCompetitorUrls = this.competitorUrlsArray
        this.urlsValidated = true
        // remove focus url that has deleted from competitor urls
        this.focusCompetitorUrls = this.focusCompetitorUrls.filter(focusUrl => {
          return this.validatedCompetitorUrls.indexOf(focusUrl) > -1
        })
      } else {
        if (this.isUserWillPickCompetitors) {
          console.log('found invalid competitor urls!  set validatedCompetitorUrls to []')
          this.validatedCompetitorUrls = []
          this.urlsValidated = false
          this.rerunType = 1
          this.currentPage = this.PageRunPages.ADD_COMPETITORS
          return
        } else {
          if (this.invalidCompetitorUrls.length > 7) {
            this.errorMsg = 'POP cannot pick competitors automatically.'
            this.urlsValidated = false
            return
          }

          this.competitorUrls = this.validatedUrls.map(url => url.url).slice(0, 10).join('\n').trim()
          this.focusCompetitorUrls = this.validatedUrls.map(url => url.url).slice(0, 3)
          this.invalidCompetitorUrls = []
        }
      }
      if (this.isRerunPage && this.pageNotBuilt) {
        if (this.pageNotBuilt) {
          let completeUrl = this.getCompleteUrl()
          this.validatedUrls.push({
            source: '<html><body></body></html>',
            url: completeUrl,
            validated: true
          })
        }
      }
      action();
      return;
      // if (this.isRerunPage && this.firstRunOnRerunPage) {
      //   this.firstRunOnRerunPage = false
      //   this.currentPage = PageRunPages.SUBMITTING_PAGE_RUN
      //   this.submitForm()
      // } else if (this.isExpressReport || !this.isUserWillPickCompetitors) {
      //   this.currentPage = PageRunPages.ADD_PAGE_TO_OPTIMIZE
      // } else {
      //   this.currentPage = PageRunPages.SELECT_FOCUS_GROUP;
      // }
      // // submit job for LSA
      // return this.fetchLsaPhrases()
    },
    fetchLsaPhrases () {
      this.submittingFetchLsaProcess = true
      return this.$store.dispatch('fetchLsaPhrases', {
          url: this.getCompleteUrl(),
          validatedUrls: this.validatedUrls,
          competitorUrls: this.cleanCompetitorUrls(this.competitorUrls),
          keyword: this.keyword,
          variations: this.runWithoutVariation ? [] : this.variations,
          excludedVariations: this.$store.state.excludedVariations,
          lsaPhrases: this.lsaPhrases,
          ignoreSpaces: this.ignoreSpaces ? 1 : 0,
          targetLang: this.targetLang,
          proxyId: this.proxyId,
          targetUrlSourceCode: this.isTargetUrlSourceDirectly ? this.targetUrlSourceCode : '',
          manualCompetitorUrlsSources: this.manualCompetitorUrlsSources,
        }).then(resp => {
          if (resp.data.status === 'SUCCESS') {
            console.log('fetchLSAPhrases response', resp.data)
            const { lsaPhrasesTask } = resp.data
            this.fetchingLSA = true
            this.lasFetchPhrasesTimer = setInterval(() => {
              return fetchTaskResult(lsaPhrasesTask).then(taskResp => {
                if (taskResp.data.status === 'SUCCESS') {
                  let lsaPhrases = taskResp.data.lsaPhrases
                  if ( this.isRerunPage && this.lsaPhrases.length > 0 ) {
                    let phraseList = lsaPhrases.map(item => item.phrase)
                    this.lsaPhrases.map(item => {
                      if (!phraseList.includes(item.phrase)) {
                        lsaPhrases.push(item)
                      }
                    })                    
                  }
                  this.lsaPhrases = lsaPhrases
                  this.numberedLsaPhrases = taskResp.data.numberedLsaPhrases
                  this.clearFetchLSAPhrasesTimer()
                  if (this.isOnePageSetup) {
                    this.currentPage = PageRunPages.SUBMITTING_PAGE_RUN
                    this.showModalForProReportSteps = false;
                    this.submitForm()
                  }
                } else if (taskResp.data.status === 'FAILURE') {
                  this.clearFetchLSAPhrasesTimer()
                } else if (taskResp.data.status === 'PROGRESS') {
                  
                }
              })
            }, 4000)
          } else if (resp.data.status === 'FAILURE') {
            
          }
        }).finally(() => {
          this.ranValidation = true
        })
    },
    validateTargetUrlSuccessProcess (resultResponse) {
      // no need to validate anythign then
      if (Object.keys(resultResponse).length==0) {
        this.clearErrorGeneralMessage()
        this.submittingValidation = false
        this.validatedTargetUrl = true
        
        if (this.isExpressReport) {
          this.submitForm()
          return;
        } else {
          this.currentPage = PageRunPages.VARIATIONS
        }

        return;
      }

      let completeUrl = this.getCompleteUrl()

      this.clearErrorGeneralMessage()
      this.submittingValidation = false
      if (this.pageNotBuilt || this.isTargetUrlSourceDirectly) {
        this.validatedTargetUrl = true;
      } else {
        this.validatedTargetUrl = resultResponse.results.find((result) => result.url === completeUrl && (result.validated && this.sourceCodeIsCorrect(result.source)))
      }
      
      resultResponse.results.slice().forEach(validatedUrl => { // add target url to the validatedUrls to send prefetched source
        const idx = this.validatedUrls.map(item => item.url).indexOf(validatedUrl.url)
        if (idx === -1) {
          this.validatedUrls.push(validatedUrl) 
        }
      })

      // const competitorUrls = resultResponse.validationResults.filter((result) => result.url !== completeUrl)
      if (this.validatedTargetUrl) {
        // this.validatedCompetitorUrls = competitorUrls.map((url) => url.url)
        if (this.isExpressReport) {
          this.submitForm()
          return;
        } else {
          this.currentPage = PageRunPages.VARIATIONS
        }
        
      } else {
        console.log('found invalid competitor urls! validating target url is failed.')
        this.validatedTargetUrl = false
        this.errorMsg = 'Failed to validate your target url'
        this.showModalForProReportSteps = false;
        return
      }
    },
    clearFetchLSAPhrasesTimer () {
      try {
        this.submittingFetchLsaProcess = false
        clearInterval(this.lasFetchPhrasesTimer)
      } catch(e) {}
    },
    validateTargetUrl () {
      this.isFetchingSourceForTargetUrl = true

      let completeUrl = this.getCompleteUrl()
      if (!completeUrl) {
        this.errorMsg = 'Can not make validaton because of url info is missing.'
        return
      }
      if (this.isTargetUrlSourceDirectly) {
        this.validateTargetUrlSuccessProcess({})
        return
      }
      if (this.pageNotBuilt) {
        this.validatedUrls.push({
          source: '<html><body></body></html>',
          url: completeUrl,
          validated: true
        })
        this.validateTargetUrlSuccessProcess({})
        return
      }

      this.submittingValidation = true
      if (this.isGoogleSearchDoneWithExtension) {
        console.log('validating target url', completeUrl)
        this.dispatch_custom_event( "validate_urls", {
          url_arr: [completeUrl]
        })
      } else {
        this.$store.dispatch('validatePageAnalysisUrls', { urls: [completeUrl], proxyLocation: this.proxyLocation, siteBaseUrl: this.site.baseURL })
        .then((resultResponse) => {
          if (resultResponse.status === 'SUCCESS') {
            this.validateTargetUrlSuccessProcess(resultResponse)
          } else if (resultResponse.status === 'FAILURE') {
            this.clearErrorGeneralMessage()
            this.ranValidation = true
            this.submittingValidation = false
          }
        })
      }
    },
    validateInputUrls () {
      this.showModalForProReportSteps= true;
      this.isFetchingSourceForTargetUrl = false

      if (!this.firstRunOnRerunPage && this.isUserWillPickCompetitors && this.competitorUrlsArray.length > this.MAX_COMPETITORS) { // because in auto pick, we will first pick 12 competitors to adapt failure case
        this.showTooManyCompetitorsModal = true
        return
      }
      if (!this.isRerunPage) {
        let cleanedBaseURL = this.baseUrl.replace(/^http(s)?:\/\//, '').replace(/^www/, '').replace(/^\./, '').replace(/\/$/, '')
        let cleanedTargetURL = this.targetUrl.replace(/^http(s)?:\/\//, '').replace(/^www/, '').replace(/^\./, '').replace(/\/$/, '')
        // does the target url start with either www or http, indicating that they pasted in
        // a second url that contains a domain, now need to check if that is a different domain

        if ((this.targetUrl.toLowerCase().startsWith('www') || this.targetUrl.toLowerCase().startsWith('http')) 
          && cleanedBaseURL.toLowerCase() != cleanedTargetURL) {
          this.showMissingProjectDomainModal = true
          return
        } else if (cleanedBaseURL.toLowerCase() === cleanedTargetURL.toLowerCase()) {
          this.showSuspectRunningHomePage = true
          return
        }
      }
      if (!this.competitorUrls.length) {
        this.errorMsg = 'Can not make validaton because of url info is missing.'
        return
      }
      // Add all competitors to validatedUrls for re-run (update )
      if (this.rerunType == 0) {
        let validatedCompetitorUrls = []
        let validatedUrls = []
        this.competitorUrlsArray.map(url => {
          if (this.validatedCompetitorUrls.indexOf(url) == -1) {
            validatedCompetitorUrls.push(url)
            validatedUrls.push({
              source: "<html></html>",
              url: url,
              validated: true
            })
          }
        })
        this.validatedCompetitorUrls = validatedCompetitorUrls
        this.validatedUrls = validatedUrls
      }


      this.submittingValidation = true
      this.ranValidation = false
      try {
        const previouslyValidateUrls = this.validatedUrls.filter(prevValidatedUrl => prevValidatedUrl.url && prevValidatedUrl.validated).map(prevValidatedUrl => prevValidatedUrl.url)
        const competitorUrlsThatHavetoValidate = this.competitorUrlsArray.filter(url => {
          return !this.manualCopySourceCompetitorUrls?.includes(url) && !previouslyValidateUrls.includes(url)
        })

        let urlsForValidation = competitorUrlsThatHavetoValidate

        if (!this.isTargetUrlSourceDirectly && !this.pageNotBuilt) { // if target url is not validated yet, then we need to validate target url too in validating competitors step
          let completeUrl = this.getCompleteUrl()
          urlsForValidation.push(completeUrl)
        }

        // If there is no urls for validating 
        if (!urlsForValidation.length) {
          this.validateUrlsSuccessProcess({})
          return
        }
        
        this.$store.commit('setValidateUrlsProgress', 1)
        if (this.isGoogleSearchDoneWithExtension) {
          console.log('validating urls', urlsForValidation)
          this.dispatch_custom_event( "validate_urls", {
            url_arr: urlsForValidation
          })
        } else {
          this.$store.dispatch('validatePageAnalysisUrls', { urls: urlsForValidation, proxyLocation: this.proxyLocation, siteBaseUrl: this.site.baseURL })
            .then((resultResponse) => {
              if (resultResponse.status === 'SUCCESS') {
                this.validateUrlsSuccessProcess(resultResponse)
              } else if (resultResponse.status === 'FAILURE') {
                this.clearErrorGeneralMessage()
                this.ranValidation = true
                this.submittingValidation = false
              }
            })
        }

      } catch(e) {
        console.log('error validating urls')
        console.log(e)
      }
    },
    goBackToEditUrlsMode () {
      this.urlsValidated = false
      this.validatedCompetitorUrls = []
      this.focusCompetitorUrls = []
    },
    checkModalDisplayForRerun () {
      if (this.firstRunOnRerunPage) {
        this.showConfirmChangeUrlOnReRun = true
        return
      }

      if (this.validationIssues.length > 0) {
        this.showErrorReasonModal = true
      } else {
        this.showConfirmChangeUrlOnReRun = true
      }
    },
    dispatch_custom_event: function ( name, data ) {

      if (!this.isAvailableChromeExtension) {
        console.log("chrome extension is not installed!")
        return window.alert("Please check you installed google extension!")
      }

      // the web application should fire this event to trigger the extension
      // the extension will listen for this event

      var event = new CustomEvent(

        "web_app_event", 
        {
          detail: {
            name: name,
            data: data,
          },
          bubbles: true,
          cancelable: true
        }
      );
      console.log('Dispatching event to extension', name)
      document.dispatchEvent( event );
    },
    checkExtensionIsInstalled () {
      if ( document.readyState === "interactive" || document.readyState === "complete" ) {
        if ( document.documentElement.dataset.page_optimizer_browser_extension_version ) {
          this.isAvailableChromeExtension = true
          console.log('Adding event listener for POP extension!!')
          document.addEventListener( "page_optimizer_browser_extension_event", this.extensionEventListener)
        } else {
          this.isAvailableChromeExtension = false
          console.log('can not find extension in this browser')
        }
      }
    },
    extensionEventListener (event) {
      var name = event.detail.name;
      var data = event.detail.data;
      
      if ( name === "parse_google_result" ) {
        let resData = data.parse_google_result
        console.log('extension callback [parse google]', resData)
        // vm.$data.result = JSON.stringify( data.parse_google_result, null, "\t" );
        let { status, pages_collected_num, pages_total_num } = resData
        
        if (status == 'PENDING' || status == 'PROGRESS') {
          let progress = pages_collected_num * 100 / pages_total_num
          if (progress < 1) progress = 1
          this.$store.commit('setGoogleSearchProgress', progress)
        } else if (status === 'SUCCESS') {
          this.googleSearchSuccessProcess(resData.results)
        } else if (status === 'FAILURE') {
          this.$store.commit('setSubmittingGoogleSearch', false)
          this.errorMsg = 'There was an error getting Google results. This error means that Google is split testing some new html in the search results.  To fix this error you can try the following: change your POP location, use the POP Chrome Extension, change keyword or simply manually input your competitor URLs.'
        }
        
      } else if ( name === "validate_urls_result" ) {
        let resData = data.validate_urls_result
        console.log('extension callback [validate urls]', resData)
        let { status, total_urls, processed } = resData

        if (status=='PENDING' || status=='PROGRESS') {
          let progress = Math.round(processed * 100 / total_urls)
          if (progress < 1) progress = 1
          this.$store.commit('setValidateUrlsProgress', progress)
        } else if (status=='FAILURE') {
          this.clearErrorGeneralMessage()
          this.ranValidation = true
          this.submittingValidation = false
        } else if (status=='SUCCESS') {
          if(this.isFetchingSourceForTargetUrl) {
            this.validateTargetUrlSuccessProcess(resData)
          } else {
            this.validateUrlsSuccessProcess(resData)
          }
        }
      };
    },
    rankSortCompetitors (competitors) {
      let rankedCompetitors = []
      if (competitors.length) {
        if (_.isString(competitors)) {
          rankedCompetitors = this.cleanCompetitorUrls(competitors).split('\n')
        } else if (_.isArray(competitors)) {
          rankedCompetitors = this.cleanCompetitorUrls(competitors.join('\n')).split('\n')
        }
      }
      
      if (this.googleSearchLocations) {
        rankedCompetitors = rankedCompetitors.map(url => {
          const obj = { url, rank: 100 }
          const i = this.googleSearchResults.findIndex(sr => sr.url && sr.url.toLowerCase() === url.toLowerCase())
          if (i > -1) {
            obj.rank = i
          }
          return obj
        })
        rankedCompetitors = _.sortBy(rankedCompetitors, ['rank']).map(x => x.url)
      }
      return rankedCompetitors.join('\n')
    }
  },

  created () {
    // makes the const available in templates
    this.PageRunPages = PageRunPages
    this.cleanText = cleanText
  },
  mounted () {
    let tips = [...SEO_TIPS].sort((a, b) => {
      return 0.5 - Math.random()
    })
    this.selected3Tips = tips.slice(0, 3)
  },
  beforeDestroy () {
      this.clearFetchLSAPhrasesTimer()
      this.$store.commit('resetVariations')
  }
}