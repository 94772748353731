export const light = {
  primary: '#014751',
  anchor: '#014751',

  mainColor: '#014751',
  mainColor2: '#1B4650',
  
  darkBlue1Color: '#012323',

  blackColor: '#000000',
  lightYellowColor: '#FFEDDD',
  lightYellow1Color: '#FFEBD9',
  lightYellow2Color: '#FFF4EE',
  yellow1Color: '#FFBC7E',
  yellow2Color: '#FF993A',
  yellow3Color: '#FAF3D0',
  yellow4Color: '#AA993B',
  yellow5Color: '#EF6111',
  yellow6Color: '#FF9839',
  yellow7Color: '#FFEDAB',
  yellow8Color: '#7D6203',
  yellow9Color: '#B86800',
  yellow10Color: '#FFF1DA',
  yellow11Color: '#FDAB3F',
  yellow12Color: '#FFFAF6',
  
  greenColor: '#3B8D67',
  // lightGreenColor: '#5DDB81',
  lightGreenColor: '#40BA7F',
  green1Color: '#57E398',
  green2Color: '#38C98C',
  green3Color: '#d7f4e8',
  green4Color: '#EBFCF2',
  green5Color: '#1AB26A',
  green6Color: '#D6FBEE',
  green7Color: '#25A97A',
  green8Color: '#068859',
  green9Color: '#25A97A',
  green10Color: '#DAF8E9',
  green11Color: '#4CC994',
  green12Color: '#EDF6F1',
  green13Color: '#EAFFF4',
  green15Color: '#46BE86',
  green16Color: '#189480',
  green17Color: '#EEF9F3',
  green18Color: '#B9EED4',
  green19Color: '#CCF4DA',
  green20Color: '#0DAA68',
  green21Color: '#00CD49',
  
  grayColor: '#CCCCCC',
  darkGrayColor: '#999999',
  gray1Color: '#939393',
  gray2Color: '#757575',
  gray7Color: '#F7F7F7',
  gray8Color: '#EEEEEE',
  gray9Color: '#B5B5B5',
  gray10Color: '#F3F3F3',
  gray12Color: '#D9D9D9',
  gray13Color: '#E6E9F1',
  gray15Color: '#E4E4E4',
  gray33Color: '#F8F9FA',
  gray36Color: '#9E9FA1',
  gray40Color: '#AAAAAA',

  mainBgColor: '#F9F9F9', // screen background

  blue1Color: '#024751',
  blue2Color: '#1D3544',
  blue3Color: '#184D9C',
  blue4Color: '#329CE9',
  blue5Color: '#3670FF',
  
  companyDetailBg: '#DCFFE2',
  
  linkColor: '#41AFED',
  link1Color: '#209CEE',
  link3Color: '#316BFF',
  link4Color: '#114DE5',
  link7Color: '#C9E7FB',
  link9Color: '#E9F3FF',
  link10Color: '#3EB6F4',
  link11Color: '#DBF0FC',
  link15Color: '#0075FF',
  
  whiteColor: '#FFFFFF',
  alwaysWhiteColor: '#FFFFFF',
  profileAnchorColor: '#FF5700',
  anchor1Color: '#FB6A00',

  redColor: '#FF1F2D',
  red1Color: '#FEF2F2',
  red2Color: '#F04438',
  red3Color: '#FF3939',
  red4Color: '#FBE8E8',
  red5Color: '#E24444',
  red6Color: '#FFF0F0',
  red7Color: '#EF34A4',
  red8Color: '#FFE1F3',
  red9Color: '#E34848',
  red10Color: '#FFF5F5',
  red11Color: '#FF3737',
  red12Color: '#FF4A4A',

  purpleColor1: '#8439FF',
  purpleColor2: '#F7F2FF',

  pink1Color: '#F7F5FB',

  topHeaderBg: '#F9F9F9',
  cardBg: '#FFFFFF',
  lightGray: '#f3f4fa',
  normalLightGray: '#E7E7E7',
  aiWriterBgColor: '#005A38'
}

export const dark = {
  primary: '#FFFFFF',
  anchor: '#D7DDE6',

  mainColor: '#D7DDE6',
  mainColor2: '#D7DDE6',
  
  darkBlue1Color: '#D7DDE6',

  blackColor: '#FFFFFF',
  lightYellowColor: '#252525',
  lightYellow1Color: '#FFEBD9',
  lightYellow2Color: '#555555',
  yellow1Color: '#FFBC7E',
  yellow2Color: '#FF993A',
  yellow3Color: '#FAF3D0',
  yellow4Color: '#AA993B',
  yellow5Color: '#EF6111',
  yellow6Color: '#FF9839',
  yellow7Color: '#FFEDAB',
  yellow8Color: '#7D6203',
  yellow9Color: '#B86800',
  yellow10Color: '#FFF1DA',
  yellow11Color: '#FDAB3F',
  yellow12Color: '#383634',
  
  greenColor: '#3B8D67',
  // lightGreenColor: '#5DDB81',
  lightGreenColor: '#40BA7F',
  green1Color: '#57E398',
  green2Color: '#38C98C',
  green3Color: '#102B1A',
  green4Color: '#EBFCF2',
  green5Color: '#1AB26A',
  green6Color: '#102B1A',
  green7Color: '#25A97A',
  green8Color: '#068859',
  green9Color: '#EAFFFA',
  green10Color: '#38403c',
  green11Color: '#4CC994',
  green12Color: '#6d736f',
  green13Color: '#3d4642',
  green15Color: '#46BE86',
  green16Color: '#189480',
  green17Color: '#464d49',
  green18Color: '#B9EED4',
  green19Color: '#CCF4DA',
  green20Color: '#0DAA68',
  green21Color: '#00CD49',
  
  grayColor: '#424242',
  darkGrayColor: '#999999',
  gray1Color: '#939393',
  gray2Color: '#AAAAAA',
  gray7Color: '#2D2E31',
  gray8Color: '#000000',
  gray9Color: '#B5B5B5',
  gray10Color: '#151515',
  gray12Color: '#D9D9D9',
  gray13Color: '#252525',
  gray15Color: '#737373',
  gray33Color: '#424242',
  gray36Color: '#9E9FA1',
  gray40Color: '#4d4d4d',

  mainBgColor: '#000000', // screen background

  blue1Color: '#024751',
  blue2Color: '#1D3544',
  blue3Color: '#184D9C',
  blue4Color: '#329CE9',
  blue5Color: '#3670FF',
  
  companyDetailBg: '#DCFFE2',
  
  linkColor: '#41AFED',
  link1Color: '#209CEE',
  link3Color: '#316BFF',
  link4Color: '#70A9FF',
  link7Color: '#6c7c87', // don't remove
  link9Color: '#18232F', // '#424242' ?
  link10Color: '#3EB6F4',
  link11Color: '#DBF0FC',
  link15Color: '#0075FF',
  
  whiteColor: '#000000',
  alwaysWhiteColor: '#FFFFFF',
  profileAnchorColor: '#FF752E',
  anchor1Color: '#FB6A00',

  redColor: '#FF1F2D',
  red1Color: '#FEF2F2',
  red2Color: '#F04438',
  red3Color: '#FF3939',
  red4Color: '#FBE8E8',
  red5Color: '#E24444',
  red6Color: '#FFF0F0',
  red7Color: '#EF34A4',
  red8Color: '#FFE1F3',
  red9Color: '#E34848',
  red10Color: '#383434',
  red11Color: '#FF3737',
  red12Color: '#FF4A4A',

  purpleColor1: '#AF7EFF',
  purpleColor2: '#424242',

  pink1Color: '#252525',

  topHeaderBg: '#252525',
  cardBg: '#252525',
  lightGray: '#000000',
  normalLightGray: '#252525',
  aiWriterBgColor: '#005A38'
}
