<template>
  <div>
    <v-container>
      <div class="top-part">
        <div class="title-text">
          Bulk Runs
          <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
            @click="() => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'How to Set up Bulk Run in POP',
                videoLink: 'https://www.youtube.com/embed/Dlz3EKlgKUo?si=fgT-31PaxcSMagps',
                description: 'Would you like to set up multiple report runs simultaneously? In this video, Dade, POP\'s Customer Success Manager, will guide you through the process of setting up bulk runs in POP, making project execution faster and smoother. Important: The bulk run feature is available for the team plan users only.',
                time: '0m 54s'
              });
            }">
            videocam
          </span>
        </div>
        <div class="explain mt-3">Here's how to do a bulk run:</div>
        <ul>
          <li>
            Download the excel template <a class="download-link" download :href="bulkDemoURL">here</a>
            <span class="material-icons video-tutorial-icon video-tutorial-icon-size ml-2"
              @click="() => {
                $store.commit('setCurrentVideoTutorialToDisplay', {
                  title: 'How to Set up Excel Sheet for Bulk Run',
                  videoLink: 'https://www.youtube.com/embed/C62SmJO-yNI?si=63NgkKeBR_1DSItn',
                  description: 'If you\'re on a Team plan, you can set up a bulk run -  POP will simultaneously run multiple reports and you\'ll get notified once your bulk report run is complete. In this video, you\'ll learn how to prepare an Excel sheet for a bulk run.',
                  time: '6m 34s'
                });
              }">
              videocam
            </span>
          </li>
          <li>Fill out the template and upload it</li>
          <li>Click the button below to start your run (you get a maximum of 50 bulk runs a day)</li>
        </ul>
      </div>
      <div class="notify-label1 mt-3">
        <i class="material-icons">error_outline</i>
        <div>
          <span>Attention: you can also access the Google NLP API and EEAT features</span>
          <div class="mt-2 font-weight-normal">
            <div class="link3Color--text">- it costs {{popCreditCharges.googleNlpCredits}} POP credits to access Google's NLP API feature</div>
            <div class="link3Color--text">- it costs {{popCreditCharges.eeatCredits}} POP credits to access POP's EEAT feature</div>
          </div>
        </div>
      </div>
  
      <div class="mt-3" @dragover.prevent @drop.prevent>
        <div class="drag-area" @drop="(e) => filesChange(e.target.name, e.dataTransfer.files)">
          <img src="/static/bulk-upload-icon.png" class="upload-icon" />
          <div class="flex justify-center">
            <label class="file-label">
              <input class="file-input has-text-centered" type="file" ref="csvfile" name="csvfile"
                @change="(e) => filesChange(e.target.name, e.target.files)" accept=".xlsx"/>
              <div class="d-flex align-center">
                <span class="file-cta has-text-weight-bold">
                  Browse Files
                </span>
                <div>&nbsp;or drag and drop your file here</div>
              </div>
            </label>
          </div>
          <div v-if="isAgencyUnlimitedUser">
            Daily runs remaining {{ user.remainedBulkRunsToday || 0 }} / 50 ( Unlimited )
          </div>
          <div class="mt-1 d-flex justify-center">
            <div v-if="selectedFile && selectedFile.name" class="selected-file">
              <span class="font-weight-bold">{{ selectedFile.name }}</span>
              <span class="material-icons" @click="resetFileSelected">cancel</span>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-btn rounded
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text"
            :disabled="isCalculatingBulkNow || !canMakeBulkRun" @click="submitForBulk">
            <span>Start bulk run</span>
          </v-btn>
        </div>
      </div>
  
      <div class="mt-15 mb-10">
        <div class="bulk-runs-result-label mb-2">Bulk runs results</div>
        <div class>Files that have been uploaded</div>
      </div>
  
      <div class="mb-10" v-for="bulkRun in bulkRuns" :key="bulkRun.id">
        <div class="d-flex align-end mb-2">
          <div class="bulk-created-date">{{ toLocalDateTimeString(bulkRun.createdAt) }}</div>
          <div class="flex-grow-1 ml-3">
            <div v-if="bulkRun.status === 'PENDING'">
              Preparing the bulk run
            </div>
            <div v-else-if="bulkRun.status === 'REVOKED'">
              This bulk run is cancelled
            </div>
            <div v-else-if="bulkRun.status === 'PROGRESS'" class="d-flex align-center">
              <div>Processing...</div>
              <v-progress-linear rounded height="8"
                class="ml-2"
                style="min-width: 200px; max-width: 200px;"
                background-color="gray7Color"
                color="profileAnchorColor"
                :value="bulkRun.progress">
              </v-progress-linear>
            </div>
          </div>
          <div>
            <v-btn outlined
              v-if="bulkRun.status === 'PROGRESS'"
              color="mainColor"
              class="button-with-icon text-none"
              @click="cancelBulkRunAction(bulkRun)">
              <i class="material-icons-outlined">stop_circle</i>
              <span>Cancel bulkrun</span>
            </v-btn>
            <v-menu offset-y v-if="bulkRun.allRuns.length > 0 && bulkRun.status === 'SUCCESS'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined
                  color="mainColor"
                  class="button-with-icon text-none ml-2"
                  v-bind="attrs"
                  v-on="on">
                  <i class="material-icons-outlined">file_download</i>
                  <span>Download all</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="downloadReports(bulkRun, 'reports')">
                  <v-list-item-title>All reports</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadReports(bulkRun, 'ai_runs')" v-if="bulkRun.hasAIRun">
                  <v-list-item-title>All AI articles</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn outlined
              v-if="bulkRun.allRuns.length > 0"
              color="mainColor"
              class="button-with-icon text-none ml-2"
              @click="deleteBulkRunConfirm(bulkRun.id)">
              <i class="material-icons-outlined">delete</i>
              <span>Delete all</span>
            </v-btn>
          </div>
        </div>  
        <pre class="notification" v-if="bulkRun.autoNotes">{{ bulkRun.autoNotes }}</pre>
  
        <div class="bulk-runs-table-wrapper mt-4">
          <table class="bulk-runs-table">
            <thead>
              <tr>
                <th>Page run ID</th>
                <th>Target url</th>
                <th>Target keyword</th>
                <th>Language</th>
                <th>Location</th>
                <!-- <th>Variations</th> -->
                <th>Progress</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="run in bulkRun.allRuns" :key="`${run.id}_${ run.isAIRun ? 'ai' : 'pagerun' }}}`" :class="{ 'odd-row': run.rowIndex % 2 == 0, 'ai-row': run.isAIRun, 'pagerun-row': !run.isAIRun }">
                <template v-if="run.isAIRun">
                  <td></td>
                  <td colspan="4">
                    Auto AI article for above report
                  </td>
                  <td>
                    <v-progress-linear rounded height="8"
                      v-if="run.autoStatus!='SUCCESS' && run.autoStatus!='FAILURE'"
                      style="min-width: 120px;"
                      background-color="gray7Color"
                      color="profileAnchorColor"
                      :value="run.autoProgress">
                    </v-progress-linear>
                    <div v-else>
                      <span v-if="run.autoStatus == 'SUCCESS'" class="success-status">Success</span>
                      <span v-else-if="run.autoStatus == 'FAILURE'" class="failure-status">Failure</span>
                    </div>
                  </td>
                  <td>
                    <div class="actions">
                      <v-tooltip top color="transparent" v-if="run.autoStatus=='SUCCESS' || run.autoStatus=='FAILURE'">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="action-icon-btn"
                            :class="{ 'disabled': run.autoStatus=='FAILURE' }"
                            v-bind="attrs" v-on="on" @click="() =>viewAIReport(run)">
                            <i class="material-icons-outlined">auto_fix_high</i>
                          </span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Go to AI article
                        </div>
                      </v-tooltip>
  
                      <v-tooltip top color="transparent" v-if="run.autoStatus=='SUCCESS' || run.autoStatus=='FAILURE'">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="action-icon-btn"
                            :class="{ 'disabled': run.autoStatus=='FAILURE' }"
                            v-bind="attrs" v-on="on" @click="() =>downloadAIReport(run)">
                            <i class="material-icons-outlined">download_for_offline</i>
                          </span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Download
                        </div>
                      </v-tooltip>
  
                      <!-- <v-tooltip top color="transparent" v-if="run.reportResourceUrl" :href="run.reportResourceUrl">
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            class="action-icon-btn"
                            v-bind="attrs" v-on="on">
                            <i class="material-icons-outlined">download_for_offline</i>
                          </a>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Download
                        </div>
                      </v-tooltip> -->
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td>{{ run.id }}</td>
                  <td class="font-weight-bold">
                    {{ run.url }}
                  </td>
                  <td>
                    <span class="variation-tag">
                      {{ run.keyword }}
                    </span>
                  </td>
                  <td>{{ run.targetLanguage | capitalize }}</td>
                  <td>{{ getProxyNameFromId(run.proxyId) }}</td>
                  <td>
                    <v-progress-linear rounded height="8"
                      v-if="run.status!='SUCCESS' && run.status!='FAILURE'"
                      style="min-width: 120px;"
                      background-color="gray7Color"
                      color="profileAnchorColor"
                      :value="run.progress">
                    </v-progress-linear>
                    <div v-else>
                      <span v-if="run.status == 'SUCCESS'" class="success-status">Success</span>
                      <span v-else-if="run.status == 'FAILURE'" class="failure-status">Failure</span>
                    </div>
                  </td>
                  <td>
                    <div class="actions">
                      <v-tooltip top color="transparent" v-if="run.status=='SUCCESS' || run.status=='FAILURE'">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="action-icon-btn"
                            :class="{ 'disabled': run.status=='FAILURE' }"
                            v-bind="attrs" v-on="on" @click="() =>gotoReport(run)">
                            <i class="material-icons-outlined">assignment</i>
                            </span>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Go to report
                        </div>
                      </v-tooltip>
  
                      <v-tooltip top color="transparent" v-if="run.reportResourceUrl">
                        <template v-slot:activator="{ on, attrs }">
                          <a :href="run.reportResourceUrl"
                            class="action-icon-btn"
                            v-bind="attrs" v-on="on">
                            <i class="material-icons-outlined">download_for_offline</i>
                          </a>
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Download
                        </div>
                      </v-tooltip>
                    </div>    
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>    
    </v-container>
  
    
    <div class="modal" :class="{'is-active': showNewBulkEstimatedCreditModal}">
      <div class="modal-background" @click="showNewBulkEstimatedCreditModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="mainColor--text modal-title">
              {{ isAgencyUnlimitedUser ? 'Bulk run credit usage' : 'Bulk run activation' }}
            </div>
            <div class="bulk-run-info-text mt-5">
              <div class="mb-3">
                <template v-if="isAgencyUnlimitedUser">
                  <div>
                    You are about to initiate a bulk run. The first 50 POP reports via Bulk run feature are free. After that, each bulk run will cost 10 credits, regardless of the number of reports generated within the run.
                  </div>
                  <div class="mt-2 font-weight-bold">Please note:</div>
                  Each bulk run can generate up to a maximum of 50 reports.<br />
                  The 10 credits fee applies to each bulk run beyond the initial 50 free reports.
                </template>
                <template v-else>
                  Using the Bulk Run feature costs 10 credits, regardless of the number of reports you wish to generate. You can generate up to 50 reports per bulk run.
                </template>
              </div>
              <div v-if="googleNlpCredits > 0" class="mb-3">
                Estimated Google NLP credits: {{ googleNlpCredits }}
              </div>
              <div v-if="eeatCredits > 0" class="mb-3">
                Estimated EEAT credits: {{ eeatCredits }}
              </div>
              <div class="mb-3">
                Estimated credits for this bulk run: {{ estimatedCredits }}
                <div v-if="isAgencyUnlimitedUser && willSpendExtra10Credits">
                  The 10 credits fee applies to this bulk run. 
                </div>
              </div>
              <div>
                Do you want to proceed with the bulk run?
              </div>
              <div v-if="openAiKeyError" class="mt-3 redColor--text">
                {{ openAiKeyError }}
              </div>
            </div>
            
            <div class="notify-label">
              <i class="material-icons">error_outline</i>
              Note: Enabling Google NLP, EEAT, and AI Writer features will incur additional costs.
              The credits estimate above is the highest amount of the credits that could be needed for this bulk run.  If you insert your own GPT-4 key into POP, the amount of credits needed will be greatly reduced.
            </div>
  
            <div class="control flex mt20">
              <div class="flex-grow-1">
              </div>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                :disabled="estimatedCredits > mainAccountAvailableCredits"
                @click="() => { createNewBulkRunTask() }">
                <span>Yes</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="showNewBulkEstimatedCreditModal = false"><i class="material-icons">close</i></button>
      </div>
    </div>
  
  
  
    <div class="modal" :class="{'is-active': showBulkRunDeleteConfirmModal}">
      <div class="modal-background" @click="showBulkRunDeleteConfirmModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden;">
          <div class="modal-content-root">
            <div class="mainColor--text subtitle">
              Are you sure you want to remove this bulk run from this dashboard? <br/>
            </div>
            <div class="body-font mainColor--text">
              Removing from the dashboard will not delete the pageruns. Your pagerun  reports will still be accessible in your Projects dashboard. 
            </div>
            <div class="control d-flex mt-5">
              <div class="flex-grow-1">
              </div>
              <v-btn rounded
                color="profileAnchorColor"
                class="button-with-icon whiteColor--text text-none"
                @click="showBulkRunDeleteConfirmModal=false">
                <i class="fa fa-times mr5"></i>
                Cancel
              </v-btn>
              <v-btn rounded
                color="grayColor"
                class="button-with-icon darkGrayColor--text text-none" @click="deleteBulkRunAction">
                <i class="fa fa-trash mr5"></i>
                Delete
              </v-btn>
            </div>
          </div>
        </div>
        <button class="custom-modal-close-btn" aria-label="close" @click="showBulkRunDeleteConfirmModal = false"><i class="material-icons">close</i></button>
      </div>
    </div>
  </div>
  </template>
  
  
  <script>
  import { mixin as export_mixin } from '@/components/export_mixin'
  import { toLocalDateString, toLocalDateTimeString } from "@/utils";
  import { fetchTaskResult } from '@/api'
  
  export default {
    mixins: [ export_mixin ],
    components: {},
    data () {
      return {
        loadBulkTimer: null,
  
        selectedFile: null,
        errorMsg: '',
  
  
        proxies: [],
        bulkDemoURL: `${API_URL}/download/?file=BULK_DEMO_EXCEL`,
  
        newBulkRunId: null,
        estimatedCredits: 0,
        googleNlpCredits: 0,
        eeatCredits: 0,
        totalReportsInExcel: 0,
        willSpendExtra10Credits: false,
        openAiKeyError: '',
  
        showNewBulkEstimatedCreditModal: false,
  
  
        showBulkRunDeleteConfirmModal: false,
        bulkRunId: null,
      }
    },
    computed: {
      popCreditCharges () {
        return this.$store.state.popCreditCharges
      },
      user () {
        return this.$store.state.user
      },
      isAgencyUnlimitedUser() {
        return this.$store.getters.isAgencyUnlimitedUser;
      },
      mainAccountAvailableCredits () {
        if (this.selectedSubAccountRelation) {
          return this.selectedSubAccountRelation.monthlyCredits + this.selectedSubAccountRelation.remainedUniversalCredits
        }
        if (this.user) {
          return this.user.monthlyCredits + this.user.remainedUniversalCredits
        }
        return 0
      },
      bulkRuns() {
        return this.$store.state.bulkRuns
        // return [{
        //   createdAt: 'aa', 
        //   pageRuns: [1, 2, 3]
        // }, 2, 3]
      },
      canMakeBulkRun () {
        if (this.selectedSubAccountRelation) {
          return this.selectedSubAccountRelation.canCreateProject
        } else {
          return true;
        }
      },
      isCalculatingBulkNow() {
        return this.$store.getters.isCalculatingBulkNow
      },
      selectedSubAccountRelation () {
        return this.$store.state.selectedSubAccountRelation
      },
    },
    methods: {
      toLocalDateString (dateStr) {
        return toLocalDateString(dateStr)
      },
      toLocalDateTimeString (d) {
        return toLocalDateTimeString(d)
      },
      deleteBulkRunConfirm (runId) {
        console.log('bulk run id', runId)
        this.bulkRunId = runId
        this.showBulkRunDeleteConfirmModal = true
      },
      deleteBulkRunAction () {
        this.showBulkRunDeleteConfirmModal = false
        this.$store.commit('showLoading')
        this.$store.dispatch('deleteBulkRun', this.bulkRunId)
        .then(res => {
          if (res.data && res.data.status == 'SUCCESS') {
            this.loadBulkRuns()
          } else {
            window.alet('can not delete this page run. please refresh and try again')
          }
        })
        .catch(err => {
          window.alet('can not delete this page run. please refresh and try again')
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      },
      getProxyNameFromId (proxyId) {
        if (this.proxies[proxyId]) {
          return this.proxies[proxyId]
        } else {
          return 'Unknown'
        }
      },
      gotoReport(pageRun) {
        if (pageRun.status=='FAILURE') {
          return;
        }
        this.$store.commit('showLoading')
        return this.$store.dispatch('loadSites')
          .then((response) => {
            if (response.data && response.data.status === 'SUCCESS') {
              this.$store.commit('setSites', response.data.sites)
              // let route = this.$router.resolve(`/sites/${pageRun.siteId}/pages/${pageRun.pageId}?pageRunId=${pageRun.id}`);
              // window.open(route.href, '_blank');
              this.$router.push(`/sites/${pageRun.siteId}/pages/${pageRun.pageId}?pageRunId=${pageRun.id}`)
            }
          })
          .finally(() => this.$store.commit('hideLoading'))
      },
      viewAIReport(aiRun) {
        let queryParams = {}      
        queryParams = {
          pageRunId: aiRun.pageRunId,
          aiRunId: aiRun.id,
        }
        queryParams['editorMode'] = 'AI_MODE'
        
        if (this.selectedSubAccountRelation) {
          queryParams['relId'] = this.selectedSubAccountRelation.id
        }
        let routeData = this.$router.resolve({name: 'FullPageSourceEditor', query: queryParams});
        window.open(routeData.href, '_blank')
      },
      downloadAIReport (aiRun) {
        this.$store.commit('showLoading')
        this.$store.dispatch('aiRunGetFullHtml', { 
          aiRunId: aiRun.id,
        })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            let fullHtml = response.data.fullHtml
            this.exportToHtml(fullHtml, `ai article ${ aiRun.id }.html`)
          } else {
            this.$notify({ group: 'info', type: 'error', text: response.data.msg || 'Failed' })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      },
      downloadReports (bulkRun, type) {
        // console.log('testing', bulkRun)
        this.$store.commit('showLoading')
        this.$store.dispatch('downloadBulkRunZip', {
          bulkRunId: bulkRun.id,
          type
        })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            let url = response.data.downloadLink
            let a = document.createElement("a");
            a.download = url.split('/').pop();
            a.target = '_blank';
            a.href = url
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
          } else {
            this.$notify({ group: 'info', type: 'error', text: response.data.msg })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
        // let urls = bulkRun.pageRuns.map(pageRun => {
        //   return pageRun.reportResourceUrl ? pageRun.reportResourceUrl : `${API_URL}/download/?file=${pageRun.excelFile}`
        // })
        // if (urls.length == undefined || urls.length==0) {
        //   return
        // }
        // let interval = setInterval(download, 300, urls);
        // function download(urls) {
        //   let url = urls.pop();
        //   console.log('BBBB', url)
        //   if (!url) { return }
        //   window.open(url, '_blank');
        //   // let a = document.createElement("a");
        //   // a.download = url.split('/').pop();
        //   // a.target = '_blank';
        //   // a.href = url
        //   // a.style.display = "none";
        //   // document.body.appendChild(a);
        //   // a.click();
        //   // document.body.removeChild(a)
        //   // if (urls.length == 0) {
        //   //   clearInterval(interval);
        //   // }
        // }
      },
      cancelBulkRunAction (bulkRun) {
        if (!bulkRun) return;
        this.$store.commit('showLoading')
        this.$store.dispatch('cancelBulkRunTask', bulkRun.taskId)
        .then((response) => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.$notify({ group: 'info', type: 'success', text: 'Your bulkrun is cancelled.' })
            if (response.data.bulkRun) {
              this.$store.commit("addOrUpdateBulkRun", response.data.bulkRun);
            }
          } else {
            this.$notify({ group: 'info', type: 'error', text: 'Failed cancel the bulk run.' })
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        })
      },
      filesChange(fieldName, fileList) {
        this.resetFileSelected()
        // handle file changes
        if (!fileList.length) return;
        let file = fileList[0];
        if (file.name.endsWith('.xlsx')) {
          this.selectedFile = file
        } else {
          this.$notify({ group: 'info', type: 'warning', text: 'Please select xlsx file.' })
        }
      },
      resetFileSelected() {
        this.selectedFile = null
        this.$refs.csvfile.type='text'
        this.$refs.csvfile.type='file'
  
        this.estimatedCredits = 0
        this.googleNlpCredits = 0
        this.eeatCredits = 0
        this.totalReportsInExcel = 0
        this.newBulkRunId = null
      },
      submitForBulk () {
        if (!this.newBulkRunId) {
          this.handleXLSXSubmit()
        } else {
          this.showNewBulkEstimatedCreditModal = true;
        }
      },
      handleXLSXSubmit: function() {
        this.errorMsg = ''
        if (!this.selectedFile) {
          alert('please select csv file to upload')
          return
        }
        this.$store.commit('showLoading')
        this.$store.dispatch('bulkExcelUploadAndValidate', this.selectedFile)
        .then((response) => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.newBulkRunId = response.data.bulkRunId
            this.estimatedCredits = response.data.estimatedCredits
            this.googleNlpCredits = response.data.googleNlpCredits
            this.eeatCredits = response.data.eeatCredits
            this.totalReportsInExcel = response.data.totalReportsInExcel
            this.openAiKeyError = response.data.openAiKeyError
            this.willSpendExtra10Credits = response.data.willSpendExtra10Credits
            this.showNewBulkEstimatedCreditModal = true;
          } else {
            if (response.data && response.data.msg) {
              this.resetFileSelected()
              this.errorMsg = response.data.msg
              this.$notify({ group: 'info', type: 'error', text: response.data.msg, duration: 5000 })
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.errorMsg = 'Cannot upload the file for some reason'
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
      },
      createNewBulkRunTask () {
        this.showNewBulkEstimatedCreditModal = false;
  
        this.$store.commit('showLoading')
        this.$store.dispatch('createNewBulkRun', {
          bulkRunId: this.newBulkRunId,
        })
        .then((response) => {
          this.resetFileSelected()
          if (response.data && response.data.status == 'SUCCESS') {
            console.log('nothing to do here.')
          } else {
            if (response.data && response.data.msg) {
              this.resetFileSelected()
              this.errorMsg = response.data.msg
              this.$notify({ group: 'info', type: 'error', text: response.data.msg, duration: 5000 })
            }
          }
        })
        .catch(err => {
          this.errorMsg = 'Cannot upload the file for some reason'
        })
      },
      gotoBulkRunsPage () {
        this.$router.push('/bulk-runs')
      },
      prepareToExit () {
        if (this.loadBulkTimer) {
          clearTimeout(this.loadBulkTimer)
          this.loadBulkTimer = null
        }
      },
      loadBulkRun(bulkRunId) {
        this.$store.dispatch("loadBulkRun", bulkRunId).then(res => {
          if (res.data && res.data.status == "SUCCESS") {
            if (res.data.bulkRun.id) {
              this.$store.commit("addOrUpdateBulkRun", res.data.bulkRun);
              this.$store.commit("setUser", res.data.user);
            }
          }
        });
      },
      loadBulkRuns () {
        // this.$store.commit('showLoading')
        this.$store.dispatch('loadBulkRuns')
          .then(res => {
            if (res.data && res.data.status == 'SUCCESS') {
              this.$store.commit('setBulkRuns', res.data.bulkRuns)
              this.$store.commit('setUser', res.data.user)
              console.log('debug??')
              let calculatingBulkRun = this.isCalculatingBulkNow
              if (calculatingBulkRun) {
                let taskId = calculatingBulkRun.taskId
  
                const timer = setInterval(() => {
                  return fetchTaskResult(taskId)
                    .then(resultResponse => {
                      let { status } = resultResponse.data;
                      if (status === "SUCCESS") {
                        clearInterval(timer);
                        this.loadBulkRun(calculatingBulkRun.id)
                      } else if (status === "REVOKED") {
                        this.loadBulkRun(calculatingBulkRun.id)
                        if (this.isCalculatingBulkNow) {
                          clearInterval(timer);
                        }
                      } else if (status === "FAILURE") {
                        clearInterval(timer);
                        this.loadBulkRun(calculatingBulkRun.id)
                      } else if (status === "PROGRESS") {
                        // load updated bulk runs again
                        this.loadBulkRun(calculatingBulkRun.id)
                      }
                    })
                    .catch(e => {
                      console.log("error in interval timer ", e);
                      clearInterval(timer);
                    });
                }, 6000);
              }
            }
          })
          .finally(() => {
            this.$store.commit('hideLoading')
          })
      }
    },
    mounted () {
      this.$store.commit('showLoading')
      this.$store.dispatch('fetchAvailableProxies')
        .then((response) => {
          let proxyMap = {}
          response.data.forEach((p) => {
            proxyMap[p.id] = p.location
          })
          this.proxies = proxyMap
  
          this.loadBulkRuns()
          
        })
        .catch((err) => {
          console.log('Errro fetching proxies', err)
          this.$store.commit('hideLoading')
        })
  
    },
    beforeDestroy () {
      this.prepareToExit()
    }
  }
  </script>
  
  <style lang="scss" scoped>
  div {
    color: var(--v-mainColor-base);
  }
  .top-part {
    margin-top: 2rem;
    color: var(--v-mainColor-base);
    .title-text {
      font-size: 1.2rem;
      font-weight: 700;
    }
    .explain {
      
    }
    ul {
      list-style: decimal;
      margin-top: 5px;
      padding-left: 15px;
      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  
  .notify-label1 {
    display: flex;
    background: var(--v-link9Color-base);
    font-size: 13px;
    padding: 10px 15px;
    border-radius: 9px;
    i {
      font-size: 20px;
      color: var(--v-link3Color-base) !important;
      margin-right: 5px;
    }
    span {
      color: var(--v-link3Color-base) !important;
      font-weight: 700;
    }
  }
  
  .selected-file {
    background: var(--v-green7Color-base);
    border-radius: 50vh;
    color: var(--v-whiteColor-base);
    padding: 5px 5px 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  
    .material-icons {
      cursor: pointer;
    }
  
  }
  
  .bulk-run-info-text {
    * {
      font-size: 1rem;
      color: var(--v-mainColor-base);
    }
  }
  
  .download-link {
    color: var(--v-link15Color-base) !important;
    font-weight: bold;
    text-decoration: underline;
    
  }
  .upload-wizard {
    width: 550px;
    margin: 20px;
    padding: 30px 50px;
  }
  .drag-area {
    border: 1px dashed var(--v-gray15Color-base);
    background: var(--v-cardBg-base);
    border-radius: 10px;
    padding: 30px 20px;
    text-align: center;
    color: var(--v-mainColor-base);
  
    .upload-icon {
      width: 50px;
      height: auto;
    }
  
    .file-cta { // override bulma class
      background: transparent;
      border: none;
      padding: 0;
      font-weight: normal;
      text-decoration: underline;
      color: var(--v-link15Color-base);
    }
  }
  
  .bulk-runs-result-label {
    font-size: 1.1rem;
    color: var(--v-mainColor-base);
    font-weight: 700;
  }
  
  .bulk-created-date {
    font-size: 1rem;
    font-weight: 700;
  }
  
  .bulk-runs-table-wrapper {
    border-radius: 5px;
    border: 1px solid var(--v-gray15Color-base);
    overflow: auto;
  }
  .bulk-runs-table {
    width: 100%;
    color: var(--v-mainColor-base);
    thead {
      th {
        padding: 20px 15px;
        background: var(--v-whiteColor-base);
        color: var(--v-mainColor-base);
        font-weight: 700;
      }
    }
    tbody {
      tr td {
        padding: 10px 15px;
      }
      tr.ai-row td {
        padding-top: 0;
      }
  
      tr.odd-row {
        td {
          background: var(--v-pink1Color-base);
        }
      }
      tr:not(.odd-row) {
        td {
          background: var(--v-whiteColor-base);
        }
      }
      tr:not(.ai-row) td {
        border-top: 1px solid var(--v-grayColor-base);
      }
    }
  
    .success-status, .failure-status {
      padding-left: 20px;
      position: relative;
      font-weight: 700;
      display: inline-block;
      &::before {
        content: '';
        position: absolute;
        left: 0px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .success-status {
      color: var(--v-green7Color-base);
      &::before {
        background: var(--v-green7Color-base);
      }
    }
  
    .actions {
      display: flex;
      gap: 5px;
      i {
        cursor: pointer;
        font-size: 1.35rem;
      }
  
      .action-icon-btn {
        background: var(--v-lightYellowColor-base);
        display: flex;
        align-items: center;
        padding: 5px;
        & > i {
          color: var(--v-profileAnchorColor-base);
        }
      }
    }
  }
  
  </style>