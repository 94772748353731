<template>
  <div>
    <div v-if="isAiWriter">
      <div class="ai-writer-info-header">
        <div class="d-flex"> 
          <div class="gap10">
            <div class="ai-writer-breadcrumbs">
              <a :href="projectURL" target="_blank" class="ai-writer-breadcrumbs-item">{{ page.siteName }}</a>
              <i class="material-icons arrow-right-size">chevron_right</i>
              <a :href="aiWriterURL" target="_blank" class="ai-writer-breadcrumbs-item">AI Writer</a>
              <i class="material-icons arrow-right-size">chevron_right</i>
              <a :href="reportURL" target="_blank" class="ai-writer-breadcrumbs-item">{{ page.name }}</a>
            </div>
          </div>
          <div class="flex-grow-1"></div>
          <div class="d-flex align-center gap10">
            <div class="d-flex gap10">
              <span v-if="estimatedCreditsRequired > ownerAvailableCredits"><i class="fa fa-exclamation-triangle" style="color: red;"></i> Not enough credits. </span>
              <span v-else><i class="fa fa-check-circle" style="color: #25A97A;"></i>&nbsp;</span>
              <span>Credits Required: {{ estimatedCreditsRequired }} </span>
              <span>Your balance: {{ ownerAvailableCredits }} credits</span>
            </div>
            <v-btn rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none"
              :disabled="$store.getters.isDemoUser" :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`">
              <i class="material-icons flip-icon font-sm">payments</i>
              <span class="font-sm">Buy credits</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="ai-writer-root">
      <ai-writer-steps
        class="stepper"
        :currentStep="currentStep"
        @goTo="(stepToGo) => goToSpecificPage(stepToGo)"
        :separateSearchEngineTitleAndTitle="separateSearchEngineTitleAndTitle"
        :hasParagraphResult="pAIResult.length > 0"
      >
      </ai-writer-steps>
      <div class="ai-writer-main-area">
        <div
          class="table-of-content-side-bar"
          v-if="showLeftSidebar && pAIResult.length > 0"
        >
          <div>
            <span
              class="material-icons-outlined hide-icon"
              @click="
                () => {
                  showLeftSidebar = false;
                }
              "
              >arrow_back</span
            >
            <div class="sidebar-label">Table of Contents</div>
            <div class="article-title">
              {{ calculated_pageTitleAIResult?.text }}
            </div>
            <div
              v-for="(item, idx) in calculated_subHeadingsAIResultArr"
              :key="`ai_result_${item.text}_${idx}`"
              @click="
                () => {
                  gotoParagraphSection(item.text);
                }
              "
              :class="{
                'h2-link': item.tagName == 'H2',
                'h3-link': item.tagName == 'H3',
              }"
            >
              {{ item.text }}
              <div></div>
            </div>
          </div>
        </div>
        <div
          class="main-except-sidebar"
          :class="{
            'final-result-bg':
              currentStep == STEPS.PARAGRAPH && pAIResult.length > 0,
          }"
          ref="aiWriterRef"
        >
          <div class="px-5 py-5">
            <div class="d-flex align-center flex-wrap gap20">
              <div class="d-flex align-center gap10">
                <span class="page-title"> AI Writer </span>
                <v-tooltip bottom color="transparent">
                  <template v-slot:activator="{ on, attrs }">
                    <i
                      class="material-icons info-icon-size mainColor--text"
                      v-bind="attrs"
                      v-on="on"
                      >info</i
                    >
                  </template>
                  <div class="white-bg-tooltip">
                    You are not updating your site content. Changes are only
                    reflected here.
                  </div>
                </v-tooltip>
                <span
                  class="material-icons video-tutorial-icon video-tutorial-icon-size"
                  @click="
                    () => {
                      $store.commit('setCurrentVideoTutorialToDisplay', {
                        title: 'How to use the POP AI Writer',
                        videoLink:
                          'https://www.youtube.com/embed/T0_L2TneP1k?si=SLK9pItH7j1wNiYU',
                        description:
                          'Learn how to create AI generated articles with the help of POP AI Writer.',
                        time: '4m 16s',
                      });
                    }
                  "
                >
                  videocam
                </span>
              </div>
              <div class="flex-grow-1">
                <span class="variation-tag font-weight-bold">{{
                  pageRun.keyword
                }}</span>
              </div>
              <div class="d-flex">
                <demo-plans :currentStep="currentStep" />
                <v-menu offset-y bottom v-if="pAIResult.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      rounded
                      v-show="!isDemo || currentStep > 6"
                      :disabled="isDemo"
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="material-icons-outlined">file_download</i>
                      <span>Download article</span>
                      <i class="material-icons-outlined">expand_more</i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link>
                      <v-list-item-title
                        @click="downloadAIResult('all', 'txt_plain')"
                        >As plain.txt</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="downloadAIResult('all', 'txt')"
                        >As html.txt</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="downloadAIResult('all', 'html')"
                        >As .html</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="downloadAIResultAsDocx()"
                        >As .docx</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="downloadOutlines()"
                        >Outline only .txt</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      small
                      :disabled="isDemo"
                      color="mainColor"
                      class="text-none ml-3 px-0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="material-icons">more_horiz</i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      link
                      v-if="
                        currentStep > STEPS.SETTING &&
                        currentStep != STEPS.PARAGRAPH
                      "
                    >
                      <v-list-item-title @click="() => goBack()"
                        >Go one step back</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="currentStep > STEPS.SEARCH_ENGINE_TITLE"
                      :to="`/sites`"
                    >
                      <v-list-item-title>Go back to projects</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="() => $emit('startOver')">
                      <v-list-item-title>Start over</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div
              class="mainColor--text d-flex justify-space-between align-center"
            ></div>
          </div>

          <div class="px-5">
            <div
              v-if="currentStep > STEPS.SELECT_COMPETITORS && isSpecialAdminUser"
              class="show-log-btn"
            >
              <v-btn
                rounded
                small
                color="purpleColor1"
                class="text-none whiteColor--text"
                @click="
                  () => {
                    showLogModal = true;
                  }
                "
              >
                <span>Show log</span>
              </v-btn>
            </div>

            <div v-if="currentStep == STEPS.START" class="start-page">
              <div class="left">
                <img src="/static/ai-writer-welcome.svg" class="welcome-ai-img" />
              </div>
              <div class="right">
                <div style="flex: 1; display: flex; flex-direction: column; justify-content: center;">
                  <div class="welcome-title">Welcome to POP AI Writer</div>
                  <div class="mt-6 base-text-style">
                    POP AI Writer is trained to get your score to 70 though it will almost always be higher.
                    <br/>
                    We recommend getting your score to at least 80 to see SEO benefits.
                  </div>
                  <div class="mt-8 before-after-section">
                    <div>
                      <div class="base-text-style" style="font-weight: 700;">Before you use AI Writer</div>
                      <div class="base-text-style mt-3">You may want to clean up some of the important terms from the POP report summary to improve the AI output for readability and quality.</div>
                      <v-btn
                        rounded small outlined color="mainColor" class="how-to-btn button-with-icon mainColor--text text-none mt-5"
                        @click="() => {
                          $store.commit('setCurrentVideoTutorialToDisplay', {
                            gifLink: '/static/ai_writer_clean_tutorial.gif',
                            gifWidth: 1100,
                            noWidthHeightConstraints: true,
                          });
                        }"
                      >
                        <i class="material-icons-outlined">play_circle</i>
                        <span>How to clean up terms</span>
                      </v-btn>
                    </div>
                    <div>
                      <div class="base-text-style" style="font-weight: 700;">After you use AI Writer</div>
                      <div class="base-text-style mt-3">It is best to think of AI content, from any tool, as a good first draft. Keep in mind that you may need to manually tweak the content a bit for tone, voice, and optimization.</div>
                    </div>
                  </div>
                </div>
                <div class="mt-10" style="text-align: right;">
                  <button class="start-btn" @click.stop="goNext(STEPS.START)">
                    Start
                  </button>
                </div>
              </div>
            </div>
            <div v-if="currentStep == STEPS.SETTING" class="setting-page">
              <div>
                <div>
                  <div class="step-title-label">
                    How many words would you like to generate?
                  </div>
                  <div class="mt-3 mainColor--text">
                    GPT 4 won't generate a precise number of words. Expect the
                    word count generated to be ± 300 words.<br />
                    Minimum word count you can select for a generation task is 500
                    words. <br />
                    If your word count is off by 500 or more, contact support with
                    your AI Report ID number.
                  </div>
                  <v-radio-group
                    v-model="selectedWordCountOption"
                    :row="true"
                    hide-details
                  >
                    <v-radio
                      :disabled="isDemo || isFailedPageRun"
                      class="mr-3"
                      v-for="(item, idx) in WORDCOUNT_OPTIONS"
                      :key="idx"
                      :value="item.value"
                    >
                      <span slot="label" class="mainColor--text">
                        {{ item.label }}
                        <template v-if="item.value == 'currentWordCount'"
                          >( {{ currentWordCount }} )</template
                        >
                        <template v-if="item.value == 'targetWordCount'"
                          >( {{ targetWordCount || 0 }} )</template
                        >
                      </span>
                    </v-radio>
                  </v-radio-group>
                  <div
                    class="d-flex mt-2"
                    v-if="selectedWordCountOption == 'customWordCount'"
                  >
                    <div style="max-width: 500px">
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        color="greenColor"
                        type="number"
                        :max="4000"
                        v-model.number="numberOfWords"
                        label="Number of words."
                        :disabled="selectedWordCountOption != 'customWordCount'"
                      >
                      </v-text-field>
                    </div>
                    <div class="flex-grow-1"></div>
                  </div>

                  <div class="mt-5">
                    <div
                      class="openai-key-message-box no-key"
                    >
                      <p>
                        <i class="material-icons">info</i>&nbsp;
                        For top-level pages, it is best to stick to POP’s target word count recommendation, while for supporting pages word count is not strictly defined and the best practice is to have around 500-1000 words.
                      </p>
                    </div>
                  </div>

                  <div class="mt-5">
                    <div class="step-title-label mt-5">
                      Connect your API key
                    </div>
                    <p v-if="!(isUsingOwnApiKey)" class="mt-3">You can connect your API key and run your POP AI Writer reports at only {{popCreditCharges.aiWriterCreditsFlatRateOwnApiKey}} POP Credit flat rate.</p>
                    <p v-else-if="isUsingOwnApiKey" class="mt-3">Your API key is enabled.</p>
                    <v-btn rounded small outlined color="mainColor" class="button-with-icon mainColor--text text-none mt-3"
                      :disabled="$store.getters.isDemoUser" :to="`/useraccount/${user.id}?mainTab=manage-gpt-key`">
                      <i v-if="!(isUsingOwnApiKey)" class="material-icons">key</i>
                      <i v-else-if="isUsingOwnApiKey" class="material-icons">key_off</i>
                      <span v-if="!(isUsingOwnApiKey)" class="">Connect API Key</span>
                      <span v-else-if="(isUsingOwnApiKey)" class="">Disconnect API Key</span>
                    </v-btn>
                  </div>

                  <div
                    class="custom-alert-box mt-10 mb-5"
                    :class="{ 'insufficient-credit': !hasEnoughCredits }"
                  >
                    <div>
                      <i class="material-icons left-icon">monetization_on</i>

                      <div class="flex-grow-1">
                        <div class="credits-required">
                          Credits required: {{ estimatedCreditsRequired }} /
                          {{ ownerAvailableCredits }}
                        </div>
                        <div class="mt-2 credit-explain">
                          <div v-if="!hasEnoughCredits">
                            Apologies, it seems that there are insufficient funds
                            available in your account to run the report.
                          </div>
                          <div v-else>
                            In a few minutes you’ll create well optimized content
                            that appears more natural to Google and humans and
                            future proofs you against algorithmic penalties.
                          </div>
                        </div>
                      </div>
                      <router-link
                        v-if="!hasEnoughCredits"
                        class="buy-credit-button"
                        :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`"
                      >
                        <img src="/static/buy-credit-icon.png" />
                        <span>Buy credits</span>
                      </router-link>
                      <a
                        class="how-credits-works-link"
                        href="https://help.pageoptimizer.pro/article/how-pop-credits-work-with-the-pop-ai-writer"
                        target="_blank"
                      >
                        <i class="material-icons">live_help</i>
                        <span>How POP AI Writer Credits work</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-5 d-flex justify-end flex-grow-1">
                <v-btn
                  rounded
                  color="profileAnchorColor"
                  class="button-with-icon text-none whiteColor--text mr-3"
                  :disabled="!canGoNext"
                  @click="goNext(STEPS.SETTING)"
                >
                  <img
                    src="/static/ai-writer-stars.png"
                    width="26px"
                    style="margin-right: 5px"
                  />
                  Next
                </v-btn>
              </div>
            </div>
            <div
              v-if="currentStep == STEPS.RICH_CONTENT"
              class="rich-content-page"
            >
              <div
                class="custom-alert-box my-5"
                :class="{ 'insufficient-credit': !hasEnoughCredits }"
              >
                <div>
                  <i class="material-icons left-icon">monetization_on</i>

                  <div class="flex-grow-1">
                    <div class="credits-required">
                      Credits required: {{ estimatedCreditsRequired }} /
                      {{ ownerAvailableCredits }}
                    </div>
                    <div class="mt-2 credit-explain">
                      <div v-if="!hasEnoughCredits">
                        Apologies, it seems that there are insufficient funds
                        available in your account to run the report.
                      </div>
                      <div v-else>
                        In a few minutes you'll create well optimized content that
                        appears more natural to Google and humans and future
                        proofs you against algorithmic penalties.
                      </div>
                    </div>
                  </div>
                  <router-link
                    v-if="!hasEnoughCredits"
                    class="buy-credit-button"
                    :to="`/useraccount/${user.id}?mainTab=billing-and-payments&subTab=buy-credits`"
                  >
                    <img src="/static/buy-credit-icon.png" />
                    <span>Buy credits</span>
                  </router-link>
                  <a
                    class="how-credits-works-link"
                    href="https://help.pageoptimizer.pro/article/how-pop-credits-work-with-the-pop-ai-writer"
                    target="_blank"
                  >
                    <i class="material-icons">live_help</i>
                    <span>How POP AI Writer Credits work</span>
                  </a>
                </div>
              </div>
              <div class="mt-10">
                <div class="d-flex align-center gap10">
                  <div class="step-title-label">Select web page type</div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="material-icons info-icon-size mainColor--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</span
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      By selecting the type of page, AI will structure the content
                      outline to fit that specific page type. This ensures a more
                      relevant and tailored outline and overall output from the
                      AI.
                    </div>
                  </v-tooltip>
                </div>
                <div class="mt-3 mainColor--text">
                  Here you can let POP AI know what type of web page you prefer.
                </div>
                <div class="mt-5 d-flex">
                  <div>
                    <v-select
                      outlined
                      dense
                      hide-details
                      color="green7Color"
                      :disabled="isDemo"
                      v-model="webPageType"
                      :items="webPageTypeOptions"
                      item-text="text"
                      item-value="value"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="mt-10" style="max-width: 600px;">
                <div class="step-title-label">Describe your product/service</div>
                <div class="flex-grow-1 mainColor--text my-3">
                  POP AI will use this information to write more informed content about your product/service. 
                </div>
                <div :class="{ 'demo-highlight-f': isDemo }">
                  <v-textarea
                    outlined
                    dense
                    v-model="productDescription"
                    :disabled="isDemo"
                    color="greenColor"
                    counter="250"
                    :counter-value="countWords"
                    :rules="[(v) => countWords(v) <= 250 || `Must be less than 250 words`]"
                  >
                  </v-textarea>
                </div>
              </div>
              <div class="mt-4" style="max-width: 600px;">
                <div class="step-title-label">What is your target audience?</div>
                <div class="flex-grow-1 mainColor--text my-3">
                  POP AI will use this information to match the content intent. 
                </div>
                <div :class="{ 'demo-highlight-f': isDemo }">
                  <v-textarea
                    outlined
                    dense
                    v-model="targetAudience"
                    :disabled="isDemo"
                    color="greenColor"
                    counter="250"
                    :counter-value="countWords"
                    :rules="[(v) => countWords(v) <= 250 || `Must be less than 250 words`]"
                  >
                  </v-textarea>
                </div>
              </div>
              <div class="mt-4">
                <div class="d-flex align-center gap10">
                  <div class="step-title-label">Exclude specific words</div>
                  <v-tooltip top color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="material-icons info-icon-size mainColor--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</span
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      By specifying words to exclude, you can ensure that the AI
                      will not use those words in the generated content. This is
                      useful for avoiding specific terms or phrases that may not
                      fit your brand or content strategy. For example, you might
                      want to exclude words like "cheap," "best," "guaranteed,"
                      "conclusion," "embark," or "final thoughts." Make sure the
                      words to exclude are not part of POP's recommendations from
                      the report.
                    </div>
                  </v-tooltip>
                </div>
                <div class="mt-3 mainColor--text">
                  Exclude specific words or phrases you don't want to appear in
                  your web page.
                </div>
                <div class="mt-5 d-flex">
                  <div style="min-width: 35vw">
                    <multiselect
                      v-model="excludeTerms"
                      :disabled="isDemo"
                      :options="[]"
                      :multiple="true"
                      :taggable="true"
                      :hide-selected="true"
                      placeholder="cheap, best, guaranteed, conclusion, embark, final thoughts"
                      tag-placeholder="Press enter to add"
                      @tag="addNewExcludeTerm"
                      @remove="handleRemoveExcludeTerm"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="mt-10">
                <div class="step-title-label">Rich Content</div>
                <div class="mt-3 mainColor--text">
                  You can create rich content for your page based on POP Content
                  prompts report. Set the amount for each component you'd like AI
                  to generate.
                </div>
                <!-- {{ JSON.stringify(selectedContentPromptsSignals) }} -->
                <!-- {{ JSON.stringify(selectedCountForPromptSignals) }} -->
                <table class="select-content-prompt-table mt-5">
                  <thead>
                    <tr>
                      <th>SELECT</th>
                      <th>COMPONENT</th>
                      <th>COST</th>
                      <th>RECOMMENDED RANGE</th>
                      <th>QUANTITY</th>
                      <th>TOTAL COST</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(row, idx) in richContentItemsForAIWriter"
                      
                    >
                      <tr
                        :key="idx"
                        :class="
                          row.signalName === 'Internal links' && selectedContentPromptsSignals.includes('Internal links')
                          ? 'remove-bottom-border'
                          : ''
                        "
                      >
                        <td>
                          <vue-toggles
                            :value="
                              selectedContentPromptsSignals.includes(row.signalName)
                            "
                            width="40"
                            height="20"
                            :disabled="isDemo"
                            :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                            :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                            @click="
                              () => {
                                if (
                                  selectedContentPromptsSignals.includes(
                                    row.signalName
                                  )
                                ) {
                                  selectedContentPromptsSignals =
                                    selectedContentPromptsSignals.filter(
                                      (item) => item != row.signalName
                                    );
                                } else {
                                  selectedContentPromptsSignals.push(
                                    row.signalName
                                  );
                                  if (
                                    row.isYesNoSignal ||
                                    !selectedCountForPromptSignals[row.signalName]
                                  ) {
                                    selectedCountForPromptSignals[
                                      row.signalName
                                    ] = 1;
                                  }
                                  $nextTick(() => {
                                    $refs?.contentPromptCountItems?.[idx]?.focus?.();
                                  });
                                }
                              }
                            "
                          ></vue-toggles>
                        </td>
                        <td class="font-weight-bold">
                          <div class="d-flex align-center">
                            {{ row.dispName ? row.dispName : row.label }}
                            <!-- {{ row.signalName }} -->
                            <v-tooltip
                              right
                              color="transparent"
                              v-if="CONTENT_PROMPT_SIGNALS_TOOLTIPS[row.signalName]"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <i
                                  class="material-icons info-icon-size ml-2 mainColor--text"
                                  v-bind="attrs"
                                  v-on="on"
                                  >info</i
                                >
                              </template>
                              <div class="white-bg-tooltip small-tooltip" style="white-space: pre-wrap;">{{ CONTENT_PROMPT_SIGNALS_TOOLTIPS[row.signalName] }}</div>
                            </v-tooltip>
                          </div>
                        </td>
                        <td>
                          <span
                            v-if="
                              costedContentPromptSignals.includes(row.signalName) && !isUsingOwnApiKey
                            "
                            class="credit-1"
                          >
                            <img src="/static/credit-1.png" />
                            1 POP credit
                          </span>
                          <span v-else class="credit-0">
                            <img src="/static/credit-0.png" />
                            0 POP credits
                          </span>
                        </td>
                        <td>
                          {{
                            row.isYesNoSignal && row.signalName !== 'Internal links'
                              ? "Use 1"
                              : `Use between ${row.targetMin} to ${row.targetMax}`
                          }}
                        </td>
                        <td>
                          <v-text-field
                            v-if="row.signalName !== 'Internal links'"
                            outlined
                            dense
                            hide-details
                            single-line
                            color="greenColor"
                            style="width: 70px"
                            :rules="[(v) => v > 0, (v) => v <= row.targetMax]"
                            ref="contentPromptCountItems"
                            :disabled="
                              !selectedContentPromptsSignals.includes(
                                row.signalName
                              ) ||
                              row.isYesNoSignal ||
                              isDemo
                            "
                            :value="selectedCountForPromptSignals[row.signalName]"
                            @input="
                              (newVal) => {
                                selectedCountForPromptSignals[row.signalName] =
                                  parseInt(newVal);
                                selectedCountForPromptSignals = {
                                  ...selectedCountForPromptSignals,
                                };
                              }
                            "
                            type="number"
                          ></v-text-field>
                        </td>
                        <td
                          :class="{
                            'pr-5':
                              getCostForContentPromptSignal(row.signalName) < 10,
                          }"
                        >
                          {{ getCostForContentPromptSignal(row.signalName) }} POP
                          credit{{
                            getCostForContentPromptSignal(row.signalName) != 1
                              ? "s"
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr
                        v-if="row.signalName === 'Internal links' && selectedContentPromptsSignals.includes('Internal links')"
                        :key="idx + 'internal-links-data'"
                      >
                        <td></td>
                        <td colspan="4">
                          <div class="mainColor--text">Add the main page you want to optimize. This is usually a key sales, product, or service page on your website that converts visitors into leads or customers.</div>
                          <div class="mainColor--text mt-3 font-weight-bold">Top-level page link (main target page)</div>
                          <v-text-field
                            outlined
                            dense
                            v-model="topLevelInternalLink"
                            :disabled="isDemo"
                            color="greenColor"
                            :rules="[(v) => isValidInternalLink(v) || `Must be an internal link that starts with ${page?.siteBaseURL || 'https://'}`]"
                            placeholder="https://www.example.com/product"
                          >
                          </v-text-field>
                          <div class="mainColor--text mt-3">Add two pages that are closely related to the main page. These should be pages that help explain, support, or provide additional value to the main page topic.</div>
                          <div class="mainColor--text mt-3 font-weight-bold">Supporting-level page link 1</div>
                          <v-text-field
                            outlined
                            dense
                            v-model="supportingLevelInternalLink1"
                            :disabled="isDemo"
                            color="greenColor"
                            :rules="[(v) => isValidInternalLink(v) || `Must be an internal link that starts with ${page?.siteBaseURL || 'https://'}`]"
                            hint=""
                            placeholder="https://www.example.com/product-details"
                          >
                          </v-text-field>
                          <div class="mainColor--text mt-3 font-weight-bold">Supporting-level page link 2</div>
                          <v-text-field
                            outlined
                            dense
                            v-model="supportingLevelInternalLink2"
                            :disabled="isDemo"
                            color="greenColor"
                            :rules="[(v) => isValidInternalLink(v) || `Must be an internal link that starts with ${page?.siteBaseURL || 'https://'}`]"
                            placeholder="https://www.example.com/product-reviews"
                          >
                          </v-text-field>
                        </td>
                        <td></td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="py-5 d-flex justify-end">
                <v-btn
                  rounded
                  color="profileAnchorColor"
                  class="button-with-icon text-none whiteColor--text mr-3"
                  :disabled="!canGoNext || countWords(productDescription) > 250 || countWords(targetAudience) > 250  || (selectedContentPromptsSignals.includes('Internal links') && (!isValidInternalLink(topLevelInternalLink)|| !isValidInternalLink(supportingLevelInternalLink1)|| !isValidInternalLink(supportingLevelInternalLink2)))"
                  @click="goNext(STEPS.RICH_CONTENT)"
                >
                  <img
                    src="/static/ai-writer-stars.png"
                    width="26px"
                    style="margin-right: 5px"
                  />
                  Next
                </v-btn>
              </div>
            </div>

            <div
              v-if="currentStep == STEPS.SELECT_COMPETITORS"
              class="select-competitors-page"
            >
              <div class="step-title-label">Select your competitors</div>
              <div class="mt-3 mainColor--text">
                Select up to three competitors with good content for the AI to use
                for inspiration.
              </div>

              <div class="mt-10">
                <table class="select-competitors-table">
                  <thead>
                    <tr>
                      <th>SELECT</th>
                      <th>TITLE</th>
                      <th nowrap>WORD COUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(c, idx) in pageRun.competitors" :key="idx">
                      <td>
                        <vue-toggles
                          :value="selectedCompetitors.includes(c.id)"
                          width="40"
                          height="20"
                          :disabled="
                            isDemo ||
                            (selectedCompetitors.length >= 3 &&
                              selectedCompetitors.indexOf(c.id) == -1)
                          "
                          :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                          :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                          @click="
                            () => {
                              if (selectedCompetitors.includes(c.id)) {
                                selectedCompetitors = selectedCompetitors.filter(
                                  (item) => item != c.id
                                );
                              } else {
                                selectedCompetitors.push(c.id);
                              }
                            }
                          "
                        ></vue-toggles>
                      </td>
                      <td>
                        <div>
                          <a class="link" :href="c.url" target="_blank">{{
                            c.url
                          }}</a>
                          <diV class="competitor-title mt-2">{{ c.title }}</diV>
                        </div>
                      </td>
                      <td class="word-count-cell">
                        {{ competitorWordCount(c) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-12">
                <v-row>
                  <v-col>
                    <div class="d-flex flex-column is-fullheight">
                      <div class="step-title-label">Tone</div>
                      <div class="flex-grow-1 mainColor--text my-3">
                        Examples you could use: formal or informal, positive or
                        negative, funny, encouraging, neutral and more
                      </div>
                      <div :class="{ 'demo-highlight-f': isDemo }">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="toneOfContent"
                          :disabled="isDemo"
                          color="greenColor"
                          maxlength="80"
                          counter
                          :rules="[(v) => v.length <= 80]"
                          :label="isDemo ? '' : 'Tone'"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="d-flex flex-column is-fullheight">
                      <div class="step-title-label">Author</div>
                      <div class="flex-grow-1 mainColor--text my-3">
                        Here you can experiment with different personas, such as
                        lawyer, SEO, scientist, accountant and more
                      </div>
                      <div>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          color="greenColor"
                          :disabled="isDemo"
                          v-model="author"
                          maxlength="80"
                          counter
                          :rules="[(v) => v.length <= 80]"
                          label="Author"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="d-flex flex-column is-fullheight">
                      <div class="step-title-label">Brand</div>
                      <div class="flex-grow-1 mainColor--text my-3">
                        Unique name given to a product, service, or business that
                        distinguishes it from others in the market, such as
                        "PageOptimizer PRO".
                      </div>
                      <div>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          color="greenColor"
                          v-model="brand"
                          :disabled="isDemo"
                          maxlength="80"
                          counter
                          :rules="[(v) => v.length <= 80]"
                          label="Brand"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <div class="mt-10" style="max-width: 600px;">
                <div class="step-title-label">Provide sample content </div>
                <div class="flex-grow-1 mainColor--text my-3">
                  If you want you can provide a piece of content for POP AI to draw inspiration from.
                </div>
                <div :class="{ 'demo-highlight-f': isDemo }">
                  <v-textarea
                    outlined
                    dense
                    v-model="sampleContent"
                    :disabled="isDemo"
                    color="greenColor"
                    counter="500"
                    :counter-value="countWords"
                    :rules="[(v) => countWords(v) <= 500 || `Must be less than 500 words`]"
                  >
                  </v-textarea>
                </div>
              </div>

              <div class="mt-12 separate-title-pagetitle-box">
                <div>
                  <div class="d-flex align-center">
                    <div class="step-title-label">
                      Generate search engine title and page title separately (not
                      recommended)
                    </div>
                    <v-tooltip bottom color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <i
                          class="material-icons info-icon-size ml-2 mainColor--text"
                          v-bind="attrs"
                          v-on="on"
                          style="font-size: 14px"
                          >info</i
                        >
                      </template>
                      <div class="white-bg-tooltip small-tooltip">
                        We don’t recommend generating search engine title and page
                        title separately. For best SEO results leave the toggle
                        off below and click Next to use the same page title and
                        H1.
                      </div>
                    </v-tooltip>
                  </div>
                  <div class="mt-5">
                    <div class="d-flex align-center">
                      <div class="flex-grow-1 mainColor--text">
                        I want to generate my search engine title and my page
                        title separately (not recommended)
                      </div>
                      <vue-toggles
                        :value="separateSearchEngineTitleAndTitle"
                        width="40"
                        height="20"
                        :disabled="useMyCurrentTitle || isDemo"
                        :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                        :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                        @click="
                          separateSearchEngineTitleAndTitle =
                            !separateSearchEngineTitleAndTitle
                        "
                      ></vue-toggles>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-5 d-flex justify-end">
                <v-btn
                  rounded
                  color="profileAnchorColor"
                  class="button-with-icon text-none whiteColor--text mr-3"
                  :disabled="isLoading || selectedCompetitors.length == 0 || countWords(sampleContent) > 500"
                  @click="goNext(STEPS.SELECT_COMPETITORS)"
                >
                  <img
                    src="/static/ai-writer-stars.png"
                    width="26px"
                    style="margin-right: 5px"
                  />
                  Next
                </v-btn>
              </div>
            </div>

            <div
              v-if="
                separateSearchEngineTitleAndTitle &&
                currentStep == STEPS.SEARCH_ENGINE_TITLE
              "
            >
              <div class="d-flex flex-wrap flex-row-gap-5 align-center mb-2">
                <div>
                  <span class="section-label mr-2"> Search Engine Title </span>
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 mainColor--text"
                        v-bind="attrs"
                        v-on="on"
                        style="font-size: 14px"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      If your current Search Engine Title is already optimized,
                      you can keep your title as is. You can manually write in
                      your own title by clicking "Edit" or you can have AI
                      generate your title by clicking "Generate." You can also
                      edit the title that AI generates. Once you are happy with
                      your title, click "Next" advance to the H1 section.
                    </div>
                  </v-tooltip>
                </div>
                <div class="flex-grow-1">
                  <v-progress-linear
                    rounded
                    height="8"
                    v-if="runningSection == 'title' && isLoading"
                    class="progress-bar"
                    background-color="gray7Color"
                    color="profileAnchorColor"
                    :value="progress"
                  >
                  </v-progress-linear>
                </div>
              </div>
              <div class="settings-area">
                <v-card
                  class="white-common-box pa-5"
                  :class="{
                    'selected-box-border':
                      optimizeCurrentTitle ||
                      optimizeCompetitorTitle ||
                      useTitleInspirationText,
                  }"
                >
                  <div class="section-label mb-3">
                    I want the AI to rewrite my search title using POP suggestions
                  </div>
                  <div class="mainColor--text mb-3">
                    You can select both options below at the same time.
                  </div>

                  <div class="d-flex align-center">
                    <div class="flex-grow-1 mainColor--text">
                      Use my search engine title for AI inspiration
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Select this option to let AI refine and rewrite your
                          search title, aligning them with the POP
                          recommendations.
                        </div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="optimizeCurrentTitle"
                      width="40"
                      height="20"
                      :disabled="isLoading"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="optimizeCurrentTitle = !optimizeCurrentTitle"
                    ></vue-toggles>
                  </div>
                  <div class="d-flex align-center mt-2">
                    <div class="flex-grow-1 mainColor--text">
                      Use my competitors' search title for AI inspiration
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div class="white-bg-tooltip" style="max-width: 500px">
                          By selecting this option, the AI will utilize your
                          leading competitors' search title to generate and
                          optimize a fresh set of search title subheadings for
                          your page. This is an ideal choice if your current page
                          lacks optimized subheadings or if you're looking to
                          restructure your content.
                        </div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="optimizeCompetitorTitle"
                      width="40"
                      height="20"
                      :disabled="isLoading"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="optimizeCompetitorTitle = !optimizeCompetitorTitle"
                    ></vue-toggles>
                  </div>
                  <div class="d-flex align-center mt-2">
                    <div class="flex-grow-1 mainColor--text">
                      Give the AI an example search engine title for inspiration
                      to write your title
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div
                          class="white-bg-tooltip"
                          style="max-width: 500px"
                        ></div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="useTitleInspirationText"
                      width="40"
                      height="20"
                      :disabled="isLoading"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="useTitleInspirationText = !useTitleInspirationText"
                    >
                    </vue-toggles>
                  </div>
                  <div class="mt-5">
                    <template
                      v-if="!useMyCurrentTitle && useTitleInspirationText"
                    >
                      <div class="font-weight-bold mainColor--text mt-3">
                        Type an example to inspire the AI
                      </div>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        color="greenColor"
                        class="mainColor--text mt-2"
                        v-model="titleInspirationText"
                        label="Give the AI an example title for inspiration to write your title"
                      >
                      </v-text-field>
                    </template>
                  </div>

                  <div class="mt-5" v-if="!useMyCurrentTitle">
                    <v-btn
                      rounded
                      v-if="titleAIResult"
                      color="profileAnchorColor"
                      class="button-with-icon text-none whiteColor--text mr-3"
                      :disabled="isLoading"
                      @click="goNext(STEPS.SEARCH_ENGINE_TITLE)"
                    >
                      <img
                        src="/static/ai-writer-stars.png"
                        width="26px"
                        style="margin-right: 5px"
                      />
                      Next
                    </v-btn>
                    <v-btn
                      rounded
                      outlined
                      v-if="titleAIResult"
                      color="mainColor"
                      class="text-none mainColor--text"
                      :class="{
                        'pulsing-yellow-button': needsPulsing(
                          STEPS.SEARCH_ENGINE_TITLE
                        ),
                      }"
                      :disabled="
                        isLoading ||
                        (!useMyCurrentTitle &&
                          !optimizeCurrentTitle &&
                          !optimizeCompetitorTitle &&
                          !useTitleInspirationText) ||
                        (useTitleInspirationText && !titleInspirationText)
                      "
                      @click="
                        () => {
                          showWarningModalForGenerate('title', () =>
                            generateResultFor('title')
                          );
                        }
                      "
                    >
                      Generate
                    </v-btn>
                  </div>
                </v-card>
                <v-card
                  v-if="!pageNotBuilt"
                  class="mt-5 white-common-box pa-5"
                  :class="{ 'selected-box-border': useMyCurrentTitle }"
                >
                  <div class="section-label mb-3">
                    I don't want to generate new titles with AI
                    <v-tooltip bottom color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <i
                          class="material-icons info-icon-size ml-2 mainColor--text"
                          v-bind="attrs"
                          v-on="on"
                          style="font-size: 14px"
                          >info</i
                        >
                      </template>
                      <div class="white-bg-tooltip small-tooltip">
                        If the current Search engine Title on your page is already
                        optimized, we recommend using it as is. Check this box if
                        you prefer not to make any changes to your existing
                        titles.
                      </div>
                    </v-tooltip>
                  </div>
                  <div class="d-flex align-center">
                    <div class="flex-grow-1 mainColor--text">
                      Use my search engine title and page title as is.
                    </div>
                    <vue-toggles
                      :value="useMyCurrentTitle"
                      width="40"
                      height="20"
                      :disabled="isLoading"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="useMyCurrentTitle = !useMyCurrentTitle"
                    ></vue-toggles>
                  </div>
                  <div class="mt-5" v-if="useMyCurrentTitle">
                    <v-btn
                      rounded
                      v-if="titleAIResult"
                      color="profileAnchorColor"
                      class="button-with-icon text-none whiteColor--text mr-3"
                      :disabled="isLoading"
                      @click="goNext(STEPS.SEARCH_ENGINE_TITLE)"
                    >
                      <img
                        src="/static/ai-writer-stars.png"
                        width="26px"
                        style="margin-right: 5px"
                      />
                      Next
                    </v-btn>
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="text-none whiteColor--text"
                      :class="{
                        'pulsing-yellow-button': needsPulsing(
                          STEPS.SEARCH_ENGINE_TITLE
                        ),
                      }"
                      :disabled="isLoading"
                      @click="
                        () => {
                          showWarningModalForGenerate('title', () =>
                            generateResultFor('title')
                          );
                        }
                      "
                    >
                      <img
                        v-if="!useMyCurrentTitle && titleAIResult"
                        src="/static/ai-writer-refresh.png"
                        width="19px"
                        style="margin-right: 1rem"
                      />
                      Extract my search engine title
                    </v-btn>
                  </div>
                </v-card>
              </div>
            </div>

            <div v-if="currentStep == STEPS.PAGE_TITLE">
              <div class="d-flex flex-wrap align-center flex-row-gap-5 mb-2">
                <div>
                  <span class="section-label">{{ pageTitlelabel }}</span>
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 mainColor--text"
                        v-bind="attrs"
                        v-on="on"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      <template v-if="separateSearchEngineTitleAndTitle">
                        If your current Page Title is already optimized, you can
                        keep your title as is. You can manually write in your own
                        title by clicking "Edit" or you can have AI generate your
                        title by clicking "Generate." You can also edit the title
                        that AI generates. Once you are happy with your title,
                        click "Next" advance to the H2/H3 section.
                      </template>
                      <template v-else>
                        We recommend using the same Search Engine Title and Page
                        Title for best SEO results. If your current titles are
                        already optimized, you can keep your titles as is.<br />
                        You can manually write in your own titles by clicking
                        "Edit" or you can have AI generate your titles by clicking
                        "Generate." You can also edit the titles that AI
                        generates. Once you are happy with your titles, click
                        "Next" advance to the H2 and H3 section.
                      </template>
                    </div>
                  </v-tooltip>
                </div>
                <div class="flex-grow-1">
                  <v-progress-linear
                    rounded
                    height="8"
                    v-if="runningSection == 'pageTitle' && isLoading"
                    class="progress-bar"
                    background-color="gray7Color"
                    color="profileAnchorColor"
                    :value="progress"
                  >
                  </v-progress-linear>
                  <v-progress-linear
                    v-else-if="isDemo && titleProgress > 0 && titleProgress < 100"
                    rounded
                    height="8"
                    class="progress-bar"
                    background-color="gray7Color"
                    color="profileAnchorColor"
                    :value="titleProgress"
                  />
                </div>
              </div>

              <div class="settings-area">
                <v-card
                  class="white-common-box pa-5"
                  :class="{
                    'selected-box-border':
                      optimizeCurrentPageTitle ||
                      optimizeCompetitorPageTitle ||
                      usePageTitleInspirationText,
                  }"
                >
                  <div class="section-label mb-3">
                    I want the AI to rewrite my titles using POP suggestions
                  </div>
                  <div class="mainColor--text mb-3">
                    You can select both options below at the same time.
                  </div>

                  <div class="d-flex align-center">
                    <div class="flex-grow-1 mainColor--text">
                      Use my title for AI inspiration
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div class="white-bg-tooltip small-tooltip">
                          Select this option to let AI refine and rewrite your
                          {{
                            separateSearchEngineTitleAndTitle
                              ? ""
                              : "Search Engine Title or"
                          }}
                          &nbsp;Page Title, aligning them with the POP
                          recommendations.
                        </div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="optimizeCurrentPageTitle"
                      width="40"
                      height="20"
                      :disabled="isLoading || isDemo"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="
                        optimizeCurrentPageTitle = !optimizeCurrentPageTitle
                      "
                    ></vue-toggles>
                  </div>
                  <div class="d-flex align-center mt-2">
                    <div class="flex-grow-1 mainColor--text">
                      Use my competitors' title for AI inspiration
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div class="white-bg-tooltip" style="max-width: 500px">
                          By selecting this option, the AI will utilize your
                          leading competitors'
                          {{
                            separateSearchEngineTitleAndTitle
                              ? ""
                              : "Search Engine Title or"
                          }}
                          Page Title to generate and optimize a fresh set of
                          {{
                            separateSearchEngineTitleAndTitle
                              ? ""
                              : "Search Engine Title or"
                          }}
                          &nbsp;Page Title for your page. This is an ideal choice
                          if your current page lacks optimized titles or if you're
                          looking to restructure your content.
                        </div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="optimizeCompetitorPageTitle"
                      width="40"
                      height="20"
                      :disabled="isLoading || isDemo"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="
                        optimizeCompetitorPageTitle = !optimizeCompetitorPageTitle
                      "
                    ></vue-toggles>
                  </div>

                  <div class="d-flex align-center mt-2">
                    <div class="flex-grow-1 mainColor--text">
                      Give the AI an example search engine title / page title for
                      inspiration to write your title
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div class="white-bg-tooltip" style="max-width: 500px">
                          Enter a sample title that reflects the AI topic you're
                          interested in. This will serve as inspiration for
                          generating a more creative and engaging headline for
                          your article, blog post, or page.
                        </div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="usePageTitleInspirationText"
                      width="40"
                      height="20"
                      :disabled="isLoading || isDemo"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="
                        usePageTitleInspirationText = !usePageTitleInspirationText
                      "
                    >
                    </vue-toggles>
                  </div>
                  <div class="mt-5">
                    <template
                      v-if="!useMyCurrentPageTitle && usePageTitleInspirationText"
                    >
                      <div class="font-weight-bold mainColor--text mt-3">
                        Type an example to inspire the AI
                      </div>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        color="greenColor"
                        class="mainColor--text mt-2"
                        v-model="pagetitleInspirationText"
                        label="Give the AI an example title for inspiration to write your title"
                      >
                      </v-text-field>
                    </template>
                  </div>

                  <div class="mt-5" v-if="!useMyCurrentPageTitle">
                    <v-btn
                      rounded
                      outlined
                      v-if="!pageTitleAIResult"
                      color="mainColor"
                      class="text-none mainColor--text"
                      :class="{
                        'pulsing-yellow-button': needsPulsing(STEPS.PAGE_TITLE),
                      }"
                      :disabled="
                        isLoading ||
                        (!useMyCurrentPageTitle &&
                          !optimizeCurrentPageTitle &&
                          !optimizeCompetitorPageTitle &&
                          !usePageTitleInspirationText) ||
                        (usePageTitleInspirationText && !pagetitleInspirationText)
                      "
                      @click="
                        () => {
                          showWarningModalForGenerate('pageTitle', () =>
                            generateResultFor('pageTitle')
                          );
                        }
                      "
                    >
                      <img
                        v-if="!useMyCurrentPageTitle && pageTitleAIResult"
                        src="/static/ai-writer-refresh.png"
                        width="19px"
                        style="margin-right: 1rem"
                      />
                      Generate
                    </v-btn>
                    <template v-else-if="isDemo">
                      <v-btn
                        v-if="titleProgress <= 0"
                        rounded
                        outlined
                        color="mainColor"
                        class="text-none mainColor--text"
                        :class="{ 'pulsing-yellow-button': true }"
                        @click="fakeProgress('titleProgress')"
                      >
                        Generate
                      </v-btn>
                    </template>
                  </div>
                </v-card>
                <v-card
                  v-if="!pageNotBuilt"
                  class="mt-5 white-common-box pa-5"
                  :class="{ 'selected-box-border': useMyCurrentPageTitle }"
                >
                  <div class="section-label mb-3">
                    I don't want to generate new titles with AI
                    <v-tooltip bottom color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <i
                          class="material-icons info-icon-size ml-2 mainColor--text"
                          v-bind="attrs"
                          v-on="on"
                          style="font-size: 14px"
                          >info</i
                        >
                      </template>
                      <div class="white-bg-tooltip small-tooltip">
                        <template v-if="separateSearchEngineTitleAndTitle">
                          If the current Page Title on your page is already
                          optimized, we recommend using it as is. Check this box
                          if you prefer not to make any changes to your existing
                          Page Title.
                        </template>
                        <template v-else>
                          If the current Search Engine Title and Page Title on
                          your page is already optimized, we recommend using it as
                          is. Check this box if you prefer not to make any changes
                          to your existing titles.
                        </template>
                      </div>
                    </v-tooltip>
                  </div>
                  <div class="d-flex align-center">
                    <div class="flex-grow-1 mainColor--text">
                      {{
                        separateSearchEngineTitleAndTitle
                          ? "Use my page title as is."
                          : "Use my search engine title and page title as is."
                      }}
                    </div>
                    <vue-toggles
                      :value="useMyCurrentPageTitle"
                      width="40"
                      height="20"
                      :disabled="isLoading || isDemo"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="useMyCurrentPageTitle = !useMyCurrentPageTitle"
                    ></vue-toggles>
                  </div>
                  <div class="mt-5" v-if="useMyCurrentPageTitle">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="text-none whiteColor--text"
                      :class="{
                        'pulsing-yellow-button': needsPulsing(STEPS.PAGE_TITLE),
                      }"
                      :disabled="isLoading"
                      @click="
                        () => {
                          showWarningModalForGenerate('pageTitle', () =>
                            generateResultFor('pageTitle')
                          );
                        }
                      "
                    >
                      <img
                        v-if="!useMyCurrentPageTitle && pageTitleAIResult"
                        src="/static/ai-writer-refresh.png"
                        width="19px"
                        style="margin-right: 1rem"
                      />
                      {{
                        separateSearchEngineTitleAndTitle
                          ? "Use my search engine title and page title"
                          : "Use my page titles"
                      }}
                    </v-btn>
                  </div>
                </v-card>
              </div>
            </div>

            <div v-if="currentStep == STEPS.SUBHEADINGS">
              <div class="d-flex flex-wrap flex-row-gap-5 align-center mb-2">
                <div>
                  <span class="section-label mr-2"> H2 and H3 sub-headings </span>
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 mainColor--text"
                        v-bind="attrs"
                        v-on="on"
                        style="font-size: 14px"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip small-tooltip">
                      Why H2s AND H3s? <br />
                      The usage of H2s and H3s is determined by the AI. You can
                      use them as the AI suggests or you could just use them all
                      as H2s if you don't want to use H3s on your page.
                      <br />
                      You can manually write in your own H2s or H3s by clicking
                      "Edit" or have AI generate them for your by clicking
                      "Generate." You can instruct the AI on where to get
                      inspiration for the subheadings that it writes by choosing
                      one or both of the inspiration options below. After the H2s
                      and H3s have been generated you can edit them or add more in
                      by clicking "Edit." Once you are happy with your H2s and H3s
                      click "Next" to advanced to the main content section.
                    </div>
                  </v-tooltip>
                </div>
                <div class="flex-grow-1">
                  <v-progress-linear
                    rounded
                    height="8"
                    v-if="runningSection == 'subHeadings' && isLoading"
                    class="progress-bar"
                    background-color="gray7Color"
                    color="profileAnchorColor"
                    :value="progress"
                  >
                  </v-progress-linear>
                  <v-progress-linear
                    v-else-if="
                      isDemo && subHeadProgress > 0 && subHeadProgress < 100
                    "
                    rounded
                    height="8"
                    class="progress-bar"
                    background-color="gray7Color"
                    color="profileAnchorColor"
                    :value="subHeadProgress"
                  />
                </div>
              </div>

              <div class="settings-area">
                <v-card
                  class="white-common-box pa-5"
                  :class="{
                    'selected-box-border':
                      optimizeCurrentH2s || optimizeCompetitorsH2s,
                  }"
                >
                  <div class="section-label mb-3">
                    I want the AI to rewrite my H2 and H3 sub-headings using POP
                    suggestions
                  </div>
                  <div class="mainColor--text mb-3">
                    You can select both options below at the same time.
                  </div>

                  <div class="d-flex align-center">
                    <div class="flex-grow-1 mainColor--text">
                      Use my H2s for AI inspiration
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div class="white-bg-tooltip" style="max-width: 500px">
                          Select this option to let AI refine and rewrite your
                          subheadings, aligning them with the POP recommendations.
                        </div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="optimizeCurrentH2s"
                      width="40"
                      height="20"
                      :disabled="isLoading || isDemo"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="optimizeCurrentH2s = !optimizeCurrentH2s"
                    ></vue-toggles>
                  </div>
                  <div class="d-flex align-center mt-2">
                    <div class="flex-grow-1 mainColor--text">
                      Use my competitors' H2s for AI inspiration
                      <v-tooltip bottom color="transparent">
                        <template v-slot:activator="{ on, attrs }">
                          <i
                            class="material-icons info-icon-size ml-2 mainColor--text"
                            v-bind="attrs"
                            v-on="on"
                            style="font-size: 14px"
                            >info</i
                          >
                        </template>
                        <div class="white-bg-tooltip" style="max-width: 500px">
                          By selecting this option, the AI will utilize your
                          leading competitors' H2s to generate and optimize a
                          fresh set of H2/H3 subheadings for your page. This is an
                          ideal choice if your current page lacks optimized
                          subheadings or if you're looking to restructure your
                          content.
                        </div>
                      </v-tooltip>
                    </div>
                    <vue-toggles
                      :value="optimizeCompetitorsH2s"
                      width="40"
                      height="20"
                      :disabled="isLoading || isDemo"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="optimizeCompetitorsH2s = !optimizeCompetitorsH2s"
                    ></vue-toggles>
                  </div>

                  <div class="mt-5">
                    <template v-if="isDemo">
                      <v-btn
                        v-if="subHeadProgress <= 0"
                        rounded
                        outlined
                        color="mainColor"
                        class="text-none mainColor--text pulsing-yellow-button"
                        @click="fakeProgress('subHeadProgress')"
                      >
                        Generate
                      </v-btn>
                    </template>
                    <v-btn
                      rounded
                      outlined
                      v-else-if="!subHeadingsAIResult"
                      color="mainColor"
                      class="text-none mainColor--text"
                      :class="{
                        'pulsing-yellow-button': needsPulsing(STEPS.SUBHEADINGS),
                      }"
                      :disabled="
                        isLoading ||
                        (!useMySubheadings &&
                          !optimizeCurrentH2s &&
                          !optimizeCompetitorsH2s)
                      "
                      @click="
                        () => {
                          showWarningModalForGenerate('subHeadings', () =>
                            generateResultFor('subHeadings')
                          );
                        }
                      "
                    >
                      <img
                        v-if="!useMySubheadings && subHeadingsAIResult"
                        src="/static/ai-writer-refresh.png"
                        width="19px"
                        style="margin-right: 1rem"
                      />
                      Generate
                    </v-btn>
                  </div>
                </v-card>
                <v-card
                  v-if="!pageNotBuilt"
                  class="mt-5 white-common-box pa-5"
                  :class="{ 'selected-box-border': useMySubheadings }"
                >
                  <div class="section-label mb-3">
                    I don't want to generate new H2 and H3 sub-headings with AI
                    <v-tooltip bottom color="transparent">
                      <template v-slot:activator="{ on, attrs }">
                        <i
                          class="material-icons info-icon-size ml-2 mainColor--text"
                          v-bind="attrs"
                          v-on="on"
                          style="font-size: 14px"
                          >info</i
                        >
                      </template>
                      <div class="white-bg-tooltip" style="max-width: 500px">
                        If the current H2/H3 on your page is already optimized, we
                        recommend using it as is. Check this box if you prefer not
                        to make any changes to your existing H2/H3.
                      </div>
                    </v-tooltip>
                  </div>
                  <div class="d-flex align-center">
                    <div class="flex-grow-1 mainColor--text">
                      Use my H2 and H3 sub-headings as is
                    </div>
                    <vue-toggles
                      :value="useMySubheadings"
                      width="40"
                      height="20"
                      :disabled="isLoading || isDemo"
                      :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                      :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
                      @click="useMySubheadings = !useMySubheadings"
                    ></vue-toggles>
                  </div>

                  <div class="mt-5" v-if="useMySubheadings">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="text-none whiteColor--text"
                      :class="{
                        'pulsing-yellow-button': needsPulsing(STEPS.SUBHEADINGS),
                      }"
                      :disabled="
                        isLoading ||
                        (!useMySubheadings &&
                          !optimizeCurrentH2s &&
                          !optimizeCompetitorsH2s)
                      "
                      @click="
                        () => {
                          showWarningModalForGenerate('subHeadings', () =>
                            generateResultFor('subHeadings')
                          );
                        }
                      "
                    >
                      <img
                        v-if="!useMySubheadings && subHeadingsAIResult"
                        src="/static/ai-writer-refresh.png"
                        width="19px"
                        style="margin-right: 1rem"
                      />
                      Extract my subheadings
                    </v-btn>
                  </div>
                </v-card>
              </div>
            </div>

            <div v-if="currentStep == STEPS.PARAGRAPH">
              <div class="d-flex flex-wrap flex-row-gap-5 align-center mb-2">
                <div v-if="pAIResult.length == 0">
                  <span class="section-label mr-2"> Main Content </span>
                  <v-tooltip bottom color="transparent">
                    <template v-slot:activator="{ on, attrs }">
                      <i
                        class="material-icons info-icon-size ml-2 mainColor--text"
                        v-bind="attrs"
                        v-on="on"
                        style="font-size: 14px"
                        >info</i
                      >
                    </template>
                    <div class="white-bg-tooltip" style="max-width: 500px">
                      You can manually write in text or copy and paste in existing
                      text from your webpage by checking the box below. The AI
                      will then use that text to re-write it and insert important
                      terms. You can also click "Generate" and the AI will write
                      new text for each H2 and H3. You can click "Check your work"
                      to check your new scores.
                    </div>
                  </v-tooltip>
                </div>
                <div>
                  <v-progress-linear
                    rounded
                    height="8"
                    v-if="runningSection == 'p' && isLoading"
                    class="progress-bar"
                    background-color="gray7Color"
                    color="profileAnchorColor"
                    :value="progress"
                  >
                  </v-progress-linear>
                  <v-progress-linear
                    v-else-if="
                      isDemo &&
                      mainContentProgress > 0 &&
                      mainContentProgress < 100
                    "
                    rounded
                    height="8"
                    class="progress-bar"
                    background-color="gray7Color"
                    color="profileAnchorColor"
                    :value="mainContentProgress"
                  />
                </div>
              </div>

              <div
                class="p-settings-area"
                v-if="
                  isDemo
                    ? mainContentProgress >= 0 && mainContentProgress < 100
                    : pAIResult.length == 0
                "
              >
                <div
                  class="mt-5 white-common-box pa-5"
                  :class="{ 'is-active': !isInputParagraphByUser }"
                  @click="
                    () => {
                      if (isDemo) return;
                      isInputParagraphByUser = false;
                    }
                  "
                >
                  <div class="d-flex align-center gap10">
                    <span class="material-icons box-top-icon">auto_awesome</span>
                    <span class="box-top-text"
                      >Quick content (Best for speed)</span
                    >
                  </div>
                  <div class="section-label mt-2 mb-3">
                    I want AI to generate new original content as my main content
                  </div>
                  <div class="mainColor--text">
                    <ul>
                      <li>
                        Description: AI will write completely original text for
                        each H2 and H3 you selected in the previous step. AI will
                        insert your keyword and important terms according to POP
                        recommendations.
                      </li>
                      <li>
                        Benefits: Easier and faster, but you have less control
                        over the output. This is the perfect option if you haven’t
                        built this page yet.
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="mt-5 white-common-box pa-5"
                  :class="{ 'is-active': isInputParagraphByUser }"
                  @click="
                    () => {
                      if (isDemo) return;
                      isInputParagraphByUser = true;
                    }
                  "
                >
                  <div class="d-flex align-center gap10">
                    <span class="material-icons-outlined box-top-icon"
                      >edit_note</span
                    >
                    <span class="box-top-text"
                      >Custom content (Best for quality)</span
                    >
                  </div>
                  <div class="section-label mt-2 mb-3">
                    I want to provide content for AI to re-write
                  </div>
                  <div class="mainColor--text">
                    <ul>
                      <li>
                        Description: Click on an empty field to see a dropdown
                        menu with paragraphs from your target page and your
                        competitors. You can select multiple paragraphs from this
                        menu to add them to the field for AI to re-write. You can
                        also manually edit these paragraphs before clicking
                        "Rewrite with AI" button.
                      </li>
                      <li>
                        Benefits: Takes longer, but you have more control over the
                        output. Ideal for those who want higher quality and
                        customization.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                v-if="pAIResult.length == 0 && isInputParagraphByUser"
                class="mt-5 input-your-paragraph-section"
              >
                <div class="d-flex align-center mb-3">
                  <div class="mainColor--text font-weight-bold mr-2">
                    Write or select your or your competitors’ paragraphs below and
                    AI will optimize them:
                    <!-- <template v-if="!isExpandInputParagraphSection">( Click arrow to expand. )</template> -->
                  </div>
                </div>
                <div class="notify-label">
                  <i class="material-icons">error_outline</i>
                  For a better output we recommend to provide at least
                  {{
                    Math.round(
                      numberOfWords /
                        (calculated_subHeadingsAIResultArr.length || 1)
                    )
                  }}
                  words in each paragraph field.
                </div>
                <div class="mt-2" v-if="!willSelectRevisionIndividually">
                  <div
                    v-for="item in availableRevisions"
                    :key="item.value"
                    class="radio-box-row"
                    @click="
                      () => {
                        selectedRevisionForAllParagraphs = item.value;
                      }
                    "
                  >
                    <span>
                      <span
                        v-if="item.value == selectedRevisionForAllParagraphs"
                        class="material-icons-outlined"
                        >radio_button_checked</span
                      >
                      <span v-else class="material-icons-outlined"
                        >radio_button_unchecked</span
                      >
                    </span>
                    <span class="checkbox-text">{{ item.text }}</span>
                  </div>
                </div>
                <div class="d-flex align-center mt-5">
                  <v-checkbox
                    hide-details
                    class="mt-0"
                    v-model="willSelectRevisionIndividually"
                  ></v-checkbox>
                  <span class="mainColor--text"
                    >I want to select the level of revision separately for each
                    paragraph</span
                  >
                </div>
                <div class="mt-5">
                  <div v-for="(item, idx) in userInputParagraphs" :key="idx">
                    <div class="text-right mainColor--text">
                      {{ userInputedParagraphWordCounts[idx] }} words
                    </div>
                    <v-textarea
                      outlined
                      dense
                      rows="1"
                      auto-grow
                      hide-details="auto"
                      :label="calculated_subHeadingsAIResultArr[idx].text"
                      v-model="userInputParagraphs[idx]"
                      @focus="
                        () => {
                          currentInputParagraphIndex = idx;
                        }
                      "
                    >
                    </v-textarea>
                    <div class="mt-2" v-if="willSelectRevisionIndividually">
                      <div
                        v-for="item in availableRevisions"
                        :key="item.value"
                        class="radio-box-row"
                        @click="
                          () => {
                            selectedRevisions = selectedRevisions.map(
                              (revItem, subIdx) => {
                                return subIdx == idx ? item.value : revItem;
                              }
                            );
                          }
                        "
                      >
                        <span>
                          <span
                            v-if="item.value == selectedRevisions[idx]"
                            class="material-icons-outlined"
                            >radio_button_checked</span
                          >
                          <span v-else class="material-icons-outlined"
                            >radio_button_unchecked</span
                          >
                        </span>
                        <span class="checkbox-text">{{ item.text }}</span>
                      </div>
                    </div>
                    <select-paragraph-box
                      v-if="idx == currentInputParagraphIndex"
                      :currentSubheadingInfo="
                        calculated_subHeadingsAIResultArr[idx]
                      "
                      :pageNotBuilt="pageNotBuilt"
                      :userInputParagraphs="userInputParagraphs"
                      :currentText="item"
                      :currentSelectedRevision="
                        selectedRevisions.length > idx
                          ? selectedRevisions[idx]
                          : ''
                      "
                      :targetUrlParagraphs="targetUrlParagraphs"
                      :competitorsParagraphs="competitorsParagraphs"
                      @clickedParagraphAction="
                        (text) => {
                          let newText =
                            userInputParagraphs[currentInputParagraphIndex];
                          if (newText) {
                            newText += '\n';
                          }
                          newText += text;
                          userInputParagraphs = userInputParagraphs.map(
                            (item, idx) => {
                              return idx == currentInputParagraphIndex
                                ? newText
                                : item;
                            }
                          );
                        }
                      "
                      @closeAction="
                        () => {
                          currentInputParagraphIndex = -1;
                        }
                      "
                    ></select-paragraph-box>
                  </div>
                </div>
              </div>

              <div class="py-5 d-flex justify-end">
                <v-btn
                  rounded
                  v-if="
                    isDemo &&
                    mainContentProgress >= 0 &&
                    mainContentProgress < 100
                  "
                  color="profileAnchorColor"
                  class="text-none whiteColor--text pulsing-yellow-button"
                  :disabled="mainContentProgress > 0"
                  @click="fakeProgress('mainContentProgress')"
                >
                  <img
                    src="/static/ai-writer-stars.png"
                    width="26px"
                    style="margin-right: 5px"
                  />
                  Generate main content
                </v-btn>
                <template v-else>
                  <v-btn
                    rounded
                    v-if="isInputParagraphByUser && pAIResult.length == 0"
                    color="profileAnchorColor"
                    class="text-none whiteColor--text"
                    :class="{
                      'pulsing-yellow-button':
                        isInputParagraphByUser && needsPulsing(STEPS.PARAGRAPH),
                    }"
                    :disabled="isLoading || !isInputParagraphByUser"
                    @click="
                      () => {
                        generateResultFor('p');
                      }
                    "
                  >
                    <img
                      src="/static/ai-writer-stars.png"
                      width="26px"
                      style="margin-right: 5px"
                    />
                    Rewrite with AI
                  </v-btn>
                  <v-btn
                    rounded
                    v-if="!isInputParagraphByUser && pAIResult.length == 0"
                    color="profileAnchorColor"
                    class="text-none whiteColor--text"
                    :class="{
                      'pulsing-yellow-button':
                        !isInputParagraphByUser && needsPulsing(STEPS.PARAGRAPH),
                    }"
                    :disabled="isLoading || isInputParagraphByUser"
                    @click="
                      () => {
                        generateResultFor('p');
                      }
                    "
                  >
                    <img
                      src="/static/ai-writer-stars.png"
                      width="26px"
                      style="margin-right: 5px"
                    />
                    Generate main content
                  </v-btn>
                </template>
              </div>
            </div>

            <div v-if="isLoading" class="mt-5">
              <v-progress-linear
                rounded
                height="8"
                v-if="runningSection"
                background-color="gray7Color"
                color="profileAnchorColor"
                :value="progress"
              >
              </v-progress-linear>
            </div>
            <div
              v-else-if="
                isDemo &&
                ((subHeadProgress > 0 && subHeadProgress < 100) ||
                  (titleProgress > 0 && titleProgress < 100) ||
                  (mainContentProgress > 0 && mainContentProgress < 100))
              "
              class="mt-5"
            >
              <v-progress-linear
                rounded
                height="8"
                background-color="gray7Color"
                color="profileAnchorColor"
                :value="mainContentProgress || subHeadProgress || titleProgress"
              >
              </v-progress-linear>
            </div>

            <div
              class="ai-results-area"
              :class="{
                'is-loading':
                  isLoading ||
                  (currentStep === STEPS.SUBHEADINGS &&
                    !calculated_subHeadingsAIResultArr.length),
              }"
            >
              <template
                v-if="
                  (!isDemo || titleProgress >= 100) &&
                  currentStep >= STEPS.SEARCH_ENGINE_TITLE &&
                  currentStep < STEPS.SUBHEADINGS &&
                  (calculated_titleAIResult || calculated_pageTitleAIResult)
                "
              >
                <div class="mt-5 mb-1 mainColor--text">
                  Click the Title or H1 you want to edit.
                </div>
                <v-card
                  class="white-common-bo py-5 d-flex flex-column"
                  style="gap: 10px"
                >
                  <template>
                    <div
                      class="result-one-section"
                      @mouseover="mouseenter('title')"
                      @mouseleave="mouseleave()"
                      @click="(e) => setActiveEditItem(e, 'ai-title')"
                      id="ai-title"
                    >
                      <div
                        v-if="calculated_titleAIResult"
                        class="title-row ai-result-card"
                      >
                        <span class="tag-symbol-span h1-tag">{{
                          calculated_titleAIResult.shortenTagName
                        }}</span>
                        <span class="text-part h1-size">
                          <div class="d-flex align-center flex-gap-5">
                            <input
                              v-if="isTitleOrPageTitleInEditingMode('title')"
                              id="input"
                              type="text"
                              class="flex-grow-1 edit-ai-result-input"
                              :disabled="isDemo"
                              v-model="calculated_titleAIResult.text"
                            />
                            <div
                              v-else
                              class="clickable d-flex align-center action"
                              @click="
                                (e) => {
                                  editInOutlineInfo = {
                                    section: 'title',
                                  };
                                  this.focusInput();
                                }
                              "
                            >
                              {{ calculated_titleAIResult.text }}
                              <span class="search-engines-only ml-3">
                                <!-- <i class="material-icons">error</i> -->
                                <span style="text-transform: uppercase !important"
                                  >For Search Engines Only</span
                                >
                              </span>
                            </div>
                            <div
                              class="d-flex flex-gap-5"
                              v-if="
                                isTitleOrPageTitleInEditingMode('title') ||
                                hoveredItem === 'title'
                              "
                            >
                              <button
                                class="action-btn action"
                                v-clipboard:copy="calculated_titleAIResult.text"
                                v-clipboard:success="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'success',
                                      text: 'Copied to clipboard',
                                    })
                                "
                                v-clipboard:error="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'error',
                                      text: 'Cannot copy to clipboard',
                                    })
                                "
                              >
                                <span class="material-icons-outlined action"
                                  >file_copy</span
                                >
                              </button>
                              <!-- <button class="action-btn">
                              <span class="material-icons-outlined">autorenew</span>
                            </button> -->
                              <!-- <button class="action-btn" @click="() => {
                              showDeleteConfirmModalAction('Do you want to delete this subheadings', deleteSubHeadingSelected)
                            }">
                              <span class="material-icons-outlined">delete</span>
                            </button> -->
                              <!-- <button
                                class="action-btn"
                                @click="() => cancelEditItemInOutlineFor('title')"
                              >
                                <span class="material-icons-outlined">done</span>
                              </button> -->
                            </div>
                          </div>
                        </span>

                        <div class="actions"></div>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="
                      currentStep >= STEPS.PAGE_TITLE &&
                      currentStep < STEPS.SUBHEADINGS
                    "
                  >
                    <div
                      class="result-one-section"
                      @mouseover="mouseenter('pageTitle')"
                      @mouseleave="mouseleave()"
                      @click="(e) => setActiveEditItem(e, 'ai-page-title')"
                      id="ai-page-title"
                    >
                      <div
                        v-if="calculated_pageTitleAIResult"
                        class="title-row ai-result-card"
                      >
                        <span class="tag-symbol-span h1-tag">{{
                          calculated_pageTitleAIResult.tagName
                        }}</span>
                        <span class="text-part">
                          <div class="d-flex align-center flex-gap-5">
                            <input
                              v-if="isTitleOrPageTitleInEditingMode('pageTitle')"
                              id="input"
                              type="text"
                              class="flex-grow-1 edit-ai-result-input"
                              :disabled="isDemo"
                              v-model="calculated_pageTitleAIResult.text"
                            />
                            <div
                              class="clickable action"
                              v-else
                              @click="
                                (e) => {
                                  if (currentStep < STEPS.SUBHEADINGS) {
                                    // disable edit in subheadings page
                                    editInOutlineInfo = {
                                      section: 'pageTitle',
                                    };
                                    this.focusInput();
                                  }
                                }
                              "
                            >
                              {{ calculated_pageTitleAIResult.text }}
                            </div>
                            <div
                              class="d-flex flex-gap-5"
                              v-if="
                                isTitleOrPageTitleInEditingMode('pageTitle') ||
                                hoveredItem === 'pageTitle'
                              "
                            >
                              <button
                                class="action-btn"
                                v-clipboard:copy="
                                  calculated_pageTitleAIResult.text
                                "
                                v-clipboard:success="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'success',
                                      text: 'Copied to clipboard',
                                    })
                                "
                                v-clipboard:error="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'error',
                                      text: 'Cannot copy to clipboard',
                                    })
                                "
                              >
                                <span class="material-icons-outlined action"
                                  >file_copy</span
                                >
                              </button>
                              <!-- <button class="action-btn">
                              <span class="material-icons-outlined">autorenew</span>
                            </button> -->
                              <!-- <button class="action-btn" @click="() => {
                              showDeleteConfirmModalAction('Do you want to delete this subheadings', deleteSubHeadingSelected)
                            }">
                              <span class="material-icons-outlined">delete</span>
                            </button> -->
                              <!-- <button
                                class="action-btn"
                                @click="
                                  () => cancelEditItemInOutlineFor('pageTitle')
                                "
                              >
                                <span class="material-icons-outlined">done</span>
                              </button> -->
                            </div>
                          </div>
                        </span>
                        <div class="actions"></div>
                      </div>
                    </div>
                  </template>
                </v-card>
              </template>
              <template
                v-if="
                  isDemo &&
                  subHeadProgress >= 0 &&
                  subHeadProgress < 100 &&
                  currentStep == STEPS.SUBHEADINGS
                "
              >
                <div class="mt-5 mb-1 mainColor--text">
                  Review and edit the outline before generating your article.
                </div>
                <v-card class="white-common-box pa-5">
                  <template>
                    <div class="result-one-section">
                      <div v-if="calculated_titleAIResult" class="title-row">
                        <span class="tag-symbol-span h1-tag">{{
                          calculated_titleAIResult.shortenTagName
                        }}</span>
                        <span class="text-part h1-size">
                          <div
                            v-if="isTitleOrPageTitleInEditingMode('title')"
                            class="d-flex align-center flex-gap-5"
                          >
                            <input
                              type="text"
                              class="flex-grow-1 edit-ai-result-input"
                              :disabled="isDemo"
                              v-model="calculated_titleAIResult.text"
                            />
                            <div class="d-flex flex-gap-5">
                              <button
                                class="action-btn"
                                v-clipboard:copy="calculated_titleAIResult.text"
                                v-clipboard:success="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'success',
                                      text: 'Copied to clipboard',
                                    })
                                "
                                v-clipboard:error="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'error',
                                      text: 'Cannot copy to clipboard',
                                    })
                                "
                              >
                                <span class="material-icons-outlined"
                                  >file_copy</span
                                >
                              </button>
                              <button
                                class="action-btn"
                                @click="() => cancelEditItemInOutlineFor('title')"
                              >
                                <span class="material-icons-outlined">done</span>
                              </button>
                            </div>
                          </div>
                          <div
                            v-else
                            class="clickable d-flex align-center"
                            @click="
                              (e) => {
                                editInOutlineInfo = {
                                  section: 'title',
                                };
                              }
                            "
                          >
                            {{ calculated_titleAIResult.text }}
                            <span class="search-engines-only ml-3">
                              <!-- <i class="material-icons">error</i> -->
                              <span>For Search Engines Only</span>
                            </span>
                          </div>
                        </span>

                        <div class="actions"></div>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="result-one-section">
                      <div v-if="calculated_pageTitleAIResult" class="title-row">
                        <span class="tag-symbol-span h1-tag">{{
                          calculated_pageTitleAIResult.tagName
                        }}</span>
                        <span class="text-part">
                          <div
                            v-if="isTitleOrPageTitleInEditingMode('pageTitle')"
                            class="d-flex align-center flex-gap-5"
                          >
                            <input
                              type="text"
                              class="flex-grow-1 edit-ai-result-input"
                              :disabled="isDemo"
                              v-model="calculated_pageTitleAIResult.text"
                            />
                            <div class="d-flex flex-gap-5">
                              <button
                                class="action-btn"
                                v-clipboard:copy="
                                  calculated_pageTitleAIResult.text
                                "
                                v-clipboard:success="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'success',
                                      text: 'Copied to clipboard',
                                    })
                                "
                                v-clipboard:error="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'error',
                                      text: 'Cannot copy to clipboard',
                                    })
                                "
                              >
                                <span class="material-icons-outlined"
                                  >file_copy</span
                                >
                              </button>
                              <button
                                class="action-btn"
                                @click="
                                  () => cancelEditItemInOutlineFor('pageTitle')
                                "
                              >
                                <span class="material-icons-outlined">done</span>
                              </button>
                            </div>
                          </div>
                          <div
                            class="clickable"
                            v-else
                            @click="
                              (e) => {
                                if (currentStep < STEPS.SUBHEADINGS) {
                                  // disable edit in subheadings page
                                  editInOutlineInfo = {
                                    section: 'pageTitle',
                                  };
                                }
                              }
                            "
                          >
                            {{ calculated_pageTitleAIResult.text }}
                          </div>
                        </span>
                        <div class="actions"></div>
                      </div>
                    </div>
                  </template>
                </v-card>
              </template>
              <template
                v-if="
                  (currentStep == STEPS.SUBHEADINGS &&
                    calculated_pageTitleAIResult) ||
                  (currentStep == STEPS.PARAGRAPH && pAIResult.length == 0) ||
                  (currentStep == STEPS.PARAGRAPH &&
                    isDemo &&
                    mainContentProgress < 100)
                "
              >
                <div
                  class="mt-10"
                  v-if="
                    !isDemo ||
                    subHeadProgress >= 100 ||
                    (currentStep == STEPS.PARAGRAPH &&
                      mainContentProgress >= 0 &&
                      mainContentProgress < 100)
                  "
                >
                  <div class="section-label mb-3">Outline</div>
                  <div
                    class="mainColor--text mb-3"
                    v-if="
                      currentStep == STEPS.SUBHEADINGS && pAIResult.length == 0
                    "
                  >
                    Review and edit the outline before generating your article.
                    <template v-if="calculated_subHeadingsAIResultArr.length > 0"
                      >Click the H2 or H3 you want to edit.</template
                    >
                  </div>
                  <div
                    v-if="maxRecommendedSubheadingsCount > 0"
                    class="notify-label"
                  >
                    <i class="material-icons">error_outline</i>
                    <span>
                      <!-- <div>Subheading Limit Notice</div> -->
                      For the selected word count, the maximum suggested number of
                      subheadings is {{ maxRecommendedSubheadingsCount }}. Adding
                      more may impact the quality and coherence of the
                      AI-generated content. Please consider this before
                      proceeding.
                    </span>
                  </div>
                  <v-card
                    class="white-common-bo py-3"
                    :class="{
                      'read-only':
                        currentStep == STEPS.PARAGRAPH || pAIResult.length > 0,
                    }"
                  >
                    <div class="ai-result-outline">
                      <div
                        v-if="calculated_titleAIResult"
                        class="outline-title ai-result-card mb-3 d-flex align-center"
                      >
                        <span class="tag-symbol-span h1-tag">{{
                          calculated_titleAIResult.shortenTagName
                        }}</span>
                        <span class="text-part">{{
                          calculated_titleAIResult.text
                        }}</span>
                        <span class="search-engines-only ml-3">
                          <!-- <i class="material-icons">error</i> -->
                          <span>For Search Engines Only</span>
                        </span>
                      </div>
                      <div
                        v-if="calculated_pageTitleAIResult"
                        class="outline-title ai-result-card mb-3"
                      >
                        <span class="tag-symbol-span h1-tag">{{
                          calculated_pageTitleAIResult.tagName
                        }}</span>
                        <span class="text-part">{{
                          calculated_pageTitleAIResult.text
                        }}</span>
                      </div>

                      <ul
                        ref="sortableList"
                        id="sortableList"
                        class="d-flex flex-column flex-gap-10"
                        v-if="showList"
                      >
                        <li
                          v-for="(item, idx) in calculated_subHeadingsAIResultArr"
                          :key="
                            'ai-outline-' +
                            (item.originIndex ? item.originIndex : idx)
                          "
                          @click="
                            (e) => setActiveEditItem(e, 'ai-outline-' + idx)
                          "
                          :id="
                            'ai-outline-' +
                            (item.originIndex ? item.originIndex : idx)
                          "
                          :data-id="item.originIndex"
                          class="d-flex"
                        >
                          <div
                            style="min-height: 47px"
                            :class="{
                              'outline-row-subheading ai-result-card flex-grow-1': true,
                              editing: isOutlineItemInEditingMode(
                                'subHeadings',
                                item
                              ),
                              'h2-indent': item.tagName == 'H2',
                              'h3-indent': item.tagName == 'H3',
                            }"
                            @mouseover="mouseenter(idx)"
                            @mouseleave="mouseleave()"
                          >
                            <div class="d-flex align-center">
                              <span
                                class="material-icons-outlined drag-indicator"
                                v-if="hoveredItem === idx"
                              >
                                drag_indicator
                              </span>
                            </div>

                            <span
                              :class="{
                                'tag-symbol-span': true,
                                'h2-tag': item.tagName == 'H2',
                                'h3-tag': item.tagName == 'H3',
                              }"
                              >{{ item.tagName }}</span
                            >
                            <span class="text-part">
                              <div
                                v-if="
                                  isOutlineItemInEditingMode(
                                    'subHeadings',
                                    item
                                  ) || hoveredItem === idx
                                "
                                class="d-flex align-center justify-space-between flex-gap-5"
                              >
                                <input
                                  id="input"
                                  type="text"
                                  class="flex-grow-1 edit-ai-result-input"
                                  :disabled="isDemo"
                                  v-model="
                                    calculated_subHeadingsAIResultArr[idx].text
                                  "
                                  v-if="
                                    isOutlineItemInEditingMode(
                                      'subHeadings',
                                      item
                                    )
                                  "
                                />
                                <span
                                  v-else
                                  @click="editSubheading(e, item)"
                                  class="ai-subheading"
                                  >{{
                                    calculated_subHeadingsAIResultArr[idx].text
                                  }}</span
                                >
                                <div class="d-flex flex-gap-5">
                                  <select
                                    v-if="
                                      isOutlineItemInEditingMode(
                                        'subHeadings',
                                        item
                                      )
                                    "
                                    class="change-tag-select action"
                                    :class="{
                                      'h2-selected': item.tagName == 'H2',
                                      'h3-selected': item.tagName == 'H3',
                                    }"
                                    @change="
                                      (e) => {
                                        item.tagName = e.target.value;
                                      }
                                    "
                                  >
                                    <option
                                      value="H2"
                                      :selected="item.tagName == 'H2'"
                                    >
                                      H2
                                    </option>
                                    <option
                                      value="H3"
                                      :selected="item.tagName == 'H3'"
                                    >
                                      H3
                                    </option>
                                  </select>
                                  <button
                                    class="action-btn"
                                    v-clipboard:copy="item.text"
                                    v-clipboard:success="
                                      () =>
                                        $notify({
                                          group: 'info',
                                          type: 'success',
                                          text: 'Copied to clipboard',
                                        })
                                    "
                                    v-clipboard:error="
                                      () =>
                                        $notify({
                                          group: 'info',
                                          type: 'error',
                                          text: 'Cannot copy to clipboard',
                                        })
                                    "
                                  >
                                    <span class="material-icons-outlined action"
                                      >file_copy</span
                                    >
                                  </button>
                                  <!-- <button class="action-btn">
                                  <span class="material-icons-outlined">autorenew</span>
                                </button> -->
                                  <button
                                    class="action-btn"
                                    @click="
                                      () => {
                                        editInOutlineInfo = item;
                                        showDeleteConfirmModalAction(
                                          'Do you want to delete this subheadings',
                                          deleteSubHeadingSelected
                                        );
                                      }
                                    "
                                  >
                                    <span class="material-icons-outlined action"
                                      >delete</span
                                    >
                                  </button>
                                  <!-- <button
                                    v-if="
                                      isOutlineItemInEditingMode(
                                        'subHeadings',
                                        item
                                      )
                                    "
                                    class="action-btn"
                                    @click="
                                      () =>
                                        cancelEditItemInOutlineFor('subHeadings')
                                    "
                                  >
                                    <span class="material-icons-outlined"
                                      >done</span
                                    >
                                  </button> -->
                                </div>
                              </div>
                              <div v-else @click="editSubheading(e, item)">
                                {{ item.text }}
                              </div>
                            </span>
                            <!-- <span class="actions">
                          </span> -->
                          </div>
                        </li>
                      </ul>

                      <!-- {{ calculated_subHeadingsAIResultArr }} -->

                      <v-btn
                        :disabled="isDemo"
                        v-if="
                          currentStep == STEPS.SUBHEADINGS
                        "
                        rounded
                        plain
                        small
                        color="profileAnchorColor"
                        class="text-none mainColor--text mt-3"
                        style="font-size: 0px !important"
                        @click="
                          () => {
                            showAddSubheadingsModal = true;
                            newSubheadingText = '';
                          }
                        "
                      >
                        <i class="material-icons">add</i>
                        <span>New subheading</span>
                      </v-btn>
                    </div>
                    <div
                      v-if="
                        currentStep == STEPS.SUBHEADINGS &&
                        calculated_subHeadingsAIResultArr.length > 0 &&
                        (isDemo || pAIResult.length == 0)
                      "
                    >
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="text-none mainColor--text mr-3"
                        :disabled="
                          isDemo ||
                          isLoading ||
                          (!useMySubheadings &&
                            !optimizeCurrentH2s &&
                            !optimizeCompetitorsH2s)
                        "
                        @click="
                          () => {
                            showWarningModalForGenerate('subHeadings', () =>
                              generateResultFor('subHeadings')
                            );
                          }
                        "
                      >
                        <!-- <img
                          src="/static/ai-writer-refresh.png"
                          width="15px"
                          style="margin-right: 0.5rem"
                        /> -->
                        <i class="material-icons">replay</i>
                        Regenerate
                      </v-btn>
                      <v-btn
                        :disabled="isDemo"
                        outlined
                        rounded
                        color="mainColor"
                        class="text-none mainColor--text mr-3"
                        @click="showSubheadingsHistoryModal = true"
                      >
                        <span>Show history</span>
                      </v-btn>
                      <v-btn
                        :disabled="isDemo"
                        outlined
                        rounded
                        color="mainColor"
                        class="text-none mainColor--text mr-3"
                        @click="() => downloadOutlines()"
                      >
                        <span>Download outline</span>
                      </v-btn>
                      <!-- <v-btn rounded outlined small
                      color="mainColor"
                      class="text-none mainColor--text ml-1"
                      @click="() => showEditAIResultAction('subHeadings')">
                      <span>Edit</span>
                    </v-btn> -->
                    </div>
                  </v-card>
                </div>
              </template>

              <div
                v-if="
                  currentStep == STEPS.PARAGRAPH &&
                  (isDemo ? mainContentProgress >= 100 : pAIResult.length > 0)
                "
                class="ai-result-full-as-google-doc-style"
              >
                <div class="with-tag-block">
                  <div class="h1">T</div>
                  <div class="main-content-normal mt-0">
                    <div class="text-part">
                      <div
                        v-if="calculated_titleAIResult"
                        class="main-content-title"
                      >
                        {{ calculated_titleAIResult.text }}
                      </div>
                    </div>
                    <div class="action-part">
                      <div>
                        <span class="material-icons-outlined expand-collapse"
                          >remove</span
                        >
                        <span class="block-type">meta title</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="with-tag-block" style="margin-top: 50px">
                  <div class="h1">h1</div>
                  <div class="main-content-normal mt-0">
                    <div class="text-part">
                      <div
                        v-if="calculated_pageTitleAIResult"
                        class="main-content-title"
                      >
                        {{ calculated_pageTitleAIResult.text }}
                      </div>
                    </div>
                    <div class="action-part">
                      <div>
                        <span class="material-icons-outlined expand-collapse"
                          >remove</span
                        >
                        <span class="block-type">title</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    v-for="(item, idx) in pAIResult"
                    :key="`paragraph_block_${idx}`"
                    class="result-one-section"
                  >
                    <template
                      v-if="
                        item.metaSection ||
                        item.introSection ||
                        item.endSection
                      "
                    >
                      <div class="with-tag-block">
                        <div></div>
                        <div class="one-grouped-block">
                          <div
                            :class="{
                              'editing-p-block':
                                editingParagraphIdxInResult == idx,
                            }"
                          >
                            <div class="main-content-normal">
                              <div class="text-part">
                                <template v-if="item.metaSection">
                                  <template
                                    v-if="editingParagraphIdxInResult == idx"
                                  >
                                    <codemirror
                                      class="meta-editor"
                                      v-model="item.answer"
                                      :options="{
                                        ...cmOptions,
                                        theme: codeMirrorTheme,
                                        readOnly: !!isDemo,
                                      }"
                                      @input="
                                        (data) =>
                                          handleParagraphEditDebounced(idx, data)
                                      "
                                      v-click-outside="
                                        () => {
                                          editingParagraphIdxInResult = -1;
                                        }
                                      "
                                    />
                                  </template>
                                  <template v-else>
                                    <div
                                      @click="
                                        () => {
                                          editingParagraphIdxInResult = idx;
                                        }
                                      "
                                    >
                                      {{ item.answer }}
                                    </div>
                                  </template>
                                </template>
                                <template v-else>
                                  <template
                                    v-if="editingParagraphIdxInResult == idx"
                                  >
                                    <VueTrix
                                      :disabledEditor="isDemo"
                                      v-model="item.answer"
                                      placeholder="You can edit paragraph"
                                      localStorage
                                      v-click-outside="
                                        () => {
                                          editingParagraphIdxInResult = -1;
                                        }
                                      "
                                    />
                                  </template>
                                  <template v-else>
                                    <div
                                      :class="item.keyTakeaways ? 'key-takeaways-editor' : ''"
                                      v-html="item.answer"
                                      @click="
                                        () => {
                                          editingParagraphIdxInResult = idx;
                                        }
                                      "
                                    ></div>
                                  </template>
                                </template>
                              </div>
                              <span class="action-part">
                                <div>
                                  <span
                                    class="material-icons-outlined expand-collapse"
                                    >remove</span
                                  >
                                  <span class="block-type">
                                    <template v-if="item.metaSection"
                                      >meta descr</template
                                    >
                                    <template v-else-if="item.introSection"
                                      >intro</template
                                    >
                                    <template v-else-if="item.endSection"
                                      >conclusion</template
                                    >
                                  </span>
                                </div>
                              </span>
                            </div>
                            <div
                              class="action-btns-row mt-2"
                              v-if="editingParagraphIdxInResult == idx"
                            >
                              <v-btn
                                rounded
                                color="mainColor"
                                class="text-none mainColor--text"
                                v-clipboard:copy="item.answer"
                                v-clipboard:success="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'success',
                                      text: 'Copied to clipboard',
                                    })
                                "
                                v-clipboard:error="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'error',
                                      text: 'can not copy to clipboard',
                                    })
                                "
                              >
                                Copy
                              </v-btn>
                              <v-btn
                                outlined
                                rounded
                                color="mainColor"
                                class="text-none mainColor--text mr-3"
                                @click="regenerateForParagraphOneSection(idx)"
                              >
                                <span class="material-icons">replay</span>
                                <span>Regenerate</span>
                              </v-btn>

                              <v-btn
                                outlined
                                rounded
                                color="mainColor"
                                class="text-none mainColor--text mr-3"
                                @click="
                                  () => {
                                    seletedParagraphSection = idx;
                                    showParagraphOneSectionHistoryModal = true;
                                  }
                                "
                              >
                                View history
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <template v-else>
                      <div
                        :id="textToCssId(item['subheadingInfo']['tagText'])"
                        class="one-grouped-block"
                      >
                        <div class="with-tag-block">
                          <div
                            :class="{
                              h2: item['subheadingInfo']['tagName'] == 'h2',
                              h3: item['subheadingInfo']['tagName'] == 'h3',
                            }"
                          >
                            <template
                              v-if="item['subheadingInfo']['tagName'] == 'h2'"
                              >h2</template
                            >
                            <template
                              v-if="item['subheadingInfo']['tagName'] == 'h3'"
                              >h3</template
                            >
                          </div>
                          <div
                            :class="{
                              'main-content-h2':
                                item['subheadingInfo']['tagName'] == 'h2',
                              'main-content-h3':
                                item['subheadingInfo']['tagName'] == 'h3',
                            }"
                          >
                            {{ item["subheadingInfo"]["tagText"] }}
                          </div>
                        </div>
                        <div class="with-tag-block">
                          <div></div>
                          <div
                            :class="{
                              'editing-p-block':
                                editingParagraphIdxInResult == idx,
                            }"
                          >
                            <div class="main-content-normal">
                              <span class="text-part">
                                <template
                                  v-if="editingParagraphIdxInResult == idx"
                                >
                                  <VueTrix
                                    :disabledEditor="isDemo"
                                    v-model="item.answer"
                                    placeholder="You can edit paragraph"
                                    localStorage
                                    v-click-outside="
                                      () => {
                                        editingParagraphIdxInResult = -1;
                                      }
                                    "
                                  />
                                </template>
                                <template v-else>
                                  <div
                                    v-html="item.answer"
                                    @click="
                                      () => {
                                        editingParagraphIdxInResult = idx;
                                        setNeedsToSave();
                                      }
                                    "
                                  ></div>
                                </template>
                              </span>
                              <span class="action-part">
                                <div>
                                  <span
                                    class="material-icons-outlined expand-collapse"
                                    >remove</span
                                  >
                                  <span
                                    class="block-type"
                                    @click="
                                      () => {
                                        editingParagraphIdxInResult = idx;
                                      }
                                    "
                                  >
                                    {{ item?.briefOverview ? 'brief overview' : item?.keyTakeaways ? 'key highlights' : 'paragraph' }}
                                  </span>
                                </div>
                              </span>
                            </div>
                            <div
                              class="action-btns-row mt-2"
                              v-if="editingParagraphIdxInResult == idx"
                            >
                              <v-btn
                                rounded
                                color="profileAnchorColor"
                                class="text-none whiteColor--text"
                                v-clipboard:copy="item.answer"
                                v-clipboard:success="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'success',
                                      text: 'Copied to clipboard',
                                    })
                                "
                                v-clipboard:error="
                                  () =>
                                    $notify({
                                      group: 'info',
                                      type: 'error',
                                      text: 'can not copy to clipboard',
                                    })
                                "
                              >
                                Copy
                              </v-btn>
                              <v-btn
                                outlined
                                rounded
                                color="mainColor"
                                class="text-none mainColor--text mr-3"
                                @click="regenerateForParagraphOneSection(idx)"
                              >
                                <span class="material-icons">replay</span>
                                <span>Regenerate</span>
                              </v-btn>
                              <v-btn
                                outlined
                                rounded
                                color="mainColor"
                                class="text-none mainColor--text mr-3"
                                @click="
                                  () => {
                                    seletedParagraphSection = idx;
                                    showParagraphOneSectionHistoryModal = true;
                                  }
                                "
                              >
                                View history
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div
                      v-for="(item, idx1) in getAllContentPromptDataForPSection(
                        idx
                      )"
                      :key="`${idx}_cp_placeholder${idx1}`"
                      class="with-tag-block"
                    >
                      <div></div>
                      <div>
                        <div class="main-content-normal my-7">
                          <div class="text-part">
                            <div
                              v-if="item.shortenKey == 'form'"
                              class="content-prompt-placeholder"
                              :class="{ 'is-empty': true }"
                            >
                              <div class="text-center">
                                <span class="material-icons main-icon"
                                  >assignment</span
                                >
                              </div>
                              <div class="text-center">
                                This is where you can add a form on your page
                              </div>
                            </div>
                            <div
                              v-else-if="item.shortenKey == 'image'"
                              class="content-prompt-placeholder"
                              :class="{ 'is-empty': true }"
                            >
                              <div class="text-center mb-3">
                                <span class="material-icons main-icon"
                                  >image</span
                                >
                              </div>
                              <div class="text-center">
                                This is where you can add an image on your page.
                                Please note, this is just a placeholder.
                              </div>
                            </div>
                            <div
                              v-else-if="
                                ['list', 'table', 'faq'].includes(item.shortenKey)
                              "
                              class="content-prompt-ai-result-section"
                            >
                              <div>
                                <div
                                  v-if="item.isEmpty"
                                  class="empty-case"
                                  @click="
                                    () =>
                                      showContnentPromptGenerationModal(
                                        true,
                                        item.key,
                                        item.shortenKey,
                                        item.uniqueIndex
                                      )
                                  "
                                >
                                  <span class="material-icons-outlined"
                                    >add_circle</span
                                  >
                                  Rich content results:
                                  <strong>{{
                                    item.shortenKey | capitalize
                                  }}</strong>
                                </div>
                                <div
                                  v-else
                                  class="content-promopt-one-signal-result"
                                >
                                  <div
                                    v-html="
                                      item.resultToDisplay || item.currentResult
                                    "
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div v-else>{{ JSON.stringify(item) }}</div>
                          </div>
                          <div class="action-part">
                            <div>
                              <span
                                class="material-icons-outlined expand-collapse"
                                >remove</span
                              >
                              <span class="block-type">
                                {{ item.shortenKey }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            ['list', 'table', 'faq'].includes(item.shortenKey)
                          "
                          class="action-btns-row"
                        >
                          <template v-if="!item.isEmpty">
                            <v-btn
                              outlined
                              rounded
                              color="mainColor"
                              class="text-none mainColor--text"
                              :disabled="isDemo"
                              @click="() => showEditPopupForRichItem(item)"
                            >
                              Edit
                            </v-btn>
                            <v-btn
                              outlined
                              rounded
                              color="mainColor"
                              class="text-none mainColor--text mr-3"
                              :disabled="isDemo"
                              @click="
                                () =>
                                  showContnentPromptGenerationModal(
                                    false,
                                    item.key,
                                    item.shortenKey,
                                    item.uniqueIndex
                                  )
                              "
                            >
                              Regenerate
                            </v-btn>
                            <v-btn
                              outlined
                              rounded
                              color="mainColor"
                              class="text-none mainColor--text mr-3"
                              :disabled="isDemo"
                              @click="
                                () =>
                                  showHistoryForRichItem(
                                    item.key,
                                    item.shortenKey,
                                    item.uniqueIndex
                                  )
                              "
                            >
                              View history
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  !isDemo &&
                  currentStep == STEPS.SEARCH_ENGINE_TITLE &&
                  calculated_titleAIResult
                "
                class="mt-3"
              >
                <v-btn
                  rounded
                  outlined
                  small
                  color="mainColor"
                  class="text-none mainColor--text mr-3"
                  :disabled="
                    isLoading ||
                    (!useMyCurrentTitle &&
                      !optimizeCurrentTitle &&
                      !optimizeCompetitorTitle &&
                      !useTitleInspirationText)
                  "
                  @click="
                    () => {
                      showWarningModalForGenerate('title', () =>
                        generateResultFor('title')
                      );
                    }
                  "
                >
                  <img
                    src="/static/ai-writer-refresh.png"
                    width="15px"
                    style="margin-right: 0.5rem"
                  />
                  Regenerate
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  small
                  v-if="titleAIResultHistory.length > 0"
                  color="mainColor"
                  class="button-with-icon text-none mainColor--text ml-1"
                  @click="showTitleHistoryModal = true"
                >
                  <span>Show history</span>
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  small
                  color="mainColor"
                  class="button-with-icon text-none mr-3"
                  @click="() => downloadAIResult('title')"
                >
                  <span>Download</span>
                </v-btn>
                <!-- <v-btn rounded outlined small
                color="mainColor"
                class="button-with-icon text-none mainColor--text ml-1"
                @click="() => showEditAIResultAction('title')">
                <span>Edit</span>
              </v-btn> -->
              </div>
              <div
                v-if="
                  (!isDemo || titleProgress >= 100) &&
                  currentStep == STEPS.PAGE_TITLE &&
                  calculated_pageTitleAIResult
                "
                class="mt-3"
              >
                <v-btn
                  outlined
                  rounded
                  color="mainColor"
                  class="text-none mainColor--text mr-3"
                  :disabled="
                    isDemo ||
                    isLoading ||
                    (!useMyCurrentPageTitle &&
                      !optimizeCurrentPageTitle &&
                      !optimizeCompetitorPageTitle &&
                      !usePageTitleInspirationText)
                  "
                  @click="
                    () => {
                      showWarningModalForGenerate('pageTitle', () =>
                        generateResultFor('pageTitle')
                      );
                    }
                  "
                >
                  <!-- <img
                    src="/static/ai-writer-refresh.png"
                    width="15px"
                    style="margin-right: 0.5rem"
                  /> -->
                  <i class="material-icons">replay</i>
                  Regenerate
                </v-btn>
                <v-btn
                  :disabled="isDemo"
                  rounded
                  outlined
                  color="mainColor"
                  class="text-none mainColor--text mr-3"
                  v-if="pageTitleAIResultHistory.length > 0"
                  @click="showPageTitleHistoryModal = true"
                >
                  <span>View history</span>
                </v-btn>
                <v-btn
                  :disabled="isDemo"
                  rounded
                  outlined
                  color="mainColor"
                  class="text-none mainColor--text mr-3"
                  @click="() => downloadAIResult('pageTitle')"
                >
                  <span>Download</span>
                </v-btn>
                <!-- <v-btn rounded outlined small
                color="mainColor"
                class="button-with-icon text-none mainColor--text ml-1"
                @click="() => showEditAIResultAction('pageTitle')">
                <span>Edit</span>
              </v-btn> -->
              </div>
            </div>

            <div class="mt-5 d-flex justify-end">
              <v-btn
                rounded
                v-if="
                  (!isDemo || titleProgress >= 100) &&
                  currentStep == STEPS.PAGE_TITLE &&
                  pageTitleAIResult
                "
                color="profileAnchorColor"
                class="button-with-icon text-none whiteColor--text mr-3"
                :disabled="isLoading"
                @click="goNext(STEPS.PAGE_TITLE)"
              >
                <img
                  src="/static/ai-writer-stars.png"
                  width="26px"
                  style="margin-right: 5px"
                />
                Next
              </v-btn>
              <v-btn
                rounded
                v-if="
                  !isDemo &&
                  currentStep == STEPS.SUBHEADINGS &&
                  subHeadingsAIResult
                "
                color="profileAnchorColor"
                class="button-with-icon text-none whiteColor--text mr-3"
                :disabled="isLoading"
                @click="
                  () => {
                    showWarnForEditNowAllowdAfterParagraphGenerationModal = true;
                    // goNext(STEPS.SUBHEADINGS)
                  }
                "
              >
                <img
                  src="/static/ai-writer-stars.png"
                  width="26px"
                  style="margin-right: 5px"
                />
                Next
              </v-btn>
              <v-btn
                rounded
                v-else-if="
                  isDemo &&
                  currentStep == STEPS.SUBHEADINGS &&
                  subHeadingsAIResult &&
                  subHeadProgress >= 100
                "
                color="profileAnchorColor"
                class="button-with-icon text-none whiteColor--text mr-3"
                :disabled="isLoading"
                @click="() => (currentStep += 1)"
              >
                <img
                  src="/static/ai-writer-stars.png"
                  width="26px"
                  style="margin-right: 5px"
                />
                Next
              </v-btn>
            </div>

            <div class="mt-5">
              <v-btn
                rounded
                v-if="currentStep == STEPS.PARAGRAPH && pAIResult.length > 0"
                color="profileAnchorColor"
                class="text-none whiteColor--text"
                :disabled="isLoading || isDemo"
                @click="goNext(STEPS.PARAGRAPH)"
                style="min-width: 280px"
              >
                <img
                  src="/static/ai-writer-stars.png"
                  width="26px"
                  style="margin-right: 5px"
                />
                <span>Check optimization score</span>
              </v-btn>
            </div>

            <div class="pb-10">
              <!-- Do not remove this. this is for giving extra space at bottom. giving padding in parent will not work. -->
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showNextButtonWillUpdateScoreModal }"
            >
              <div
                class="modal-background"
                @click="showNextButtonWillUpdateScoreModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="subtitle-font mainColor--text mb-5">
                      When you click "Next" your score will update automatically
                    </div>
                    <div class="text-right">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="showNextButtonWillUpdateScoreModal = false"
                      >
                        Ok
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showTitleHistoryModal }">
              <div
                class="modal-background"
                @click="showTitleHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root"></div>
                  <div class="modal-title-size mainColor--text mb-3">
                    Search engine title history
                  </div>
                  <div
                    v-for="(item, idx) in titleAIResultHistory"
                    :key="idx"
                    class="history-box"
                  >
                    <div class="result">
                      <div class="timestamp">
                        <span>{{ item.timestamp }}</span>
                      </div>
                      {{ item.data }}
                    </div>
                    <div class="action">
                      <v-btn
                        rounded
                        x-small
                        color="profileAnchorColor"
                        class="text-none whiteColor--text"
                        @click="
                          () =>
                            loadAndSaveCurrentSelectionForSection(
                              'title',
                              idx,
                              item
                            )
                        "
                      >
                        <span>Load</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showTitleHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showPageTitleHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showPageTitleHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="modal-title-size mainColor--text mb-3">
                      Page title history
                    </div>
                    <div
                      v-for="(item, idx) in pageTitleAIResultHistory"
                      :key="idx"
                      class="history-box"
                    >
                      <div class="result">
                        <div class="timestamp">
                          <span>{{ item.timestamp }}</span>
                        </div>
                        {{ item.data }}
                      </div>
                      <div class="action">
                        <v-btn
                          rounded
                          x-small
                          color="profileAnchorColor"
                          class="text-none whiteColor--text"
                          @click="
                            () =>
                              loadAndSaveCurrentSelectionForSection(
                                'pageTitle',
                                idx,
                                item
                              )
                          "
                        >
                          <span>Load</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showPageTitleHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showSubheadingsHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showSubheadingsHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="modal-title-size mainColor--text mb-3">
                      Subheadings history
                    </div>
                    <div
                      v-for="(item, idx) in subheadingsAIResultHistory"
                      :key="`main_${idx}`"
                      class="history-box"
                    >
                      <div class="result">
                        <div class="timestamp">
                          <span>{{ item.timestamp }}</span>
                        </div>
                        <!-- <div v-html="item.data">
                      </div> -->
                        <div>
                          <div
                            v-for="(item1, idx1) in item.dataAsList"
                            :key="`sub_${idx1}`"
                          >
                            {{ item1 }}
                          </div>
                        </div>
                      </div>
                      <div class="action">
                        <v-btn
                          rounded
                          x-small
                          color="profileAnchorColor"
                          class="text-none whiteColor--text"
                          @click="
                            () =>
                              loadAndSaveCurrentSelectionForSection(
                                'subHeadings',
                                idx,
                                item
                              )
                          "
                        >
                          <span>Load</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showSubheadingsHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showParagraphOneSectionHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showParagraphOneSectionHistoryModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div class="modal-title-size mainColor--text mb-3">
                      Main Content history for section
                    </div>
                    <div
                      v-for="(
                        item, idx
                      ) in selectedParagraphSectionAIResultHistory"
                      :key="idx"
                      class="history-box"
                    >
                      <div class="result">
                        <div class="timestamp">
                          <span>{{ item.timestamp }}</span>
                        </div>
                        {{ item.answer }}
                      </div>
                      <div class="action">
                        <v-btn
                          rounded
                          x-small
                          color="profileAnchorColor"
                          class="text-none whiteColor--text"
                          @click="
                            () =>
                              loadAndSaveCurrentSelectionForSection(
                                'p',
                                idx,
                                item
                              )
                          "
                        >
                          <span>Load</span>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showParagraphOneSectionHistoryModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showEditAIResultModal }">
              <div
                class="modal-background"
                @click="showEditAIResultModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-title-size mainColor--text">Edit</div>
                  <v-textarea
                    outlined
                    color="greenColor"
                    class="mt-5"
                    v-model="editResultText"
                  >
                  </v-textarea>

                  <div class="text-right">
                    <v-btn
                      rounded
                      outlined
                      color="mainColor"
                      class="button-with-icon text-none mr-3"
                      @click="showEditAIResultModal = false"
                    >
                      <span>Cancel</span>
                    </v-btn>
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      @click="saveEditedAIResultAction"
                    >
                      <span>Save</span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showExceedNumberOfWordsModal }"
            >
              <div
                class="modal-background"
                @click="showExceedNumberOfWordsModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div>
                    <div
                      class="mainColor--text mb-5"
                      style="font-size: 20px; line-height: 25px"
                    >
                      Your current entry has more than the maximum allowed 4000
                      words. <br />
                      Please reduce your word number to continue.
                    </div>
                  </div>

                  <div class="text-right">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      @click="showExceedNumberOfWordsModal = false"
                    >
                      Ok
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showLogModal }">
              <div class="modal-background" @click="showLogModal = false"></div>
              <div class="modal-content">
                <div class="box modal-container" style="min-height: 90vh">
                  <div class="modal-title-size mainColor--text">Prompt logs</div>

                  <v-textarea
                    outlined
                    :value="allLogs.join(`\n${'-'.repeat(60)}\n`)"
                    readonly
                    rows="20"
                  ></v-textarea>

                  <div class="text-right">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none ml-2"
                      @click="showLogModal = false"
                    >
                      Close
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRegenerateFreeWarnModal }"
            >
              <div
                class="modal-background"
                @click="showRegenerateFreeWarnModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="d-flex align-center">
                    <img
                      src="/static/ai-credits-1-icon.png"
                      style="width: 30px; height: auto"
                    />
                    <div class="ml-3 text-h5 font-weight-medium mainColor--text">
                      Clicking regenerate requires 1 POP credit.
                    </div>
                  </div>
                  <div class="mainColor--text mt-5">
                    You get 5 free regeneration clicks per article. Each click
                    after that requires 1 POP credit.
                  </div>
                  <div class="d-flex align-center mt-5">
                    <div class="flex-grow-1">
                      <v-checkbox
                        dense
                        v-model="regenerateFreeWarnAcknowledged"
                        hide-details
                        label="Do not show this again checkbox."
                      >
                      </v-checkbox>
                    </div>
                    <div>
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none mr-2"
                        @click="showRegenerateFreeWarnModal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="proceedRegenerateFree"
                      >
                        Ok, proceed
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRegenerateChargeWarnModal }"
            >
              <div
                class="modal-background"
                @click="showRegenerateChargeWarnModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div>
                    <div
                      class="mainColor--text mb-5"
                      style="font-size: 20px; line-height: 25px"
                    >
                      You have used all of your free regeneration clicks for this
                      article. Each regeneration click will require 1 POP credit.
                    </div>
                  </div>
                  <div>
                    <v-checkbox
                      v-model="regenerateChargeWarnAcknowledged"
                      hide-details
                      label="Do not show this again checkbox."
                    ></v-checkbox>
                  </div>
                  <div class="text-right">
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      @click="proceedRegenerateCharge"
                    >
                      Proceed
                    </v-btn>
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none ml-2"
                      @click="showRegenerateChargeWarnModal = false"
                    >
                      Cancel
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showDisclaimerModal }">
              <div
                class="modal-background"
                @click="showDisclaimerModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div>
                      <div
                        class="mainColor--text font-weight-bold mb-5"
                        style="font-size: 20px"
                      >
                        Disclaimer:
                      </div>
                      <div
                        class="mainColor--text mb-5"
                        style="font-size: 16px; line-height: 18px"
                      >
                        We strongly advise against choosing a word count lower
                        than the target suggested. It is important to note that
                        target optimization ranges and the overall optimization
                        score will be influenced by the selected word count.
                        <br /><br />
                        Additionally, please be aware that if you optimize
                        according to a custom word count, the score generated by
                        the AI writer may differ from that of the score in the
                        Content Brief or Extension.
                      </div>
                    </div>
                    <div class="text-right">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none ml-2"
                        @click="showDisclaimerModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        @click="
                          () => {
                            confirmedDisclaim = true;
                            showDisclaimerModal = false;
                            goNext(STEPS.SETTING);
                          }
                        "
                      >
                        <span>Next</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{
                'is-active':
                  showWarnForEditNowAllowdAfterParagraphGenerationModal,
              }"
            >
              <div
                class="modal-background"
                @click="
                  showWarnForEditNowAllowdAfterParagraphGenerationModal = false
                "
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="pad25">
                    <div>
                      <div
                        class="mainColor--text font-weight-bold mb-5"
                        style="font-size: 20px"
                      >
                        Attention, Please!
                      </div>
                      <div
                        class="mainColor--text mb-5"
                        style="font-size: 16px; line-height: 18px"
                      >
                        You are now about to proceed to the next step in your AI
                        workflow. Kindly note, once you click on the 'NEXT'
                        button, you will not be able to go back and make edits to
                        the titles or subheadings in the previous step.
                        <br /><br />
                        Please review all your entries and ensure you are
                        completely satisfied, as this action cannot be undone.
                        <br /><br />
                        Are you sure you want to proceed?
                      </div>
                    </div>
                    <div class="text-right">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        @click="
                          () => {
                            showWarnForEditNowAllowdAfterParagraphGenerationModal = false;
                            goNext(STEPS.SUBHEADINGS);
                          }
                        "
                      >
                        <span>Ok</span>
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none ml-2"
                        @click="
                          () => {
                            showWarnForEditNowAllowdAfterParagraphGenerationModal = false;
                          }
                        "
                      >
                        <span>Cancel</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showAddSubheadingsModal }">
              <div
                class="modal-background"
                @click="showAddSubheadingsModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="pad25">
                    <div>
                      <div class="title-font is-size-4 mainColor--text">
                        Add new subheading
                      </div>
                      <v-select
                        outlined
                        dense
                        hide-details
                        class="mt-5"
                        color="greenColor"
                        :items="[
                          { text: 'H2', value: 'H2' },
                          { text: 'H3', value: 'H3' },
                        ]"
                        label="Please select the tag"
                        v-model="newSubHeadingTag"
                      >
                      </v-select>
                      <v-text-field
                        outlined
                        dense
                        hide-details="auto"
                        color="greenColor"
                        class="mt-5"
                        label="Please input subheading text you want to add"
                        v-model="newSubheadingText"
                      >
                      </v-text-field>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none ml-2"
                        @click="showAddSubheadingsModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        :disabled="!newSubHeadingTag || !newSubheadingText"
                        @click="addNewSubheadingsAction"
                      >
                        <span>Add</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal" :class="{ 'is-active': showDeleteConfirmModal }">
              <div
                class="modal-background"
                @click="showDeleteConfirmModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="pad25">
                    <div>
                      <div class="title-font is-size-4 mainColor--text">
                        {{ deleteConfirmMessage }}
                      </div>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none ml-2"
                        @click="showDeleteConfirmModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        @click="
                          () => {
                            if (deleteAction) {
                              deleteAction();
                            }
                          }
                        "
                      >
                        <span>Yes</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRegenerateInspirationModal }"
            >
              <div
                class="modal-background"
                @click="showRegenerateInspirationModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div>
                    <div>
                      <div class="title-font is-size-4 mainColor--text">
                        Please tell the AI how to improve
                      </div>
                      <div v-if="!createFreshPromptNotRegen">
                        <v-textarea
                          outlined
                          hide-details
                          color="greenColor"
                          class="mt-5"
                          label="Give instructions on what to add or avoid to make the output better."
                          v-model="regenerateGuideText"
                        >
                        </v-textarea>
                      </div>
                      <div v-if="regenerateSectionName != 'p'">
                        <v-checkbox
                          label="Use the original settings for inspiration and not anything the AI generated"
                          v-model="createFreshPromptNotRegen"
                        ></v-checkbox>
                      </div>
                    </div>
                    <div class="text-right mt-5">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none ml-2"
                        @click="showRegenerateInspirationModal = false"
                      >
                        <span>Cancel</span>
                      </v-btn>
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none ml-2"
                        :disabled="
                          !regenerateGuideText && !createFreshPromptNotRegen
                        "
                        @click="
                          () => {
                            showRegenerateInspirationModal = false;
                            if (mustRunCallback) {
                              scrollToTop();
                              mustRunCallback();
                            }
                          }
                        "
                      >
                        <span>Regenerate</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRichContentItemEditModal }"
            >
              <div
                class="modal-background"
                @click="showRichContentItemEditModal = false"
              ></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div
                    class="modal-content-root"
                    v-if="showRichContentItemEditModal"
                  >
                    <div>
                      <div class="modal-title-size mainColor--text">
                        Edit rich content ( {{ richContentItemToEdit.dispName }} )
                      </div>
                      <div class="mt-5">
                        <!-- {{ JSON.stringify(richContentItemToEdit) }} -->
                        <v-textarea
                          outlined
                          v-model="richContentItemToEdit.currentResult"
                        ></v-textarea>
                      </div>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="() => saveEditedRichContentItem()"
                      >
                        <span>Save</span>
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none ml-2"
                        @click="showRichContentItemEditModal = false"
                      >
                        <span>Close</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              :class="{ 'is-active': showRichContentItemHistoryModal }"
            >
              <div
                class="modal-background"
                @click="showRichContentItemHistoryModal = false"
              ></div>
              <div class="modal-content" style="min-width: 80vw">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div>
                      <div class="modal-title-size mainColor--text">
                        Rich content history
                      </div>
                      <div class="mt-5">
                        <div
                          v-for="(
                            item, idx
                          ) in selectedRichContentItemAIResultHistory"
                          :key="idx"
                          class="history-box"
                        >
                          <div class="result">
                            <div class="timestamp">
                              <span>{{ item.timestamp }}</span>
                            </div>
                            <div>
                              <!-- {{ JSON.stringify(item) }} -->
                              <textarea
                                :value="item.data"
                                :rows="item.rowsRequired || 5"
                                readonly
                              >
                              </textarea>
                              <!-- {{ item.data }} -->
                            </div>
                          </div>
                          <div class="action">
                            <v-btn
                              rounded
                              x-small
                              color="profileAnchorColor"
                              class="text-none whiteColor--text"
                              @click="
                                () =>
                                  loadAndSaveCurrentSelectionForRichContent(
                                    idx,
                                    item
                                  )
                              "
                            >
                              <span>Load</span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right mt-10">
                      <v-btn
                        rounded
                        outlined
                        color="mainColor"
                        class="button-with-icon mainColor--text text-none ml-2"
                        @click="showRichContentItemHistoryModal = false"
                      >
                        <span>Close</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal openai-error-modal"
              :class="{ 'is-active': showErrorModal }"
            >
              <div class="modal-background" @click="showErrorModal = false"></div>
              <div class="modal-content">
                <div class="box modal-container">
                  <div class="modal-content-root">
                    <div
                      class="modal-title-size font-weight-bold mainColor--text"
                    >
                      <template v-if="isOpenAiError">
                        Action Required: OpenAI API Key Issue Detected
                      </template>
                      <template v-else>
                        Action Needed: Contact Support for Account Assistance
                      </template>
                    </div>
                    <div
                      class="mt-5 mainColor--text"
                      v-if="isOpenAiError && errorMsg"
                    >
                      {{ errorMsg }}
                    </div>
                    <div class="mt-5 mainColor--text">
                      <template v-if="isOpenAiError">
                        We've detected a system error related to the OpenAI API
                        key. Please take the following steps to resolve this
                        issue:
                        <ol class="mt-2">
                          <li>
                            Check Your OpenAI Account Limits: Log in to your
                            OpenAI account and verify that you haven't exceeded
                            your
                            <a
                              href="https://platform.openai.com/usage"
                              target="_blank"
                              >usage limits.</a
                            >
                          </li>
                          <li>
                            Generate a New API Key: If your limits are within
                            acceptable ranges, try
                            <a
                              href="https://platform.openai.com/api-keys"
                              target="_blank"
                              >generating a new GPT4 API key from your OpenAI
                              account settings.</a
                            >
                          </li>
                          <li>
                            Update Your API Key: Once you have a new key, update
                            it in Account Setting under the relevant settings
                            section and retry the operation.
                          </li>
                        </ol>
                      </template>
                      <template v-else>
                        We've encountered an issue with your account that needs
                        your immediate attention. This situation requires direct
                        support intervention and cannot be fixed with usual
                        troubleshooting methods. Please follow these steps:
                        <ol class="mt-2">
                          <li>
                            Contact Support: Use the "Contact Support" chat in
                            your account or email our support team directly.
                          </li>
                          <li>
                            Include Account Details: Mention your account email,
                            report ID and a brief description of the problem.
                          </li>
                          <li>
                            Wait for Instructions: Our support team will guide you
                            on how to resolve the issue.
                          </li>
                        </ol>
                        <div class="mt-5">
                          We're here to help get things back on track quickly.
                          Thank you for your prompt action.
                        </div>
                      </template>
                    </div>
                    <div class="text-right mt-5">
                      <v-btn
                        rounded
                        color="profileAnchorColor"
                        class="button-with-icon whiteColor--text text-none"
                        @click="showErrorModal = false"
                      >
                        Ok
                      </v-btn>
                    </div>
                  </div>
                </div>
                <button
                  class="custom-modal-close-btn"
                  aria-label="close"
                  @click="() => (showErrorModal = false)"
                >
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>

            <content-prompt-one-signal-generator-popup
              ref="contnentPromptGenerationModal"
              :aiRunId="aiRun ? aiRun.id : null"
              @completed="
                () => {
                  loadAiRun();
                }
              "
            ></content-prompt-one-signal-generator-popup>
          </div>
        </div>
      </div>
      <span
        class="show-table-of-content-icon"
        v-if="
          currentStep == STEPS.PARAGRAPH &&
          pAIResult.length > 0 &&
          !showLeftSidebar
        "
        @click="
          () => {
            showLeftSidebar = true;
          }
        "
      >
        <span class="material-icons-outlined">menu_open</span>
      </span>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";
import AiWriterSteps from "@/components/PageRun/OpenAIEditor/AiWriterSteps.vue";

import { toLocalDateString } from "@/utils";
import DemoPlans from "@/components/DemoPlans.vue";
import {
  beautifyHtmlString,
  downloadAsHtml,
} from "@/components/PageRun/OpenAIEditor/helper.js";
import {
  STEPS,
  CONTENT_PROMPT_SIGNALS_TOOLTIPS,
} from "@/components/PageRun/OpenAIEditor/AiWriter_constants.js";
import { DEMO_PRICES } from "@/components/PageRun/constants.js";
import { mixin as cb_mixin } from "@/components/PageRun/ContentBrief/cb_mixin";
// import draggable from "vuedraggable";

import { Sortable, MultiDrag } from "sortablejs";
Sortable.mount(new MultiDrag());

// import TinyMceEditor from '@tinymce/tinymce-vue';
import VueTrix from "vue-trix";
// import { TINY_MCE_API_KEY } from '@/utils/config.js'

import { codemirror } from "vue-codemirror";
// import language js
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";

import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/html-hint.js";
import "codemirror/addon/hint/css-hint.js";
import "codemirror/addon/search/search.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/dialog/dialog.js";

// import base style
import "codemirror/lib/codemirror.css";
// import theme style
import "codemirror/theme/material.css";
// codemirror addon
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/matchesonscrollbar.css";

import ContentPromptOneSignalGeneratorPopup from "./ContentPromptOneSignalGeneratorPopup.vue";
import SelectParagraphBox from "./SelectParagraphBox.vue";

const costedContentPromptSignals = [
  "list",
  "Frequently Asked Questions",
  "Table Total",
  
  "meta_description",
  "intro_section",
  "Brief Overview",
  "Key Highlights",
  "end_section",
];

const channel = new BroadcastChannel('silo_ai_writer_channel');

export default {
  name: "OpenAiAssistanceInEditor",
  mixins: [cb_mixin],
  props: [
    "aiRunId",
    "pageRunId",
    "pageRun",
    "runStrategies",
    "page",
    // 'initialData',
    // 'saveOpenAITabData', // function
    "currentWordCount",
    "targetWordCount",
    "forwardResultToEditor",
  ],
  components: {
    DemoPlans,
    Multiselect,
    // draggable,
    // 'tinymce-editor': TinyMceEditor,
    VueTrix,
    codemirror,

    AiWriterSteps,
    ContentPromptOneSignalGeneratorPopup,
    SelectParagraphBox,
  },
  computed: {
    popCharges() {
      return this.$store.state.popCharges;
    },
    popCreditCharges() {
      return this.$store.state.popCreditCharges;
    },
    isAiWriter() {
      return this.$route.query.editorMode === 'AI_MODE';
    },
    projectURL() {
      return `/#/sites/${this.page.siteId}/pages`;
    },
    pageURL() {
      return `${this.projectURL}/${this.page.id}`;
    },
    aiWriterURL() {
      return `${this.pageURL}?tab=ai-writer`;
    },
    reportURL() {
      return `${this.pageURL}/pageruns/${this.pageRun.id}`;
    },
    ownerAvailableCredits () {
      if (this.user) {
          return this.ownerUser.monthlyCredits + this.ownerUser.remainedUniversalCredits
      }
      return 0
    },
    isDemo() {
      return this.$store.getters.isDemoUser;
    },
    pAIResultClone() {
      return JSON.parse(JSON.stringify(this.pAIResult));
    },
    isOpenAiError() {
      return this.errorMsg.toLowerCase().includes("openai");
    },
    pageNotBuilt() {
      if (this.pageRun) {
        return this.pageRun.pageNotBuilt;
      }
      return false;
    },
    CONTENT_PROMPT_SIGNALS_TOOLTIPS() {
      return CONTENT_PROMPT_SIGNALS_TOOLTIPS;
    },
    DEMO_PRICES() {
      return DEMO_PRICES;
    },
    isFailedPageRun() {
      return this.pageRun.status == 'FAILURE'
    },
    canGoNext() {
      if (!this.hasEnoughCredits) {
        return false;
      }
      if (this.isFailedPageRun) {
        return false
      }

      let data;
      // let isValidInput = true;

      switch (this.currentStep) {
        case this.STEPS.SETTING:
          break;
        case this.STEPS.RICH_CONTENT:
          data = this.getCleanedContentPromptUserInput();
          if (!data) {
            return false;
          }
          break;
        default:
          break;
      }
      return true;
    },
    tagTotalData() {
      if (!this.pageRun) {
        return [];
      }
      return this.pageRun.tagCounts;
    },
    scaleFactorForRichContent() {
      if (this.targetWordCount > 0) {
        return (this.numberOfWords * 1.0) / this.targetWordCount;
      }
      return 1;
    },
    richContentItemsForAIWriter() {
      const allowed_signals = [
        "Brief Overview",
        "Key Highlights",
        "Bold Tag Total",
        "Italic Tag Total",
        "Image File total",
        "list",
        "Table Total",
        "Form Total",
        "Frequently Asked Questions",
        "Table of content",
        "Internal links",
      ];
      const must_1_signals = [
        "Frequently Asked Questions",
        "Table of content",
        
        "meta_description",
        "Brief Overview",
        "Key Highlights",
        "intro_section",
        "end_section",
        "Internal links",
      ];

      const customDispNames = {
        list: "Lists",
        "Table Total": "Tables",
        "Table of content": "Table of Contents",
        "Frequently Asked Questions": "Frequently Asked Questions",
        meta_description: "Meta description",
        intro_section: "Introduction paragraph",
        "Brief Overview": "Brief Overview",
        "Key Highlights": "Key Highlights",
        end_section: "Conclusion paragraph",
        "Internal links": "Internal links",
      };

      let signalsFound = [];
      let rlt = this.pageStructureRecommendations.filter((item) => {
        let isAllowedSignal = allowed_signals.includes(item.signalName);
        if (isAllowedSignal) {
          signalsFound.push(item.signalName);
        }
        return isAllowedSignal;
      });

      let signalsToAddManuallyIfMissed = [
        "Brief Overview",
        "Key Highlights",
        "list",
        "Table Total",
        "Frequently Asked Questions",
        "Table of content",

        "meta_description",
        "intro_section",
        "end_section",
        "Internal links",
      ];
      signalsToAddManuallyIfMissed.map((signal) => {
        if (!signalsFound.includes(signal)) {
          rlt.push({
            signalName: signal,
            targetMin: 5,
            targetMax: 10,
          });
        }
      });

      rlt = rlt.map((item) => {
        let scaleFactor = this.scaleFactorForRichContent;
        console.log("rich content scale", item.targetMax, scaleFactor);
        item.targetMin = Math.ceil(item.targetMin * scaleFactor);
        item.targetMax = Math.ceil(item.targetMax * scaleFactor);
        console.log("after: ", item.targetMax);
        if (must_1_signals.includes(item.signalName)) {
          item.isYesNoSignal = true;
          item.targetMin = 0;
          item.targetMax = 1;
        }
        if (item.signalName === 'Internal links') {
          item.targetMin = 0;
          item.targetMax = 3;
        }
        if (Object.keys(customDispNames).includes(item.signalName)) {
          item.dispName = customDispNames[item.signalName];
        }
        return item;
      });
      return this.reorderItems(rlt);
    },
    pageTitlelabel() {
      if (this.separateSearchEngineTitleAndTitle) {
        return "Page title";
      } else {
        return "Search engine title and page title";
      }
    },
    isUsingOwnApiKey() {
      return this.isSubAccountRelationSelected
        ? this.ownerUser.openAIKey && this.ownerUser.useMyOwnApiKey
        : this.user.openAIKey && this.user.useMyOwnApiKey;
    },
    estimatedCreditsRequired() {
      let cost = this.isUsingOwnApiKey
        ? this.popCreditCharges.aiWriterCreditsFlatRateOwnApiKey
        : this.popCreditCharges.aiWriterCreditsPer1000Words * Math.ceil(this.numberOfWords / 1000);



      let countData = this.selectedCountForPromptSignals;
      !this.isUsingOwnApiKey && this.selectedContentPromptsSignals
        .filter((signalName) =>
          this.costedContentPromptSignals.includes(signalName)
        )
        .map((signalName) => {
          let count = countData[signalName];
          if (typeof count == "number") {
            cost += count;
          } else {
            console.log("testing for type", typeof count);
          }
        });
      return cost;
    },
    user() {
      return this.$store.state.user;
    },
    onlyForViewPurpose() {
      if (this.user && this.aiRun) {
        return this.user.isAdmin && this.user.id != this.aiRun.userId && !this.isSubAccountRelationSelected;
      }
      return false;
    },
    isSubAccountRelationSelected() {
      return !!this.$store.state.selectedSubAccountRelation;
    },
    ownerUser() {
      return this.$store.state.selectedSubAccountRelation || this.user;
    },
    ownerAvailableCredits() {
      if (this.ownerUser) {
        return (
          this.ownerUser.monthlyCredits +
          this.ownerUser.remainedUniversalCredits
        );
      }
      return 0;
    },
    hasEnoughCredits() {
      try {
        return this.ownerAvailableCredits - this.estimatedCreditsRequired >= 0;
      } catch {
        return false;
      }
    },
    titleAIResultHistory() {
      if (this.aiRun) {
        return this.aiRun.searchEngineTitleResult;
      }
      return [];
    },
    pageTitleAIResultHistory() {
      if (this.aiRun) {
        return this.aiRun.pageTitleResult;
      }
      return [];
    },
    subheadingsAIResultHistory() {
      if (this.aiRun) {
        return this.aiRun.subheadingsResult.map((item) => {
          item.dataAsList = item.data.split("\n");
          return item;
        });
      }
      return [];
    },
    selectedParagraphSectionAIResultHistory() {
      if (
        this.aiRun &&
        this.aiRun.paragraphResult.length > this.seletedParagraphSection
      ) {
        return this.aiRun.paragraphResult[this.seletedParagraphSection];
      }
      return [];
    },

    selectedRichContentItemAIResultHistory() {
      if (!this.aiRun || !this.contentPromptAiResults) {
        return [];
      }
      try {
        let { key, uniqueIndex } = this.selectedRichContentItemData;
        let dataForOneSignal = this.contentPromptAiResults[key];
        if (!dataForOneSignal) {
          console.log(
            "not found the the key signal in data",
            key,
            this.contentPromptAiResults
          );
          return [];
        }
        let found = dataForOneSignal.find(
          (item) => item.uniqueIndex == uniqueIndex
        );
        if (found) {
          return found.aiResults.map((item) => {
            item.data = beautifyHtmlString(item.data);
            let rowsRequired = item.data.split("\n").length;
            item.rowsRequired = rowsRequired > 10 ? 10 : rowsRequired;
            return item;
          });
        }
        return [];
      } catch (e) {
        console.log(e);
      }
      return [];
    },

    isSpecialAdminUser() {
      return this.$store.getters.isSpecialAdminUser;
    },
    cleanedContentBrief() {
      let found = (this.runStrategies || []).find(
        (item) => item.dashboardType == "contentBrief"
      );
      if (found) {
        return found;
      }
      return {};
    },
    fullHtml() {
      let titleTag = "";
      let pageTitleTag = "";
      if (this.separateSearchEngineTitleAndTitle) {
        titleTag = this.titleAIResult;
        pageTitleTag = this.pageTitleAIResult;
      } else {
        let tags = this.pageTitleAIResult.split("\n");
        titleTag = tags.length >= 1 ? tags[0] : "";
        pageTitleTag = tags.length >= 2 ? tags[1] : "";
      }

      let metaDescription = "";

      let subHeadingTags =
        this.pAIResult.length > 0 ? "" : this.subHeadingsAIResult;
      let sectionData = this.pAIResult.map((item, idx) => {
        if (item.metaSection) {
          metaDescription = item.answer;
          return "";
        }

        let rlt = "";

        if (item.introSection || item.endSection) {
          let sectionResult = item.answer.trim();
          if (!sectionResult.startsWith("<p>")) {
            rlt += `<p>${item.answer}</p>`;
          } else {
            rlt += `${item.answer}`;
          }
        } else {
          let subheadingTag = item["subheadingInfo"]["tagName"];
          let subheadingText = item["subheadingInfo"]["tagText"];
          let sectionResult = item.answer.trim();

          rlt += `<${subheadingTag}>${subheadingText}</${subheadingTag}>`;

          if (!sectionResult.startsWith("<p>")) {
            rlt += `<p>${item.answer}</p>`;
          } else {
            rlt += `${item.answer}`;
          }
        }

        rlt += "\n";
        let allContentPromptDataForSection =
          this.getAllContentPromptDataForPSection(idx);
        allContentPromptDataForSection.map((item) => {
          rlt += `<div>Rich content results: ${item.dispName}</div>\n`;
          switch (item.shortenKey) {
            case "form":
              rlt += `<form><!-- your form here --></form>\n`;
              break;
            case "image":
              rlt += `<img src="your image file link here" alt="your image file link here" />\n`;
              break;
            case "list":
            case "table":
            case "faq":
              if (!item.isEmpty) {
                rlt += `${item.resultToDisplay || item.currentResult}\n`;
              }
              break;
            default:
              rlt += `${item.currentResult}\n`;
              break;
          }
          rlt += "\n";
        });
        return rlt;
      });

      let result = `
        <!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              ${titleTag}
              ${metaDescription}
            </head>
            <body>
              ${pageTitleTag}
              ${subHeadingTags}
              ${sectionData.join(`\n`)}
            </body>
          </html>
      `;
      return result;
    },
    contentPromoptPlaceHolderData() {
      // this is about content promopt signals which are just place holder
      if (this.aiRun) {
        return this.aiRun.contentPromoptPlaceHolderData;
      }
      return {};
    },
    contentPromptAiResults() {
      // content promopt signals which is not place holder (generates with ai)
      if (this.aiRun) {
        return this.aiRun.contentPromptAiResults;
      }
      return {};
    },
    shouldDisplayTableOfContent() {
      if (this.aiRun) {
        return this.aiRun.shouldDisplayTableOfContent;
      }
      return false;
    },
    userInputedParagraphWordCounts() {
      return this.userInputParagraphs.map((item) => {
        return item.split(/\s+/).filter(Boolean).length;
      });
    },
  },
  watch: {
    // pageRun: function (newVal, oldVal) {
    // },
    pAIResultClone: {
      handler(newVal, oldVal) {
        this.$store.commit("setAiWriterNeedsToSave", true);
        // console.log('testing for vue trix change')
        // console.log(newVal, oldVal)
        // this.handleParagraphEditDebounced(idx, data)

        if (Array.isArray(newVal) && Array.isArray(oldVal)) {
          newVal.map((item, idx) => {
            if (oldVal.length > idx && item.answer != oldVal[idx].answer) {
              console.log("found difff", idx, item.answer);
              this.handleParagraphEditDebounced(idx, item.answer);
            }
          });
        }
      },
      deep: true,
    },
    currentStep: function (newVal) {
      this.$store.commit("setAiWriterStep", newVal);
      if (newVal !== STEPS.SUBHEADINGS) {
        return;
      }
      this.initSortableList();
    },
    optimizeCurrentPageTitle: function (newVal, oldVal) {
      if (newVal) {
        this.usePageTitleInspirationText = false;
        this.useMyCurrentPageTitle = false;
      }
    },
    optimizeCompetitorPageTitle: function (newVal, oldVal) {
      if (newVal) {
        this.usePageTitleInspirationText = false;
        this.useMyCurrentPageTitle = false;
      }
    },
    usePageTitleInspirationText: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentPageTitle = false;
        this.optimizeCompetitorPageTitle = false;
        this.useMyCurrentPageTitle = false;
      }
    },
    useMyCurrentPageTitle: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentPageTitle = false;
        this.optimizeCompetitorPageTitle = false;
        this.usePageTitleInspirationText = false;
      }
    },

    optimizeCurrentTitle: function (newVal, oldVal) {
      if (newVal) {
        this.useTitleInspirationText = false;
        this.useMyCurrentTitle = false;
      }
    },
    optimizeCompetitorTitle: function (newVal, oldVal) {
      if (newVal) {
        this.useTitleInspirationText = false;
        this.useMyCurrentTitle = false;
      }
    },
    useTitleInspirationText: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentTitle = false;
        this.optimizeCompetitorTitle = false;
        this.useMyCurrentTitle = false;
      }
    },
    useMyCurrentTitle: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentTitle = false;
        this.optimizeCompetitorTitle = false;
        this.useTitleInspirationText = false;
      }
    },

    optimizeCurrentH2s: function (newVal, oldVal) {
      if (newVal) {
        this.useMySubheadings = false;
      }
    },
    optimizeCompetitorsH2s: function (newVal, oldVal) {
      if (newVal) {
        this.useMySubheadings = false;
      }
    },
    useMySubheadings: function (newVal, oldVal) {
      if (newVal) {
        this.optimizeCurrentH2s = false;
        this.optimizeCompetitorsH2s = false;
      }
    },

    fullHtml: function (newVal, oldVal) {
      // just emit html but don't update
      this.$emit("onCalcChecklist", {
        aiRunId: this.aiRun ? this.aiRun.id : 0,
        htmlStr: newVal,
        updateScore: false,
      });
    },
    calculated_subHeadingsAIResultArr: function (newVal, oldVal) {
      if (newVal) {
        this.userInputParagraphs = Array(
          this.calculated_subHeadingsAIResultArr.length
        ).fill("");
        this.initSortableList();
      }
    },
    isInputParagraphByUser: function (newVal, oldVal) {
      if (newVal) {
        this.userInputParagraphs = Array(
          this.calculated_subHeadingsAIResultArr.length
        ).fill("");
      }
    },
    selectedWordCountOption: function (newVal, oldVal) {
      let wordCount = 0;
      switch (newVal) {
        case "currentWordCount":
          wordCount = this.currentWordCount;
          break;
        case "targetWordCount":
          wordCount = this.targetWordCount;
          break;
        case "customWordCount":
          wordCount = 500;
          break;
      }
      this.numberOfWords = wordCount;
    },
    currentWordCount: function (newVal, oldVal) {
      if (this.selectedWordCountOption == "currentWordCount") {
        this.numberOfWords = newVal;
      }
    },

    taskId: function (newVal, oldVal) {
      if (oldVal != newVal && newVal) {
        if (this.taskInterval) {
          this.clearTaskInterval(true);
        }
        this.taskInterval = setInterval(() => {
          this.$store.dispatch("getTaskStatus", newVal).then((response) => {
            if (response.data && response.data.status) {
              if (response.data.status == "SUCCESS") {
                let sectionName = this.runningSection;
                if (this.currentSection === "p" || this.currentSection === "subHeadings") {
                  channel.postMessage('ai_writer_updated');
                }
                console.log(response.data);
                if (response.data.targetUrlSourceCode) {
                  this.targetUrlSourceCode = response.data.targetUrlSourceCode;
                }

                if (sectionName == "title") {
                  this.titleAIResult = response.data.aiAnswer;
                  this.allLogs.push(
                    ">>>>>Search engine title prompt\n" + response.data.prompt
                  );
                  this.allLogs.push(
                    ">>>>>Search engine title Answer\n" + response.data.aiAnswer
                  );
                } else if (sectionName == "pageTitle") {
                  this.pageTitleAIResult = response.data.aiAnswer;
                  this.allLogs.push(
                    ">>>>>Page title prompt\n" + response.data.prompt
                  );
                  this.allLogs.push(
                    ">>>>>Page title Answer\n" + response.data.aiAnswer
                  );
                } else if (sectionName == "subHeadings") {
                  this.subHeadingsAIResult = response.data.aiAnswer;
                  this.maxRecommendedSubheadingsCount =
                    response.data.maxRecommendedSubheadingsCount;
                  if (response.data.prompt) {
                    this.allLogs.push(
                      ">>>>>Subheadings prompt\n" + response.data.prompt
                    );
                    if (response.data.promptAdminMessages) {
                      this.allLogs.push(
                        "\n>>>>Subheadings admin prompts\n" +
                          response.data.promptAdminMessages
                            .map((item) => item?.content || JSON.stringify(item))
                            .join(`\n${".".repeat(10)}\n`)
                      );
                    }
                    this.allLogs.push(
                      ">>>>>Subheadings Answer\n" + response.data.aiAnswer
                    );
                  }
                } else if (sectionName == "p") {
                  if (Array.isArray(response.data.aiAnswer) || typeof response.data.aiAnswer === 'object') {
                    response.data.aiAnswer.forEach((item) => {
                      if (item?.briefOverview) {
                        this.allLogs.push(
                          ">>>>>Brief Overview prompt\n" + item.prompt
                        );
                        this.allLogs.push(
                          ">>>>>Brief Overview Answer\n" + item.answer
                        );
                      } else if (item?.keyTakeaways) {
                        this.allLogs.push(
                          ">>>>>Key Takeaways prompt\n" + item.prompt
                        );
                        this.allLogs.push(
                          ">>>>>Key Takeaways Answer\n" + item.answer
                        );
                      }
                    });
                  }
                  const pSectionId = response.data.pSectionId;
                  if (pSectionId > -1) {
                    this.pAIResult = this.pAIResult.map((item, idx) => {
                      if (idx == pSectionId) {
                        item["answer"] = response.data.aiAnswer;
                        if (response.data.prompt) {
                          this.allLogs.push(
                            ">>>>>Paragraph section retry prompt\n" +
                              response.data.prompt
                          );
                          this.allLogs.push(
                            ">>>>>Paragraph section retry answer\n" +
                              response.data.aiAnswer
                          );
                        }
                      }
                      return item;
                    });
                  } else {
                    this.pAIResult = response.data.aiAnswer;

                    if (this.isInputParagraphByUser) {
                      // collapse once we got result.
                      this.isExpandInputParagraphSection = false;
                    }

                    if (response.data.prompt1) {
                      this.allLogs.push(
                        ">>>>>Paragraph first phase prompt prompt\n" +
                          response.data.prompt1
                      );
                      this.allLogs.push(
                        ">>>>>Paragraph first phase AI answer\n" +
                          response.data.prompt1Result
                      );
                    }
                    if (response.data.prompt2) {
                      this.allLogs.push(
                        ">>>>>Paragraph second phase prompts\n" +
                          response.data.prompt2.join(`\n${".".repeat(10)}\n`)
                      );
                    }

                    if (response.data.promptAdminMessages) {
                      this.allLogs.push(
                        ">>>>Paragraph second phase admin prompts\n" +
                          response.data.promptAdminMessages
                            .map((item) => item?.content || JSON.stringify(item))
                            .join(`\n${".".repeat(10)}\n`)
                      );
                    }
                    // this.allLogs.push('>>>>>Paragraph answers\n' + response.data.aiAnswer.join(`\n${ '.'.repeat(10) }\n`))
                  }
                }

                if (response.data.targetUrlSourceCode) {
                  this.targetUrlSourceCode = response.data.targetUrlSourceCode;
                }
                if (response.data.competitorsSourceCode) {
                  this.competitorsSourceCode =
                    response.data.competitorsSourceCode;
                }
                if (response.data.targetUrlParagraphs) {
                  this.targetUrlParagraphs = response.data.targetUrlParagraphs;
                }
                if (response.data.competitorsParagraphs) {
                  this.competitorsParagraphs =
                    response.data.competitorsParagraphs;
                }
                if (!response.data.pSectionId) {
                  this.scrollToBottom();
                }

                this.clearTaskInterval();
                if (response.data.error) {
                  this.showErrorModalAction(response.data.error);
                  this.allLogs.push(">>>>> Error\n" + response.data.error);
                } else {
                  this.$notify({
                    group: "info",
                    type: "success",
                    text: "Success!",
                  });
                  this.loadAiRun();
                }
              } else if (response.data && response.data.status == "FAILURE") {
                this.showErrorModalAction(response.data.msg);
                this.clearTaskInterval();
              } else {
                let newProgress = 0;
                if (response.data && response.data.value) {
                  if (typeof response.data.value == "number") {
                    newProgress = response.data.value;
                  }
                }
                if (newProgress > this.progress) {
                  this.progress = newProgress;
                } else {
                  newProgress = this.progress + this.progressIncreaseAmount;
                  if (newProgress >= 90) {
                    this.progressIncreaseAmount = 0.08;
                  }
                  if (newProgress > 98) newProgress = 98;
                  this.progress = newProgress;
                }
              }
            }
          });
        }, 3000);
      }
    },
    titleAIResult: function (newVal, oldVal) {
      if (this.separateSearchEngineTitleAndTitle) {
        this.calculated_titleAIResult = this.getTagInfoFromHtmlTag(newVal);
      }
    },
    pageTitleAIResult: function (newVal, oldVal) {
      if (this.separateSearchEngineTitleAndTitle) {
        this.calculated_pageTitleAIResult = this.getTagInfoFromHtmlTag(newVal);
      } else {
        let data = newVal.split("\n");
        if (data.length >= 2) {
          this.calculated_titleAIResult = this.getTagInfoFromHtmlTag(data[0]);
          this.calculated_pageTitleAIResult = this.getTagInfoFromHtmlTag(
            data[1]
          );
        } else {
          this.calculated_titleAIResult = "";
          this.calculated_pageTitleAIResult = "";
        }
      }
    },
    subHeadingsAIResult: function (newVal, oldVal) {
      let rlt = [];
      if (!newVal) {
        this.calculated_subHeadingsAIResultArr = [];
      } else {
        rlt = newVal.trim().split("\n");
        this.calculated_subHeadingsAIResultArr = rlt.map((item, idx) => {
          return {
            ...this.getTagInfoFromHtmlTag(item),
            originIndex: idx,
          };
        });
      }
    },
    userInputParagraphs: function (newVal, oldVal) {
      if (oldVal.length != newVal.length && newVal.length > 0) {
        this.selectedRevisions = Array(newVal.length).fill("medium");
      }
    },
  },
  data() {
    return {
      currentSection: '',
      sortableActive: false,
      showList: true,
      // TINY_MCE_API_KEY: TINY_MCE_API_KEY,
      // wysiwygConfigTinyMce: {
      //   height: '200px',
      //   inline: false,
      //   fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 34px 38px 42px 48px 54px 60px",
      //   plugins: 'print preview powerpaste searchreplace autolink directionality advcode visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount tinymcespellchecker a11ychecker mediaembed linkchecker textpattern help',
      //   toolbar1: 'code fullscreen | fontsizeselect | bold italic | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | undo redo',
      //   image_advtab: true,
      //   // theme: 'dark',
      //   content_css: [
      //     '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
      //     '//www.tinymce.com/css/codepen.min.css'
      //   ],
      //   // content_css: 'dark',
      //   menubar: false,
      //   branding: false,
      //   verify_html : false,
      // },
      hoveredItem: null,
      activeEditSubHeading: null,
      cmOptions: {
        tabSize: 4,
        // mode: "text/javascript",
        // mode: "text/html",
        mode: "htmlmixed",
        // theme: "material",
        theme: "default",
        lineNumbers: true,
        lineWrapping: true,
        line: true,
        height: "200px",
        // more CodeMirror options...
      },
      codeMirrorTheme: "default", // default, "material", "abcdef"

      costedContentPromptSignals: costedContentPromptSignals,

      aiRun: null,
      currentStep: 0, // 0 start button only, 1, search engine title 2 page titie, 3 subheadings, 4 paragraph
      STEPS: STEPS,
      WORDCOUNT_OPTIONS: [
        { value: "currentWordCount", label: "Current word count" },
        { value: "targetWordCount", label: "Target word count" },
        { value: "customWordCount", label: "Custom word count" },
      ],
      selectedWordCountOption: "currentWordCount",
      numberOfWords: 0,

      selectedCompetitors: [],
      selectedContentPromptsSignals: [],
      selectedCountForPromptSignals: {},

      topLevelInternalLink: "",
      supportingLevelInternalLink1: "",
      supportingLevelInternalLink2: "",

      webPageType: "",
      webPageTypeOptions: [
        {
          value: "article",
          text: "Article",
        },
        {
          value: "blog",
          text: "Blog post",
        },
        {
          value: "product",
          text: "Product page",
        },
        {
          value: "category",
          text: "Category page",
        },
        {
          value: "product-category",
          text: "Product category page",
        },
        {
          value: "service",
          text: "Service page",
        },
      ],
      excludeTerms: [],

      // these needed to avoid duplicated scraping.
      targetUrlSourceCode: "",
      competitorsSourceCode: [],

      targetUrlParagraphs: [],
      competitorsParagraphs: [],
      // targetUrlParagraphs: [
      //   'This is just for the testing thing as if blable ia 12',
      //   'This is just for the testing thing as if blable ia qwqwqw',
      //   'This is just for the testing thing as if blable ia aaa',
      // ],
      // competitorsParagraphs: [
      //   [
      //     'competitor 1 thing paragraph 1',
      //     'competitor 1 thing paragraph 2',
      //     'competitor 1 thing paragraph 3',
      //   ],
      //   [
      //     'competitor 2 thing paragraph 1',
      //     'competitor 2 thing paragraph 2',
      //     'competitor 2 thing paragraph 3',
      //   ],
      // ],

      separateSearchEngineTitleAndTitle: false,

      useTitleInspirationText: false,
      titleInspirationText: "",
      useMyCurrentTitle: false,
      optimizeCurrentTitle: true,
      optimizeCompetitorTitle: true,

      usePageTitleInspirationText: false,
      pagetitleInspirationText: "",
      useMyCurrentPageTitle: false,
      optimizeCurrentPageTitle: true,
      optimizeCompetitorPageTitle: true,

      useMySubheadings: false,
      optimizeCurrentH2s: true,
      optimizeCompetitorsH2s: true,

      isInputParagraphByUser: false, // true - user will input paragraph, and we will make prompt with it. false - for now temp
      isExpandInputParagraphSection: false,
      userInputParagraphs: [],
      toneOfContent: "",
      author: "",
      brand: "",

      targetAudience: "",
      productDescription: "",
      sampleContent: "",

      titlePrompt: "",
      pageTitlePrompt: "",
      subHeadingsPrompt: "",
      // pPromptPhase1: '',
      // pPromptPhase2: '',

      titleAIResult: "",
      pageTitleAIResult: "",
      subHeadingsAIResult: "",
      pAIResult: [],
      maxRecommendedSubheadingsCount: 0,
      // pageTitleAIResult: testPageTitleAIResult,
      // subHeadingsAIResult: testSubheadingsAIResult,
      // pAIResult: testPresult,

      calculated_titleAIResult: "",
      calculated_pageTitleAIResult: "",
      calculated_subHeadingsAIResultArr: [],

      isLoading: false,
      progress: 0,
      progressIncreaseAmount: 0,

      runningSection: "",
      taskId: "",
      taskInterval: null,
      showNextButtonWillUpdateScoreModal: false,

      showTitleHistoryModal: false,
      showPageTitleHistoryModal: false,
      showSubheadingsHistoryModal: false,
      showParagraphOneSectionHistoryModal: false,

      showEditAIResultModal: false,
      selectedSection: "",
      seletedParagraphSection: 0,
      editResultText: "",

      editInOutlineInfo: {},
      showDeleteConfirmModal: false,
      deleteConfirmMessage: "",
      deleteAction: null,
      showAddSubheadingsModal: false,
      newSubHeadingTag: "h2",
      newSubheadingText: "",

      collapsedSections: [], // in final page only

      allLogs: [],
      showLogModal: "",
      subHeadProgress: 0,
      titleProgress: 0,
      mainContentProgress: 0,

      showExceedNumberOfWordsModal: false,

      regenerateFreeWarnAcknowledged: false,
      showRegenerateFreeWarnModal: false,

      showRegenerateInspirationModal: false,
      regenerateSectionName: "",
      regenerateGuideText: "",
      createFreshPromptNotRegen: false,

      regenerateChargeWarnAcknowledged: false,
      showRegenerateChargeWarnModal: false,

      mustRunCallback: null,

      showDisclaimerModal: false,
      confirmedDisclaim: false,
      showWarnForEditNowAllowdAfterParagraphGenerationModal: false,

      showRichContentItemEditModal: false,
      richContentItemToEdit: {},

      showRichContentItemHistoryModal: false,
      selectedRichContentItemData: {},

      availableRevisions: [
        {
          text: "Minimal revision (POP AI will just naturally add target keywords to the provided content)",
          value: "minimum",
        },
        {
          text: "Medium revision (POP AI will re-write provided content but will mainly keep the order and the structure of sentences)",
          value: "medium",
        },
        {
          text: "Maximum revision (POP AI will use provided content only as a source of inspiration and will completely re-write it)",
          value: "maximum",
        },
      ],
      willSelectRevisionIndividually: false,
      selectedRevisionForAllParagraphs: "medium",
      selectedRevisions: {},
      currentInputParagraphIndex: -1,
      editingParagraphIdxInResult: -1,

      showLeftSidebar: false,

      showErrorModal: false,
      errorMsg: "",
    };
  },
  methods: {
    reorderItems(items) {
      // Define the new order for the dispNames or label fields
      const newOrder = [
        "Meta description",
        "Introduction paragraph",
        "Table of content",
        "Brief Overview",
        "Key Highlights"
      ];

      const endItems = ["Frequently Asked Questions", "Conclusion paragraph", "Internal links"];

      // Create empty arrays for ordered and remaining items
      let orderedItems = [];
      let remainingItems = [];
      let closingItems = [];

      // Add items in the order specified by newOrder
      newOrder.forEach(name => {
        const item = items.find(i => i.dispName === name || i.label === name);
        if (item) orderedItems.push(item);
      });

      // Add remaining items that are not in newOrder or endItems
      remainingItems = items.filter(
        i =>
          !newOrder.includes(i.dispName) &&
          !newOrder.includes(i.label) &&
          !endItems.includes(i.dispName) &&
          !endItems.includes(i.label)
      );

      // Add "Frequently Asked Questions" and "Conclusion paragraph" at the end
      closingItems = items.filter(i => endItems.includes(i.dispName) || endItems.includes(i.label));

      // Combine ordered, remaining, and end items
      return [...orderedItems, ...remainingItems, ...closingItems];
    },
    setNeedsToSave() {
      this.$store.commit("setAiWriterNeedsToSave", true);
    },
    setActiveEditItem(_, name) {
      this.activeEditSubHeading = name;
      this.$store.commit("setAiWriterNeedsToSave", true);
    },
    handleClickOutside(e) {
      console.log(
        e.target.className,
        "ai-subheading",
        this.activeEditSubHeading,
        "ai-outline-" + e.target.id
      );

      if (e.target.className.split(" ").includes("action")) {
        return;
      }
      if (
        e.target.className === "ai-subheading" ||
        this.activeEditSubHeading === "ai-outline-" + e.target.id
      ) {
        return;
      }
      if ("ai-title" === e.target.id || "ai-page-title" === e.target.id) {
        return;
      }

      this.cancelEditItemInOutlineFor("subHeadings");
      this.cancelEditItemInOutlineFor("pageTitle");
      this.cancelEditItemInOutlineFor("title");
      this.activeEditSubHeading = null;
    },
    mouseenter(id) {
      this.hoveredItem = id;
    },
    mouseleave() {
      this.hoveredItem = null;
    },
    focusInput() {
      setTimeout(() => {
        document.getElementById("input").focus();
      }, 10);
      this.$store.commit("setAiWriterNeedsToSave", true);
    },
    editSubheading(e, item) {
      if (this.editInOutlineInfo && this.editInOutlineInfo.section) {
        this.cancelEditItemInOutlineFor(this.editInOutlineInfo.section);
      }
      this.editInOutlineInfo = {
        section: "subHeadings",
        originIndex: item.originIndex,
      };
      this.focusInput();
    },
    showErrorModalAction(errorMsg) {
      this.errorMsg = errorMsg;
      this.showErrorModal = true;
    },
    fakeProgress(keyName, nextStep) {
      this.isLoading = true;

      this[keyName] = 1;
      const interval = setInterval(() => {
        this[keyName] += 20;
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        const stateKey = {
          titleProgress: "isDemoTitleDone",
          subHeadProgress: "isDemoHeadingsDone",
          mainContentProgress: "isDemoMainContentDone",
        }[keyName];
        this.$store.commit("setDemoStepDone", stateKey);
        this.isLoading = false;
        if (nextStep) this.currentStep = nextStep;
      }, 5100);
    },
    downloadAIResultAsDocx() {
      let data = {
        htmlStr: this.fullHtml,
      };
      this.$store.dispatch("downloadDocxForAiWriter", data).then((response) => {
        if (response.data && response.status == 200) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = URL.createObjectURL(blob);
          var element = document.createElement("a");
          element.setAttribute("href", url);
          let fullFileName = `ai-article-${toLocalDateString(
            this.aiRun.createdAt
          )}-${this.aiRun.id}.docx`;
          element.setAttribute("download", fullFileName);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else {
          console.log("FAILED");
        }
      });
    },
    scrollToTop() {
      this.$nextTick(() => {
        this.$refs.aiWriterRef.scrollTop = 0;
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.aiWriterRef.scrollTop = this.$refs.aiWriterRef.scrollHeight;
      });
    },
    textToCssId(text) {
      // return text.replaceAll(' ', '').replaceAll('  ', '')
      return text.replace(/[^a-zA-Z0-9]/g, "_");
    },
    isValidInternalLink(v) {
      return v?.length ? v.startsWith(this.page?.siteBaseURL) : true
    },
    gotoParagraphSection(text) {
      this.$nextTick(() => {
        let found = document.querySelector(`#${this.textToCssId(text)}`);
        if (found) {
          this.$refs.aiWriterRef.scrollTop = found.offsetTop;
          let foundIndex = -1;
          for (let i = 0; i < this.pAIResult.length; i++) {
            const item = this.pAIResult[i];
            if (
              item["subheadingInfo"] &&
              item["subheadingInfo"]["tagText"] == text
            ) {
              foundIndex = i;
              break;
            }
          }
          console.log("ASDFASDFASDF", foundIndex);
          if (foundIndex != -1) {
            this.editingParagraphIdxInResult = foundIndex;
          }
        }
      });
    },
    getCostForContentPromptSignal(signalName) {
      if (this.isUsingOwnApiKey) {
        return 0;
      }
      if (!this.costedContentPromptSignals.includes(signalName)) {
        return 0;
      }
      if (this.selectedContentPromptsSignals.includes(signalName)) {
        return this.selectedCountForPromptSignals[signalName] || 0;
      }
      return 0;
    },

    getAllContentPromptDataForPSection(idx) {
      function shortenKey(key) {
        let shortenKeys = {
          "Form Total": "form",
          "Image File total": "image",
          list: "list",
          "Table Total": "table",
          "Frequently Asked Questions": "faq",
        };
        if (Object.keys(shortenKeys).includes(key)) {
          return shortenKeys[key];
        }
        return key;
      }
      function dispName(key) {
        let shortenKeys = {
          "Form Total": "Form",
          "Image File total": "Image",
          list: "List",
          "Table Total": "Table",
          "Frequently Asked Questions": "FAQs",
        };
        if (Object.keys(shortenKeys).includes(key)) {
          return shortenKeys[key];
        }
        return key;
      }
      function contentPromptSignalWeightForOrdering(key) {
        let weights = {
          "Form Total": 1,
          "Image File total": 100,
          list: 100,
          "Table Total": 100,
          "Frequently Asked Questions": 0,
        };
        if (Object.keys(weights).includes(key)) {
          return weights[key];
        }
        return key;
      }

      let keys = Object.keys(this.contentPromoptPlaceHolderData || {});
      let rlt = [];
      for (const key of keys) {
        let count =
          this.contentPromoptPlaceHolderData[key].length > idx
            ? this.contentPromoptPlaceHolderData[key][idx]
            : 0;
        if (count > 0) {
          rlt.push({
            key: key,
            shortenKey: shortenKey(key),
            dispName: dispName(key),
            weight: contentPromptSignalWeightForOrdering(key),
            // count: count // this is max 1 so not needed currently
          });
        }
      }

      keys = Object.keys(this.contentPromptAiResults || {});
      for (const key of keys) {
        let dataForOneSignal = this.contentPromptAiResults[key];
        dataForOneSignal
          .filter((item) => item.pIndex == idx)
          .map((item) => {
            rlt.push({
              key: key,
              shortenKey: shortenKey(key),
              dispName: dispName(key),
              isEmpty: item.aiResults.length == 0,
              currentResult:
                item.aiResults.length > item.selectedIndex
                  ? item.aiResults[item.selectedIndex].data
                  : "",
              uniqueIndex: item.uniqueIndex,
            });
          });
      }

      // pre processing for display formatting
      rlt = rlt.map((item) => {
        if (item.shortenKey == "faq") {
          item.resultToDisplay = item.currentResult.replaceAll("\n", "<br />");
        }
        return item;
      });

      rlt.sort((a, b) => {
        return b.weight - a.weight;
      });

      return rlt;
    },
    getTagInfoFromHtmlTag(htmlStr) {
      // console.log('getTagInfoFromHtmlTag', htmlStr)
      const parser = new DOMParser();
      let doc = parser.parseFromString(htmlStr, "text/html");
      let tag = doc.querySelector("title, body > *");
      if (tag) {
        return {
          tagName: tag.tagName,
          shortenTagName: tag.tagName == "TITLE" ? "T" : "",
          text: tag.textContent || "H1 not found",
        };
      } else {
        return {};
      }
    },
    competitorWordCount(c) {
      if (!c) return 0;
      let data = c.rawKeywordCounts || [];
      let wordCount = data["word_count"] || 0;
      return wordCount;
    },
    getUserSettingData(key) {
      if (this.user) {
        const settings = Object.assign({}, this.user.settings);
        return settings[key];
      }
      return null;
    },
    addDataToUserSetting(key, value) {
      if (this.user) {
        const settings = Object.assign({}, this.user.settings);
        settings[key] = value;

        this.$store
          .dispatch("updateUserSettings", settings)
          .then((response) =>
            this.$store.commit("setUserSettings", response.data)
          )
          .finally(() => {});
      }
    },
    needsPulsing(step) {
      if (this.currentStep != step) {
        return false;
      }
      if (this.isLoading) {
        return false;
      }

      switch (step) {
        case this.STEPS.SEARCH_ENGINE_TITLE:
          if (this.titleAIResult) return false;
          break;
        case this.STEPS.PAGE_TITLE:
          if (this.pageTitleAIResult) return false;
          break;
        case this.STEPS.SUBHEADINGS:
          if (this.subHeadingsAIResult) return false;
          break;
        case this.STEPS.PARAGRAPH:
          if (this.pAIResult.length > 0) return false;
      }
      return true;
    },
    startOverAgain() {
      this.aiRun = null;
      this.currentStep = 0;
      this.selectedTargetUrlParagraphs = [];
      this.separateSearchEngineTitleAndTitle = false;

      this.titleInspirationText = "";
      this.useMyCurrentTitle = false;
      if (this.pageRun?.pageNotBuilt) {
        this.optimizeCurrentTitle = false;
      } else {
        this.optimizeCurrentTitle = true;
      }

      this.optimizeCompetitorTitle = false;

      this.pagetitleInspirationText = "";
      this.useMyCurrentPageTitle = false;
      if (this.pageRun?.pageNotBuilt) {
        this.optimizeCurrentPageTitle = false;
      } else {
        this.optimizeCurrentPageTitle = true;
      }
      this.optimizeCompetitorPageTitle = true;

      this.useMySubheadings = false;
      if (this.pageRun?.pageNotBuilt) {
        this.optimizeCurrentH2s = false;
      } else {
        this.optimizeCurrentH2s = true;
      }
      this.optimizeCompetitorsH2s = true;

      this.targetUrlParagraphs = [];
      this.competitorsParagraphs = [];

      this.isInputParagraphByUser = false;
      this.userInputParagraphs = [];
      this.toneOfContent = "";
      this.author = "";
      this.brand = "";
      this.webPageType = "";
      this.excludeTerms = [];

      this.topLevelInternalLink = "";
      this.supportingLevelInternalLink1 = "";
      this.supportingLevelInternalLink2 = "";

      this.targetAudience = ""
      this.productDescription = ""
      this.sampleContent = ""

      this.willSelectRevisionIndividually = false;
      this.selectedRevisionForAllParagraphs = "medium";

      this.titleAIResult = "";
      this.pageTitleAIResult = "";
      this.subHeadingsAIResult = "";
      this.pAIResult = [];

      this.selectedCompetitors = [];
      this.selectedContentPromptsSignals = [];
      this.selectedCountForPromptSignals = {};
      this.currentInputParagraphIndex = -1;
      this.editingParagraphIdxInResult = -1;
      this.showLeftSidebar = false;

      this.isLoading = false;
      this.progress = 0;
      this.taskId = "";

      if (this.pageNotBuilt) {
        this.selectedWordCountOption = "targetWordCount";
        // selectedWordCountOption watch will not be executed. so need this.
        this.numberOfWords = this.targetWordCount;
      } else {
        this.selectedWordCountOption = "currentWordCount";
        // selectedWordCountOption watch will not be executed. so need this.
        this.numberOfWords = this.currentWordCount;
      }
      this.confirmedDisclaim = false;
      this.$store.commit("setCurrentAIRun", null);
    },
    dragEndedForSubheadings() {
      console.log("drag ended!!", this.calculated_subHeadingsAIResultArr);
      this.saveEditedSubheadings();
    },
    saveEditedTitle() {
      let rlt = "";
      let data = [];

      data.push(this.calculated_titleAIResult);
      if (!this.separateSearchEngineTitleAndTitle) {
        data.push(this.calculated_pageTitleAIResult);
      }
      rlt = data
        .map((item) => {
          let tagName = item.tagName.toLowerCase();
          let text = item.text;
          return `<${tagName}>${text}</${tagName}>`;
        })
        .join("\n");

      this.selectedSection = this.separateSearchEngineTitleAndTitle
        ? "title"
        : "pageTitle";
      this.editResultText = rlt;
      this.saveEditedAIResultAction();
    },
    saveEditedPageTitle() {
      let rlt = "";
      let data = [];
      if (!this.separateSearchEngineTitleAndTitle) {
        data.push(this.calculated_titleAIResult);
      }
      data.push(this.calculated_pageTitleAIResult);
      rlt = data
        .map((item) => {
          let tagName = item.tagName.toLowerCase();
          let text = item.text;
          return `<${tagName}>${text}</${tagName}>`;
        })
        .join("\n");

      this.selectedSection = "pageTitle";
      this.editResultText = rlt;
      console.log("haha will save this.", rlt);
      this.saveEditedAIResultAction();
    },
    saveEditedSubheadings() {
      let rlt = this.calculated_subHeadingsAIResultArr
        .map((item) => {
          let tagName = item.tagName.toLowerCase();
          let text = item.text;
          return `<${tagName}>${text}</${tagName}>`;
        })
        .join("\n");
      this.selectedSection = "subHeadings";
      this.editResultText = rlt;
      this.saveEditedAIResultAction();
    },
    handleParagraphEdit(idx, data) {
      console.log(idx, data);
      this.$store
        .dispatch("aiRunSaveEditedContent", {
          aiRunId: this.aiRun.id,
          sectionName: "p",
          editedContent: data,
          pSectionIndex: idx,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
            this.$store.commit("setAiWriterNeedsToSave", false);
          }
        });
    },
    loadAndSaveCurrentSelectionForSection(sectionName, selectedIndex, item) {
      this.showPageTitleHistoryModal = false;
      this.showParagraphOneSectionHistoryModal = false;

      switch (sectionName) {
        case "title":
          this.titleAIResult = item.data;
          break;
        case "pageTitle":
          this.pageTitleAIResult = item.data;
          break;
        case "subHeadings":
          this.subHeadingsAIResult = item.data;
          break;
        case "p":
          this.pAIResult[this.seletedParagraphSection]["answer"] = item.answer;
          break;
      }

      let data = {
        aiRunId: this.aiRun.id,
        sectionName,
        selectedIndex,
      };
      if (sectionName == "p") {
        data["pSectionIndex"] = this.seletedParagraphSection;
      }

      this.$store
        .dispatch("aiRunChangeCurrentSelection", data)
        .then((response) => {
          console.log(response.data);
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
          }
        });
    },
    showEditAIResultAction(sectionName, pIndex = null) {
      this.selectedSection = sectionName;
      switch (sectionName) {
        case "title":
          this.editResultText = this.titleAIResult;
          break;
        case "pageTitle":
          this.editResultText = this.pageTitleAIResult;
          break;
        case "subHeadings":
          this.editResultText = this.subHeadingsAIResult;
          break;
        case "p":
          this.editResultText = this.pAIResult[pIndex]["answer"];
          break;
      }
      if (pIndex) {
        this.seletedParagraphSection = pIndex;
      }
      this.showEditAIResultModal = true;
    },
    saveEditedAIResultAction() {
      if (this.onlyForViewPurpose) {
        return;
      }

      switch (this.selectedSection) {
        case "title":
          this.titleAIResult = this.editResultText;
          break;
        case "pageTitle":
          this.pageTitleAIResult = this.editResultText;
          break;
        case "subHeadings":
          this.subHeadingsAIResult = this.editResultText;
          break;
        case "p":
          this.pAIResult[this.seletedParagraphSection]["answer"] =
            this.editResultText;
          break;
      }
      this.$store
        .dispatch("aiRunSaveEditedContent", {
          aiRunId: this.aiRun.id,
          sectionName: this.selectedSection,
          editedContent: this.editResultText,
          pSectionIndex: this.seletedParagraphSection,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
            this.$store.commit("setAiWriterNeedsToSave", false);
          }
        });
      this.showEditAIResultModal = false;
    },
    loadAiRun(isFirstTimeLoad = false) {
      this.$store
        .dispatch("loadAiRun", {
          pageRunId: this.pageRunId,
          aiRunId:
            this.aiRunId && isFirstTimeLoad
              ? this.aiRunId
              : this.aiRun
              ? this.aiRun.id
              : null,
        })
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            let aiRun = response.data.aiRun;
            this.$store.commit("setCurrentAIRun", aiRun);

            this.targetUrlParagraphs = aiRun.targetUrlParagraphs;
            this.competitorsParagraphs = aiRun.competitorsParagraphs;

            this.separateSearchEngineTitleAndTitle =
              aiRun.isSeparateTitleAndPagetitle;

            this.selectedContentPromptsSignals = aiRun.selectedPromptSignals;
            this.selectedCountForPromptSignals = aiRun.selectedPromptSignalsWithCount;

            if (
              aiRun.searchEngineTitleResult.length >
              aiRun.selectedSearchEngineTitleIndex
            ) {
              this.titleAIResult =
                aiRun.searchEngineTitleResult[
                  aiRun.selectedSearchEngineTitleIndex
                ]["data"];
            }
            if (aiRun.pageTitleResult.length > aiRun.selectedPageTitleIndex) {
              this.pageTitleAIResult =
                aiRun.pageTitleResult[aiRun.selectedPageTitleIndex]["data"];
            }
            if (
              aiRun.subheadingsResult.length > aiRun.selectedSubheadingsIndex
            ) {
              this.subHeadingsAIResult =
                aiRun.subheadingsResult[aiRun.selectedSubheadingsIndex]["data"];
            }

            if (aiRun.paragraphResult.length > 0) {
              this.pAIResult = aiRun.paragraphResult.map((item, idx) => {
                const selectedPos = aiRun.selectedParagraphIndexes[idx];
                return JSON.parse(JSON.stringify(item[selectedPos]));
              });
            }

            if (aiRun.wordCount > 0) {
              this.numberOfWords = aiRun.wordCount;
            }

            this.toneOfContent = aiRun.toneOfContent;
            this.author = aiRun.author;
            this.brand = aiRun.brand;

            this.targetAudience = aiRun.targetAudience;
            this.productDescription = aiRun.productDescription;
            this.sampleContent = aiRun.sampleContent;

            this.topLevelInternalLink = aiRun.topLevelInternalLink
            this.supportingLevelInternalLink1 = aiRun.supportingLevelInternalLink1
            this.supportingLevelInternalLink2 = aiRun.supportingLevelInternalLink2

            console.log("hh testing it.", this.aiRun, aiRun);
            if (isFirstTimeLoad) {
              // first load
              console.log(
                "this is ai run first load. so need to update step.",
                aiRun.lastStep
              );
              this.goToSpecificPage(
                aiRun.lastStep > 0
                  ? aiRun.lastStep
                  : this.separateSearchEngineTitleAndTitle
                  ? 1
                  : 2,
                true
              );
              this.$nextTick(() => {
                this.$emit("onCalcChecklist", {
                  aiRunId: this.aiRun ? this.aiRun.id : 0,
                  htmlStr: this.fullHtml,
                  updateScore: true,
                });
              });

              if (aiRun.runningSection && aiRun.runningTaskId) {
                this.updateRunningSection(
                  aiRun.runningSection,
                  aiRun.runningTaskId
                );
              }
            }
            this.aiRun = aiRun;
          } else if (response.data && response.data.status == "FAILURE") {
            if (this.pageRun.pageNotBuilt) {
              this.optimizeCurrentTitle = false;
              this.optimizeCurrentPageTitle = false;
              this.optimizeCurrentH2s = false;
            }
          }
        });
    },
    // indexForSection(sectionIndex) {
    //   const sectionLabel = Object.keys(this.STEPS).find(key => this.STEPS[key] == sectionIndex)
    //   let stepIndexWithoutSeparate =  {
    //     // 'SEARCH_ENGINE_TITLE':1,
    //     'PAGE_TITLE': 1,
    //     'SUBHEADINGS': 2,
    //     'PARAGRAPH': 3,
    //   }
    //   let stepIndex = {
    //     'SEARCH_ENGINE_TITLE': 1,
    //     'PAGE_TITLE': 2,
    //     'SUBHEADINGS': 3,
    //     'PARAGRAPH': 4,
    //   }
    //   if (this.separateSearchEngineTitleAndTitle) {
    //     return stepIndex[sectionLabel]
    //   } else {
    //     return stepIndexWithoutSeparate[sectionLabel]
    //   }

    // },

    // getRulesForSection(sectionName) {
    //   let rules = []
    //   let cbData = []
    //   if (this.cleanedContentBrief) {
    //     cbData = this.cleanedContentBrief[`${sectionName}`] || []
    //     console.log(cbData);
    //   }

    //   rules = cbData.map(item => {
    //     console.log(item)
    //     let count = 0
    //     if ('target' in item.contentBrief) {
    //       count = item.contentBrief.target
    //     } else {
    //        count = Math.round((item.contentBrief.targetMin + item.contentBrief.targetMax) / 2)
    //     }
    //     return {
    //       term: item.term.phrase,
    //       count: count,
    //     }
    //   })
    //   return rules;
    // },
    calculateProgressAmount(sectionName, isImproveOneSection) {
      this.progress = 0;

      let amount = 0;
      switch (sectionName) {
        case "title":
        case "pageTitle":
          amount = 6;
          break;
        case "subHeadings":
          if (this.competitorsSourceCode.length > 1) {
            amount = 3;
          } else {
            amount = 1.2;
          }
          break;
        case "p":
          if (isImproveOneSection) {
            amount = 6;
          }
          if (this.competitorsSourceCode.length > 1) {
            amount = 0.8;
          } else {
            amount = 0.5;
          }
          break;
        default:
          amount = 1;
      }
      this.progressIncreaseAmount = amount;
    },
    clearProgress() {
      this.isLoading = false;
      this.progress = 0;
      this.taskId = "";
      this.runningSection = "";
    },
    clearTaskInterval(intervalOnly = false) {
      if (this.taskInterval) {
        clearInterval(this.taskInterval);
      }
      if (!intervalOnly) {
        this.clearProgress();
      }
    },
    updateRunningSection(sectionName, taskId, improveForOnesection) {
      this.calculateProgressAmount(sectionName, improveForOnesection);
      this.isLoading = true;
      this.progress = 0;
      this.runningSection = sectionName;
      this.taskId = taskId;
    },
    regenerateForParagraphOneSection(idx) {
      let item = this.pAIResult[idx];
      this.showWarningModalForGenerate("p", () =>
        this.generateResultFor("p", item, idx)
      );
    },
    isRegenerateForSection(sectionName) {
      switch (sectionName) {
        case "title":
          return this.titleAIResult ? true : false;
        case "pageTitle":
          return this.pageTitleAIResult ? true : false;
        case "subHeadings":
          return this.subHeadingsAIResult ? true : false;
        case "p":
          return this.pAIResult && this.pAIResult.length > 0 ? true : false;
      }
    },
    showRegenerateInspirationModalAction() {
      this.showRegenerateInspirationModal = true;
      this.regenerateGuideText = "";
      this.createFreshPromptNotRegen = false;
    },
    showWarningModalForGenerate(sectionName, callback) {
      if (!this.aiRun || !this.isRegenerateForSection(sectionName)) {
        callback();
        return;
      }
      this.regenerateSectionName = sectionName;
      this.mustRunCallback = callback;

      if (this.aiRun.useMyOwnApiKey) {
        // don't need to display the
        this.showRegenerateInspirationModalAction();
        return;
      }

      if (this.aiRun.remainedFreeRegenerate == 0) {
        if (
          !this.getUserSettingData("doNotDisplay_AIRegenerateChargeWarnModal")
        ) {
          this.showRegenerateChargeWarnModal = true;
        } else {
          this.showRegenerateInspirationModalAction();
        }
      } else {
        if (
          !this.getUserSettingData("doNotDisplay_AIRegenerateFreeWarnModal")
        ) {
          this.showRegenerateFreeWarnModal = true;
        } else {
          this.showRegenerateInspirationModalAction();
        }
      }
    },
    proceedRegenerateFree() {
      this.showRegenerateFreeWarnModal = false;
      if (this.regenerateFreeWarnAcknowledged) {
        this.addDataToUserSetting(
          "doNotDisplay_AIRegenerateFreeWarnModal",
          true
        );
      }
      this.showRegenerateInspirationModalAction();

      // if (this.mustRunCallback) {
      //   this.mustRunCallback();
      // }
    },
    proceedRegenerateCharge() {
      this.showRegenerateChargeWarnModal = false;
      if (this.regenerateChargeWarnAcknowledged) {
        this.addDataToUserSetting(
          "doNotDisplay_AIRegenerateChargeWarnModal",
          true
        );
      }
      this.showRegenerateInspirationModalAction();
      // if (this.mustRunCallback) {
      //   this.mustRunCallback();
      // }
    },
    getCleanedContentPromptUserInput() {
      let data = {};
      let isValid = true;
      this.selectedContentPromptsSignals.map((signalName) => {
        let targetVal = this.selectedCountForPromptSignals[signalName] || 0;
        let found = this.richContentItemsForAIWriter.find(
          (item) => item.signalName == signalName
        );
        if (!found || targetVal <= 0 || targetVal > found.targetMax) {
          isValid = false;
        }
        data[signalName] = targetVal;
      });
      if (!isValid) {
        return isValid;
      }
      return data;
    },
    generateResultFor(sectionName, pSection = null, pSectionId = -1) {
      if (this.onlyForViewPurpose) {
        return;
      }

      if (sectionName == "title") {
        this.titleInspirationText = this.titleInspirationText.trim();
      }
      if (sectionName == "pageTitle") {
        this.pagetitleInspirationText = this.pagetitleInspirationText.trim();
      }

      let contentPromptsUserInput = this.getCleanedContentPromptUserInput();
      let openAIParamters = {
        titleInspirationText: this.useTitleInspirationText
          ? this.titleInspirationText
          : "",
        useMyCurrentTitle: this.useMyCurrentTitle,
        optimizeCurrentTitle: this.optimizeCurrentTitle,
        optimizeCompetitorTitle: this.optimizeCompetitorTitle,

        pagetitleInspirationText: this.usePageTitleInspirationText
          ? this.pagetitleInspirationText
          : "",
        useMyCurrentPageTitle: this.useMyCurrentPageTitle,
        optimizeCurrentPageTitle: this.optimizeCurrentPageTitle,
        optimizeCompetitorPageTitle: this.optimizeCompetitorPageTitle,

        useMySubheadings: this.useMySubheadings,
        optimizeCurrentH2s: this.optimizeCurrentH2s,
        optimizeCompetitorsH2s: this.optimizeCompetitorsH2s,

        pSection: pSection, // this means for improve for 1 section
        pSectionId: pSectionId,
        makeFreshRun: this.aiRun == null,
        aiRunId: this.aiRun ? this.aiRun.id : null,
        contentPromptsData: contentPromptsUserInput || {},

        isRegenerate: this.isRegenerateForSection(sectionName),
        regenerateGuideText: this.createFreshPromptNotRegen
          ? ""
          : this.regenerateGuideText,
        createFreshPromptNotRegen: this.createFreshPromptNotRegen,
      };

      // hkghkghkg for test
      // console.log(openAIParamters)
      // return;

      // let rules = []
      // if (!pSection) {
      //   rules = this.getRulesForSection(sectionName)
      // }
      const revisions = this.willSelectRevisionIndividually
        ? this.selectedRevisions
        : Array(this.userInputParagraphs.length).fill(
            this.selectedRevisionForAllParagraphs
          );
      let data = {
        ...openAIParamters,

        pageRunId: this.pageRunId,
        targetUrlSourceCode: this.targetUrlSourceCode,
        competitorsSourceCode: this.competitorsSourceCode,
        numberOfWords: this.numberOfWords > 4000 ? 4000 : this.numberOfWords,
        selectedCompetitors: this.selectedCompetitors,
        // rules: rules,
        sectionName: sectionName,
        separateSearchEngineTitleAndTitle:
          this.separateSearchEngineTitleAndTitle,

        titleAIResult: this.titleAIResult,
        pageTitleAIResult: this.pageTitleAIResult, // this will be used for subheadings and p prompt generation
        subHeadingsAIResult: this.subHeadingsAIResult,
        isInputParagraphByUser: this.isInputParagraphByUser,
        userInputParagraphs: this.isInputParagraphByUser
          ? this.userInputParagraphs
          : [],
        aiParagraphRevisions: revisions,
        toneOfContent: this.toneOfContent,
        author: this.author,
        brand: this.brand,
        webPageType: this.webPageType,
        excludeTerms: this.excludeTerms,

        ...this.selectedContentPromptsSignals.includes('Internal links')
          ?{
            topLevelInternalLink: this.topLevelInternalLink,
            supportingLevelInternalLink1: this.supportingLevelInternalLink1,
            supportingLevelInternalLink2: this.supportingLevelInternalLink2,
          }
          :{},

        targetAudience: this.targetAudience,
        productDescription: this.productDescription,
        sampleContent: this.sampleContent,

        justGiveAIAnswer: 1,
        currentStep: this.currentStep,
      };

      if (sectionName == "p" && !pSection) {
        this.$notify({
          group: "info",
          type: "success",
          duration: 60000,
          closeOnClick: true,
          text: "Depending on the length of your content, this section may take up to 3 minutes to complete.",
        });
      }

      this.runningSection = sectionName;
      this.isLoading = true; // this is needed to make progressbar appear asap
      this.$store
        .dispatch("getPromptForAI", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.currentSection = sectionName;
            this.updateRunningSection(
              sectionName,
              response.data.taskId,
              !!pSection
            );
          } else {
            this.clearProgress();
          }
        })
        .finally(() => {});
    },
    goNext(buttonStep) {
      if (this.isDemo) {
        this.currentStep += this.currentStep === 3 ? 2 : 1;
        return;
      }
      console.log("current is in", this.currentStep);
      if (buttonStep > this.STEPS.SELECT_COMPETITORS) {
        this.$emit("onCalcChecklist", {
          aiRunId: this.aiRun ? this.aiRun.id : 0,
          htmlStr: this.fullHtml,
          updateScore: true,
        });
      }

      let nextStep = 0;
      switch (buttonStep) {
        case this.STEPS.START:
          nextStep = this.STEPS.SETTING;
          break;
        case this.STEPS.SETTING:
          if (this.numberOfWords > 4000) {
            this.showExceedNumberOfWordsModal = true;
            return;
          }
          if (this.numberOfWords < 500) {
            this.$notify({
              group: "info",
              type: "warning",
              text: "Minimum word count you can select for a generation task is 500 words. Please select higher word count.",
            });
            return;
          }

          if (!this.hasEnoughCredits) return;
          if (
            this.numberOfWords == this.targetWordCount ||
            this.confirmedDisclaim
          ) {
            nextStep = this.STEPS.RICH_CONTENT;
            // pre select comeptitors
            let preSelectFocusCompetitors = [];
            let preSelectFirst3Competitors = [];
            this.pageRun.competitors.map((c) => {
              if (c.isFocus) {
                preSelectFocusCompetitors.push(c.id);
              }
              if (preSelectFirst3Competitors.length < 3) {
                preSelectFirst3Competitors.push(c.id);
              }
            });
            this.selectedCompetitors =
              preSelectFocusCompetitors.length > 0
                ? preSelectFocusCompetitors
                : preSelectFirst3Competitors;
          } else {
            this.showDisclaimerModal = true;
          }
          break;
        case this.STEPS.RICH_CONTENT:
          nextStep = this.STEPS.SELECT_COMPETITORS;
          break;
        case this.STEPS.SELECT_COMPETITORS:
          if (this.separateSearchEngineTitleAndTitle) {
            nextStep = this.STEPS.SEARCH_ENGINE_TITLE;
          } else {
            nextStep = this.STEPS.PAGE_TITLE;
          }
          break;
        case this.STEPS.SEARCH_ENGINE_TITLE:
          nextStep = this.STEPS.PAGE_TITLE;
          break;
        case this.STEPS.PAGE_TITLE:
          nextStep = this.STEPS.SUBHEADINGS;
          break;
        case this.STEPS.SUBHEADINGS:
          nextStep = this.STEPS.PARAGRAPH;
          break;
        case this.STEPS.PARAGRAPH:
          nextStep = this.STEPS.PARAGRAPH;
          break;
      }

      if (
        buttonStep == this.STEPS.SEARCH_ENGINE_TITLE ||
        buttonStep == this.STEPS.PAGE_TITLE
      ) {
        if (
          !this.getUserSettingData(
            "doNotDisplay_ScoreAutoUpdateInNextClickOnAIModal"
          )
        ) {
          this.showNextButtonWillUpdateScoreModal = true;
          this.addDataToUserSetting(
            "doNotDisplay_ScoreAutoUpdateInNextClickOnAIModal",
            true
          );
        }
      }

      if (nextStep >= buttonStep) {
        this.currentStep = nextStep;
        this.scrollToTop();
      }
      console.log("done done????");
    },
    goBack(buttonStep = -1) {
      console.log("current is in", this.currentStep);

      if (buttonStep == -1) {
        buttonStep = this.currentStep;
        if (buttonStep < 0) {
          buttonStep = 0;
        }
      }

      let newStep = -1;
      switch (buttonStep) {
        case this.STEPS.START:
          newStep = this.STEPS.START;
          break;
        case this.STEPS.SETTING:
          newStep = this.STEPS.START;
          break;
        case this.STEPS.RICH_CONTENT:
          newStep = this.STEPS.SETTING;
          break;
        case this.STEPS.SELECT_COMPETITORS:
          newStep = this.STEPS.RICH_CONTENT;
          break;
        case this.STEPS.SEARCH_ENGINE_TITLE:
          newStep = this.STEPS.SELECT_COMPETITORS;
          break;
        case this.STEPS.PAGE_TITLE:
          if (this.separateSearchEngineTitleAndTitle) {
            newStep = this.STEPS.SEARCH_ENGINE_TITLE;
          } else {
            newStep = this.STEPS.SELECT_COMPETITORS;
          }
          break;
        case this.STEPS.SUBHEADINGS:
          newStep = this.STEPS.PAGE_TITLE;
          break;
        case this.STEPS.PARAGRAPH:
          newStep = this.STEPS.SUBHEADINGS;
          break;
      }

      if (newStep >= 0) {
        this.currentStep = newStep;
      }
    },
    goToSpecificPage(targetStep, isFristTimeLoad = false) {
      console.log("current is in", this.currentStep);

      // let buttonStep = targetStep;

      let newStep = targetStep;

      if (isFristTimeLoad) {
        this.currentStep = this.isDemo ? 0 : newStep;
        this.$store.commit("setAiWriterStep", 0);
        return;
      }

      if (newStep == this.currentStep) {
        this.$notify({
          group: "info",
          type: "warning",
          text: "You are already on this step.",
        });
        return;
      }
      if (this.aiRun && this.aiRun.lastStep + 1 < newStep) {
        this.$notify({
          group: "info",
          type: "error",
          text: "You cannot go to this step directly.",
        });
        return;
      }
      if (
        this.currentStep == STEPS.PARAGRAPH
      ) {
        this.$notify({
          group: "info",
          type: "warning",
          text: "Once content is generated you cannot go back to this step.  To change settings, you will need to start over which will cost more credits.",
        });
        return;
      }
      this.currentStep = newStep;
    },
    // getAIResultAsPlainText () {

    // },
    async downloadOutlines() {
      let data = "";
      if (this.separateSearchEngineTitleAndTitle) {
        data += this.titleAIResult;
        data += "\n" + this.pageTitleAIResult;
      } else {
        data += this.pageTitleAIResult;
      }
      data += "\n\n" + this.subHeadingsAIResult;

      console.log(
        "ai test",
        this.titleAIResult,
        this.pageTitleAIResult,
        this.subHeadingsAIResult
      );

      if (data) {
        let fileName = `ai-article-outlines-${toLocalDateString(
          this.aiRun.createdAt
        )}-${this.aiRun.id}.txt`;
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(data)
        );
        element.setAttribute("download", fileName);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    },
    async downloadAIResult(sectionName, format = "") {
      let fullFileName = "";
      if (sectionName == "all") {
        if (format == "html") {
          fullFileName = `ai-article-${toLocalDateString(
            this.aiRun.createdAt
          )}-${this.aiRun.id}.html`;
          downloadAsHtml(fullFileName, this.fullHtml);
        } else if (["txt", "txt_plain"].includes(format)) {
          let data = "";
          if (format == "txt") {
            fullFileName = `ai-article-${toLocalDateString(
              this.aiRun.createdAt
            )}-${this.aiRun.id}-html txt.txt`;
            data = beautifyHtmlString(this.fullHtml);
          } else if (format == "txt_plain") {
            fullFileName = `ai-article-${toLocalDateString(
              this.aiRun.createdAt
            )}-${this.aiRun.id}-plain txt.txt`;
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.fullHtml, "text/html");
            data = doc.body.innerText;
            // const allTags = doc.body.querySelectorAll('*')
            // data += doc.title + '\n\n';
            // for (const tag of allTags) {
            //   data += tag.textContent
            //   const tagName = tag.tagName.toLowerCase()
            //   let delimiter = '';
            //   if (tagName == 'h1') {
            //     delimiter = '\n\n\n';
            //   } else if (tagName == 'p') {
            //     delimiter = '\n\n';
            //   } else {
            //     delimiter = '\n';
            //   }
            //   data += delimiter;
            // }
          }

          let element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(data)
          );
          element.setAttribute("download", fullFileName);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }
        return;
      }

      let getPDataForDownload = () => {
        let pData = "";
        this.pAIResult.map((item) => {
          pData += `${item["subheadingInfo"]["tagName"]}: ${item["subheadingInfo"]["tagText"]}\n`;
          pData += `${item.answer}\n\n`;
        });
        return pData;
      };

      let data = "";

      switch (sectionName) {
        case "title":
          data = this.titleAIResult;
          break;
        case "pageTitle":
          data = this.pageTitleAIResult;
          break;
        case "subHeadings":
          data = this.subHeadingsAIResult;
          break;
        case "p":
          data = getPDataForDownload();
          break;
      }

      if (data) {
        let fileName = `ai-article-${sectionName}-${toLocalDateString(
          this.aiRun.createdAt
        )}-${this.aiRun.id}.txt`;
        var element = document.createElement("a");
        element.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(data)
        );
        element.setAttribute("download", fileName);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    },
    showContnentPromptGenerationModal(
      isFirstGenerate,
      key,
      shortenKey,
      uniqueIndex
    ) {
      this.$refs.contnentPromptGenerationModal.showModalAction(
        isFirstGenerate,
        key,
        shortenKey,
        uniqueIndex
      );
    },
    showEditPopupForRichItem(item) {
      this.showRichContentItemEditModal = true;
      this.richContentItemToEdit = item;
    },
    saveEditedRichContentItem() {
      console.log("rich item edited", this.richContentItemToEdit);
      let data = {
        aiRunId: this.aiRun ? this.aiRun.id : 0,
        signalName: this.richContentItemToEdit.key,
        uniqueIndex: this.richContentItemToEdit.uniqueIndex,
        editedContent: this.richContentItemToEdit.currentResult,
      };

      console.log("data sending to the backend", data);
      this.$store.commit("showLoading");
      this.$store
        .dispatch("aiRunUpdateEditedContentForRichItem", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
          } else {
            this.$notify({
              group: "info",
              type: "error",
              text: "Failed to saving your edited content",
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
          this.showRichContentItemEditModal = false;
        });
    },
    showHistoryForRichItem(key, shortenKey, uniqueIndex) {
      console.log(key, shortenKey, uniqueIndex);
      this.showRichContentItemHistoryModal = true;
      this.selectedRichContentItemData = {
        key,
        shortenKey,
        uniqueIndex,
      };
    },
    loadAndSaveCurrentSelectionForRichContent(selectedIndex, item) {
      let { key, uniqueIndex } = this.selectedRichContentItemData;
      let data = {
        aiRunId: this.aiRun.id,
        signalName: key,
        uniqueIndex,
        selectedIndex,
      };

      console.log("test", data);
      this.$store.commit("showLoading");
      this.$store
        .dispatch("aiRunChangeCurrentSelectionForRichItem", data)
        .then((response) => {
          if (response.data && response.data.status == "SUCCESS") {
            this.aiRun = response.data.aiRun;
          } else {
            console.log("failed to load this rich content history");
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
          this.showRichContentItemHistoryModal = false;
        });
    },
    isTitleOrPageTitleInEditingMode(sectionName) {
      return (
        this.editInOutlineInfo && this.editInOutlineInfo.section == sectionName
      );
    },
    isOutlineItemInEditingMode(sectionName, item) {
      return (
        this.editInOutlineInfo &&
        this.editInOutlineInfo.section == sectionName &&
        this.editInOutlineInfo.originIndex == item.originIndex
      );
    },
    cancelEditItemInOutlineFor(sectionName) {
      if (
        this.editInOutlineInfo &&
        this.editInOutlineInfo.section == sectionName
      ) {
        this.editInOutlineInfo = {};
      }

      if (sectionName == "title") {
        this.saveEditedTitle();
      }
      if (sectionName == "pageTitle") {
        this.saveEditedPageTitle();
      }
      if (sectionName == "subHeadings") {
        this.saveEditedSubheadings();
      }
    },
    showDeleteConfirmModalAction(msg, func) {
      this.showDeleteConfirmModal = true;
      this.deleteConfirmMessage = msg;
      this.deleteAction = func;
    },
    deleteSubHeadingSelected() {
      console.log("deleteSubHeadingSelected", this.editInOutlineInfo);
      if (this.editInOutlineInfo) {
        this.calculated_subHeadingsAIResultArr =
          this.calculated_subHeadingsAIResultArr.filter(
            (item) => item.originIndex != this.editInOutlineInfo.originIndex
          );
      }
      this.saveEditedSubheadings();
      this.editInOutlineInfo = {};
      this.showDeleteConfirmModal = false;
    },
    addNewSubheadingsAction() {
      let originIndex = 0;
      this.calculated_subHeadingsAIResultArr.map((item) => {
        if (item.originIndex > originIndex) {
          originIndex = item.originIndex;
        }
      });
      originIndex++;

      this.calculated_subHeadingsAIResultArr.push({
        tagName: this.newSubHeadingTag,
        text: this.newSubheadingText,
        originIndex: originIndex,
      });
      this.saveEditedSubheadings();
      this.showAddSubheadingsModal = false;
    },

    addNewExcludeTerm(termsStr) {
      termsStr = termsStr.trim().toLowerCase();
      if (!termsStr) {
        return;
      }
      const excludeTerms = this.excludeTerms.slice();

      let terms = termsStr.split(",").map((item) => item.trim());

      terms.map((term) => {
        if (!excludeTerms.includes(term)) {
          excludeTerms.push(term);
        }
      });
      this.excludeTerms = excludeTerms;
    },
    handleRemoveExcludeTerm(p) {
      this.excludeTerms = this.excludeTerms.filter((term) => term != p);
    },
    initSortableList() {
      // if (!this.sortableActive)
      this.sortableActive = new Sortable(this.$refs.sortableList, {
        multiDrag: true,
        handle: ".drag-indicator",
        selectedClass: "selected", // CSS class for selected item
        animation: 150, // Animation duration in milliseconds
        // draggable: ".outline-row-subheading",
        // dataIdAttr: "data-id",

        onStart: () => {
          this.drag = true;
        },
        onEnd: async (evt) => {
          this.showList = false;
          this.sortableActive = null;

          const draggedItemsIDs = evt.items?.length
            ? evt.items.map((item) => item.id.split("-")[2])
            : [evt.item.id.split("-")[2]];
            
          const draggedItems = this.calculated_subHeadingsAIResultArr.filter(
            (item) => draggedItemsIDs.includes(item.originIndex.toString())
          );
          const tempData = this.calculated_subHeadingsAIResultArr.filter(
            (item) => !draggedItemsIDs.includes(item.originIndex.toString())
          );

          const finalData = [];
          if (evt.newIndex > tempData.length) {
            finalData.push(...tempData);
            finalData.push(...draggedItems);
          } else {
            tempData.forEach((item, i) => {
              if (i === evt.newIndex) {
                finalData.push(...draggedItems);
              }
              finalData.push(item);
            });
          }
          this.calculated_subHeadingsAIResultArr = finalData.map((el, id) => ({
            ...el,
            originIndex: id,
          }));

          this.drag = false;
          await this.dragEndedForSubheadings();

          // this.loadAiRun(true);

          this.showList = true;
        },
      });
    },
    countWords(value) {
      return value?.split?.(' ')?.filter?.(x => x !== '')?.length || 0
    }
  },
  updated() {
    if (this.$refs.sortableList) this.initSortableList();
  },
  beforeDestroy() {
    // this.sortableActive.destroy();
    // this.sortableActive = null;

    document.removeEventListener("click", this.handleClickOutside);

    console.log("save data to parent");
    // if (this.saveOpenAITabData) {
    //   this.saveOpenAITabData({
    //     temperature: this.temperature,
    //     maxTokens: this.maxTokens,
    //     topP: this.topP,
    //     frequencyPenalty: this.frequencyPenalty,
    //     presencePenalty: this.presencePenalty,
    //     // these needed to avoid duplicated scraping.
    //     targetUrlSourceCode: this.targetUrlSourceCode,
    //     competitorsSourceCode: this.competitorsSourceCode,

    //     pageTitlePrompt: this.pageTitlePrompt,
    //     subHeadingsPrompt: this.subHeadingsPrompt,
    // pPromptPhase1: this.pPromptPhase1,
    // pPromptPhase2: this.pPromptPhase2,

    //     pageTitleAIResult: this.pageTitleAIResult,
    //     subHeadingsAIResult: this.subHeadingsAIResult,
    //     pAIResultPhase1: this.pAIResultPhase1,
    //     pAIResultPhase2: this.pAIResultPhase2,
    //   })
    // }
  },
  mounted() {
    if (this.$route.query.selectTargetWC === 'true') {
      this.selectedWordCountOption = "targetWordCount";
    }

    if (
      this.selectedWordCountOption == "currentWordCount" &&
      this.currentWordCount == 0
    ) {
      this.selectedWordCountOption = "targetWordCount"
    }
    if (this.$refs.sortableList) this.initSortableList();

    document.addEventListener("click", this.handleClickOutside);
    // if (this.initialData) {
    //   Object.keys(this.initialData).map(key => {
    //     if (key in this) {
    //       this[key] = this.initialData[key]
    //     }
    //   })
    // }
    if (this.isDemo) {
      this.selectedWordCountOption = "targetWordCount";
      this.webPageType = "blog";
      this.selectedContentPromptsSignals = this.richContentItemsForAIWriter.map(
        (row) => row.signalName
      );
      this.selectedContentPromptsSignals.forEach(
        (name) => (this.selectedCountForPromptSignals[name] = 1)
      );
      this.selectedCompetitors = this.pageRun.competitors
        .slice(0, 3)
        .map((run) => run.id);
      this.excludeTerms = ["embark", "embrace", "final thoughts", "conclusion"];
    }
    if (
      this.selectedWordCountOption == "currentWordCount" &&
      this.currentWordCount > 0
    ) {
      this.numberOfWords = this.currentWordCount;
    }
    this.loadAiRun(true);
    // this.$vuetify.theme.dark = true;
  },
  created() {
    this.handleParagraphEditDebounced = debounce(
      this.handleParagraphEdit,
      3000
    );
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  p {
    margin-bottom: 0px !important;
  }
  p, strong, li {
    font-size: 1rem;
    line-height: 26px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
    border: 1px solid #f5f5f5;
    padding: 10px;
  }
  
  tr td {
    padding: 10px;
  }
  tr th {
    padding: 10px;
  }
  
  th {
    background-color: #f5f5f5;
    padding: 10px;
    font-weight: bold;
    text-align: left;
  }
  
  
  tr td {
    border: 1px solid #f5f5f5;
  }
}
.ai-writer-root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .ai-writer-main-area {
    flex-grow: 1;
    overflow: auto;
    background: var(--v-whiteColor-base);
    display: flex;
    .table-of-content-side-bar {
      flex-basis: 300px;
      flex-shrink: 0;
      border-right: 1px solid var(--v-grayColor-base);
      padding: 20px 10px 20px 15px;
      overflow-y: auto;
      .hide-icon {
        font-size: 1.5rem;
        color: #5f6368;
        cursor: pointer;
      }
      .sidebar-label {
        margin: 30px 0;
        text-transform: uppercase;
      }
      .article-title {
        font-size: 0.9rem;
        font-weight: 700;
        line-height: 110%;
      }
      .h2-link,
      .h3-link {
        margin-top: 15px;
        cursor: pointer;
        line-height: 110%;
      }
      .h2-link {
        font-size: 0.85rem;
        margin-left: 10px;
      }
      .h3-link {
        font-size: 0.8rem;
        margin-left: 30px;
      }
      .h2-link:hover,
      .h3-link:hover {
        color: var(--v-profileAnchorColor-base);
        text-decoration: underline;
        font-weight: 700;
      }
    }
    .main-except-sidebar {
      flex-grow: 1;
      overflow-y: auto;
    }
    .final-result-bg {
      background: var(--v-gray7Color-base);
    }
  }
  .show-table-of-content-icon {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 35vh;
    padding: 5px 5px 5px 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 2px solid var(--v-gray12Color-base);
    border-bottom: 2px solid var(--v-gray12Color-base);
    border-right: 2px solid var(--v-gray12Color-base);
    .material-icons-outlined {
      color: #5f6368;
    }
  }
}

.selected-box-border {
  border: 1.5px solid var(--v-profileAnchorColor-base) !important;
}

.page-title {
  color: var(--v-mainColor-base);
  font-size: 25px;
  font-weight: bold;
}

.show-log-btn {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 10;
}

.step-title-label {
  font-size: 1rem;
  font-weight: 800;
  color: var(--v-mainColor-base);
}

.white-common-box {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08) !important;
}

.start-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--v-whiteColor-base);
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: auto;

  .left {
    background: #1c2b49;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 50px;
    background: var(--v-whiteColor-base);
  }

  .welcome-ai-img {
    min-width: 300px;
    width: 25vw;
    height: auto;
  }
  .welcome-title {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 800;
    color: var(--v-mainColor-base);
  }
  .base-text-style {
    font-size: 1rem;
    color: var(--v-mainColor-base);
  }

  .before-after-section {
    display: flex;
    flex-direction: row;
    gap: 12px;
    & > div {
      flex: 1;
      border: 0.5px solid #CCCCCC;
      border-radius: 10px;
      padding: 18px;
    }

    .base-text-style {
      font-size: 0.9rem;;
    }

    .how-to-btn {
      border: 0.5px solid #CCCCCC;

      .v-btn__content span {
        font-weight: normal; 
      }

      i {
        color: #CCCCCC;
      }
    }
  }

  .bottom-part {
    & > div {
      display: flex;
      i {
        margin-right: 10px;
        font-size: 1.8rem;
        color: var(--v-profileAnchorColor-base);
      }
      .explain {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--v-mainColor-base);
      }
    }
  }

  .start-btn {
    background: var(--v-profileAnchorColor-base);
    color: var(--v-whiteColor-base);
    border-radius: 19.2px;
    font-size: 23px;
    font-family: Mulish;
    font-weight: 800;
    padding: 15px 25px;
  }
}
@media screen and (max-width: 800px) {
  .start-page {
    display: block;

    .left,
    .right {
      min-height: 50vh;
      padding: 50px 20px;
    }
  }
}

.setting-page {
  .openai-key-message-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: var(--v-link9Color-base);
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 13px;
    /* font-weight: 700; */
    padding: 10px 15px;
    border-radius: 9px;
    max-width: 50rem;

    &,
    * {
      color: var(--v-link3Color-base);
    }
    a {
      text-decoration: underline;
      cursor: pointer;
    }

    .bigger {
      /* font-size: 15px; */
      font-weight: 700;
    }

    &.enabled-key {
      background: var(--v-green12Color-base);
      color: var(--v-green7Color-base);
    }
    &.disabled-key {
    }
    &.no-key {
    }
  }
}
.v-application.theme--dark {
  .enabled-key {
    color: var(--v-blackColor-base);
    a {
      color: var(--v-blackColor-base);
    }
  }
}

.custom-alert-box {
  position: relative;
  // margin-top: 35px;
  // margin-bottom: 35px;
  padding: 25px 40px 25px 40px;
  background: var(--v-green12Color-base);
  border-radius: 10px;
  overflow: hidden;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background: var(--v-green7Color-base);
    z-index: 1;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 10px;

    .left-icon {
      font-size: 2.5rem;
      color: var(--v-mainColor-base);
    }
    .credits-required {
      font-size: 19px;
      color: var(--v-green9Color-base);
      font-weight: 800;
      line-height: 24px;
    }

    .credit-explain {
      font-size: 16px;
      font-weight: 600;
      color: var(--v-mainColor-base);
    }
  }

  .buy-credit-button,
  .how-credits-works-link {
    padding: 8px 18px;
    border-radius: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: auto;
    i {
      font-size: 1.2rem;
    }
    span {
      font-size: 0.95rem;
      font-weight: 800;
      white-space: nowrap;
    }
    img {
      height: 20px;
      width: auto;
    }
  }
  .buy-credit-button {
    background: var(--v-mainColor-base);
    color: var(--v-whiteColor-base);
  }
  .how-credits-works-link {
    border: 1px solid var(--v-mainColor-base);
  }

  &.insufficient-credit {
    background: var(--v-red6Color-base);
    &::before {
      background: var(--v-red3Color-base);
    }
    .credits-required {
      color: var(--v-red5Color-base);
    }
  }
}

.rich-content-page {
  .select-content-prompt-table {
    width: 100%;
    th {
      background: var(--v-gray7Color-base);
      border-bottom: 1px solid var(--v-grayColor-base);
      color: var(--v-darkGrayColor-base);
      padding: 12px 10px;
      font-size: 0.8rem;
      font-weight: 500;
    }
    td {
      padding: 12px 10px;
      border-bottom: 1px solid var(--v-grayColor-base);
      vertical-align: middle;
      color: var(--v-mainColor-base);
    }

    .credit-1,
    .credit-0 {
      display: inline-flex;
      gap: 5px;
      align-items: center;
      font-size: 0.67rem;
      padding: 7px 13px;
      border-radius: 10px;
      font-weight: 600;
      white-space: nowrap;
    }
    .credit-1 {
      background: var(--v-yellow7Color-base);
      color: var(--v-yellow8Color-base);
    }
    .credit-0 {
      background: var(--v-link9Color-base);
      color: var(--v-blue5Color-base);
    }

    tr.remove-bottom-border{
      td {
        border-bottom: 0 !important;
      }
    }
  }
}

.select-competitors-page {
  .select-competitors-table {
    width: 100%;
    th {
      background: var(--v-gray7Color-base);
      border-bottom: 1px solid var(--v-grayColor-base);
      color: var(--v-darkGrayColor-base);
      padding: 12px 10px;
      font-size: 0.8rem;
      font-weight: 500;
    }
    td {
      padding: 12px 10px;
      border-bottom: 1px solid var(--v-grayColor-base);
      vertical-align: middle;
    }
    .link {
      color: var(--v-link15Color-base);
    }
    .competitor-title {
      color: var(--v-mainColor-base);
      font-weight: 700;
      font-size: 0.9rem;
    }

    .word-count-cell {
      text-align: center;
      color: var(--v-mainColor-base);
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
  .separate-title-pagetitle-box {
    border-radius: 10px;
    padding: 20px 30px;
    gap: 20px;
    border: 1px solid var(--v-gray15Color-base);
    background: var(--v-whiteColor-base);
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);

    & > div:nth-child(1) {
      flex-shrink: 0;
      img {
        width: 95px;
        height: auto;
      }
    }
  }
}

.v-input--checkbox {
  label {
    font-size: 14px;
  }
}
.component-title {
  font-size: 20px;
  font-weight: bold;
  color: var(--v-mainColor-base);
}
.section-label {
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--v-mainColor-base);
}
.progress-bar {
  margin-left: 15px;
  min-width: 200px;
  max-width: 200px;
}

.modal-title-size {
  font-size: 1.2rem;
  font-weight: 700;
}

.input-your-paragraph-section {
  // background: var(--v-lightYellow1Color-base);
  // padding: 10px 5px;
  i.material-icons {
    cursor: pointer;
  }
}

.p-result-area {
  color: var(--v-mainColor-base);
  .heading-info {
    font-weight: bold;
    .tag-name {
      text-transform: uppercase;
    }
  }
}

.answer-block {
  // border: 1px solid var(--v-darkGrayColor-base);
  margin: 0px 0 0px 0;
  padding: 0px 0px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  .answer {
    flex-grow: 1;
  }
}

.current-p {
  border: 1px solid var(--v-darkGrayColor-base);
  margin: 3px 0 15px 0;
  padding: 7px 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  .answer {
    flex-grow: 1;
  }
  &.active {
    background-color: var(--v-gray15Color-base);
  }
}

.history-box {
  // display: flex;
  // align-items: center;
  border-radius: 10px;
  border: 1px solid var(--v-gray15Color-base);
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
  color: var(--v-mainColor-base);
  padding: 18px 21px;
  margin-bottom: 10px;
  .result {
    flex-grow: 1;
    .timestamp {
      margin-bottom: 12px;
      & span {
        background: var(--v-greenColor-base);
        color: var(--v-whiteColor-base);
        border-radius: 5px;
        padding: 2px 10px;
      }
    }
  }
  .action {
    // padding-left: 20px;
  }

  textarea {
    width: 100%;
  }
}
textarea {
  color: var(--v-mainColor-base) !important;
}

// Content editor
::v-deep {
  .vue-codemirror.meta-editor {
    .CodeMirror {
      height: 150px !important;
      border: 1px solid rgb(209, 209, 209);
    }
  }
}

.ai-results-area {
  position: relative;
  &.is-loading {
    &::before {
      position: absolute;
      content: "";
      border-radius: 5px;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: var(--v-mainColor-base);
      opacity: 0.2;
    }
  }

  .ai-result-outline {
    // border: 2px dashed var(--v-gray15Color-base);
    border-radius: 0px 0px 5px 5px;
    padding: 0px 0px 30px 0px;
    // background: var(--v-gray33Color-base);
    // max-height: 400px;
    // overflow-y: auto;

    .outline-title {
      font-size: 1.5rem;
      font-weight: 800;
    }
    .drag-indicator {
      font-size: 1.2rem;
      color: var(--v-gray2Color-base);
    }
    .outline-row-subheading {
      background: var(--v-whiteColor-base);
      // border: 1px solid var(--v-grayColor-base);
      // box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 10px 10px;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      &.editing {
        display: flex;
        background: var(--v-gray8Color-base);
      }

      .text-part {
        flex-grow: 1;
      }
    }
  }
  .search-engines-only {
    display: inline-flex;
    align-items: center;
    background: var(--v-link9Color-base);
    // border: 1px solid var(--v-link3Color-base);
    color: var(--v-link3Color-base) !important;
    padding: 8px;
    border-radius: 3px;

    i {
      font-size: 0.75rem;
      color: var(--v-link3Color-base) !important;
      margin-right: 5px;
    }
    span {
      font-size: 0.6rem;
      line-height: 100%;
      color: var(--v-link3Color-base) !important;
      font-weight: 800;
      text-wrap: nowrap;
      text-transform: uppercase !important;
    }
  }

  input.edit-ai-result-input {
    border: 1px solid var(--v-gray2Color-base);
    background: var(--v-whiteColor-base);
    color: var(--v-blackColor-base);
    padding: 0px 5px;
    border-radius: 5px;
    cursor: pointer;
    &:focus-visible {
      outline-color: var(--v-gray2Color-base);
    }
  }
  .change-tag-select {
    border-radius: 5px;
    padding: 2px 4px;
    font-size: 0.65rem;
    font-weight: 700;
    color: var(--v-whiteColor-base);
    &.h2-selected {
      background: var(--v-link10Color-base);
    }
    &.h3-selected {
      background: var(--v-red7Color-base);
    }
  }
  .action-btn {
    border: 0.5px solid var(--v-gray15Color-base);
    background: var(--v-whiteColor-base);
    border-radius: 6px;
    .material-icons,
    .material-icons-outlined {
      font-size: 1rem;
      padding: 3px;
      color: var(--v-gray2Color-base);
    }
  }

  .tag-symbol-span {
    border-radius: 5px;
    margin-right: 10px;
    color: var(--v-whiteColor-base);
  }
  .intro-tag {
    background: var(--v-green7Color-base);
    padding: 4px 10px;
    font-size: 0.75rem;
  }
  .h1-tag {
    background: #d6faed;
    color: var(--v-green7Color-base);
    padding: 4px 10px;
    font-size: 0.75rem;
  }
  .h2-tag {
    background: var(--v-link10Color-base);
    padding: 4px 8px;
    font-size: 0.65rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .h3-tag {
    background: var(--v-red7Color-base);
    padding: 2px 6px;
    font-size: 0.6rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .h2-indent {
    margin-left: 13px;
    .text-part {
      font-size: 0.9rem;
    }
  }
  .h3-indent {
    margin-left: 28px;
    .text-part {
      font-size: 0.8rem;
    }
  }

  .table-of-content-box {
    border-radius: 5px;
    // border: 1px solid var(--v-gray15Color-base);
    background: var(--v-gray7Color-base);
    padding: 20px 20px;

    .top-part {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 20px;
        height: auto;
      }
      span {
        font-size: 1rem;
        font-weight: 800;
        // color: var(--v-mainColor-base);
      }
    }

    .text-part {
      flex-grow: 1;
    }
  }

  .result-one-section {
    .title-row {
      // background: var(--v-gray7Color-base);
      border-radius: 5px;
      padding: 10px;
      font-weight: 700;
      display: flex;
      align-items: center;

      .text-part {
        flex-grow: 1;
        // color: var(--v-mainColor-base);
      }
      .h1-size {
        font-size: 1rem;
      }
    }
    .paragraph-individual-area {
      border: 2px dashed var(--v-gray15Color-base);
      border-top: none;
      border-radius: 0px 0px 5px 5px;
      padding: 15px;
      background: var(--v-gray33Color-base);
    }
  }

  .content-prompt-placeholder {
    padding: 20px 10px;
    background: var(--v-gray7Color-base);
    border-radius: 5px;

    &.is-empty {
      background: #f6f9fc;
      font-size: 1rem;
    }
    .main-icon {
      font-size: 3rem;
      color: var(--v-grayColor-base);
    }
  }

  .content-prompt-ai-result-section {
    /* background: var(--v-green17Color-base); */
    border-radius: 5px;
    .empty-case {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      border: 1.5px solid #e3e2e2;
      border-bottom: 3.5px solid #e3e2e2;
      border-radius: 5px;
      padding: 8px;
      span {
        color: var(--v-profileAnchorColor-base);
        cursor: pointer;
      }
      strong {
        font-size: 1rem;
      }
    }

    .content-promopt-one-signal-result {
      ul,
      ol {
        list-style: disc;
        padding-left: 40px;
        li {
          padding-top: 5px;
          font-size: 1rem;
        }
      }
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border: none;
        th,
        td {
          padding: 10px 15px;
          border: solid 1px #cccccc;
          border-style: none solid solid none;
          font-size: 1rem;
        }
        th {
          font-weight: 800;
          background: #f6f9fc;
          border-bottom: none;
        }
        td {
        }
        th:first-child {
          border-top-left-radius: 5px;
        }
        th:last-child {
          border-top-right-radius: 5px;
        }

        tr:last-child td:first-child {
          border-bottom-left-radius: 5px;
        }
        tr:last-child td:last-child {
          border-bottom-right-radius: 5px;
        }

        tr:first-child th,
        tr:first-child td {
          border-top-style: solid;
        }
        tr th:first-child,
        tr td:first-child {
          border-left-style: solid;
        }
      }
    }
  }

  .main-content-normal {
    display: flex;
    gap: 10px;
    color: var(--v-blackColor-base);
    margin-top: 20px;
    .text-part {
      flex-grow: 1;
      &,
      p,
      div {
        font-size: 1rem;
        line-height: 150%;
      }
      ::v-deep strong {
        color: var(--v-blackColor-base) !important;
      }
      .main-content-title {
        font-size: 2rem;
        font-weight: 800;
        color: var(--v-blackColor-base);
        line-height: 110%;
      }
      a {
        color: var(--v-link1Color-base) !important;
        font-weight: 800;
      }
    }
    .action-part {
      flex-basis: 140px;
      flex-shrink: 0;
      & > div {
        display: flex;
        align-items: center;
        gap: 7px;
      }
      .expand-collapse {
        color: #595959;
      }
      .block-type {
        color: #595959;
        background: var(--v-whiteColor-base);
        font-weight: 500;
        border-radius: 100vh;
        padding: 8px 8px;
        box-shadow: 0 0 5px 0 #aaa;
      }
    }
  }
  .main-content-h2 {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 110%;
    color: var(--v-blackColor-base);
  }
  .main-content-h3 {
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 110%;
    color: var(--v-blackColor-base);
  }

  .ai-result-full-as-google-doc-style {
    margin: 30px 5vw 10px 5vw;
    padding: 4vw 4vw;
    background: var(--v-whiteColor-base);
    border: 1px solid #d0d7e6;
  }
  .one-grouped-block {
    margin-top: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .editing-p-block {
    background: var(--v-lightYellow2Color-base);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    .expand-collapse,
    .block-type {
      color: var(--v-profileAnchorColor-base) !important;
    }
  }

  .with-tag-block {
    display: flex;
    & > div:nth-child(1) {
      flex-basis: 35px;
      flex-shrink: 0;
      font-size: 0.85rem;
      font-weight: 600;
      color: #6e6e6e;
      &.h1 {
        padding-top: 8px;
      }
      &.h2 {
        padding-top: 4px;
      }
    }
    & > div:nth-child(2) {
      flex-grow: 1;
    }
  }
}

trix-editor {
  border: none;
  padding-top: 20px;
  padding-bottom: 0px;
}
trix-toolbar {
  background: var(--v-whiteColor-base);
  border-radius: 10px;
  padding: 10px;
  .trix-button-group {
    margin-bottom: 0px;
    border-radius: 0px;
    border: none;
  }
  .trix-button-group:not(:last-child) {
    border-right: 2px solid var(--v-grayColor-base);
  }
  .trix-button-group:first-child {
    padding-right: 15px;
  }
  .trix-button-group:not(:first-child) {
    margin-left: 0px;
    padding-left: 15px;
  }
  .trix-button {
    border: none;
  }
}

// .v-menu__content {
//   border-radius: 50vh !important;
// }
.action-btns-row {
  display: flex;
  gap: 5px;
  button {
    padding: 10px 15px;
  }
}

.read-only {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--v-mainColor-base);
    opacity: 0.1;
  }
}

.openai-error-modal {
  ol {
    li {
      margin-top: 10px;
    }
    a {
      text-decoration: underline;
      color: var(--v-blue5Color-base);
    }
  }
}

.p-settings-area {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  & > * {
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;

    &.is-active {
      border: 2px solid var(--v-profileAnchorColor-base) !important;
      background: var(--v-yellow12Color-base) !important;
    }

    .box-top-icon {
      font-size: 1.8rem;
      color: var(--v-profileAnchorColor-base);
    }
    .box-top-text {
      font-weight: 700;
      font-size: 1.05rem;
      color: var(--v-mainColor-base);
    }
  }
  ul {
    list-style: disc;
    li {
      margin-top: 10px;
    }
  }
}

.radio-box-row {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  span {
    color: var(--v-mainColor-base);
  }
  .checkbox-text {
    flex-grow: 1;
  }
}
.ai-action-btn {
  padding: 15px !important;
  &.fill {
    color: var(--v-whiteColor-base) !important;
  }
  .outline {
    color: var(--v-profileAnchorColor-base) !important;
  }
}
.ai-result-card {
  border: 0.5px solid #cccccc !important;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 7px !important;
  &:hover {
    box-shadow: 0px 5px 8px 0px #00000017;
    transition: all 0.2s linear;
  }
}
.selected {
  .outline-row-subheading {
    background-color: #ddd !important;
  }
}
.ai-writer-info-header {
  padding: 10px;
  border-bottom: 1px solid #cccccc;
}
.ai-writer-report-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 11.45px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-top: 10px;
}
.ai-writer-breadcrumbs {
  display: flex;
  gap: 2px;
  align-items: center;
  padding-top: 10px;
}
.ai-writer-breadcrumbs-item {
  font-size: 0.9rem;
  line-height: 11.45px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.ai-writer-breadcrumbs-item:last-child {
  font-weight: 700;
}
.font-sm {
  font-size: 0.75rem;
}
.flip-icon {
  transform: scaleX(-1);
}
.align-items-center {
  display: flex;
  align-items: center;
  gap: 5px;
}
.key-takeaways-editor {
  ul {
    list-style-type: disc;
  }
}
</style>
<style lang="scss">
.main-content-normal {
    .text-part {
      a {
        color: var(--v-link1Color-base) !important;
        font-weight: 800;
        font-size: 1rem;
      }
    }
  }
</style>
