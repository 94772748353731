let prefix = "P3__"; // Current payment amount version

export default {
  SERVICE_PKG_B2: {
    title: "Basic",
    description: "$34 per month with 12 reports per month",
    code: `${prefix}SERVICE_PKG_B2`,
    price: 37,
    urlParam: "basic",
    queryParam: { name: "product", value: "basic" },
    timeUnit: "month",
    items: [
      ["POP Reports credits per month, POP EEAT, AI Writer, NLP, Watchdog credits", "20"],
      ["Unlimited domains", "Yes"],
      ["Access to POP AI Writer", "Yes"]
    ]
  },
  SERVICE_PKG_C: {
    title: "Premium",
    description: "$47.5 per month with 25 reports per month",
    code: `${prefix}SERVICE_PKG_C`,
    price: 47.5,
    urlParam: "premium",
    queryParam: { name: "product", value: "premium" },
    timeUnit: "month",
    items: [
      ["POP Reports credits per month, POP EEAT, AI Writer, NLP, Watchdog credits", "40"],
      ["Unlimited domains", "Yes"],
      ["Access to POP AI Writer", "Yes"]
    ]
  },
  SERVICE_PKG_D: {
    title: "Unlimited",
    description: "Unlimited monthly reports",
    code: `${prefix}SERVICE_PKG_D`,
    price: 67,
    urlParam: "monthly-unlimited",
    queryParam: { name: "product", value: "unlimited" },
    timeUnit: "month",
    items: [
      ["POP Reports credits per month", "Unlimited*"],
      ["POP EEAT, AI Writer, NLP, Watchdog credits", "50"],
      ["Unlimited domains", "Yes"],
      ["Access to POP AI Writer", "Yes"]
    ],
    showStarSignExplainText: true,
  },
  SERVICE_PKG_E: {
    title: "Unlimited Yearly",
    description: "Unlimited yearly reports",
    code: `${prefix}SERVICE_PKG_E`,
    price: 670,
    urlParam: "yearly-unlimited",
    queryParam: { name: "product", value: "yearlyunlimited" },
    timeUnit: "year",
    items: [
      ["POP Reports credits per month", "Unlimited*"],
      ["POP EEAT, AI Writer, NLP, Watchdog credits", "50"],
      ["Unlimited domains", "Yes"],
      ["Access to POP AI Writer", "Yes"]
    ],
    showStarSignExplainText: true,
  },
  SERVICE_PKG_F: {
    title: "1 to 5 sub-accounts",
    description: "Agency Unlimited Monthly ( 5 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_F`,
    price: 87,
    urlParam: "monthly-agency-1-5",
    queryParam: { name: "product", value: "agency_5_monthly" },
    timeUnit: "month",
    items: [
      "Unlimited reports per month",
      "Unlimited domains",
      "Project level access for employees/clients",
      "Access to Google's NLP API",
      "EEAT Analysis",
      "Excel bulk project submission",
      "White label PDF reports",
      "No free trial"
    ]
  },
  SERVICE_PKG_G: {
    title: "6 to 10 sub-accounts",
    description: "Agency Unlimited Monthly ( 10 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_G`,
    price: 99,
    urlParam: "monthly-agency-6-10",
    queryParam: { name: "product", value: "agency_10_monthly" },
    timeUnit: "month",
    items: [
      "Unlimited reports per month",
      "Unlimited domains",
      "Project level access for employees/clients",
      "Access to Google's NLP API",
      "EEAT Analysis",
      "Excel bulk project submission",
      "White label PDF reports",
      "No free trial"
    ]
  },
  SERVICE_PKG_H: {
    title: "11 to 20 sub-accounts",
    description: "Agency Unlimited Monthly ( 20 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_H`,
    price: 109,
    urlParam: "monthly-agency-11-20",
    queryParam: { name: "product", value: "agency_20_monthly" },
    timeUnit: "month",
    items: [
      "Unlimited reports per month",
      "Unlimited domains",
      "Project level access for employees/clients",
      "Access to Google's NLP API",
      "EEAT Analysis",
      "Excel bulk project submission",
      "White label PDF reports",
      "No free trial"
    ]
  },
  SERVICE_PKG_I: {
    title: "1 to 5 sub-accounts",
    description: "Agency Unlimited Yearly ( 5 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_I`,
    price: 859,
    urlParam: "yearly-agency-1-5",
    queryParam: { name: "product", value: "agency_5_yearly" },
    timeUnit: "year",
    items: [
      "Unlimited reports per year",
      "Unlimited domains",
      "Project level access for employees/clients",
      "Access to Google's NLP API",
      "EEAT Analysis",
      "Excel bulk project submission",
      "White label PDF reports",
      "No free trial"
    ]
  },
  SERVICE_PKG_J: {
    title: "6 to 10 sub-accounts",
    description: "Agency Unlimited Yearly ( 10 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_J`,
    price: 979,
    urlParam: "yearly-agency-6-10",
    queryParam: { name: "product", value: "agency_10_yearly" },
    timeUnit: "year",
    items: [
      "Unlimited reports per year",
      "Unlimited domains",
      "Project level access for employees/clients",
      "Access to Google's NLP API",
      "EEAT Analysis",
      "Excel bulk project submission",
      "White label PDF reports",
      "No free trial"
    ]
  },
  SERVICE_PKG_K: {
    title: "11 to 20 sub-accounts",
    description: "Agency Unlimited Yearly ( 20 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_K`,
    price: 1089,
    urlParam: "yearly-agency-11-20",
    queryParam: { name: "product", value: "agency_20_yearly" },
    timeUnit: "year",
    items: [
      "Unlimited reports per year",
      "Unlimited domains",
      "Project level access for employees/clients",
      "Access to Google's NLP API",
      "EEAT Analysis",
      "Excel bulk project submission",
      "White label PDF reports",
      "No free trial"
    ]
  },
  SERVICE_PKG_AA: {
    title: "Teams Plan(5 sub-accounts)",
    description: "Teams Plan Unlimited Monthly ( 5 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_AA`,
    price: 130,
    urlParam: "monthy-team-plan",
    queryParam: { name: "product", value: "monthy-team-plan" },
    timeUnit: "month",
    items: [
      'Unlimited* POP Reports and Watchdog Credits per month',
      '120 POP EEAT, AI Writer, NLP Credits per month',
      'Additional seats can be added ',
      'Unlimited domains',
      'Access to POP AI Writer',
    ],
    showStarSignExplainText: true,
  },
  SERVICE_PKG_BB: {
    title: "Teams Plan(5 sub-accounts)",
    description: "Teams Plan Unlimited Yearly ( 5 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_BB`,
    price: 1300,
    urlParam: "yearly-team-plan",
    queryParam: { name: "product", value: "yearly-team-plan" },
    timeUnit: "year",
    items: [
      'Unlimited* POP Reports and Watchdog Credits per month',
      '120 POP EEAT, AI Writer, NLP Credits per month',
      'Additional seats can be added ',
      'Unlimited domains',
      'Access to POP AI Writer',
    ],
    showStarSignExplainText: true,
  },
  SERVICE_PKG_CC: {
    title: "White Glove Plan(5 sub-accounts)",
    description: "White Glove Plan Unlimited Monthly ( 5 Sub Accounts )",
    code: `${prefix}SERVICE_PKG_CC`,
    price: 130,
    urlParam: "monthly-white-glove-plan",
    queryParam: { name: "product", value: "monthly-white-glove-plan" },
    timeUnit: "month",
    items: [
      'Everything in all other plans',
      'Dedicated Account Manager',
      'Priority support',
      '3rd party tool migration',
      'Custom team onboarding',
      'Setup and customization',
      'Monthly Done For You services',
      'Great for agencies',
      'Great for business owners',
    ],
    showStarSignExplainText: true,
  },
  SERVICE_PKG_Z: {
    title: "PageOptimizer Watchdog Monthly",
    description: "PageOptimizer Watchdog Monthly",
    code: `${prefix}SERVICE_PKG_Z`,
    price: 15,
    // urlParam: '',
    // queryParam: {name: 'product', value: ''},
    timeUnit: "month",
    items: []
  },

  ADD_ON_PKG_A: {
    title: "PageOptimizer API Add-on Monthly",
    description: "PageOptimizer API Add-on Package Monthly",
    code: `${prefix}ADD_ON_PKG_A`,
    price: 10,
    // urlParam: '',
    // queryParam: {name: 'product', value: ''},
    timeUnit: "month",
    items: []
  }
};
