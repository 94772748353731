<template>
  <div class="pb-16">
    <template v-if="silo">
      <div class="card-big-title mb-8">Silo: {{ silo.name }}</div>

      <div
        class="flex white-selected-box w550 h78 justify-space-between mt-4"
        v-if="selectedSiloKeywords?.length"
      >
        <div class="box-primary-bg d-flex align-center">
          <div class="count-value px-5">
            {{ selectedSiloKeywords?.length || 0 }}
          </div>
        </div>
        <div class="px-3 d-flex align-center">
          <div class="selected-text">items selected</div>
        </div>
        <div class="d-flex flex-wrap align-center">
          <div class="flex gap15 align-center fill-height">
            <span
              class="flex flex-column align-center cursor-pointer w78"
              @click="openBulkModal()"
            >
              <i class="material-icons-outlined orange-color">sync</i>
              <div class="action-title">Create POP run reports</div>
            </span>
            <span class="flex flex-column align-center cursor-pointer w78">
              <i class="material-icons-outlined flip-icon orange-color">list</i>
              <div class="action-title">Create AI Writer outlines</div>
            </span>
            <span class="flex flex-column align-center cursor-pointer w78">
              <img
                src="/static/star_group.svg"
                style="width: 24px; height: 24px"
              />
              <div class="action-title">Create AI Writer articles</div>
            </span>
            <div
              class="cross-container flex justify-center align-center fill-height px-5"
            >
              <i @click="resetSelectedSiloKeywords" class="material-icons cursor-pointer">close</i>
            </div>
          </div>
        </div>
      </div>

      <silo-result-table
        :siteId="siteId"
        :silo="silo"
        :silo_keywords="silo_keywords.filter(k => !k.isDeleted)"
        :deleted_silo_keywords="silo_keywords.filter(k => k.isDeleted)"
        @refreshData="(silent) => loadData(silent)"
        @onKeywordRemoveSuccess="onKeywordRemoveSuccess"
        @onKeywordRestoreSuccess="onKeywordRestoreSuccess"
      />

      <bulk-run-modal :bulkPopUp="bulkPopUp" @submit="createBulkReport" @closeModal="closeBulkModal" />
    </template>
  </div>
</template>

<script>
import SiloResultTable from '@/components/Campaigns/SiloResultTable.vue';
import BulkRunModal from '@/components/BulkRunModal.vue';
import { getPureServicePkgName } from '@/utils';
import {
  whiteGlovePlans,
} from "@/components/plans_constants"

const channel = new BroadcastChannel('silo_content_editor_channel');
const aiWriteChannel = new BroadcastChannel('silo_ai_writer_channel');

export default {
  props: ['campaignId'],
  components: {
    SiloResultTable,
    BulkRunModal,
  },
  data() {
    return {
      silo: null,
      silo_keywords: [],
      bulkPopUp: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    selectedSiloKeywords() {
      return this.$store.getters.selectedSiloKeywords;
    },
    curSiloId() {
      return this.$route.query.id?Number(this.$route.query.id):null;
    },
    site () {
      return this.$store.getters.siteById(parseInt(this.$route.params.siteId)) || {};
    },
    siteId () {
      return parseInt(this.$route.params.siteId);
    },
  },
  methods: {
    createBulkReport() {
      const selectedKeywords = this.silo_keywords.filter(siloKeyword => this.selectedSiloKeywords.includes(siloKeyword.id))
      const body = selectedKeywords.map(siloKeyword => {
        const isTopLevel = siloKeyword.keywordData.isTopLevelKeyword;
        const config = this.site.settings;
        const url = siloKeyword.keywordData.page || siloKeyword.keywordData.currentRankingUrl

        return {
          "silo_keyword_id": siloKeyword.id,
          "project_name": this.site.name,
          "proxy_location": config.proxyLocation,
          "target_language": config.targetLang,
          "ignore_spaces": config.ignoreSpaces,
          "page_name": siloKeyword.keyword,
          "competitor_urls": [],
          "page_not_built": isTopLevel && url ? 0 : 1,
          "keyword": siloKeyword.keyword,
          "url": url || this.site.baseURL,
          "tab_name": "silo bulk run",
          "variations": [],
          "pop_pick_competitors": "yes",
        }
      })
      this.$store.commit('showLoading')
      this.$store.dispatch('generateNewBulkRun', body)
      .then((response) => {
        if (response.data && response.data.status == 'SUCCESS') {
          const bulkRunId = response.data.bulkRun.id;
          this.$store.dispatch('createNewBulkRun', { bulkRunId, isSilo: true }).then(() => {
            this.loadData();
          });
          this.closeBulkModal();
          this.resetSelectedSiloKeywords();
        } else if (response.data && response.data.msg) {
          this.$notify({ group: 'info', type: 'error', text: response.data.msg, duration: 5000 })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading')
      })
    },
    closeBulkModal() {
      this.bulkPopUp = false;
    },
    openBulkModal() {
      this.bulkPopUp = true;
    },
    resetSelectedSiloKeywords() {
      this.$store.commit('resetSelectedSiloKeywords');
    },
    loadData(silent = false) {
      if (!silent) {
        this.$store.commit('showLoading');
      }
      this.$store
        .dispatch('getSilo', this.curSiloId)
        .then((response) => {
          this.silo = response.data.data;
        })
        .then(() => this.$store.dispatch('getSiloKeywords', {
          silo_id: this.curSiloId,
          paginate: 0,
          includeDeleted: 1
        }))
        .then((response) => {
          this.silo_keywords = response.data.data;
        })
        .finally(() => {
          if (!silent) {
            this.$store.commit('hideLoading');
          }
        });
    },
    onKeywordRemoveSuccess(keywordId) {
      this.silo_keywords = this.silo_keywords.map(siloKeyword =>( {
        ...siloKeyword,
        isDeleted: siloKeyword.id === keywordId ? true : siloKeyword.isDeleted
      }))
    },
    onKeywordRestoreSuccess(keywordId) {
      this.silo_keywords = this.silo_keywords.map(siloKeyword =>( {
        ...siloKeyword,
        isDeleted: siloKeyword.id === keywordId ? false : siloKeyword.isDeleted
      }))
    }
  },
  mounted() {
    this.loadData();
    channel.onmessage = (message) => {
      if (message.data === 'flag_updated') {
        this.loadData();
      }
    };
    aiWriteChannel.onmessage = (message) => {
      if (message.data === 'ai_writer_updated') {
        this.loadData();
      }
    };
  },
  beforeDestroy() {
    channel.close();
    aiWriteChannel.close();
  }
};

</script>

<style lang="scss" scoped>
@import './silo.scss';
</style>
