export const STEPS = {
  'START': 0,
  'SETTING': 1,
  'RICH_CONTENT': 2,
  'SELECT_COMPETITORS': 3,
  'SEARCH_ENGINE_TITLE':4,
  'PAGE_TITLE': 5,
  'SUBHEADINGS': 6,
  'PARAGRAPH': 7,
};


export const STPES_LABEL = [
  '', // 0
  'Word count', // 1
  'Rich content', // 2
  'Customization', // 3
  'Search engine title', // 4
  'Page title', // 5
  'Sub-headings', // 6
  'Main content', // 7
]


export const CONTENT_PROMPT_SIGNALS_TOOLTIPS = {
  'Key Highlights': 'The AI will write the Key Highlights of the main content.',
  'Brief Overview': 'The AI will write a brief overview of the main content.',
  'Bold Tag Total': 'The AI will bold as many terms as you ask it to bold.',
  'Italic Tag Total': 'The AI will italicize as many terms as you ask it to italicize.',
  'Form Total': 'The AI will put a placeholder in an area to put a form. The AI will not create any forms.',
  'Image File total': 'The AI will put a placeholder in an area to put an image. The AI will not create any images.',
  'Table of content': 'A Table of Contents will be created based on your H2 and H3 subheadings.',
  'list': 'After the main content has been generated, you will be prompted to give the AI direction on what type of list or lists you need for your content.',
  'Table Total': 'After the main content has been generated, you will be prompted to give the AI direction on what type of table or tables you need for your content.',
  'Frequently Asked Questions': 'After the main content has been generated, you will be prompted to give the AI direction on what type of FAQs you need for your content.',
  'meta_description': 'The AI will write a meta description based on your title/topic. Meta descriptions typically appear in Google search results.',
  'intro_section': 'The AI write an introduction paragraph based on your title/topic.',
  'end_section': 'The AI will write a conclusion paragraph at the end based on your title/topic.',
  'Internal links': 'Step 1: Enter the link to your main page (the page you want to focus on).\nStep 2: Add links to two related pages that provide supporting content to your main page.\nWhen selecting links, focus on relevance and authority. For your Top-Level pages (key sales, product, or service pages), choose Supporting Pages that are closely related and add value to the topic of the Top-Level page. Supporting Pages might include detailed guides, related product pages, testimonials, or blog posts that strengthen the main page’s authority.\n\nIf you don’t have any Supporting Pages, simply leave the fields blank.\n\nAvoid linking to irrelevant pages like your Terms of Service, Privacy Policy, or any other content that doesn’t directly contribute to the subject matter or user intent for the Top-Level page.',
}