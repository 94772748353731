<template>
  <div>
    <div class="modal" :class="{ 'is-active': bulkPopUp }">
      <div class="modal-background" @click="ModalClose"></div>
      <div class="modal-content">

        <div class="box modal-container">
          <div>
            <div class="p-5">
              <div class="select-a-setup-type">
                Bulk running {{ selectedSiloKeywords?.length || 0 }} keywords
              </div>
              <button class="custom-modal-close-btn" aria-label="close" @click="ModalClose">
                <i class="material-icons">close</i>
              </button>
            </div>
          </div>
          <div class="modal-content-root px-5 pb-5">
            <div class="description">
              We will create a full POP run report for your <span>top-level keyword</span>  
              and AI Writer reports for your <br /> <span>supporting keywords</span>.
            </div>

            <div class="continue-button-area">
              <v-btn rounded class="button-with-icon back-button-text mr-2 mainColor--text text-none"
                @click="ModalClose">
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded color="profileAnchorColor" class="continue-button-text whiteColor--text text-none"
                @click="onSubmit">
                <span>Run POP</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from '@/components/mixin'
import _ from "lodash";
export default {
  mixins: [mixin],
  props: ["bulkPopUp"],
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    selectedSiloKeywords() {
      return this.$store.getters.selectedSiloKeywords;
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    },
    ModalClose() {
      this.$emit("closeModal");
    },
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  width: 35.563rem;
  max-width: 35.563rem;
}

.modal-container {
  padding: 0 !important;
}

.material-icons {
  margin-top: -10px;
  color: var(--v-mainColor-base);
}

.description {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: var(--v-mainColor-base);

  span {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6rem;

  }
}

.back-button-text {
  border: 1px solid var(--v-mainColor-base);
  height: 38px !important;
  background: none !important;

  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }
}

.continue-button-text {
  height: 38px !important;

  i,
  span {
    font-size: 17px !important;
    font-weight: 700 !important;
  }

  i {
    margin-top: 2px;
  }
}

.select-a-setup-type {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
  color: var(--v-mainColor-base);
}

.continue-button-area {
  margin-top: 25px;
  text-align: right;
}
</style>
