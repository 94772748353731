<template>
  <div>
    <v-card class="is-fullheight flex flex-column white-common-box">
      <div class="pa-5 flex-grow-1 flex flex-column">
        <div class="title-font mainColor--text box-title-label">
          Buy credits
        </div>
        <div class="description mt-3">
          Additional credits can be purchased to unlock features in bundles of 10, 25, 50, 100, 500 & 1000. These add-on credits do not expire, even if you cancel your monthly subscription.
        </div>
        <div v-if="isLoadingPackages" class="mt-2 greenColor--text">Loading available packages..</div>
        <div class="flex-grow-1">
          <v-radio-group v-model="selectedPackage">
            <div v-for="item in packagesToRender" :key="item.value"
              class="package-item"
              :class="{ 'is-selected': item.value == selectedPackage }"
              @click="() => {
                selectedPackage = item.value;
              }">
              <span>
                <v-radio :value="item.value"
                  :selected="item.value == selectedPackage"
                  color="profileAnchorColor"></v-radio>
              </span>
              <div class="flex-grow-1">
                <div class="text">{{ item.text }}</div>
                <div class="explain">
                  The price of one POP Credit is ${{ item.pricePerUnit.toFixed(2) }}
                </div>
              </div>
              <div class="price">
                ${{ calculatePriceForPackage(item.value).toFixed(2) }}
              </div>
              <div v-if="item.saveMoney > 0" class="save-money">
                Save ${{ item.saveMoney.toFixed(2) }}                
              </div>
            </div>
          </v-radio-group>
        </div>
        <div class="mt-3">
          <v-row>
            <v-col cols="8">
              <div class="d-flex align-center">
                <v-text-field outlined dense
                  hide-details
                  color="greenColor"
                  v-model="promoCode"
                  placeholder="Input a coupon code here if you have one"></v-text-field>
                <v-btn
                  color="profileAnchorColor"
                  :disabled="!promoCode"
                  class="ml-3 text-none mainColor--text"
                  @click="applyCoupon">
                  <span>Apply</span>
                </v-btn>
              </div>
              <div class="mt-1">
                <div v-if="appliedCoupon" class="greenColor--text">Coupon applied for {{ appliedCoupon.amountOff }}% discount.</div>
                <div v-else-if="couponError" class="redColor--text" v-html="couponError"></div>
              </div>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </div>
        <div class="mt-5 d-flex gap10">
          <div class="pay-button" style="position: relative; z-index: 1;">
            <div id="credit-purchase-paypal-button"></div>
          </div>
          <v-btn
            color="profileAnchorColor"
            class="button-with-icon text-none whiteColor--text"
            @click.stop="submit"
            :disabled="!selectedPackage">
            <span class="icon">
              <i class="fa fa-money"></i>
            </span>
            <span>Pay with credit card</span>
          </v-btn>
        </div>

        <div class="modal" :class="{ 'is-active': showConfirmModal }">
          <div class="modal-background" @click.stop="showConfirmModal = false"></div>
          <div class="modal-content">
            <div class="box modal-container">
              <div class="subtitle-font mainColor--text">
                You're about to purchase {{ packageExplainObj[selectedPackage]?.text }}. <br />
                Do you want to proceed?
              </div>
              <div class="text-right">
                <v-btn rounded outlined color="mainColor" class="button-with-sicon mainColor--text text-none"
                  @click.stop="showConfirmModal = false;">
                  Cancel
                </v-btn>
                <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
                  @click.stop="() => confirmedGoProcess()">
                  Yes
                </v-btn>
              </div>
            </div>
            <button class="custom-modal-close-btn" aria-label="close" @click="() => (showConfirmModal = false)">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>

        <div class="modal" :class="{ 'is-active': showModal }">
          <div class="modal-background" @click.stop="showModal = false"></div>
          <div class="modal-content">
            <div class="box modal-container">
              <p class="title mainColor--text">Purchase Complete</p>
              <p class="subtitle-font mainColor--text">
                Please click the button below to go to projects page and run a page analysis
              </p>
              <div class="field">
                <div class="control">
                  <v-btn rounded color="profileAnchorColor" class="button-with-icon mainColor--text text-none"
                    @click.stop="goToSites">Go to projects</v-btn>
                </div>
              </div>
            </div>
          </div>
          <button class="modal-close is-large" aria-label="close" @click.stop="showModal = false"></button>
        </div>
        <div class="modal" :class="{ 'is-active': showErrorModal }">
          <div class="modal-background" @click.stop="showErrorModal = false"></div>
          <div class="modal-content">
            <div class="box modal-container">
              <p class="title mainColor--text">Puchase Failure</p>
              <p class="subtitle-font mainColor--text">
                Transaction failure. Please verify your payment method is valid. <br />
                Please remove your card in 'Manage Credit cards' and try again.
              </p>
              <v-btn rounded color="profileAnchorColor" class="button-with-icon mainColor--text text-none"
                @click.stop="showErrorModal = false">Back to Purchases</v-btn>
            </div>
          </div>
          <button class="modal-close is-large" aria-label="close" @click.stop="showErrorModal = false"></button>
        </div>
      </div>
    </v-card>
    <div class="mt-10"></div>
    <v-card class="is-fullheight flex flex-column white-common-box mb-10">
      <div class="pa-5 flex-grow-1 flex flex-column">
        <div class="title-font mainColor--text box-title-label d-flex align-center gap10">
          Here’s how you can exchange POP credits
          <span class="material-icons video-tutorial-icon video-tutorial-icon-size"
            @click="() => {
              $store.commit('setCurrentVideoTutorialToDisplay', {
                title: 'POP Credits Explained',
                videoLink: 'https://www.youtube.com/embed/MX2hpmnTA8Y?si=IJ4L-pLVyjn28wnW',
                description: 'POP Credits are like currency inside your POP account and can be spent how you like on various extra features. In this video, you\'ll learn more about what POP Credits are, how to spend them, and where to purchase them.',
                time: '2m 18s'
              });
            }">
            videocam
          </span>
        </div>
        <div class="my-5">
          <div class="title-font mainColor--text">
            POP AI Writer
          </div>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              1,000 words of new & original optimized Content <br/>
              <small>(includes creating a new Title, H1, Subheadings, and paragraph content)</small>
            </v-col>
            <v-col cols="12" md="3">
              {{popCreditCharges.aiWriterCreditsPer1000Words}} POP credit{{ areCreditsMoreThanOne(popCreditCharges.aiWriterCreditsPer1000Words) ? 's' : '' }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              Regenerate title section and/or H1
            </v-col>
            <v-col cols="12" md="3">
              1 POP credit
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              Regenerate list of optimized sub-headings
            </v-col>
            <v-col cols="12" md="3">
              1 POP credit
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              Regenerate new or optimize current paragraph text (by subtitles)
            </v-col>
            <v-col cols="12" md="3">
              1 POP credit
            </v-col>
          </v-row>
        </div>
        <div >
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              <div class="title-font mainColor--text">
                POP run *
              </div>
            </v-col>
            <v-col cols="12" md="3">
              2 POP credits
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              <div class="title-font mainColor--text">
                POP re-run *
              </div>
            </v-col>
            <v-col cols="12" md="3">
              1 POP credit
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              <div class="title-font mainColor--text">
                POP Watchdog run *
              </div>
            </v-col>
            <v-col cols="12" md="3">
              1 POP credit
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              <div class="title-font mainColor--text">
                Google NLP run
              </div>
            </v-col>
            <v-col cols="12" md="3">
              {{popCreditCharges.googleNlpCredits}} POP credit{{ areCreditsMoreThanOne(popCreditCharges.googleNlpCredits) ? 's' : '' }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="9">
              <div class="title-font mainColor--text">
                EEAT run
              </div>
            </v-col>
            <v-col cols="12" md="3">
              {{popCreditCharges.eeatCredits}} POP credit{{ areCreditsMoreThanOne(popCreditCharges.eeatCredits) ? 's' : '' }}
            </v-col>
          </v-row>
        </div>
        <div class="mt-4">
          <div class="mainColor--text">
            <small>* POP reports are unlimited for Unlimited and Teams packages</small>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { getPureServicePkgName } from '@/utils'

export default {
  data() {
    return {
      isLoadingPackages: false,
      packages: [],
      prices: {},
      packageExplainObj: {
        '10_CREDITS': {
          text: '10 POP Credits',
          pricePerUnit: 1.5,
          saveMoney: 0,
        },
        '25_CREDITS': {
          text: '25 POP Credits',
          pricePerUnit: 1.3,
          saveMoney: 5,
        },
        '50_CREDITS': {
          text: '50 POP Credits',
          pricePerUnit: 1.2,
          saveMoney: 15,
        },
        '100_CREDITS': {
          text: '100 POP Credits',
          pricePerUnit: 1.0,
          saveMoney: 50,
        },
        '500_CREDITS': {
          text: '500 POP Credits',
          pricePerUnit: 0.9,
          saveMoney: 300,
        },
        '1000_CREDITS': {
          text: '1000 POP Credits',
          pricePerUnit: 0.8,
          saveMoney: 700,
        }
      },
      pakcagesPricePerUnit: {
        '10_CREDITS': 1.5,
        '25_CREDITS': 1.3,
        '50_CREDITS': 1.2,
        '100_CREDITS': 1.0,
        '500_CREDITS': 0.9,
        '1000_CREDITS': 0.8,
      },
      selectedPackage: '',
      promoCode: '',
      appliedCoupon: null,
      couponError: '',

      showErrorModal: false,
      showModal: false,

      paymentMethod: '', // stripe / paypal
      showConfirmModal: false,
      isConfirmedByUser: false,
    }
  },
  methods: {
    applyCoupon () {
      this.appliedCoupon = null;
      this.couponError = '';
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchValidatedCoupons', { promoCode: this.promoCode.trim(), isRegisterPage: 0 })
        .then((response) => {
          if (!response.data) {
            this.couponError = 'Invalid coupon';
            return;
          }
          if (response.data && response.data.status == 'SUCCESS') {
            let allServiceCoupons = response.data.coupons
            if (allServiceCoupons.length == 1) {
              const coupon = allServiceCoupons[0];
              if (coupon.couponType == 'Credit Percentage Off') {
                console.log(coupon)
                this.appliedCoupon = coupon;
              } else {
                console.log('no no', coupon)
                this.couponError = 'This coupon is not for credit packages.'
                if (coupon.couponType == 'Credits') {
                  this.couponError += '<br/> Click the Promo code tab and insert the promo code there.'
                }
              }
            } else {
              this.couponError = 'Invalid coupon.'
            }
          } else {
            if (response.data.status == 'FAILURE') {
              this.couponError = response.data.errorMsg ? response.data.errorMsg : 'Invalid coupon'
            }
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    calculatePriceForPackage(package_name) {
      let price = this.prices[package_name] || 0
      if (this.appliedCoupon) {
        console.log('dis count is just', this.appliedCoupon.amountOff, price)
        price -= (price * this.appliedCoupon.amountOff / 100)
      }
      return price            
    },
    goToSites () {
      this.showModal = false
      this.$router.push('/sites')
    },
    clearConfirm() {
      this.paymentMethod = '';
      this.isConfirmedByUser = false;
    },
    confirmedGoProcess() {
      this.showConfirmModal = false;
      this.isConfirmedByUser = true;
      if (this.paymentMethod == 'stripe') {
        this.submit();
        return;
      }
      // now modal is only for stripe, so following is not needed.
      // if (this.payment == 'paypal') {
      //   paypal.Button.click('#credit-purchase-paypal-button');
      //   return;
      // }
    },
    submit() {

      if (!this.isConfirmedByUser) { // to ask user once more about confirm purchase.
        this.paymentMethod = 'stripe';
        this.showConfirmModal = true;
        return;
      }

      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'CREDIT_PURCHASE',
        selectedPackage: this.selectedPackage,
        amount: this.purchase,
        userID: this.user.id,
        promoCode: this.promoCode.trim(),
      })
      .then(function (response) {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_self')
        }
      })
      .finally(() => {
        this.$store.commit('showLoading');
      })
    },
    getPureServicePkgName(arg_serviceName) {
      return getPureServicePkgName(arg_serviceName)
    },
    areCreditsMoreThanOne(credits) {
      return credits > 1;
    }
  },
  computed: {
    popCreditCharges () {
      return this.$store.state.popCreditCharges
    },
    purchase () {
      return this.calculatePriceForPackage(this.selectedPackage)
    },
    user() {
      return this.$store.state.user
    },
    isPaypalDisabled () {
      if (this.user) {
        return this.user.isPaypalDisabled
      }
      return true
    },
    packagesToRender () {
      return this.packages.map(p => {
        let found = this.packageExplainObj[p]
        return {
          ...found,
          value: p,
        }
      })
    }
  },
  mounted() {

    this.isLoadingPackages = true;
    this.$store.dispatch('getAvailableUniversalPackages')
    .then(response => {
      if (response.data && response.data.status == 'SUCCESS') {
        this.packages = response.data.packages;
        this.prices = response.data.prices;
        this.selectedPackage = this.packages[0];
      }
    })
    .finally(() => {
      this.isLoadingPackages = false;
    })
    

    const self = this
    paypal.Button.render({
      env: PAYPAL_ENV,
      local: 'en_US',
      style: {
        size: 'responsive',
        color: 'gold',
        shape: 'rect',
        height: 36,
        label: 'paypal'
      },
      payment: function (data, actions) {
        if (!self.selectedPackage || self.purchase == 0) {
          return false;
        }

        return self.$store.dispatch('createPayPalPayment', {
          packageType: 'CREDIT_PURCHASE',
          selectedPackage: self.selectedPackage,
          amount: self.purchase,
          userID: self.user.id
        })
          .then(function (response) {
            return response.data.id
          })
      },
      onAuthorize: function (data, actions) {
        return self.$store.dispatch('buyUniversalCreditsPakcage', {
          description: 'CREDIT_PURCHASE',
          servicePackage: self.selectedPackage,
          amount: self.purchase,
          paymentService: 'paypal',
          paymentID: data.paymentID,
          payerID: data.payerID,
          userID: self.user.id,
          promoCode: self.promoCode.trim(),
        })
        .then(function(response) {
          console.log('credit purchase response', response)
          if (response.data.status === 'SUCCESS') {
            self.showModal = true
            self.clearConfirm();
            self.$store.commit('setUser', response.data.user)
          }
        })
      }
    }, '#credit-purchase-paypal-button')
  }
}
</script>

<style lang="scss" scoped>
.description {
  color: var(--v-mainColor-base);
}
.package-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border: 1px solid var(--v-grayColor-base);
  border-radius: 5px;
  margin-top: 10px;
  &.is-selected {
    background: #FFF7F0;
    border-color: var(--v-profileAnchorColor-base);
  }
  .text {
    font-size: 1.05rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }
  .explain {
    margin-top: 5px;
    font-size: 1rem;
    color: var(--v-mainColor-base);
  }
  .price {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }
  .save-money {
    background: #FFF7F0;
    border: 1px solid var(--v-profileAnchorColor-base);
    padding: 5px 10px;
    border-radius: 50vh;
    font-weight: 700;
    color: var(--v-profileAnchorColor-base);
  }
}
.pay-button {
  width: 170px;
}
.purchase_amount_txt {
  color: var(--v-greenColor-base);
  font-weight: bold;
  font-size: x-large !important;
}
</style>