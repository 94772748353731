<template>
<div class="d-flex pl20 pr20">
  <div>
    <div class="all-tools-label">{{ site ? site.name : '' }}</div>

    <site-content-audit-widget
      v-if="site && isWhiteGloveUser"
      :site="site"
    />

    <div class="new-tool-root mt-10">
      <pages-card></pages-card>
      <ai-writers-card></ai-writers-card>
      <keyword-research-card v-if="showKeywordTool"></keyword-research-card>
      <keyword-research-card-locked v-else></keyword-research-card-locked>
      <!-- <div class="tool-card pa-5"></div> -->
    </div>

  </div>
</div>
</template>

<script>
import { mixin } from '@/components/mixin'

import PagesCard from "@/components/Tools/PagesCard.vue";
import AiWritersCard from "@/components/Tools/AiWritersCard.vue";
import KeywordResearchCard from "@/components/Tools/KeywordResearchCard.vue";
import KeywordResearchCardLocked from "@/components/Tools/KeywordResearchCardLocked.vue";
import SiteContentAuditWidget from "@/components/Tools/SiteContentAuditWidget/Index.vue";
import { getPureServicePkgName } from '@/utils'
import {
  SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS,
  whiteGlovePlans
} from "@/components/plans_constants";

export default {
  mixins: [ mixin ],
  components: {
    PagesCard,
    AiWritersCard,
    KeywordResearchCard,
    KeywordResearchCardLocked,
    SiteContentAuditWidget,
  },
  computed: {
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return null
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    showKeywordTool () {
      if (this.isSpecialAdminUser || this.isSpecialOwnerSelected) {
        return true;
      }
      if (this?.selectedSubAccountRelation?.grantedDevModeTools?.length > 0 || this?.user?.grantedDevModeTools?.length > 0) {
        return true;
      }
      if (this.selectedSubAccountRelation && SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.selectedSubAccountRelation.ownerAccountType))) {
        return true
      }
      if (!this.selectedSubAccountRelation && this.user && SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(this.user.accountType))) {
        return true
      }
      return false;
    }
  },
  data () {
    return {
      
    }
  }
}
</script>


<style lang="scss">
@import "./index.scss";
</style>