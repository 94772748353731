<template>
  <div class="supporting-keyword-root">
    <div class="mainColor--text" style="max-width: 1100px; line-height: 1.4rem;">
      The Keyword Strategy Tool will help you start keyword research by generating clusters of related, high-quality keywords based on semantic similarity and search intent. By analyzing data from sources like Google Suggestions and People Also Ask, the tool organizes keywords into clusters. It then uses algorithmic and AI analysis to identify top-level and supporting keywords, simplifying content planning and allowing you to focus on strategic decisions.
    </div>
    
    <div>
      <div class="mt-10 location-lang-domain-area">
        <div>
          <div class="card-small-title">
            Select Location
          </div>
          <v-autocomplete outlined dense hide-details
            :items="availableLocations"
            item-text="location_name"
            item-value="location_code"
            :value="config.wizardLocation2"
            @input="value => setConfig('wizardLocation2', value)"
            color="grayColor"
            class="mt-5"
          >
          </v-autocomplete>
        </div>
        <div>
          <div class="card-small-title">
            Select Language
          </div>
          <v-autocomplete outlined dense hide-details
            :items="targetLanguages"
            :value="config.wizardLanguage2"
            @input="value => setConfig('wizardLanguage2', value)"
            item-text="language_name"
            item-value="language_code"
            color="grayColor"
            class="mt-5">
          </v-autocomplete>
        </div>
        <div>
          <div class="card-small-title">
            Domain name
          </div>
          <v-text-field outlined dense hide-details
            class="mt-5"
            color="grayColor"
            readonly
            v-model="domain"
          />
        </div>
      </div>
      <div class="mt-10" style="max-width: 680px;">
        <div class="d-flex align-center gap10">
          <div class="card-small-title">
            Enter your target keywords or topics
          </div>
          <span class="input-count-info"
            :style="(config?.wizardTopics?.length || 0) > 10 ? 'color: red; border-color: red;' : ''"
          >{{ config?.wizardTopics?.length || 0 }} / 10</span>
          <div class="flex-grow-1"></div>
        </div>
        <div class="customized-multiselect1">
          <multiselect class="mt-5"
            v-model="topics"
            placeholder="Enter the keyword or topic you wish to explore further."
            :options="[]"
            :multiple="true"
            :taggable="true"
            :optionsLimit="10"
            @tag="addTopicAction">
          </multiselect>
        </div>
        <!--
        <div class="mainColor--text mt-3">
          If you have your own list of keywords, you can import it and we will cluster your list. Please note that the list can’t have more than 1000 keywords.
          <a class="link15Color--text text-decoration-underline">Import it here.</a>
        </div>
        <div class="mt-3 d-flex">
          <div v-if="selectedFile && selectedFile.name" class="selected-file">
            <span class="font-weight-bold">{{ selectedFile.name }}</span>
            <span class="material-icons" @click="resetFileSelected">cancel</span>
          </div>
        </div>
        -->
      </div>
      <div class="mt-10" style="max-width: 680px;">
        <div class="d-flex align-center gap10">
          <div class="card-small-title">
            Enter your competitor URLs <span v-if="!checkOnlyCompetitors" class="tag optional-field-label ml-1">Optional</span>
          </div>
          <span
            class="input-count-info"
            :style="competitorUrlsCountInputed > 20 ? 'color: red; border-color: red;' : ''"
          >
            {{ competitorUrlsCountInputed }} / 20
          </span>
          <div class="flex-grow-1"></div>
        </div>
        <div class="mainColor--text mt-3">
          <i class="material-icons" style="color: darkorange; vertical-align: bottom; font-size: 1.2rem;">warning_amber</i> URLs should link directly to specific features or product pages rather than main homepages, <br/>
          e.g. use <span class="font-weight-bold">https://www.domain.com/platform/marketing-automation</span> instead of https://www.domain.com/.
        </div>
        <div class="mt-3">
          <v-textarea
            outlined
            hide-details
            color="greenColor"
            placeholder="Enter a list of URLs (separated by line breaks)"
            :value="config.wizardCompTexts"
            @input="value => setConfig('wizardCompTexts', value)"
          />
        </div>
        <div class="mt-3 d-flex align-center gap10">
          <div class="d-flex align-center">
            <vue-toggles
              :value="checkOnlyCompetitors"
              width="40" height="20"
              :checkedBg="$vuetify.theme.currentTheme['green7Color']"
              :uncheckedBg="$vuetify.theme.currentTheme['grayColor']"
              @click="checkOnlyCompetitors = !checkOnlyCompetitors"
            ></vue-toggles>
            <div class="mainColor--text ml-2">
              I want to analyze competitors only
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 d-flex align-center gap10">
        <v-btn rounded
          color="profileAnchorColor"
          class="button-with-icon text-none whiteColor--text"
          :disabled="isCalculating || !canStartSearchKeywords || (isWhiteGloveUser && !isMissionControlSession)"
          @click="() => hasUnlimitedAccess?startSearchKeywords():(() => {showSearchKeywordsCostModal = true})()">
          <span>Search keywords</span>
        </v-btn>
      </div>
    </div>
  
    <div v-if="!isCalculating && researchReport" class="result-area mt-10">
      <div class="mt-6 mb-6 keyword-strategy-tab-group">
        <v-btn-toggle>
          <v-btn
            :class="{ 'v-btn--active': selectedTab === 'clusters' }"
            @click="() => { selectedTab = 'clusters' }"
          >
            Clusters
          </v-btn>
          <v-btn
            :class="{ 'v-btn--active': selectedTab === 'keyword-data-results' }"
            @click="() => { selectedTab = 'keyword-data-results' }"
          >
            Keyword data results
          </v-btn>
        </v-btn-toggle>
      </div>
      
      <v-card outlined class="p-4 mainColor--text" v-if="!extractedKeywords.length">
        No results found. Please try again with different topics or competitor urls.
      </v-card>
      
      <div v-else>
        <div class="mt-10">
          <div v-if="selectedTab === 'clusters'">

            <template>
              <div class="white-common-box pa-5">
                <!-- cluster org chart -->
                <div class="d-flex align-center" v-if="extractedKeywords.length > 0">
                  <div class="general-info flex-grow-1">{{ finalKeywords.length }} keywords in {{ finalClusterNames.length }} topic clusters</div>
                  <div class="d-flex gap10">
                    <div class="keyword-filter-area" @click="() => { showKeywordIntentFilterPopup = true; }">
                      <div>KEYWORD INTENT</div>
                      <div class="filter-btn">
                        {{ keywordIntentsFilter.length }} filters applied
                      
                        <div v-if="showKeywordIntentFilterPopup" v-click-outside="() => { showKeywordIntentFilterPopup = false; }"
                          class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                          <v-checkbox dense hide-details="auto" color="#007aff"
                            v-for="k_intent in allKeywordIntents" :key="k_intent"
                            style="text-transform: capitalize;"
                            v-model="keywordIntentsFilter" :value="k_intent" :true-value="[]" :label="k_intent">
                          </v-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="keyword-filter-area" @click="() => { showKeywordTypeFilterPopup = true; }">
                      <div>KEYWORD TYPE</div>
                      <div class="filter-btn">
                        {{ keywordTypesFilter.length }} filters applied
                      
                        <div v-if="showKeywordTypeFilterPopup" v-click-outside="() => { showKeywordTypeFilterPopup = false; }"
                          class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                          <v-checkbox dense hide-details="auto" color="#007aff"
                            v-for="kType in keywordTypeOptions" :key="kType"
                            v-model="keywordTypesFilter" :value="kType" :true-value="[]" :label="kType">
                          </v-checkbox>
                          <div class="d-flex align-center mt-5">
                            <vue-toggles
                              :value="includeQuestionKeyword"
                              width="40" height="20"
                              :checkedBg="$vuetify.theme.currentTheme['green7Color']"
                              :uncheckedBg="$vuetify.theme.currentTheme['gray35Color']"
                              @click="() => {
                                includeQuestionKeyword = !includeQuestionKeyword
                              }">
                            </vue-toggles>
                            <div class="ml-2 mainColor--text">
                              Question Keywords
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="keyword-filter-area" @click="() => { showIncludeExcludeFilterPopup = true; }">
                      <div>FILTERS</div>
                      <div class="filter-btn">
                        {{ filterTermFilerCount }} filters applied
                      
                        <div v-if="showIncludeExcludeFilterPopup" v-click-outside="() => { showIncludeExcludeFilterPopup = false; }"
                          class="filter-popup" style="position: absolute; right: 0; top: 50px; z-index: 12;">
                          <div class="d-flex align-center">
                            <div class="mainColor--text font-weight-bold flex-grow-1">Include words</div>
                            <div class="clear-btn" :class="{ 'disabled': includeFilterTermText == '' }" @click="() => { includeFilterTermText = '' }">
                              <i class="material-icons">close</i>
                              <span>Clear</span>
                            </div>
                          </div>
                          <v-textarea outlined rows="4" v-model="includeFilterTermText"></v-textarea>
                          <div class="d-flex align-center">
                            <div class="mainColor--text font-weight-bold flex-grow-1">Exclude words</div>
                            <div class="clear-btn" :class="{ 'disabled': excludeFilterTermText == '' }" @click="() => { excludeFilterTermText = '' }">
                              <i class="material-icons">close</i>
                              <span>Clear</span>
                            </div>
                          </div>
                          <v-textarea outlined hide-details rows="4" v-model="excludeFilterTermText"></v-textarea>
                        </div>
                      </div>
                    </div>
                    <v-btn outlined color="mainColor"
                      class="button-with-icon text-none"
                      @click="downloadClustersAndKeywords">
                      Download
                    </v-btn>
                  </div>
                </div>
                <custom-auto-complete
                  :items="keywordWizardReports"
                  :selected-value="selectedResearchReportId"
                  search-placeholder="Search reports"
                  item-value="id"
                  @updateSelectedValue="(id) => { selectedResearchReportId = id }"
                  @rename="onShowModal(`Rename ${$event.name}`, 1, $event.name, $event.id)"
                  @toggle-favorite="updateFavoriteStatus($event)"
                  @delete="onShowModal(`Delete ${$event.name}`, 2, '', $event.id)"
                />
                <clusters-chart
                  v-if="extractedKeywords.length > 0"
                  :topics="topicsUsedInExtrackingKeyword"
                  :clusterNames="clusterNames"
                  :showClustersCount="showClustersCount"
                  :optionsLimit="10"
                  @showMoreClusters="() => {
                    showMoreClusters()
                  }"
                  @showClusterDetail="(cName) => showClusterDetail(cName)"
                />
              </div>
            </template>

            <div
              class="d-flex align-center sticky-keywords-list-header"
            >
              <div class="flex-grow-1">
                <div class="card-small-title mb-2">Keywords ({{ finalKeywords.length }})</div>
                <div class="mainColor--text">To get the keyword data, you can select up to 100 keywords in different clusters below.</div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
                <v-tooltip top color="transparent" :disabled="(isWhiteGloveUser && !isMissionControlSession)">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn rounded outlined
                        color="mainColor"
                        class="button-with-icon"
                        :disabled="(researchReport?.clustersAIFilteringAttempts || 0) >= MAX_CLUSTERS_AI_FILTERING_ATTEMPTS || isCalculating || (isWhiteGloveUser && !isMissionControlSession)"
                        @click="() => {
                          showAIFormModal = true
                        }">
                        <i class="material-icons-outlined">auto_awesome</i>
                        <span style="text-transform: initial;">Filter clusters with AI</span>
                      </v-btn>
                    </span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    {{ (researchReport?.clustersAIFilteringAttempts || 0) >= MAX_CLUSTERS_AI_FILTERING_ATTEMPTS ? `You have already filtered the clusters ${MAX_CLUSTERS_AI_FILTERING_ATTEMPTS} times` : `Enter your topic title or target keyword. AI will filter and prioritize key terms, saving time by highlighting the most relevant top-level and supporting keywords. You can use this feature up to ${MAX_CLUSTERS_AI_FILTERING_ATTEMPTS} times on the same cluster list.` }}
                  </div>
                </v-tooltip>
                <v-tooltip top color="transparent" :disabled="!(finalKeywords.length === 0 || finalKeywords.length > 100) || (isWhiteGloveUser && !isMissionControlSession)">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn rounded
                        color="profileAnchorColor"
                        class="button-with-icon text-none whiteColor--text"
                        :disabled="finalKeywords.length === 0 || finalKeywords.length > 100 || (isWhiteGloveUser && !isMissionControlSession)"
                        @click="() => {
                          showGetKeywordDataCostWarnModal = true;
                        }">
                        <i class="material-icons-outlined">query_stats</i>
                        <span>Get Keyword Data</span>
                      </v-btn>
                    </span>
                  </template>
                  <div class="white-bg-tooltip small-tooltip">
                    {{
                      finalKeywords.length === 0
                      ?'Please select at least one cluster first'
                      :finalKeywords.length > 100
                        ?'Number of selected keywords must be less than 100. Please, deselect some keywords or clusters.'
                        :''
                    }}
                  </div>
                </v-tooltip>
              </div>
            </div>

            <v-btn
              v-if="selectedKeywords.length"
              outlined
              class="mb-2"
              :style="`border-color: #e1e1e1; background-color: #fff; text-transform: initial; color: var(--v-mainColor-base); letter-spacing: normal;`"
              @click="selectedKeywords = []"
            >
              Deselect all
            </v-btn>

            <v-btn
              v-else
              outlined
              class="mb-2"
              :style="`border-color: #e1e1e1; background-color: #fff; text-transform: initial; color: var(--v-mainColor-base); letter-spacing: normal;}`"
              @click="selectedKeywords = [...filteredKeywords.map(item => ({keyword: item.keyword, clusterName: item.clusterName}))]"
            >
              Select all
            </v-btn>

            <v-card v-for="clusterName in clusterNamesToDisp" :id="`clusterId_${clusterNameToId(clusterName)}`" :key="clusterName" class="white-common-box pa-5 mt-3 cluster-box">
              <div class="cluster-header">
                <i class="material-icons expand-icon" v-if="!expandedClusterNames.includes(clusterName)"
                  @click="() => { expandedClusterNames.push(clusterName) }">keyboard_arrow_right</i>
                <i class="material-icons expand-icon" v-else
                  @click="() => { expandedClusterNames = expandedClusterNames.filter(item => item != clusterName) }">keyboard_arrow_down</i>
                <v-checkbox color="#007aff" hide-details="auto" class="mt-0 pt-0" :input-value="finalClusterNames.includes(clusterName)" @change="toggleSelectedCluster(clusterName)"></v-checkbox>
                <span class="cluster-name">{{ clusterName }}</span>
                <span class="keywords-count">
                  {{ keywordsByCluster[clusterName]?.length }}
                  {{ keywordsByCluster[clusterName]?.length > 1 ? 'keywords' : 'keyword' }}
                </span>
                <span
                  class="best-match-label"
                  v-if="clusterName.startsWith('Top-level keywords for:') || clusterName.startsWith('Supporting keywords for:')"
                >
                  best match
                </span>
                <div class="flex-grow-1"></div>
                <span class="material-icons remove-cluster-icon" @click="() => {
                  extractedKeywords = extractedKeywords.filter(arg => arg.clusterName != clusterName)
                }">close</span>
              </div>
              <div class="cluster-body mt-5" v-if="expandedClusterNames.includes(clusterName)">
                <div class="keywords-wrapper">
                  <div v-for="item in keywordsByCluster[clusterName]" :key="item.keyword">
                    <v-checkbox color="#007aff" hide-details="auto" class="mt-0 pt-0" :input-value="Boolean(selectedKeywords.filter(selectedKeywords => item.keyword === selectedKeywords.keyword && item.clusterName === selectedKeywords.clusterName).length)" @change="toggleSelectedKeyword(item.keyword, item.clusterName)"></v-checkbox>
                    <span class="font-weight-bold">{{ item.keyword }}</span>
                    <span v-if="item.keyword_intent" style="text-transform: capitalize;">[ {{ item.keyword_intent }} ]</span>
                    <span>( {{ item.source }} )</span>
                  </div>
                </div>
              </div>
            </v-card>
            <!-- {{ keywordsByCluster }} -->
            <div class="text-center mt-3">
              <v-btn outlined rounded color="mainColor"
                v-if="showClustersCount < clusterNames.length"
                @click="() => {
                  showClustersCount += 10;
                }"
                class="text-none">
                <span>Load more</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-10" v-if="researchReport && !isCalculating && selectedTab === 'keyword-data-results'">
          <keyword-research-result
            reportType="TopLevelSupportKeywordResearch"
            :report="researchReport"
            :showSiloExtraActions="false"
            :researchHistory="keywordWizardReports"
            :hasUnlimitedAccess="hasUnlimitedAccess"
            :showModal="showModal"
            :modalTitle="modalTitle"
            :modalType="modalType"
            :reportTitle="reportTitle"
            @onChangeResearchId="(id) => { selectedResearchReportId = id }"
            @onKeywordLabelUpdateSuccess="onKeywordLabelUpdateSuccess"
            @refreshReport="() => loadResearchReport()"
            @onAIResultReady="() => loadResearchReport()"
            @requestedAdvancedData="(keywords) => getAdvancedDataAction(keywords)"

            @rename="onShowModal(`Rename ${$event.name}`, 1, $event.name, $event.id)"
            @toggle-favorite="updateFavoriteStatus($event)"
            @delete="onShowModal(`Delete ${$event.name}`, 2, '', $event.id)"

            @cancel="onCancelModal"
            @close="onCloseModal"
            @update-title="updateReportTitle">
          </keyword-research-result>
        </div>
      </div>
    </div>
  
    <div class="modal search-keywords-cost-modal" :class="{'is-active': showSearchKeywordsCostModal}">
      <div class="modal-background" @click.stop="showSearchKeywordsCostModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <p class="title-font is-size-4 mainColor--text">Search keywords</p>
            <div class="mainColor--text message-text" style="font-size: 1rem; line-height: 150%;">
              You have selected:
              <ul class="mt-2 mb-2" style="list-style: initial;">
                <li class="font-weight-bold" v-if="!checkOnlyCompetitors && config.wizardTopics.length">{{ config.wizardTopics.length }} topic{{config.wizardTopics.length >1 ?'s':''}}</li>
                <li class="font-weight-bold" v-if="competitorsListInputed?.length">{{ competitorsListInputed.length }} competitor url{{competitorsListInputed.length >1 ?'s':''}}</li>
              </ul>
              It will cost <span class="font-weight-bold">{{ (checkOnlyCompetitors?0:config.wizardTopics.length) + Math.ceil(competitorsListInputed.length / 5) }} POP Credit{{(checkOnlyCompetitors?0:config.wizardTopics.length) + Math.ceil(competitorsListInputed.length / 5) >1 ?'s':''}}</span> to search keywords for the selected items. Results will be organized in keyword clusters.<br />
            </div>
            <div class="d-flex justify-end gap10 mt-5">
              <v-btn rounded outlined color="mainColor"
                class="text-none"
                @click.stop="() => {
                  showSearchKeywordsCostModal = false;
                }">
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded color="profileAnchorColor"
                class="whiteColor--text text-none"
                @click.stop="startSearchKeywords">
                <span>Search keywords</span>
              </v-btn>
              
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showSearchKeywordsCostModal = false)">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
    
    <div class="modal keyword-data-cost-modal" :class="{'is-active': showGetKeywordDataCostWarnModal}">
      <div class="modal-background" @click.stop="showGetKeywordDataCostWarnModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <p class="title-font is-size-4 mainColor--text">Get cluster keyword data</p>
            <div class="mainColor--text message-text">
              <div class="mb-2">You’ve selected <span class="font-weight-bold">{{ finalClusterNames.length }} cluster{{finalClusterNames.length >1 ?'s':''}}</span> containing a total of <span class="font-weight-bold">{{ finalKeywords.length }} keyword{{finalKeywords.length >1 ?'s':''}}</span>.</div>
              <div class="mb-1">We’ll retrieve detailed metrics for {{finalKeywords.length >1 ?'each':'the'}} keyword, including:</div>
              <ul class="mb-2" style="list-style: initial;">
                <li>Keyword Difficulty Score</li>
                <li>Search Volume</li>
                <li>Search Volume trend</li>
                <li>Ranking URL and Position</li>
              </ul>

              <template v-if="!hasUnlimitedAccess">
                <br/>
                This action will consume <span class="font-weight-bold">{{ Math.ceil(finalKeywords.length / 100) }} POP Credit{{Math.ceil(finalKeywords.length / 100) >1 ?'s':''}}</span>. Click <span class="font-weight-bold">Get Data</span> to proceed.
              </template>
            </div>
            <div class="d-flex justify-end gap10 mt-5">
              <v-btn rounded outlined color="mainColor"
                class="text-none"
                @click.stop="() => {
                  showGetKeywordDataCostWarnModal = false;
                }">
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded color="profileAnchorColor"
                class="whiteColor--text text-none"
                @click.stop="() => createSupportKeywordResearchAction()">
                <span>Get data</span>
              </v-btn>
              
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showGetKeywordDataCostWarnModal = false)">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
    <keyword-research-modal
      :showModal="showModal"
      :modalTitle="modalTitle"
      :modalType="modalType"
      :reportTitle="reportTitle"
      @cancel="onCancelModal"
      @close="onCloseModal"
      @update-title="updateReportTitle"
    />
    <div class="modal" :class="{'is-active': showAIFormModal}">
      <div class="modal-background" @click.stop="showAIFormModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container">
          <div class="modal-content-root">
            <p class="title-font is-size-4 mainColor--text">Filter clusters with AI</p>
            <div class="mainColor--text" style="font-size: 1rem; line-height: 150%;">
              AI will filter keywords to highlight the most relevant options for your content strategy, dividing them into top-level and supporting keywords. You can repeat this process up to {{MAX_CLUSTERS_AI_FILTERING_ATTEMPTS}} times for the same cluster list.<br /><br />
              <v-text-field outlined dense
                v-model="aiTopicFieldValue"
                placeholder="Enter topic or target keyword"
                :error="aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80"
                :error-messages="(aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80)?'Must be 1 to 80 characters':''"
                color="grayColor"
              ></v-text-field>
            </div>
            <div class="d-flex justify-end gap10 mt-5">
              <v-btn rounded outlined color="mainColor"
                class="text-none"
                @click.stop="() => {
                  showAIFormModal = false;
                }">
                <span>Cancel</span>
              </v-btn>
              <v-btn rounded color="profileAnchorColor"
                class="whiteColor--text text-none"
                :disabled="aiTopicFieldValue.length < 1 || aiTopicFieldValue.length > 80"
                @click.stop="() => {
                  analyzeClustersWithAI(); showAIFormModal = false
                }"
              >
                <span>Run</span>
              </v-btn>
              
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click="() => (showAIFormModal = false)">
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import moment from 'moment';
  import Multiselect from 'vue-multiselect'
  
  import dataForSeoLocations from '@/consts/dataforseo_locations.json';
  import languages from '@/consts/dataforseo_languages.json';
  import { getHostName } from '@/utils'
  import ClustersChart from '@/components/Campaigns/keyword-wizard/existing-content/supporting-keyword-research/ClustersChart.vue';
  import { getSplittedKeywords, getValidKeywords } from '@/components/Campaigns/CommonFunctions.js'
  import KeywordResearchResult from '@/components/Campaigns/KeywordResearchResult.vue';
  import { mixin as export_mixin } from '@/components/export_mixin'
  import * as d3 from 'd3';
  import { toLocalDateString, getPureServicePkgName } from '../../../../../utils';
  import CustomAutoComplete from '@/common/CustomAutoComplete.vue';
  import KeywordResearchModal from '@/common/KeywordResearchModal.vue';
  import {
    whiteGlovePlans,
  } from "@/components/plans_constants"
  
  const MAX_CLUSTERS_AI_FILTERING_ATTEMPTS = 10
  const ALLOWED_KEYWORD_COUNT = 10
  const KeywordTypeOptionsFull = [
    "Related Keywords",
    // "Keyword Suggestions",
    "Competitor's Keyword",
    "People Also Ask",
    "Google Suggest",
    "AI Generated",
  ]
  const question_prefixs = 'who|what|where|when|why|how|was|were|did|do|does|is|are'.split('|')
  
  export default {
    mixins: [ export_mixin ],
    components:  {
      Multiselect,
      ClustersChart,
      KeywordResearchResult,
      CustomAutoComplete,
      KeywordResearchModal,
    },
    props: ['hasUnlimitedAccess'],
    data () {
      return {
  
        selectedLocation: '',
        selectedLanguage: '',
        domain: '',
        topics: [],
        competitorUrls: '',
        checkOnlyCompetitors: false,
        selectedFile: null,
        // selectedFile: {
        //   name: 'test.xlsx'
        // },
        STEPS: {
          'LOADING_KEYWORD': 'LOADING_KEYWORD',
          'LOADING_RESULT_FOR_KEYWORD': 'LOADING_RESULT_FOR_KEYWORD',
        },
        currentStep: '',
  
        topicsUsedInExtrackingKeyword: [],
        extractedKeywords: [],
        showClustersCount: 10,
        expandedClusterNames: [],
        selectedKeywords: [],
  
        keywordTypeOptions: KeywordTypeOptionsFull,
        keywordTypesFilter: [],
        showKeywordTypeFilterPopup: false,
        includeQuestionKeyword: false,

        showKeywordIntentFilterPopup: false,
        keywordIntentsFilter: [],
  
        includeFilterTermText: '',
        includeAllFilter: false,
        excludeFilterTermText: '',
        excludeAllFilter: false,
        showIncludeExcludeFilterPopup: false,
  
        showSearchKeywordsCostModal: false,

        showGetKeywordDataCostWarnModal: false,
  
        researchHistory: [],
        selectedResearchReportId: null,
        researchReport: null,
  
        isStartingAsyncTask: false,
        isAwaitingAsyncTaskStatus: false,

        keywordWizardReports: [],

        showModal: false,
        reportTitle: '',
        modalTitle: '',
        modalType: 1,
        selectedReportId: 0,
        selectedTab: 'clusters',

        showAIFormModal: false,
        aiTopicFieldValue: '',
      }
    },
    computed: {
      MAX_CLUSTERS_AI_FILTERING_ATTEMPTS() {
        return MAX_CLUSTERS_AI_FILTERING_ATTEMPTS
      },
      config() {
        return this.$store.getters.insightKeywordsConfig;
      },
      user() {
        return this.$store.state.user
      },
      isWhiteGloveUser () {
        if (!this.user) return false;

        const accountType = this.$store.state.selectedSubAccountRelation
          ?this.$store.state.selectedSubAccountRelation.ownerAccountType
          :this.user.accountType

        if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
          return true;
        }
        return false;
      },
      isMissionControlSession () {
        return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
      },
      campaignId () {
        return parseInt(this.$route.params.campaignId || 0)
      },
      campaign () {
        return this.$store.state.currentCampaign
      },
      competitorUrlsCountInputed () {
        return this.config.wizardCompTexts.split('\n').filter(item => item.trim()).length
      },
      competitorsListInputed() {
        return this.config.wizardCompTexts.split('\n').filter(item => item.trim())
      },
      // googleSearchLocations() {
      //   if (this.$store.getters.getGoogleLocations) {
      //     return this.$store.getters.getGoogleLocations.filter(item => !item.includes('Extension'))
      //   }
      //   return []
      // },
      // targetLanguages () {
      //   return this.$store.state.targetLanguages.map(item => {
      //     return {
      //       text: capitalize(item),
      //       value: item
      //     }
      //   })
      // },
      availableLocations () {
        return dataForSeoLocations.locations;
      },
      targetLanguages () {
        return languages;
      },
      canStartSearchKeywords () {
        if (this.checkOnlyCompetitors) {
          return this.competitorUrlsCountInputed > 0 && this.competitorUrlsCountInputed <= 20
        }
        return ((this.config?.wizardTopics?.length || 0) > 0 && (this.config?.wizardTopics?.length || 0) <= 10) || (this.competitorUrlsCountInputed > 0 && this.competitorUrlsCountInputed <= 20)
      },
      clusterNames () {
        let rlt = [];
        this.filteredKeywords.map(item => {
          if (!rlt.includes(item.clusterName)) {
            rlt.push(item.clusterName);
          }
        })
        rlt.sort();
        return rlt;
      },
      clusterNamesToDisp () {
        let sortedClusterNames = [...this.clusterNames]
        sortedClusterNames.sort((a, b) => {

          let aValue = 2
          if (a.startsWith('Top-level keywords for:')) {
            aValue = 0
          } else if (a.startsWith('Supporting keywords for:')) {
            aValue = 1
          }
          let bValue = 2
          if (b.startsWith('Top-level keywords for:')) {
            bValue = 0
          } else if (b.startsWith('Supporting keywords for:')) {
            bValue = 1
          }

          return aValue - bValue
        })
        return sortedClusterNames.slice(0, this.showClustersCount)
      },
      hasAlreadyAnalyzedClustersWithAI() {
        return (this.extractedKeywords || []).filter(keyword => keyword.is_best_match_keyword).length > 0
      },
      keywordsByCluster () {
        let rlt = {}
        for ( const item of this.filteredKeywords) {
          if (!(item.clusterName in rlt)) {
            rlt[item.clusterName] = []
          }
          rlt[item.clusterName].push({
            ...item
          })
        }
        return rlt
      },
      filterTermFilerCount () {
        let includeFilters = getSplittedKeywords(this.includeFilterTermText)
        let exlcudeFilters = getSplittedKeywords(this.excludeFilterTermText)
        return includeFilters.length + exlcudeFilters.length;
      },
      filteredKeywords () {
        return this.extractedKeywords.filter(item => { // Apply include filters
          const filterTerms = this.includeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
          if (filterTerms.length == 0) return true;
  
          if (!this.includeAllFilter) {
            for (const term of filterTerms) {
              if (item['keyword'].includes(term)) {
                return true;
              }
            }
            return false;
          } else {
            for (const term of filterTerms) {
              if (!item['keyword'].includes(term)) {
                return false;
              }
            }
            return true;
          }
        })
        .filter(item => {  // Apply exclude filters
          const filterTerms = this.excludeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
          if (filterTerms.length == 0) return true;
  
          if (!this.excludeAllFilter) {
            for (const term of filterTerms) {
              if (item['keyword'].includes(term)) {
                return false;
              }
            }
            return true;
          } else {
            for (const term of filterTerms) {
              if (!item['keyword'].includes(term)) {
                return true;
              }
            }
            return false;
          }
        })
        .filter(item => {
          if (this.includeQuestionKeyword) {
            for (let index = 0; index < question_prefixs.length; index++) {
              const element = question_prefixs[index];
              if (item['keyword'].startsWith(element)) {
                return true
              }
            }
            return false
          } else {
            return true
          }
        })
        .filter(item => { // Keyword Type filter
          if (this.keywordTypesFilter.length > 0) {
            for (let index = 0; index < this.keywordTypesFilter.length; index++) {
              const keywordType_ = this.keywordTypesFilter[index];
              if (item['source'].includes(keywordType_)) {
                return true
              }
            }
            return false
          }
          return true
        })
        .filter(item => { // keyword intent filter
          if (this.keywordIntentsFilter.length > 0) {
            return this.keywordIntentsFilter.includes(item?.['keyword_intent'])
          }
          return true;
        })
      },
      finalKeywords () {
        // NOTE(Hichem): we transform selectedkeywords to a map for enhancing performance when a lot of keywords are selected (more than 1000)
        const selectedKeywordsMap = Object.fromEntries(this.selectedKeywords.map(selectedKeyword => [`${selectedKeyword.keyword}-${selectedKeyword.clusterName}`, true]))
        return this.filteredKeywords.filter(item => {
          return selectedKeywordsMap[`${item.keyword}-${item.clusterName}`]
        })
      },
      finalClusterNames () {
        let rlt = [];
        this.finalKeywords.map(item => {
          if (!rlt.includes(item.clusterName)) {
            rlt.push(item.clusterName);
          }
        })
        return rlt;
      },
      allKeywordIntents () {
        let rlt = [];
        this.extractedKeywords.map(item => {
          if (item.keyword_intent && !rlt.includes(item.keyword_intent)) {
            rlt.push(item.keyword_intent);
          }
        })
        return rlt;
      },
      asyncTasks() {
        return this.$store.state.asyncTasks
      },
      currentAsyncTask() {
        return this.asyncTasks.filter(task => task.id === this.researchReport?.taskId)[0]
      },
      isCalculating() {
        return this.isStartingAsyncTask || this.isAwaitingAsyncTaskStatus || this.currentAsyncTask?.is_pending_or_in_progress
      },
    },
    watch: {
      topics: function (newVal) {
        if (this.config.wizardTopics !== newVal) {
          this.setConfig("wizardTopics", newVal)
        }
      },
      campaign: function(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.domain = getHostName(newVal.targetUrl)
          this.setConfig("wizardLanguage2", newVal.languageCode || 'en')
          if (newVal.locationCode) {
            this.setConfig("wizardLocation2", parseInt(newVal.locationCode || 0))
          }
          this.loadResearchReport()
        }
      },
      selectedResearchReportId: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && newVal !== this?.researchReport?.id) {
          this.loadResearchReport()
        }
      },
      currentAsyncTask: function (newVal, oldVal) {
        if (newVal && !oldVal) {
          this.isAwaitingAsyncTaskStatus = false;
        }
        if (newVal?.is_completed && newVal?.is_completed !== oldVal?.is_completed) {
          if (this.researchReport.currentStep == 0) {
            this.setExtractedKeywordsAction(newVal.task_status?.results?.keywords || []);
          } else {
            this.selectedTab = 'keyword-data-results';
          }
          this.loadResearchReport(true)
        }
      },
    },
    methods: {
      updateReportTitle(value) {
        this.reportTitle = value;
      },
      onShowModal(title, type, currentTitle = '', reportId = 0) {
        this.reportTitle = currentTitle;
        this.showModal = true;
        this.modalTitle = title;
        this.modalType = type;
        this.selectedReportId = reportId;
      },
      onCancelModal() {
        this.showModal = false;
        this.modalTitle = '';
        this.modalType = 1;
        this.reportTitle = '';
        this.selectedReportId = 0;
      },
      onCloseModal() {
        if (this.modalType == 1) {
          this.updateKeywordWizardReportName(
            this.selectedReportId,
            'name',
            this.reportTitle,
            () => {
              this.onCancelModal()
            }
          );
        } else {
          this.deleteKeywordWizardReport(this.selectedReportId, () => {
            this.onCancelModal()
          });
        }
      },
      updateFavoriteStatus(item) {
        this.updateKeywordWizardReportName(item.id, 'isFavorite', !item.isFavorite, () => {})
      },
      updateKeywordWizardReportName(reportId, key, value, onComplete = () => {}) {
        this.$store.commit('showLoading')
        this.$store.dispatch('updateKeywordWizardReport', {
          data: {
            researchId: reportId,
            key,
            value
          },
          subAccountRelationId: this.subAccountRelationId
        }).then(() => {
          this.loadKeywordWizardReports()
        }).catch((e) => {
          console.log('Error starring/unstarring keyword wizard report', e)
          // do nothing
        }).finally(() => {
          this.$store.commit('hideLoading')
          onComplete()
        })
      },
      deleteKeywordWizardReport(reportId, onComplete = () => {}) {
        this.$store.commit('showLoading')
        this.$store.dispatch('deleteKeywordWizardReport', {
          reportId,
          subAccountRelationId: this.subAccountRelationId
        }).then(() => {
          this.loadKeywordWizardReports(true)
        }).catch((e) => {
          console.log('Error deleting keyword wizard report', e)
          // do nothing
        }).finally(() => {
          this.$store.commit('hideLoading')
          onComplete()
        })
      },
      toLocalDateString (d) {
        return toLocalDateString(d)
      },
      loadKeywordWizardReports (updateSelectedReportId = false) {
        this.$store.dispatch('fetchKeywordWizardReports', this.campaignId)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.keywordWizardReports = response.data.data
            if (updateSelectedReportId) {
              this.selectedResearchReportId = this.keywordWizardReports.length > 0 ? this.keywordWizardReports[0].id : 0;
            }
          }
        })
        .catch(error => {
          console.log('Error fetching keyword wizard reports', error)
        })
      },
      downloadClustersAndKeywords () {
        let data = []
        data.push(['Cluster', 'Keyword'])
        this.finalKeywords.map(item => {
          data.push([item.clusterName, item.keyword])
        })
        let csvData = data.map(item => {
          return item.join(',')
        }).join('\n')
        const timestamp = moment().format('YYYY_M_D_h_mm_ss')
        this.exportToCSV(csvData, `clusters_and_keywords_${timestamp}.csv`)
      },
      clusterNameToId (name) {
        return name.replaceAll(' ', '_')
      },
      toggleSelectedKeyword (keyword, clusterName) {
        const isSelected = Boolean(this.selectedKeywords.filter(item => item.keyword === keyword && item.clusterName === clusterName).length)
        if (isSelected) {
          this.selectedKeywords = this.selectedKeywords.filter(item => item.keyword !== keyword || item.clusterName !== clusterName)
        } else {
          this.selectedKeywords = [...this.selectedKeywords, {keyword: keyword, clusterName: clusterName}]
        }
      },
      toggleSelectedCluster (clusterName) {
        if (this.finalClusterNames.includes(clusterName)) {
          this.selectedKeywords = this.selectedKeywords.filter(item => item.clusterName !== clusterName)
        } else {
          const clusterKeywords = this.filteredKeywords.filter(item => item.clusterName === clusterName)
          this.selectedKeywords = [...this.selectedKeywords, ...clusterKeywords.map(item => ({keyword: item.keyword, clusterName: item.clusterName}))]
        }
      },
      selectBestMatchClusters () {
        const bestMatchClusterNames = this.clusterNamesToDisp.filter(clusterName => clusterName.startsWith('Top-level keywords for:') || clusterName.startsWith('Supporting keywords for:'))
        
        if (bestMatchClusterNames.length) {
          this.selectedKeywords = []
          bestMatchClusterNames.forEach(name => {
            this.toggleSelectedCluster(name)
          })
        }
      },
      showClusterDetail (cName) {
        if (!this.expandedClusterNames.includes(cName)) {
          this.expandedClusterNames.push(cName)
        }
        this.$nextTick(() => {
          document.getElementById(`clusterId_${this.clusterNameToId(cName)}`).scrollIntoView({
            behavior: 'smooth'
          });
        })
      },
      showMoreClusters () {
        this.showClustersCount += 10;
        this.$nextTick(() => {
          setTimeout(() => {
            let divObj = document.getElementById("mainBodyScrollArea");
            divObj.scrollTop = divObj.scrollHeight;
          }, 500);
        })
      },
      setConfig(key, value) {
        this.$store.commit("setInsightKeywordsConfig", { key, value });
      },
      // clusterChartPaddingStyle (idx) {
      //   let gap = 0;
      //   let countInOneSide = this.clusterNames / 2
      //   if ( idx % 2 == 0) { // left side
      //     let sideIndex = Math.int(idx / 2);
      //   } else {
  
      //   }
      // },
      addTopicAction (newTopic) {
        if (newTopic) {
          const topics = getSplittedKeywords(newTopic)
          const result = [...this.config.wizardTopics, ...topics]
          this.topics = result.filter((k, pos) => result.indexOf(k) == pos)
        }
      },
      loadResearchReport (scrollBottom = false) {
        this.extractedKeywords = []
        this.$store.commit('showLoading')
        // reload user to update ui with any credit usage
        this.$store.dispatch("loadUser").then(response => {
          this.$store.commit("setUser", response.data.user);
        });

        return this.$store.dispatch('topLevel_loadSupportKeywordResearchData', {
          researchId: this.selectedResearchReportId,
        })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.researchReport = response.data.research;
            this.setConfig("wizardLanguage2", this.researchReport.languageCode)
            this.setConfig("wizardLocation2", parseInt(this.researchReport.locationCode || 0))

            this.setConfig("wizardCompTexts", this.researchReport.competitorUrls.join('\n'))
            this.checkOnlyCompetitors = Boolean(this.researchReport.checkOnlyCompetitors)

            this.topics = this.researchReport.targetTopics

            this.topicsUsedInExtrackingKeyword = !this.checkOnlyCompetitors
              ?this.researchReport.targetTopics
              :[]
            if (this.researchReport.competitorUrls.length) {
              this.topicsUsedInExtrackingKeyword = [
                ...this.topicsUsedInExtrackingKeyword,
                `${this.researchReport.competitorUrls.length} competitor url${this.researchReport.competitorUrls.length>1?'s':''}`
              ]
            }

            if ((this.researchReport?.allClusteredKeywords?.length || 0) > 0) {
              this.setExtractedKeywordsAction(this.researchReport.allClusteredKeywords)
            }
            if (this.researchReport.currentStep == 0) {
              this.currentStep = this.STEPS.LOADING_KEYWORD
            } else if (this.researchReport.currentStep == 1) {
              
            }

            if (scrollBottom) {
              setTimeout(() => {
                document.querySelector(".result-area").scrollIntoView({
                  behavior: 'smooth'
                });
              }, 500);
            }
          }
        }) .finally(() => {
          this.$store.commit('hideLoading')
        })
      },
      loadSupportKeywordResearchHistoryForCampaign( scrollBottom = false ) {
        return this.$store.dispatch('topLevel_getSupportKeywordResearchHistoryForCampaign', {
          campaignId: this.campaignId
        })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.researchHistory = response.data.researches
            if (this.researchHistory.length > 0 && (!this.researchReport || this.selectedResearchReportId < this.researchHistory[0].id )) {
              this.selectedResearchReportId = this.researchHistory[0].id
            }
            if (scrollBottom) {
              setTimeout(() => {
                document.querySelector(".result-area").scrollIntoView({
                  behavior: 'smooth'
                });
              }, 500);
            }
            return Promise.resolve()
          }
          return Promise.reject()
        })
      },

      resetFileSelected () {
        this.selectedFile = null;
      },
      startSearchKeywords () {
        this.showSearchKeywordsCostModal = false;
        if (this.config.wizardTopics.length > ALLOWED_KEYWORD_COUNT) {
          return this.$notify({
            group: 'info', type: 'error',
            text: `${ ALLOWED_KEYWORD_COUNT } keywords are allowed at max`
          })
        }
        this.extractedKeywords = []
  
        const topics = this.config.wizardTopics.slice(0, 10)

        this.topicsUsedInExtrackingKeyword = !this.checkOnlyCompetitors
          ?topics
          :[]
        if (this.competitorsListInputed.length) {
          this.topicsUsedInExtrackingKeyword = [
            ...this.topicsUsedInExtrackingKeyword,
            `${this.competitorsListInputed.length} competitor url${this.competitorsListInputed.length>1?'s':''}`
          ]
        }
          
        const formData = {
          campaignId: this.campaign?.id || 0,
          topics,
          domain: this.domain,
          locationCode: this.config.wizardLocation2,
          languageCode: this.config.wizardLanguage2,
          checkOnlyCompetitors: this.checkOnlyCompetitors,
          competitors: this.competitorsListInputed,
          minWordsLimit: 1,
          maxWordsLimit: 100,
        }

        this.currentStep = this.STEPS.LOADING_KEYWORD
        this.isStartingAsyncTask = true
        this.$store.dispatch('topLevel_getKeywordsFromTopic', formData)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.$store.commit('triggerAsyncTasksRefresh');
            this.researchReport = response.data.report
            this.loadKeywordWizardReports(false)
            this.selectedResearchReportId = response.data.report.id
            this.isAwaitingAsyncTaskStatus = true;
            this.isStartingAsyncTask = false
            this.$notify({
              group: 'info', type: 'success',
              text: 'Keyword research task is started!'
            })
          } else {
            this.isStartingAsyncTask = false;
            this.$notify({
              group: 'info', type: 'error',
              text: response?.data?.msg || 'Failed to create keyword research task'
            })
          }
        })
        .catch(() => {
          this.isStartingAsyncTask = false
        })
      },
      setExtractedKeywordsAction (arg_extractedKeywords) {
        this.extractedKeywords = arg_extractedKeywords
        if (this.hasAlreadyAnalyzedClustersWithAI) {
          this.selectBestMatchClusters()
        } else {
          this.selectedKeywords = this.extractedKeywords.map(item => ({keyword: item.keyword, clusterName: item.clusterName}));
        }
      },
      analyzeClustersWithAI () {
  
        const postData = {
          reportId: this.researchReport.id,
          topic: this.aiTopicFieldValue
        }

        this.isStartingAsyncTask = true
        this.$store.dispatch('topLevel_analyzeClustersWithAI', postData)
        .then(response => {
          if (response.data) {
            if (response.data.status == 'SUCCESS') {
              this.$store.commit('triggerAsyncTasksRefresh');
              this.researchReport = response.data.report
              this.selectedResearchReportId = response.data.report.id
              this.isAwaitingAsyncTaskStatus = true;
              this.isStartingAsyncTask = false
            } else if (response.data.status == 'FAILURE') {
              this.$notify({
                group: 'info', type: 'error',
                text: response?.data?.msg || 'Failed to start clusters analysis'
              })
              this.isStartingAsyncTask = false
            }
          }
        })
        .catch(() => {
          this.isStartingAsyncTask = false
        })
      },
      createSupportKeywordResearchAction () {
        this.showGetKeywordDataCostWarnModal = false;
  
        const keywords = getValidKeywords(this.finalKeywords.map(f => f.keyword))
        const postData = {
          campaignId: this.campaignId,
          reportId: this.researchReport ? this.researchReport.id : 0,
          keywords,
          locationCode: this.config.wizardLocation2,
          languageCode: this.config.wizardLanguage2,
          domain: this.domain,
          targetTopics: this.topicsUsedInExtrackingKeyword,
          competitorUrls: this.competitorsListInputed
        }

        this.currentStep = this.STEPS.LOADING_RESULT_FOR_KEYWORD
        this.isStartingAsyncTask = true
        this.$store.dispatch('topLevel_createSupportKeywordResearch', postData)
        .then(response => {
          if (response.data) {
            if (response.data.status == 'SUCCESS') {
              this.$store.commit('triggerAsyncTasksRefresh');
              this.researchReport = response.data.report
              this.selectedResearchReportId = response.data.report.id
              this.isAwaitingAsyncTaskStatus = true;
              this.isStartingAsyncTask = false
            } else if (response.data.status == 'FAILURE') {
              this.$notify({
                group: 'info', type: 'error',
                text: response?.data?.msg || 'Failed to analyze keyword research data'
              })
              this.isStartingAsyncTask = false
            }
          }
        })
        .catch(() => {
          this.isStartingAsyncTask = false
        })
      },
      getAdvancedDataAction (keywords) {
  
        const postData = {
          campaignId: this.campaignId,
          reportId: this.researchReport.id,
          keywords,
          advanced_mode: true,
        }

        this.isStartingAsyncTask = true
        this.$store.dispatch('topLevel_createSupportKeywordResearch', postData)
        .then(response => {
          if (response.data) {
            if (response.data.status == 'SUCCESS') {
              this.$store.commit('triggerAsyncTasksRefresh');
              this.researchReport = response.data.report
              this.selectedResearchReportId = response.data.report.id
              this.isAwaitingAsyncTaskStatus = true;
              this.isStartingAsyncTask = false
            } else if (response.data.status == 'FAILURE') {
              this.$notify({
                group: 'info', type: 'error',
                text: response?.data?.msg || 'Failed to analyze keyword research data'
              })
              this.isStartingAsyncTask = false
            }
          }
        })
        .catch(() => {
          this.isStartingAsyncTask = false
        })
      },
      d3TestChart () {
        let data = {
  
        }
        const root = d3.hierarchy(data);
      },
      onKeywordLabelUpdateSuccess(keyword, label) {
        this.researchReport = {
          ...this.researchReport,
          result: this.researchReport.result.map((row) => {
            if (label === 'T' && row.isTopLevelKeyword) {
              return {
                ...row,
                isTopLevelKeyword: false
              }
            }
            if (row.keyword === keyword) {
              return {
                ...row,
                isTopLevelKeyword: label === 'T',
                isSupportingKeyword: label === 'S',
              }
            }
            return row
          })
        }
      },
      handleStickyKeywordsListHeader () {
        const el = document.querySelector(".sticky-keywords-list-header");
        if (el) {
          const mainBodyTop = document.querySelector('#mainBodyScrollArea').getBoundingClientRect().top;
          const currentTop = el.getBoundingClientRect().top;
          el.classList.toggle("is-pinned", currentTop === mainBodyTop);
        }
      }
    },
    created () {
      document.querySelector('#mainBodyScrollArea').addEventListener('scroll', this.handleStickyKeywordsListHeader);
    },
    destroyed () {
      document.querySelector('#mainBodyScrollArea').removeEventListener('scroll', this.handleStickyKeywordsListHeader);
    },
    mounted () {
      Promise.resolve()
      .then(() => {
        this.domain = getHostName(this.campaign.targetUrl)
        const lang = this.campaign.languageCode || 'en'
        const loc = parseInt(this.campaign.locationCode || 0)
        if (lang && !this.config.wizardLanguage2) {
          this.setConfig("wizardLanguage2", lang)
        }
        if (loc && !this.config.wizardLocation2) {
          this.setConfig("wizardLocation2", loc)
        }
        if (this.config?.wizardTopics?.length) {
          this.topics = this.config.wizardTopics
        }
        
        return Promise.resolve()
      })
      .then(() => {
        return this.loadKeywordWizardReports(true)
      })
    }
  }
  </script>
  
  
  
  <style lang="scss" scoped>
  @import "../../../keyword-research.scss";
  
  .general-info {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--v-mainColor-base);
  }
  .cluster-chart {
    position: relative;
    min-height: 300px;
  
    .clusters-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 600px;
      row-gap: 5px;
      & > div {
        color: var(--v-mainColor-base);
      }
    }
    .cluster-name-in-chart {
      cursor: pointer;
    }
  
    .topics-inputed {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 30px;
      border: 1px solid var(--v-grayColor-base);
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      & > div {
        text-align: center;
        font-weight: 700;
        font-size: 1.5rem;
        color: var(--v-mainColor-base);
      }
    }
  }
  
  
  .cluster-box {
    .cluster-header {
      display: flex;
      align-items: center;
      gap: 15px;
  
      .expand-icon {
        cursor: pointer;
      }
      .cluster-name {
        font-weight: 700;
        font-size: 1.1rem;
        color: var(--v-mainColor-base);
      }
      .keywords-count {
        padding: 5px 10px;
        border-radius: 5px;
        background: var(--v-lightYellowColor-base);
        color: var(--v-profileAnchorColor-base);
        font-weight: 700;
      }

      .best-match-label {
        padding: 5px 10px;
        border-radius: 40px;
        background: #CDF7E3;
        color: #006843;
        font-weight: 700;
      }
    }
    .cluster-body {
      .keywords-wrapper {
        & > div {
          margin-top: 10px;
          background: var(--v-gray7Color-base);
          color: var(--v-mainColor-base);
          padding: 5px 10px;
          border-radius: 50vh;
          display: flex;
          align-items: center;
          gap: 5px;
          span.close {
            color: var(--v-profileAnchorColor-base);
            cursor: pointer;
          }
        }
      }
    }
  }
  .remove-cluster-icon {
    color: var(--v-profileAnchorColor-base);
    cursor: pointer;
  }

  .sticky-keywords-list-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--v-mainBgColor-base);
    padding-top: 18px;
    padding-bottom: 18px;

    &.is-pinned {
      box-shadow: 0px 8px 6px -4px rgba(0, 0, 0, 0.18);
    }
  }

  .keyword-data-cost-modal, .search-keywords-cost-modal {
    .message-text, .message-text * {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  </style>
