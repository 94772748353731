<template>
  <section class="section">
    <div class="pl-2 pr-2">
      <div class="columns">
        <div class="column">
          <div class="columns is-desktop">
            <div class="column is-2">
              <div class="field">
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="perPage" @change="onPerPageChange">
                      <option value="10">10 per page</option>
                      <option value="15">15 per page</option>
                      <option value="25">25 per page</option>
                      <option value="50">50 per page</option>
                      <option value="100">100 per page</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div class="field">
                <div class="control">
                  <input type="text" class="input" placeholder="Search" v-model="searchText" @input="changeSearchText">
                </div>
              </div>
            </div>
            <div class="column is-3">
              <date-picker v-model="dates" range></date-picker>
            </div>
            <div class="column is-2">
              <button class="button is-info is-fullwidth" @click="onFilter">Search</button>
            </div>
            <div class="column is-2">
              <button @click="exportAll" class="button is-link is-fullwidth">Export All</button>
            </div>
          </div>

          <div v-if="totalPaid" class="mb-4 text-right mainColor--text font-weight-bold" style="font-size: 1rem;">
            Total paid: ${{ totalPaid }}.00
          </div>
          
          <b-table :data="records" :loading="isLoading" paginated backend-pagination :total="totalRows"
            :per-page="perPage" :current-page="currentPage" @page-change="onPageChange" backend-sorting
            :default-sort-direction="defaultSortOrder" :default-sort="[sortField, sortOrder]" @sort="onSort">
            <template slot-scope="props">
              <b-table-column field="userId" label="User ID" sortable>{{ props.row.userId }}</b-table-column>
              <b-table-column field="userEmail" label="Email" sortable>{{ props.row.userEmail }}</b-table-column>
              <b-table-column field="id" label="Silo Order ID" sortable numeric>{{ props.row.id }}</b-table-column>
              <b-table-column field="delivery_period_starts_at" label="Silo Order Date" sortable numeric>{{ props.row.delivery_period_starts_at }}</b-table-column>
              <b-table-column field="price_on_checkout" label="Silo Amount ($)" sortable numeric>
                <span v-if="![null, undefined].includes(props.row.price_on_checkout)">
                  ${{ props.row.price_on_checkout }}.00
                </span>
                <span v-else>
                  -
                </span>
              </b-table-column>
              <b-table-column field="due_date" label="Delivery date" sortable numeric>{{ props.row.due_date }}</b-table-column>
              <template v-for="pkg in packages">
                <b-table-column :key="pkg.key + 'quantity'" :field="pkg.key + pkg.type" :label="getReportColumnLabel(pkg)" numeric>
                  <div style="min-width: 100px;">{{ getReportQuantity(props.row, pkg.key) }}</div>
                </b-table-column>
                <b-table-column :key="pkg.key + 'price'" :field="pkg.key + pkg.type" :label="'$ ' + getReportColumnLabel(pkg)" numeric>
                  <div style="min-width: 100px;">{{ getReportPrice(props.row, pkg.key) }}</div>
                </b-table-column>
              </template>
            </template>
          </b-table>
        </div>
      </div>

    </div>
    <page-setting-modal ref="pageSettingModal" :disableDeletePage="true" :isAdminSetupTheGuard="true" />
  </section>
</template>

<script>
import { mixin } from '@/components/mixin'
import PageSettingModal from "@/components/PageSettingModal.vue";

import { mixin as exportmixin } from '@/components/export_mixin'
import { toLocalDateString } from '@/utils'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { PageSettingModal, DatePicker },
  mixins: [mixin, exportmixin],
  data() {
    return {
      dates: [moment().subtract(1, 'months').toDate(), moment().toDate()],
      totalPaid: null,
      packages: [],
      records: [],
      totalRows: 0,
      isLoading: false,
      sortField: 'delivery_period_starts_at',
      sortOrder: 'desc',
      defaultSortOrder: 'asc',
      currentPage: 1,
      perPage: 15,
      searchText: '',
    }
  },
  computed: {
    currentUser() {
      if (this.$store.state.user) {
        return this.$store.state.user.id
      }
      return {}
    }
  },
  methods: {
    exportRecords(records) {
      const rows = records.map((record) =>
        [
          record.userId,
          record.userEmail,
          record.id,
          record.price_on_checkout,
          moment(record.delivery_period_starts_at).format(),
          ...this.packages.map(pkg => ([
            this.getReportQuantity(record, pkg.key),
            this.getReportPrice(record, pkg.key),
          ]))
        ].join(',')
      );
      const data = [
        [
          'User ID', 'User Email', 'Silo Order ID', 'Silo Amount ($)', 'Silo Order Date',
          ...this.packages.map(pkg => ([
            this.getReportColumnLabel(pkg),
            '$ ' + this.getReportColumnLabel(pkg),
          ]))
        ].join(',')
      ]
        .concat(rows)
        .join('\n');
      const now = new Date();
      const fileName = "SiloOrders_" + now.getUTCFullYear() + "_" + (now.getUTCMonth() + 1) + "_" + now.getUTCDate() + ".csv"
      this.exportToCSV(data, fileName)
    },
    exportPage() {
      this.exportRecords(this.records)
    },
    exportAll() {
      this.$store.commit('showLoading')
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=1`,
        `perPage=${this.totalRows}`,
        `filterText=${this.searchText}`
      ].join('&')

      this.$store.dispatch('getSiloOrdersForAdmin', { params })
        .then(response => {
          this.exportRecords(response.data.records)
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    mainPageWordCount(pageRun) {
      const wordCount = pageRun.keywordCounts.rows.find(wc => wc.signal === 'Word Count')
      return wordCount.signalData.targetKeywordCnt
    },
    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr)
    },
    onPerPageChange() {
      this.$nextTick(() => this.loadData())
    },
    onPageChange(page) {
      this.currentPage = page
      this.loadData()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadData()
    },
    onFilter() {
      // console.log('filtering')
      this.$nextTick(() => {
        this.loadData()
      })
    },
    loadData() {
      this.isLoading = true
      this.$store.commit('showLoading')

      let startDate = this.dates[0]?moment(this.dates[0]).startOf('day').toDate():null;
      let endDate = this.dates[1]?moment(this.dates[1]).endOf('day').toDate():null;

      let params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`
      ]

      if (startDate) {
        params.push(`startDate=${startDate.getTime()}`)
      }
      if (endDate) {
        params.push(`endDate=${endDate.getTime()}`)
      }

      params = params.join('&')

      this.$store.dispatch('getAvailableSiloContentReportPackages')
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.packages = response.data.packages;
        }
      })
      .then(() => this.$store.dispatch('getSiloOrdersForAdmin', { params }))
        .then(({ data }) => {
          const { total, records, totalPaid } = data
          this.totalRows = total
          this.records = records
          this.totalPaid = totalPaid
          this.isLoading = false
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    changeSearchText() {
      console.log(this.searchText);
    },
    getReportPrice(row, report_key) {
      const checkout_item = (row?.items_on_checkout || []).filter(item => item.report_key === report_key)?.[0]
      if (checkout_item) {
        return `$${checkout_item.quantity * checkout_item.unit_amount}.00`
      }
      return ''
    },
    getReportQuantity(row, report_key) {
      const checkout_item = (row?.items_on_checkout || []).filter(item => item.report_key === report_key)?.[0]
      if (checkout_item) {
        return checkout_item.quantity
      }
      return ''
    },
    getReportColumnLabel(pkg) {
      const type_map = {
        'top_level': "Top-Level",
        'supporting': "Supporting"
      }
      return type_map[pkg.type] + ' Pages: ' + pkg.label
    }
  },
  beforeMount() {
    this.loadData()
  }
}
</script>

<style lang="scss">
.table-mobile-sort {
  display: none;
}
</style>
