<template>
<div>
  <div class="price-packages mt-10 mb-10">
    <div class="pa-5"
      v-for="(pkg, idx) in packages" :key="idx">

      <div class="package-image">
        <img :src="pkg.img" />
      </div>
      <div class="flex-grow-1">
        <div class="mt-3 secondary-label">
          {{ pkg.secondary_label }}
        </div>
        <div class="mt-2 package-label font-weight-bold">
          {{ pkg.label }}
        </div>

        <div class="mt-2 package-description">
          {{ pkg.full_description }}
        </div>
      </div>

      
      <div class="mt-7" style="display: flex; flex-direction: column; align-items: center; gap: 8px;">
        <v-btn block rounded
          @click="() => {
            showConfirmModalAction(pkg.key)
          }"
          color="profileAnchorColor"
          class="text-none whiteColor--text">
          <span>Buy for ${{ finalPrice(pkg.key) }}</span>
        </v-btn>
        <span
          v-if="appliedCoupons[pkg.key]"
          style="color: #0CB370;"
        ><i class="material-icons" style="vertical-align: sub;">check</i> Coupon applied ({{ appliedCoupons[pkg.key].coupon.couponType === 'AmountOff' ? '$':'' }}{{ appliedCoupons[pkg.key].coupon.amountOff }}{{ appliedCoupons[pkg.key].coupon.couponType === 'Percent Off' ? '%':'' }} off)</span>
        <a
          v-else
          href="#" @click.prevent="showCouponModalForPackage = pkg.key"
          style="color: var(--v-link15Color-base);"
        >Apply a coupon</a>
      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': Boolean(showCouponModalForPackage)}">
    <div class="modal-background" @click.stop="showCouponModalForPackage = null"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Apply coupon</p>
          <div class="mainColor--text" style="font-size: 1rem; line-height: 150%;">
            Insert your coupon here for a discount<br /><br />
            <v-text-field outlined dense
              v-model="modalCouponFieldValue"
              placeholder="Enter coupon"
              color="grayColor"
            ></v-text-field>
          </div>
          <div class="d-flex justify-end gap10 mt-5">
            <v-btn rounded outlined color="mainColor"
              class="text-none"
              @click.stop="() => {
                showCouponModalForPackage = null;
              }">
              <span>Cancel</span>
            </v-btn>
            <v-btn rounded color="profileAnchorColor"
              class="whiteColor--text text-none"
              :disabled="!modalCouponFieldValue"
              @click.stop="() => {
                applyCoupon(showCouponModalForPackage);
              }"
            >
              <span>Apply coupon</span>
            </v-btn>
            
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showCouponModalForPackage = null)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
  
  <div v-if="showConfirmModal" class="modal is-active">
    <div class="modal-background" @click.stop="showConfirmModal = false"></div>
    <div class="modal-content" style="max-width: 550px;">
      <div class="box modal-container" style="padding: 30px;">

        <p class="title-font is-size-4 mainColor--text mb-8">Order a content audit for your project</p>

        <div class="subtitle-font mainColor--text font-weight-bold mb-2">Project</div>

        <v-autocomplete
          class="mb-2"  
          dense
          outlined
          placeholder="Choose a project"
          v-model="selectedSiteId"
          :items="availableSites"
          item-text="name"
          item-value="id"
        ></v-autocomplete>

        <div class="text-right">
          <v-btn rounded outlined color="mainColor" class="button-with-sicon mainColor--text text-none"
            @click.stop="showConfirmModal = false;">
            Cancel
          </v-btn>
          <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
            :disabled="!selectedSiteId"
            @click.stop="() => confirmedGoProcess()">
            Order
          </v-btn>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="() => (showConfirmModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{ 'is-active': showAddonPurchaseSuccessModal }">
    <div class="modal-background" @click.stop="showAddonPurchaseSuccessModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <div class="subtitle-font mainColor--text">
            Thank you for your White Glove Addon purchase.
          </div>
          <div class="text-right">
            <v-btn rounded color="profileAnchorColor" class="button-with-sicon whiteColor--text text-none ml-2"
              @click.stop="() => showAddonPurchaseSuccessModal = false">
              Ok
            </v-btn>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="() => (showAddonPurchaseSuccessModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>  

</div>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.state.user;
    },
    availableSites() {
      return this.$store.getters.getSiteData
    },
  },
  data () {
    return {
      paymentMethod: '', // stripe / paypal
      showConfirmModal: false,
      isConfirmedByUser: false,

      showAddonPurchaseSuccessModal: false,

      selectedPackageKey: '',
      packages: [],

      selectedSiteId: null,

      showCouponModalForPackage: null,
      modalCouponFieldValue: '',
      appliedCoupons: {},
    }
  },
  watch: {
    showCouponModalForPackage: function(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.modalCouponFieldValue = ''
      }
    }
  },
  methods: {
    getPackage (key) {
      return this.packages.filter(p => p.key === key)[0];
    },
    showConfirmModalAction (packageName) {
      if (!packageName) return;
      this.selectedPackageKey = packageName;
      this.paymentMethod = 'stripe';
      this.showConfirmModal = true;
    },
    confirmedGoProcess() {
      this.showConfirmModal = false;
      this.isConfirmedByUser = true;
      if (this.paymentMethod == 'stripe') {
        this.submit();
        return;
      }
      // now modal is only for stripe, so following is not needed.
      // if (this.payment == 'paypal') {
      //   paypal.Button.click('#credit-purchase-paypal-button');
      //   return;
      // }
    },
    applyCoupon (packageName) {
      this.appliedCoupons = {
        ...this.appliedCoupons,
        [packageName]: null
      };
      this.$store.commit('showLoading')
      return this.$store.dispatch('fetchValidatedCoupons', { promoCode: this.modalCouponFieldValue.trim(), isRegisterPage: 0 })
        .then((response) => {
          if (!response.data) {
            this.$notify({
              group: 'info', type: 'warning',
              text: 'Invalid coupon'
            })
            return;
          }
          if (response.data && response.data.status == 'SUCCESS') {
            let allServiceCoupons = response.data.coupons
            if (allServiceCoupons.length == 1) {
              const coupon = allServiceCoupons[0];
              if (['Percent Off', 'Amount Off'].includes(coupon.couponType) && coupon.product === packageName) {
                this.appliedCoupons = {
                  ...this.appliedCoupons,
                  [packageName]: {
                    code: this.modalCouponFieldValue.trim(),
                    coupon
                  }
                };
                this.showCouponModalForPackage = null;
              } else {
                this.$notify({
                  group: 'info', type: 'warning',
                  text: 'Invalid coupon'
                })
              }
            } else {
              this.$notify({
                group: 'info', type: 'warning',
                text: 'Invalid coupon'
              })
            }
          } else {
            if (response.data.status == 'FAILURE') {
              this.$notify({
                group: 'info', type: 'warning',
                text: response.data.errorMsg ? response.data.errorMsg : 'Invalid coupon'
              })
            }
          }
        })
        .finally(() => {
          this.$store.commit('hideLoading')
        })
    },
    finalPrice(packageName) {
      let price = this.getPackage(packageName).price
      let amountOff = 0
      if (this.appliedCoupons[packageName]) {
        amountOff = this.appliedCoupons[packageName].coupon.amountOff
        if (this.appliedCoupons[packageName].coupon.couponType == 'Percent Off') {
          amountOff = (price * amountOff / 100)
        }
      }
      return (price - amountOff)
    },
    submit() {

      if (!this.isConfirmedByUser) { // to ask user once more about confirm purchase.
        this.showConfirmModal = true;
        return;
      }

      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'WHITE_GLOVE_ADDON_PURCHASE',
        selectedPackage: this.selectedPackageKey,
        userID: this.user.id,
        selectedSiteId: this.selectedSiteId,
        promoCode: this.appliedCoupons?.[this.selectedPackageKey]?.code
      })
      .then(function (response) {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_self')
        }
      })
      .finally(() => {
        this.$store.commit('showLoading');
      })
    },
  },
  mounted () {
    this.$store.commit('showLoading')
    this.$store.dispatch('getAvailableWhiteGloveAddonPackages')
    .then(response => {
      if (response.data && response.data.status == 'SUCCESS') {
        this.packages = response.data.packages;
        if (this.$route.query.addonPurchaseSuccess) {
          this.showAddonPurchaseSuccessModal = true
        }
      }
    })
    .finally(() => {
      this.$store.commit('hideLoading')
    })
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/ui-for-white-glove-addon-packages.scss";
</style>