export const dispNames = {
  'Key Highlights': 'Key Highlights',
  'Brief Overview': 'Brief Overview',
  'Bold Tag Total': 'Bold Tags',
  'Italic Tag Total': 'Italic Tags',
  'Image File total': 'Images',
  'list': 'Lists',
  'Table Total': 'Tables',
  'Form Total': 'Forms',
  'Anchor Text Tag Total': 'Links',
  'Frequently Asked Questions': 'Frequently Asked Questions',
  'Table of content': 'Table of content'
}

export const StopLightBGColorOptions = {
  red: {
    color: '#FF1F2D',
    order: 1
  },
  yellow: {
    color: '#FF9B2D',
    order: 2
  },
  purple: {
    color: '#B886FF',
    order: 3
  },
  green: {
    color: '#00CD49',
    order: 4
  },
}