<template>
  <section class="section">
      <nav>
        <div class="tabs is-centered">
          <div class="ml-auto mr-auto">
            <ul v-if="currentUser.isAdmin" style="padding-left: 0;">
              <li :class="{'is-active': activeTab === 'customerOverview'}"><a @click="showTab('customerOverview')">Customer Overview</a></li>
              <li :class="{'is-active': activeTab === 'auditOrdersTimeline'}"><a @click="showTab('auditOrdersTimeline')">Audit Orders Timeline</a></li>
              <li :class="{'is-active': activeTab === 'siloOrdersTimeline'}"><a @click="showTab('siloOrdersTimeline')">Silo Orders Timeline</a></li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <section v-if="activeTab === 'customerOverview'" class="section pt15">
          <customer-overview/>
        </section>
        <section v-if="activeTab === 'auditOrdersTimeline'" class="section pt15">
          <audit-orders-timeline/>
        </section>
        <section v-if="activeTab === 'siloOrdersTimeline'" class="section pt15">
          <silo-orders-timeline/>
        </section>
      </div>
  
  </section>
  </template>
  
  <script>
  import CustomerOverview from '@/components/Admin/WhiteGlove/CustomerOverview.vue'
  import AuditOrdersTimeline from '@/components/Admin/WhiteGlove/AuditOrdersTimeline.vue'
  import SiloOrdersTimeline from '@/components/Admin/WhiteGlove/SiloOrdersTimeline.vue'
  
  export default {
    components: {
      CustomerOverview,
      AuditOrdersTimeline,
      SiloOrdersTimeline,
    },
    data () {
      return {
        activeTab: 'customerOverview',
        ready: false
      }
    },
    methods: {
      showTab (tab) {
        this.activeTab = tab
      }
    },
    computed: {
      isLoading () {
        return this.$store.state.isLoading
      },
      currentUser() {
        if (this.$store.state.user) {
          return this.$store.state.user
        }
        return {}
      }
    },
    mounted () {
      
    }
  }
  </script>
  
  <style lang="scss">
  
  </style>