<template>
  <div class="wrapper">
    <div class="graph-margin-auto">
      <div id="graph-main" class="graph-main" ref="graph-main">
        <div class="side-column left-column" ref="left-column">
          <template v-for="keyword, idx in graphData.left">
            <div class="keyword-item supporting" :ref="'left' + idx" :key="idx + 'keyword'">
              <div class="keyword-item-tag">S</div>
              <div class="keyword">{{ keyword }}</div>
              <div class="keyword-type">supporting keyword</div>
            </div>
            <img
              v-if="graphData.center && idx < graphData.left.length - 1"
              :key="idx + 'double-arrows'" width="20" height="26" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMDA1ODkgMTIuNTE5MUw5LjY0MTM3IDkuNjg2MzZMNi4zNzA0MSA5LjY4NjM2TDguMDA1ODkgMTIuNTE5MVpNNy43MjI2MiAwLjE1NjM3Mkw3LjcyMjYyIDkuOTY5NjNMOC4yODkxNiA5Ljk2OTY0TDguMjg5MTYgMC4xNTYzNzJMNy43MjI2MiAwLjE1NjM3MloiIGZpbGw9IiNCMkIyQjIiLz4KPHBhdGggZD0iTTIuMzk0MTIgMC4xNTYzNzFMMC43NTg2NDUgMi45ODkxTDQuMDI5NiAyLjk4OTFMMi4zOTQxMiAwLjE1NjM3MVpNMi42NzczOSAxMi41MTkxTDIuNjc3MzkgMi43MDU4M0wyLjExMDg1IDIuNzA1ODNMMi4xMTA4NSAxMi41MTkxTDIuNjc3MzkgMTIuNTE5MVoiIGZpbGw9IiNCMkIyQjIiLz4KPC9zdmc+Cg=="
            />
          </template>
        </div>

        <svg class="left-arrows" width="100" :height="arrowsSVGHeight">
          <template v-if="graphData.center">
            <defs>
              <marker id="arrow" markerWidth="10" markerHeight="10" refX="8.7" refY="3" orient="auto" markerUnits="strokeWidth" fill="#B2B2B2">
                <path d="M0,0 L0,6 L9,3 z" />
              </marker>
              <marker id="arrow-start" markerWidth="10" markerHeight="10" refX="0.7" refY="3" orient="auto" markerUnits="strokeWidth" fill="#B2B2B2">
                <path d="M 9 0 L 9 6 L 0 3 z" />
              </marker>
            </defs>
            <template
              v-for="keyword, idx in graphData.left"
            >
              <line
                :key="idx"
                v-bind="{
                  x1: 0 + 5,
                  y1: arrowY1Map['left' + idx],
                  x2: 100 - 5,
                  y2: (
                    (arrowsSVGHeight / 2)       // height center
                    + ((idx - (graphData.left.length / 2)) * 4)   // + some 4px vertical spacing for arrow heads depending on idx
                  )
                }"
                stroke="#B2B2B2"
                marker-end="url(#arrow)"
              />
              <line
                v-if="idx === 0"
                :key="idx + 'extra-arrow'"
                v-bind="{
                  x1: 0 + 5,
                  y1: arrowY1Map['left' + idx] - 6,
                  x2: 100 - 5,
                  y2: (
                    (arrowsSVGHeight / 2)       // height center
                    + ((idx - (graphData.left.length / 2)) * 4)   // + some 4px vertical spacing for arrow heads depending on idx
                  ) - 6
                }"
                stroke="#B2B2B2"
                marker-start="url(#arrow-start)"
              />
            </template>
          </template>
        </svg>

        <div class="center-column" ref="center-column">
          <div v-if="graphData.center" class="keyword-item top-level" ref="center-item">
            <div class="keyword-item-tag">T</div>
            <div class="keyword">{{ graphData.center }}</div>
            <div class="keyword-type">top-level keyword</div>
          </div>
        </div>

        <svg class="right-arrows" width="100" :height="arrowsSVGHeight">
          <template v-if="graphData.center">
            <defs>
              <marker id="arrow" markerWidth="10" markerHeight="10" refX="8.7" refY="3" orient="auto" markerUnits="strokeWidth" fill="#B2B2B2">
                <path d="M0,0 L0,6 L9,3 z" />
              </marker>
            </defs>
            <line
              v-for="keyword, idx in graphData.right"
              :key="idx"
              v-bind="{
                x1: 100 - 5,
                y1: arrowY1Map['right' + idx],
                x2: 0 + 5,
                y2: (
                  (arrowsSVGHeight / 2)       // height center
                  + ((idx - (graphData.right.length / 2)) * 4)   // + some 4px vertical spacing for arrow heads depending on idx
                )
              }"
              stroke="#B2B2B2"
              marker-end="url(#arrow)"
            />
          </template>
        </svg>

        <div class="side-column right-column" ref="right-column">
          <template v-for="keyword, idx in graphData.right">
            <div class="keyword-item supporting" :ref="'right' + idx" :key="idx + 'keyword'">
              <div class="keyword-item-tag">S</div>
              <div class="keyword">{{ keyword }}</div>
              <div class="keyword-type">supporting keyword</div>
            </div>
            <img
              v-if="graphData.center && idx < graphData.right.length - 1"
              :key="idx + 'double-arrows'" width="20" height="26" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguMDA1ODkgMTIuNTE5MUw5LjY0MTM3IDkuNjg2MzZMNi4zNzA0MSA5LjY4NjM2TDguMDA1ODkgMTIuNTE5MVpNNy43MjI2MiAwLjE1NjM3Mkw3LjcyMjYyIDkuOTY5NjNMOC4yODkxNiA5Ljk2OTY0TDguMjg5MTYgMC4xNTYzNzJMNy43MjI2MiAwLjE1NjM3MloiIGZpbGw9IiNCMkIyQjIiLz4KPHBhdGggZD0iTTIuMzk0MTIgMC4xNTYzNzFMMC43NTg2NDUgMi45ODkxTDQuMDI5NiAyLjk4OTFMMi4zOTQxMiAwLjE1NjM3MVpNMi42NzczOSAxMi41MTkxTDIuNjc3MzkgMi43MDU4M0wyLjExMDg1IDIuNzA1ODNMMi4xMTA4NSAxMi41MTkxTDIuNjc3MzkgMTIuNTE5MVoiIGZpbGw9IiNCMkIyQjIiLz4KPC9zdmc+Cg=="
            />
          </template>
        </div>

        <div v-if="graphData?.right?.length" class="bottom-arrows">
          <svg :width="bottomArrowsSVGWidth" :height="20">
            <defs>
              <marker id="arrow" markerWidth="10" markerHeight="10" refX="8.7" refY="3" orient="auto" markerUnits="strokeWidth" fill="#B2B2B2">
                <path d="M0,0 L0,6 L9,3 z" />
              </marker>
            </defs>
            <line
              v-bind="{
                x1: 5,
                y1: 7,
                x2: bottomArrowsSVGWidth - 5,
                y2: 7
              }"
              stroke="#B2B2B2"
              stroke-width="1"
              marker-end="url(#arrow)"
            />
            <line
              v-bind="{
                x1: bottomArrowsSVGWidth - 5,
                y1: 13,
                x2: 5,
                y2: 13
              }"
              stroke="#B2B2B2"
              stroke-width="1"
              marker-end="url(#arrow)"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    graphData: {
      type: Object,
    }
  },
  methods: {
    calculateLayout() {
      if (this.$refs['graph-main'] && this.$refs['center-column']) {
        this.arrowsSVGHeight = this.$refs['graph-main'].scrollHeight
        this.bottomArrowsSVGWidth = this.$refs['center-column'].scrollWidth + 200;
        ['left', 'right'].forEach(side => {
          this.graphData[side].forEach((keyword, idx) => {
            const element = this.$refs[side + idx][0]
            const y1 = element.offsetTop + (element.scrollHeight / 2)
  
            const key = side + idx
  
            this.arrowY1Map = {
              ...this.arrowY1Map,
              [key]: y1
            }
          })
        })
      }
    },
  },
  data() {
    return {
      arrowsSVGHeight: 100,
      bottomArrowsSVGWidth: 300,
      arrowY1Map: {}
    }
  },
  mounted () {
    // wait for $refs to be populated
    this.$nextTick()
    .then(() => {
      this.calculateLayout()
    })
  }
}
</script>

<style lang="scss">
.wrapper {
  background: #fff;
}

.graph-margin-auto {
  margin: 0 auto;
  overflow-x: auto;
  width: 1080px;
  padding: 40px;
}

.graph-main {
  width: 1000px;

  background: #fff;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  position: relative;
  
  .side-column, .center-column {
    flex: 1;
  }

  .side-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    min-height: 160px;
  }

  .center-column {
    align-self: center;
  }

  .bottom-arrows {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .keyword-item {

    width: 100%;
    
    border-radius: 4px;
    padding: 10px 30px 10px 10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);

    position: relative;

    .keyword-item-tag {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 3px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }

    .keyword {
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 5px;
    }

    .keyword-type {
      font-size: 0.8rem;
    }

    &.supporting {
      background-color: #fff6fb;
      border: 1px solid #EF34A4;

      .keyword-item-tag {
        color: #EF34A4;
        background: #fff6fb;
        border: 1px solid #EF34A4;
      }

      .keyword {
        color: #014751;
      }

      .keyword-type {
        color: #014751;
      }
    }

    &.top-level {
      background-color: #f1fff3;
      border: 1px solid #007438;

      .keyword-item-tag {
        color: #007438;
        background: #D6FBEE;
        border: 1px solid #007438;
      }

      .keyword {
        color: #014751;
      }

      .keyword-type {
        color: #014751;
      }
    }
  }
}


</style>
