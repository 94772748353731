<template>
<section class="section">
    <nav>
      <div class="tabs is-centered">
        <div class="ml-auto mr-auto">
          <ul v-if="currentUser.isAdmin">
            <li :class="{'is-active': activeTab === 'userAccounts'}"><a @click="showTab('userAccounts')">User Accounts</a></li>
            <li :class="{'is-active': activeTab === 'history'}"><a @click="showTab('history')">History</a></li>
            <li :class="{'is-active': activeTab === 'keywordResearch'}"><a @click="showTab('keywordResearch')">Keyword Research</a></li>
            <li :class="{'is-active': activeTab === 'watchdogHistory'}"><a @click="showTab('watchdogHistory')">Watchdog History</a></li>
            <li :class="{'is-active': activeTab === 'watchdogTrack'}"><a @click="showTab('watchdogTrack')">Watchdog track</a></li>
            <li :class="{'is-active': activeTab === 'whiteGlove'}"><a @click="showTab('whiteGlove')">White Glove</a></li>
            <li :class="{'is-active': activeTab === 'aiRuns'}"><a @click="showTab('aiRuns')">AI Runs</a></li>
            <li :class="{'is-active': activeTab === 'apiUsage'}"><a @click="showTab('apiUsage')">Api Usage</a></li>
            <li :class="{'is-active': activeTab === 'coupons'}"><a @click="showTab('coupons')">Coupons</a></li>
            <li :class="{'is-active': activeTab === 'notifications'}"><a @click="showTab('notifications')">Notifications</a></li>
            <li :class="{'is-active': activeTab === 'salesMetrics'}"><a @click="showTab('salesMetrics')">Sales Metrics</a></li>
            <li :class="{'is-active': activeTab === 'usageMetrics'}"><a @click="showTab('usageMetrics')">Usage Metrics</a></li>
            <li :class="{'is-active': activeTab === 'comparisonMetrics'}"><a @click="showTab('comparisonMetrics')">Comparison Metrics</a></li>
            <li :class="{'is-active': activeTab === 'affiliateSales'}"><a @click="showTab('affiliateSales')">Affiliate Sales</a></li>
            <li :class="{'is-active': activeTab === 'affiliatePayouts'}"><a @click="showTab('affiliatePayouts')">Affiliate Payouts</a></li>
            <li :class="{'is-active': activeTab === 'paypalRefunds'}"><a @click="showTab('paypalRefunds')">PayPal Refunds</a></li>
          </ul>
        </div>
      </div>
    </nav>
    <div>
      <section v-if="activeTab === 'userAccounts'" class="section pt15">
        <user-accounts/>
      </section>
      <section v-if="activeTab === 'history'" class="section pt15">
        <history/>
      </section>
      <section v-if="activeTab === 'keywordResearch'" class="section pt15">
        <keyword-research/>
      </section>
      <section v-if="activeTab === 'coupons' && currentUser.isAdmin" class="section pt15">
        <coupons/>
      </section>
      <section v-if="activeTab == 'watchdogHistory'" class="section pt15">
        <watchdog-history/>
      </section>
      <section v-if="activeTab == 'watchdogTrack'" class="section pt15">
        <watchdog-track/>
      </section>
      <section v-if="activeTab == 'whiteGlove'" class="section pt15">
        <white-glove/>
      </section>
      <section v-if="activeTab == 'aiRuns'" class="section pt15">
        <a-i-writer-log-dashboard-for-admin />
      </section>
      <section v-if="activeTab === 'salesMetrics'" class="section pt15">
        <sales-metrics/>
      </section>
      <section v-if="activeTab === 'usageMetrics'" class="section pt15">
        <usage-metrics/>
      </section>
      <section v-if="activeTab === 'comparisonMetrics'" class="section pt15">
        <comparison-metrics/>
      </section>
      <section v-if="activeTab === 'notifications'" class="section pt15">
        <admin-notifications/>
      </section>
      <section v-if="activeTab === 'affiliateSales'" class="section pt15">
        <affiliate-sales/>
        <!-- <template v-if="currentUser.isAdmin">
          <affiliate-sales/>
        </template>
        <template v-else>
          <affiliate-sales-for-user />
        </template> -->
      </section>
      <section v-if="activeTab === 'affiliatePayouts'" class="section pt15">
        <affiliate-payouts/>
      </section>
      <section v-if="activeTab === 'paypalRefunds'" class="section pt15">
        <paypal-refunds/>
      </section>
      <section v-if="activeTab === 'apiUsage'" class="section pt15">
        <api-usage-dashboard
          :forAdmin="true"
        ></api-usage-dashboard>
      </section>
    </div>

</section>
</template>

<script>
import UserAccounts from '@/components/Admin/UserAccounts.vue'
import Coupons from '@/components/Admin/Coupons.vue'
import History from '@/components/Admin/History.vue'
import KeywordResearch from '@/components/Admin/KeywordResearch.vue'
import WatchdogHistory from '@/components/Admin/WatchdogHistory.vue'
import WatchdogTrack from '@/components/Admin/WatchdogTrack.vue'
import WhiteGlove from '@/components/Admin/WhiteGlove/Index.vue'
import SalesMetrics from '@/components/Admin/SalesMetrics.vue'
import UsageMetrics from '@/components/Admin/UsageMetrics.vue'
import ComparisonMetrics from '@/components/Admin/ComparisonMetrics.vue'
import Notifications from '@/components/Admin/Notifications.vue'
import AffiliateSales from '@/components/Admin/AffiliateSales.vue'
import AffiliatePayouts from '@/components/Admin/AffiliatePayouts.vue'
import PaypalRefunds from '@/components/Admin/PaypalRefunds.vue'
import ApiUsageDashboard from '@/components/ApiUsageDashboard.vue'
import AIWriterLogDashboardForAdmin from '@/components/Admin/AIWriterLogDashboardForAdmin.vue'

export default {
  components: {
    UserAccounts,
    Coupons,
    History,
    KeywordResearch,
    WatchdogHistory,
    WatchdogTrack,
    WhiteGlove,
    SalesMetrics,
    UsageMetrics,
    ComparisonMetrics,
    AffiliateSales,
    AffiliatePayouts,
    PaypalRefunds,
    AdminNotifications: Notifications,
    ApiUsageDashboard,
    AIWriterLogDashboardForAdmin
  },
  data () {
    return {
      activeTab: 'history',
      ready: false
    }
  },
  methods: {
    showTab (tab) {
      this.activeTab = tab
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.isLoading
    },
    currentUser() {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return {}
    }
  },
  beforeMount() {
    if (this.currentUser.isAffiliate) {
      this.activeTab = 'affiliateSales'
    }
  },
  mounted () {
    this.$store.commit('setHideSideBar', true)
  }
}
</script>

<style lang="scss">

</style>